import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 20px;
  width: 100%;
  position: relative;
  & > div {
    margin-bottom: 40px;
  }
`;
