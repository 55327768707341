import React, { useContext } from "react";
import { useTabsContext } from "@reach/tabs";
import ChartTypes from "./tabs/ChartTypes";
import Design from "./tabs/Design";
import Annotate from "./tabs/Annotate";
import Refine from "./tabs/Refine";
import {
  SlTab,
  SlTabPanel,
  SlTabPanels,
  SlTabsList,
  TabsBody,
  Wrapper,
} from "./style";
import { ChartContext } from "../../context/ChartContext";

interface CustomTabProps {
  index: number;
  hide?: boolean;
  [prop: string]: any;
}
function CustomTab({ index, hide = false, ...props }: CustomTabProps) {
  const { selectedIndex, focusedIndex } = useTabsContext();

  const indexToColorMap = {
    [selectedIndex]: "#CE375C",
    [focusedIndex]: "blue",
  };

  return (
    <SlTab
      $borderColor={indexToColorMap[index] ?? "#B9B7BD"}
      $color={selectedIndex === index ? "#CE375C" : "#222222"}
      $hide={hide}
      {...props}
    />
  );
}

const Settings = () => {
  const { chartSelected } = useContext(ChartContext);
  return (
    <Wrapper>
      <TabsBody>
        <SlTabsList>
          <CustomTab index={0}>Chart type</CustomTab>
          <CustomTab
            index={1}
            hide={["dtList", "list", "star"].includes(chartSelected)}
          >
            Refine
          </CustomTab>
          <CustomTab
            index={2}
            hide={["dtList", "list"].includes(chartSelected)}
          >
            Design
          </CustomTab>
          <CustomTab index={3}>Annotate</CustomTab>
        </SlTabsList>

        <SlTabPanels>
          <SlTabPanel>
            <ChartTypes />
          </SlTabPanel>
          <SlTabPanel>
            <Refine />
          </SlTabPanel>
          <SlTabPanel>
            <Design />
          </SlTabPanel>
          <SlTabPanel>
            <Annotate />
          </SlTabPanel>
        </SlTabPanels>
      </TabsBody>
    </Wrapper>
  );
};

export default Settings;
