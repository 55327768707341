import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-top: 40px;
  width: 100%;
`;

export const EmptyWrapperBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  width: min(90%, 760px);
  padding: 40px 12%;
`;

export const EmptyWrapperTitle = styled.p`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
  color: var(--primary-text-black);
`;

export const EmptyWrapperSubTitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: var(--primary-text-black);
  //color: #6d7175;
  margin-bottom: 30px;
`;

export const EmptyWrapperButton = styled.button`
  width: 135px;
  height: 40px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-text-black);
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
`;
