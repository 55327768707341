import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import type {
  DesignState,
  SelectedSection,
  SurveySettings,
  SurveyStatus,
} from "./types";
import {
  Alignment,
  ConfigType,
  DisplayType,
  SectionOrder,
  SelectedQuestion,
} from "./types";
import cloneDeep from "lodash/cloneDeep";

const initialState: DesignState = {
  status: "idle",
  display: null,
  name: "",
  instructions: "",
  surveyStatus: "DRAFT",
  alignment: "LEFT",
  sectionOrders: [],
  selectedQuestion: null,
  selectedSection: null,
  questionsById: null,
  configType: null,
  sectionsList: [],
  questionsList: [],
  chartSettingsList: [],
  chartSettingsById: null,
  isNewSection: false,
  isNewQuestion: false,
  surveySettings: null,
};

export const incrementAsync = createAsyncThunk(
  "design/fetchCount",
  async (amount: number) => {
    // const response = await fetchCount(amount);
    // The value we return becomes the `fulfilled` action payload
    // return response.data;
    return null;
  }
);

const removeNewQuestionAndSection = ({ sectionOrders, questionsList }) => {
  // remove any new section
  const newSectionOrders = cloneDeep(sectionOrders);
  const sectionOrdersWithoutNew = newSectionOrders.filter(
    (sect) => sect.sectionId !== null
  );
  // remove any new question
  const newQuestionList = cloneDeep(questionsList);
  newQuestionList.filter((ques) => ques.questionId !== null);

  // replace orders questions list
  const lastOrder = sectionOrdersWithoutNew[sectionOrdersWithoutNew.length - 1];
  const lastOrderQuestions = lastOrder.questions;
  const newLastOrderQues = lastOrderQuestions.filter((ques) => ques !== null);
  newSectionOrders[newSectionOrders.length - 1].questions = newLastOrderQues;
  return {
    questionsList: newQuestionList,
    sectionOrders: sectionOrdersWithoutNew,
  };
};

export const designSlice = createSlice({
  name: "design",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setConfigurationType: (state, action: PayloadAction<ConfigType>) => {
      state.configType = action.payload;
    },
    setDisplayOptions: (state, action: PayloadAction<DisplayType>) => {
      state.display = action.payload;
    },
    setSectionOrders: (state, action: PayloadAction<SectionOrder[]>) => {
      state.sectionOrders = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setInstructions: (state, action: PayloadAction<string>) => {
      state.instructions = action.payload;
    },
    setSurveyStatus: (state, action: PayloadAction<SurveyStatus>) => {
      state.surveyStatus = action.payload;
    },
    setAlignment: (state, action: PayloadAction<Alignment>) => {
      state.alignment = action.payload;
    },
    setSurveySettings: (state, action: PayloadAction<SurveySettings>) => {
      state.surveySettings = action.payload;
    },
    setSelectedQuestion: (state, action: PayloadAction<SelectedQuestion>) => {
      state.selectedQuestion = action.payload;
      if (action.payload?.questionId || state.isNewSection) {
        // if not selecting the new question(it's id = null)
        const newResult = removeNewQuestionAndSection({
          sectionOrders: state.sectionOrders,
          questionsList: state.questionsList,
        });
        state.sectionOrders = newResult.sectionOrders;
        state.questionsList = newResult.questionsList;
        state.isNewSection = false;
      }
    },
    setSelectedSection: (state, action: PayloadAction<SelectedSection>) => {
      state.selectedSection = action.payload;

      if (action.payload?.sectionId || state.isNewQuestion) {
        // if not selecting the new section(it's id = null)
        const newResult = removeNewQuestionAndSection({
          sectionOrders: state.sectionOrders,
          questionsList: state.questionsList,
        });
        state.sectionOrders = newResult.sectionOrders;
        state.questionsList = newResult.questionsList;
        state.isNewQuestion = false;
      }
    },
    setSectionsList: (state, action: PayloadAction<SelectedQuestion[]>) => {
      state.sectionsList = action.payload;
    },

    setNewSection: (state, action) => {
      // const newSectionOrders = cloneDeep(state.sectionOrders);

      state.isNewSection = true;
      // To prevent adding sections without saving
      const newResult = removeNewQuestionAndSection({
        sectionOrders: state.sectionOrders,
        questionsList: state.questionsList,
      });
      const newSectionOrders = newResult.sectionOrders;
      state.questionsList = newResult.questionsList;

      const newSectionOrder = {
        questions: [],
        questionsOrder: [],
        sectionId: null,
        sortIndex: newSectionOrders.length - 1,
      };
      newSectionOrders.push(newSectionOrder);
      state.sectionOrders = newSectionOrders;
    },
    setQuestionsList: (state, action: PayloadAction<SelectedQuestion[]>) => {
      state.questionsList = action.payload;

      const newQuestionsById = {};
      action.payload.forEach((question) => {
        newQuestionsById[question.questionId] = question;
      });
      state.questionsById = newQuestionsById;
    },
    setChartSettingsList: (state, action: PayloadAction<any[]>) => {
      state.chartSettingsList = action.payload;

      const newChartSettingsById = {};
      action.payload.forEach((chartSetting) => {
        newChartSettingsById[chartSetting.configId] = chartSetting;
      });
      state.chartSettingsById = newChartSettingsById;
    },

    setNewQuestion: (state, action) => {
      const { payload } = action;
      state.isNewQuestion = true;
      state.questionsById[payload.questionId] = payload;
      const newQuestionsList = [...state.questionsList];

      // remove old question
      const oldQuestionIndex = state.questionsList.findIndex(
        (que) => que.questionId === null
      );
      // if old question exists and the new question has id
      if (oldQuestionIndex !== -1 && payload.questionId) {
        newQuestionsList.splice(oldQuestionIndex, 1);
        state.questionsList = newQuestionsList;
      }

      // add or replace new question
      const newQuestionIndex = state.questionsList.findIndex(
        (que) => que.questionId === payload.questionId
      );
      if (newQuestionIndex !== -1) {
        newQuestionsList.splice(newQuestionIndex, 1, payload);
        state.questionsList = newQuestionsList;
      } else {
        state.questionsList.push(payload);
      }
      state.selectedQuestion = payload;

      const newSecOrders = [...state.sectionOrders];
      const lastOrder = newSecOrders[newSecOrders.length - 1];

      // check if new order exists
      if (lastOrder.questions[lastOrder.questions.length - 1] !== null) {
        // add new question to last element
        lastOrder.questions.push(null);

        // replace last element
        newSecOrders.splice(newSecOrders.length - 1, 1, lastOrder);
        state.sectionOrders = newSecOrders;
      }
    },

    setUpdatedQuestion: (state, action) => {
      const { payload } = action;
      const { questionId, updatedQues } = payload;
      state.selectedQuestion = updatedQues;
      state.questionsById[questionId] = {
        ...state.questionsById[questionId],
        ...updatedQues,
      };

      const questionIndex = state.questionsList.findIndex(
        (question) => question.questionId === payload.questionId
      );
      // let question = state.questionsList.find(question => question.questionId === action.payload.questionId)
      if (questionIndex !== -1) {
        const quesListCopy = [...state.questionsList];
        quesListCopy[questionIndex] = {
          ...state.questionsById[questionId],
          ...updatedQues,
          questionId: payload.questionId,
        };
        state.questionsList = quesListCopy;
      }
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(incrementAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(incrementAsync.fulfilled, (state, action) => {
        state.status = "idle";
        // state.value += action.payload;
      })
      .addCase(incrementAsync.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const {
  setDisplayOptions,
  setInstructions,
  setName,
  setSectionOrders,
  setSurveyStatus,
  setAlignment,
  setQuestionsList,
  setChartSettingsList,
  setSelectedQuestion,
  setSelectedSection,
  setNewQuestion,
  setUpdatedQuestion,
  setSectionsList,
  setConfigurationType,
  setNewSection,
  setSurveySettings,
} = designSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.design.value)`

export default designSlice.reducer;
