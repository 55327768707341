import React, { useState } from "react";
import { Wrapper } from "./style";
import Options from "./components/Options";
import Body from "./components/Body";

const BuildSurvey = () => {
  const [isShowingChart, setIsShowingChart] = useState(false);

  const onToggleEditChart = () => {
    setIsShowingChart(!isShowingChart);
  };

  return (
    <Wrapper>
      <Options />
      <Body />
    </Wrapper>
  );
};

export default BuildSurvey;
