import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Text = styled.p`
  margin-bottom: unset;
  text-align: left;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #c4c4c4;
`;

export const AnswerBox = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  padding-left: 5px;
`;
