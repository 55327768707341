import React, { useContext, useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChartContext } from "../../../context/ChartContext";
import type { SingleChoiceChartData } from "../../../context/types";

function PieChart({ theRef }: { theRef: any }) {
  const {
    loading,
    headerText,
    resultData: chartData,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    legendLayout,
    dataLabelsVisible,
    selectedColorList,
    donutInnerRadius,
    chartSelected,
    countPercentageFormat,
    dataLabelsPosition,
    headerTextAlignment,
  } = useContext(ChartContext);

  const [cData, setCData] = useState([]);
  const [innerRadius, setInnerRadius] = useState(
    donutInnerRadius ? donutInnerRadius.toString() : "0%"
  );

  const options = useMemo(() => {
    if (!loading) {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
          style: {
            fontFamily: "Mulish",
          },
        },
        colors: selectedColorList,
        title: {
          text: headerText,
          align: headerTextAlignment,
        },
        tooltip: {
          pointFormat:
            countPercentageFormat === "percentage"
              ? "<b>{point.name}</b>: {point.percentage:.1f} %"
              : "<b>{point.name}</b>: {point.y}",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: dataLabelsVisible,
              format:
                countPercentageFormat === "percentage"
                  ? "{point.name}:<br/> <b>{point.percentage:.1f} %</b>"
                  : "{point.name}:<br/> <b>{point.y}</b>",
              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                if (this.y > 0) return this.y;
                return null;
              },
              // inside: true,
              // connectorColor: "black",
              distance: dataLabelsPosition === "outside" ? 30 : -50,
            },
            innerSize: innerRadius,
            showInLegend: legendVisible,
          },
        },
        series: [
          {
            // name: 'Brands',
            colorByPoint: true,
            data: cData,
          },
        ],
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: legendHorPosition,
          verticalAlign: legendVerPosition,
          layout: legendLayout,
          enabled: legendVisible,
          symbolRadius: legendSymbolShape === "square" ? 0 : null,
        },
      };
    }
    return {};
  }, [
    loading,
    legendLayout,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    dataLabelsVisible,
    chartData,
    headerText,
    selectedColorList,
    innerRadius,
    countPercentageFormat,
    cData,
    dataLabelsPosition,
  ]);

  useEffect(() => {
    const formattedData = chartData.map((da: SingleChoiceChartData) => ({
      id: da.key,
      name: da.value,
      y: da.responses,
    }));
    setCData(formattedData);
  }, [chartData]);

  useEffect(() => {
    if (chartSelected === "donut") {
      setInnerRadius(`${donutInnerRadius * 100}%`);
    } else if (chartSelected === "pie") {
      setInnerRadius("0%");
    }
  }, [donutInnerRadius, chartSelected]);

  return (
    <>
      <HighchartsReact ref={theRef} highcharts={Highcharts} options={options} />
    </>
  );
}

export default PieChart;
