import React from "react";
import Portal from "@reach/portal";
import loader from "../../../assets/images/loader.png";
import { Wrapper } from "./style";

const Loader = ({ isLoading = false }: { isLoading: boolean }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <Portal>
      <Wrapper>
        <img src={loader} alt="loader" />
      </Wrapper>
    </Portal>
  );
};

export default Loader;
