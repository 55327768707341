import styled from "styled-components";

export const CardContainer = styled.button`
  text-align: center;
  width: 100%;
  /* max-width: 350px; */
  border-radius: 10px;
  border: none;
  background-color: white;
  color: #222222;
  padding: 0px;
  margin-right: 15px;
`;

export const Card = styled.div`
  border-radius: 10px;
  border: none;
  box-shadow: 10px 10px 30px rgba(174, 174, 192, 0.2);
  padding-bottom: 10px;
  width: 100%;
`;

export const CardImg = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 160px;
  object-fit: cover;
  width: 100%;
`;

export const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 20px 12px 10px;
  min-height: 168px;
`;

export const CardTitle = styled.h3`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #222222;
`;

export const CardContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #4f4f4f;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
