import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  & > div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow-y: auto;
  }
`;

export const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 3rem 5%;
`;
