import jwt_decode from "jwt-decode";

import { isJwtPayload } from "./types";
/**
 * @desc verifies token
 * @param {string} token jwt token
 * @returns {object} object
 */
const checkAuth = (token: string) => {
  if (!token) {
    throw Error("no token");
  }
  const decoded = jwt_decode(token);
  if (decoded && isJwtPayload(decoded)) {
    if (decoded.exp && decoded.exp > Date.now() / 1000) {
      return { userId: decoded.user_id };
    }
    throw Error("expired token");
  }
};

export default checkAuth;
