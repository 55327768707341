import React, { useState } from "react";
import { Body, Container, Left, Right, Wrapper } from "./style";
import Elements from "./components/Elements";
import Themes from "./components/Themes";
import EditProvider from "./context/EditContext";
import { useAppSelector } from "../../app/hooks";
import OnboardingScreen from "./IntroScreen/Screens";

const EditSurvey = () => {
  const { display } = useAppSelector((state) => state.design);
  const [visible, setVisible] = useState(true);

  return (
    <Wrapper>
      <EditProvider displayOptions={display}>
        <Container>
          <Left>
            <Elements />
          </Left>
          <Body>
            {/*<Elements />*/}
            <OnboardingScreen visible={visible} setVisible={setVisible} />
          </Body>
          <Right>
            <Themes />
          </Right>
        </Container>
      </EditProvider>
    </Wrapper>
  );
};

export default EditSurvey;
