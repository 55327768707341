import styled from "styled-components";
import { Select } from "antd";

export const Wrapper = styled.div`
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
`;

export const QaWrapper = styled.div`
  padding: 20px 14px;
  background-color: #fafafa;
`;

export const Title = styled.p`
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const QaBody = styled.div`
  padding: 20px 0;
`;

export const QaItem = styled.div`
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 15px;
  }
  & > div:first-child {
    margin-right: 10px;
  }
  & > div:last-child {
    margin-left: 10px;
  }
`;

export const FilterSelect = styled(Select)`
  height: 36px;
  width: ${(props: { $width?: string }) => props.$width || "150px"};

  & > div[class="ant-select-selector"] {
    padding: 0 5px !important;
    height: 100% !important;
    border: 1px solid #000000 !important;
  }

  & span[class="ant-select-arrow"] {
    width: unset !important;
    color: #000000 !important;
  }
`;

// export const FilterInput = styled(Input)`
//   background: #ffffff;
//   height: 36px;
//   border: 1px solid rgba(0, 0, 0, 0.15);
//   box-sizing: border-box;
//   border-radius: 6px;
// `;

export const AddButton = styled.button`
  //padding: 8px 14px;
  border: unset;
  //border-top: 1px solid #d0d5dd;
  //border-bottom: 1px solid #d0d5dd;
  font-size: 20px;
  //font-weight: bold;
  cursor: pointer;
  background-color: #ffffff;
  color: #000000;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
`;

export const FilterItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const FilterRemoveButton = styled.button`
  //padding: 5px;
  border: unset;
  //border: 1px solid #000000;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  margin-left: 10px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
