import styled from "styled-components";

export const ExportButtonWrapper = styled.button`
  background: #008060;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  color: #ffffff;
  border: unset;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
