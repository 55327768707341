import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { Steps } from "intro.js-react";
import { NewSectionButton, NewSectionDiv, Wrapper } from "./style";
import PageTitle from "./PageTitle";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import QuestionList from "../SurveyDisplay/components/QuestionList";
import {
  setConfigurationType,
  setSelectedQuestion,
  setSelectedSection,
  setNewSection,
} from "../../../../pages/Design/designSlice";
import { useCookies } from "react-cookie";

const Page = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [sectList, setSectList] = useState([]);
  const [stepsEnabled, setStepsEnabled] = useState(true);
  const [cookies, setCookie] = useCookies(["question-intro"]);

  const steps = [
    {
      element: '[data-tab="logic"]',
      intro:
        "The toolbar manages your survey’s design options, security settings, and logic structures (i.e., Survey Flow).",
      position: "right",
    },
    {
      element: '[data-panel="questions"]',
      intro:
        "Use the question editing pane to choose among more than a dozen question types (e.g., Multiple Choice, Rank Order, Heat Map, NPS, Graphic Slider, among many others).",
      position: "right",
    },
  ];

  const dispatch = useAppDispatch();

  const { sectionOrders, sectionsList } = useAppSelector(
    (state) => state.design
  );
  const getSection = (sectionId) =>
    sectList.find((sect) => sect.sectionId === sectionId);

  const getSectionTitle = (sectionId) => {
    return getSection(sectionId)?.title;
  };

  const getSectionDescription = (sectionId) => {
    return getSection(sectionId)?.description;
  };

  const onClickNewPage = () => {
    dispatch(
      setSelectedSection({
        sectionId: null,
        title: "New page",
        description: "",
      })
    );
    dispatch(setSelectedQuestion(null));
    dispatch(setConfigurationType("PAGE"));
    dispatch(setNewSection({}));
  };

  useEffect(() => {
    if (sectionOrders.length !== 0) {
      const sectionKeys = sectionOrders.map((sect) => sect.sectionId).sort();
      const sectionsListKeys = sectionsList
        .map((sect) => sect.sectionId)
        .sort();

      // Sorting because isEqual doesnt compare out of order arrays
      const sectionKeysEqualsSectionListKeys = isEqual(
        sectionKeys,
        sectionsListKeys
      );
      setIsVisible(sectionKeysEqualsSectionListKeys);

      setSectList(sectionsList);
    }
  }, [sectionOrders, sectionsList]);
  return (
    <Wrapper>
      {!cookies["question-intro"] && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={() => setStepsEnabled(false)}
          onComplete={() =>
            setCookie("question-intro", "question-intro", { path: "/" })
          }
          options={{
            showStepNumbers: true,
            exitOnEsc: true,
            disableInteraction: true,
            doneLabel: "Done",
            autoPosition: true,
          }}
        />
      )}
      {sectionOrders.map((sectOrderObj, index) => (
        <div key={sectOrderObj.sectionId}>
          <PageTitle
            sectionId={sectOrderObj.sectionId}
            title={getSectionTitle(sectOrderObj.sectionId)}
            description={getSectionDescription(sectOrderObj.sectionId)}
          />
          <QuestionList questionKeys={sectOrderObj.questions} />
        </div>
      ))}
      <NewSectionDiv>
        <NewSectionButton
          type="button"
          onClick={onClickNewPage}
          disabled={
            sectionOrders[sectionOrders.length - 1]?.questions?.length === 0
          }
        >
          Add new page
        </NewSectionButton>
      </NewSectionDiv>
    </Wrapper>
  );
};

export default Page;
