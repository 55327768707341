import React from "react";
import {
  Action,
  Add,
  Condition,
  Destination,
  Label,
  Options,
  // SelectDropdown,
  Wrapper,
} from "./style";
import OptionsDropdown from "../OptionsDropdown";
// import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/down-arrow.svg";
import CustomSelect from "../../../../../common/CustomSelect";

interface Props {
  isLast?: boolean;
}

const Row = ({ isLast = false }: Props) => (
  <Wrapper>
    {isLast && <Add></Add>}
    <Condition>
      <Label>Answer</Label>
      <CustomSelect
        options={[
          { value: "one_of", label: "Is one of" },
          { value: "is", label: "Is" },
        ]}
        defaultValue={"is"}
      />
    </Condition>
    <Options>
      <OptionsDropdown options={[]} />
    </Options>
    <Action>
      <Label>Action</Label>
      <CustomSelect
        options={[
          { value: "one_of", label: "Is one of" },
          { value: "is", label: "Is" },
        ]}
      />
    </Action>
    <Destination>
      <Label>Destination</Label>
      <CustomSelect
        options={[
          { value: "one_of", label: "Is one of" },
          { value: "is", label: "Is" },
        ]}
      />
    </Destination>
  </Wrapper>
);

export default Row;
