import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const SwatchDiv = styled.div`
  padding: 1px;
  background: #fff;
  border: 1px solid #000000;
  border-radius: 10px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;

export const ColorDiv = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background: ${(props: { $backgroundColor: string }) =>
    props.$backgroundColor};
`;

export const CoverDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PopoverDiv = styled.div`
  position: absolute;
  z-index: 2;
`;
