import { Dropdown, Menu } from "antd";
import React, { useContext } from "react";
import domtoimage from "dom-to-image";
import { saveAs } from "file-saver";
import Highcharts from "highcharts";
import HC_Exporting from "highcharts/modules/exporting";
import HC_ExportingOffline from "highcharts/modules/offline-exporting";
// import HC_ExportData from 'highcharts/modules/export-data';
// import HC_More from 'highcharts/highcharts-more';
import { ExportButtonWrapper } from "./style";
import { ChartContext } from "../../../../components/Analytics/context/ChartContext";

HC_Exporting(Highcharts);
HC_ExportingOffline(Highcharts);
// HC_ExportData(Highcharts);
// HC_More(Highcharts);
// require("highcharts/modules/exporting")(Highcharts);
// require("highcharts/modules/offline-exporting")(Highcharts);

interface ExportButtonT {
  chartComponent: any;
}

const ExportButton = ({ chartComponent }: ExportButtonT) => {
  const { onAddToDashboard, chartSelected } = useContext(ChartContext);

  const customExport = ["list", "dtList", "star", "image"].includes(
    chartSelected
  );

  const downloadPNG = () => {
    if (customExport && chartComponent.current) {
      domtoimage.toBlob(chartComponent.current).then((blob) => {
        saveAs(blob, "my-node.png");
      });
    } else if (chartComponent && chartComponent?.current?.chart) {
      chartComponent.current.chart.exportChartLocal({
        // sourceWidth: 800,
        scale: 3,
      });
    }
  };

  const downloadPDF = () => {
    if (chartComponent && chartComponent?.current?.chart) {
      chartComponent.current.chart.exportChartLocal({
        type: "application/pdf",
        filename: "my-pdf",
        // sourceWidth: 800,
        scale: 3,
      });
    }
  };
  const menu = (
    <Menu>
      <Menu.Item key="3" onClick={downloadPNG}>
        <p>Download PNG image</p>
      </Menu.Item>
      <Menu.Item key="6" disabled={!!customExport} onClick={downloadPDF}>
        <p>Download PDF</p>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} className="download" placement="bottomRight" arrow>
      <ExportButtonWrapper type="button" onClick={onAddToDashboard}>
        <span>Export</span>
      </ExportButtonWrapper>
    </Dropdown>
  );
};

export default ExportButton;
