import React, { useState } from "react";
import { Content, Wrapper, Title, Subtitle, Button } from "./style";
import emptyImage from "../../../Build/icons/empty-survey.png";
import Templates from "./components/Templates";
import { Modal } from "antd";

const EmptyPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <Wrapper>
      <Content>
        <img src={emptyImage} alt="empty survey image" />
        <Title>Add your first question</Title>
        <Subtitle>
          Save time on questionnaire design and get started with an
          expert-designed survey template to get the information you’ll need to
          make the right decision.
        </Subtitle>
        <Button onClick={showModal}>Show me the templates</Button>
      </Content>

      <Modal
        title=""
        width={600}
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        closable={false}
      >
        <Templates onClose={handleCancel} />
      </Modal>
    </Wrapper>
  );
};

export default EmptyPage;
