import React, { createContext, ReactNode, useEffect, useMemo } from "react";
import { ConfigurationState, Dispatch } from "./types";
import configurationReducer from "./reducer";

const initialState: ConfigurationState = {
  questionId: undefined,
  questionText: "",
  questionType: undefined,
  isRequired: false,
  requiredMessage: "This question requires an answer.",
  hasLogic: false,
  hasRandomizedChoices: false,
  rowOptions: [
    { index: 0, value: "" },
    { index: 1, value: "" },
  ],
  colOptions: [
    { index: 0, value: "", weight: 1 },
    { index: 1, value: "", weight: 2 },
  ],
  sliderOptions: {
    labels: {
      left: "0",
      center: "",
      right: "100",
    },
    scale: {
      min: 0,
      max: 100,
      step: 1,
    },
    showAdjustScale: false,
    showInput: true,
    startPosition: "LEFT",
  },
  dateOptions: {
    hasDateInfo: true,
    hasTimeInfo: false,
    dateFormat: "DMY",
    dateRowOptions: [{ index: 0, value: "" }],
  },
  starRatingOptions: {
    starRatingColor: "#ce375c",
    // starRatingColor: "#f5a623",
    starRatingCount: 5,
    starRatingShape: "Star",
    labels: [
      { index: 0, value: "" },
      { index: 1, value: "" },
      { index: 2, value: "" },
      { index: 3, value: "" },
      { index: 4, value: "" },
    ],
    hasLabels: false,
  },
  imageOptions: {
    isCheckbox: false,
    imageRowOptions: [
      { index: 0, value: "", url: "" },
      { index: 1, value: "", url: "" },
    ],
  },
  matrixScaleOptions: {
    isMatrixRatingWeighted: false,
    allowsMultiResponsesPerRow: false,
  },
  checkboxOptions: { choicesType: undefined, choicesNumber: undefined },
};

const ConfigurationContext = createContext<
  | {
      state: ConfigurationState;
      dispatch: Dispatch;
      // updateToken: (x: string) => void;
    }
  | undefined
>(undefined);

const ConfigurationProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = React.useReducer(
    configurationReducer,
    initialState
  );

  const updateToken = (token: string) => {
    // if (token) {
    // }
  };
  useEffect(() => {
    // on initial load, get data from storage
    const configurationLocalStoredDataString = localStorage.getItem("u-1-a");
    if (configurationLocalStoredDataString) {
      const configurationLocalStoredData = JSON.parse(
        configurationLocalStoredDataString
      );
      dispatch({ type: "initialise", payload: configurationLocalStoredData });
    }
  }, []);

  useEffect(() => {
    const stringData = JSON.stringify(state);
    localStorage.setItem("u-1-a", stringData);
  }, [state]);

  const value = useMemo(() => ({ state, dispatch, updateToken }), [state]);
  return (
    <ConfigurationContext.Provider value={value}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;

export const useConfiguration = () => {
  const context = React.useContext(ConfigurationContext);
  if (context === undefined) {
    throw new Error("useConfiguration must be used within a CountProvider");
  }
  return context;
};
