import React, { useState } from "react";
import { Wrapper } from "./style";
import Logos from "../../features/library/components/Logos";
import Colors from "../../features/library/components/Colors";
import OnboardingScreen from "./IntroScreen";

const Library = () => {
  const [visible, setVisible] = useState(true);

  return (
    <Wrapper>
      <OnboardingScreen visible={visible} setVisible={setVisible} />
      <Logos />
      <Colors />
    </Wrapper>
  );
};

export default Library;
