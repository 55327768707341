import React from "react";
import { Wrapper } from "./style";
import EmailList from "../EmailList";
import EmptyMail from "../EmptyMail";
import type {
  CollectorType,
  ResponsesByCollectors,
} from "../../../../../pages/CollectResponses/types";

const EmailTab = ({
  collectors = [],
  responsesByCollectors,
}: {
  collectors: CollectorType[];
  responsesByCollectors: ResponsesByCollectors;
}) => {
  return (
    <Wrapper>
      {collectors.length > 0 ? (
        <EmailList
          collectors={collectors}
          responsesByCollectors={responsesByCollectors}
        />
      ) : (
        <EmptyMail />
      )}
    </Wrapper>
  );
};

export default EmailTab;
