import React from "react";
import {
  EmptyWrapper,
  EmptyWrapperBody,
  EmptyWrapperButton,
  EmptyWrapperSubTitle,
  EmptyWrapperTitle,
} from "./style";
import { ReactComponent as EmptyImage } from "../../assets/illustration.svg";

const NoResponses = () => {
  return (
    <EmptyWrapper>
      <EmptyWrapperBody>
        <EmptyImage />
        <EmptyWrapperTitle>No survey responses yet</EmptyWrapperTitle>
        <EmptyWrapperSubTitle>
          You can add a collector by using the survey links, social media, or
          email. You can also kickstart an Audience campaign to start collecting
          responses.
        </EmptyWrapperSubTitle>
        {/*<EmptyWrapperButton>Copy survey link</EmptyWrapperButton>*/}
      </EmptyWrapperBody>
    </EmptyWrapper>
  );
};

export default NoResponses;
