import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import IntroModal from "../IntroModal";
import SlideFunction from "../SlideFunction";
import { Wrapper, OutlinedButton, ColoredButton } from "./styles";

const OnboardingScreen: FC<{
  setNextIntro?: Dispatch<SetStateAction<boolean>>;
}> = ({ setNextIntro }) => {
  const [visible, setVisible] = useState(true);
  const [cookies, setCookie] = useCookies(["dashboardIntro"]);

  const { handleCountAndSlide, count, onboardingView } = SlideFunction({
    setVisible,
    setCookie,
    cookieName: "dashboardIntro",
    cookieValue: "dashboard-intro",
  });

  return (
    <>
      {!cookies.dashboardIntro && (
        <Modal
          visible={visible}
          centered
          closable={false}
          footer={false}
          bodyStyle={{
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Wrapper ref={onboardingView}>
            <IntroModal
              title="Welcome to Surveylake!"
              content="Create, distribute and analyze surveys in minutes."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="DANGER"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="DANGER"
              icon="https://assets.surveylake.com/macbook.svg"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Create your way"
              content="SurveyLake makes it easy to create a survey from scratch or from a template using our extensive library of properly curated survey templates."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="INFO"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="INFO"
              icon="https://assets.surveylake.com/magnify.svg"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Real-time and feature rich analytics"
              content="View survey responses in real-time and start analyzing using extensive features such as grouping, filter options, annotations and more!"
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="SUCCESS"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="SUCCESS"
              icon="https://assets.surveylake.com/desktop.svg"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Insights in minutes"
              content="Unlock important insghts that introduces and validates new ideas and drives actions."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="WARNING"
                  type="button"
                  onClick={(e) => {
                    handleCountAndSlide(e);
                    setNextIntro(true);
                  }}
                >
                  Get started
                </ColoredButton>
              }
              bgColor="WARNING"
              icon="https://assets.surveylake.com/lightbulb.svg"
              pageLength={4}
              count={count}
            />
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default OnboardingScreen;
