import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  flex: 1 1;
  justify-content: center;
  display: flex;
  background-color: var(--primary-background-color);
`;

export const Body = styled.div`
  background-color: ${(props: { $backgroundColor: string }) =>
    props.$backgroundColor || "#ffffff"};
  height: 100%;
  width: min(90%, 900px);
`;
