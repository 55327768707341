import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  & > p {
    margin-top: 5px;
    font-size: 12px;
    color: #000000;
    padding: 5px;
    background: #ffffff;
    text-align: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  & > button {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-red-color);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    border: none;
    padding: 5px;
    height: 25px;
    width: 25px;
    display: none;
  }

  &:hover > button {
    display: flex;
  }

  ${(props: { $itemType: "color" | "logo"; $content: string }) => {
    if (props.$itemType === "color") {
      return css`
        background-color: ${props.$content};
        width: 55px;
        height: 55px;
        border-radius: 10px;
        border: 1px solid #000000;
      `;
    }
    return css`
      background-color: transparent;
      // background-image: url(${props.$content});
      width: 150px;
      //min-height: 40px;
      & > img {
        width: 100%;
      }

      & > button {
        position: absolute;
        right: 0;
        top: 0;
      }
    `;
  }}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MoreButton = styled.button`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border: none;
  margin-left: 10px;
  background-color: transparent;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 128%;
`;

export const ColorList = styled.div`
  display: flex;
  align-items: center;
  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ColorBox = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-right: 10px;
`;

export const AddBox = styled.button`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid black;
`;
