import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  padding: 10px 50px 10px 70px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;

  & a {
    margin-left: 16px;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const LogoContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
  //width: 40px;
`;

export const ProfileContainer = styled.div`
  display: flex;
  border-radius: 10px;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
`;

export const ProfilePic = styled.div`
  display: inline-flex;
  background: #c4c4c4;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
`;

export const Username = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
  margin-bottom: 0;
`;

export const UserEmail = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #999999;
  margin-bottom: 0;
`;

export const UserMobileDropdown = styled.div`
  display: flex;
  /* display: ${(props: { visible?: boolean }) =>
    props.visible === true ? "none" : "flex"}; */
  align-items: center;
  cursor: pointer;
  margin-top: 2rem;

  /* @media (min-width: 640px) {
    display: none;
  } */
`;

export const Email = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin-bottom: 0;
`;

export const UserDropdown = styled.div`
  display: none;
  align-items: center;
  cursor: pointer;

  @media (min-width: 640px) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const ProfileLink = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #222222;
  margin-bottom: 0;
  text-decoration: none;
  margin-left: 20px;
`;

export const AccountBtn = styled.button`
  background-color: transparent;
  border: none;
  padding-left: 20px;
`;

export const MenuItem = styled.button`
  display: flex;
  gap: 12px;
  padding: 4px 8px;

  background-color: transparent;
  border: none;
  width: 100%;
  & > span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
`;

export const IconContainer = styled.span`
  display: inline-flex;
  flex: none;
  height: 16px;
  width: 16px;
  align-items: center;
  justify-content: center;
`;

export const MenuButton = styled.button`
  height: 45px;
  padding: 10px 16px;
  flex: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #f9fafb;
  border-radius: 6px;
  border: none;
`;

export const UserDetails = styled.div``;

export const Right = styled.div`
  display: flex;
  gap: 1rem;
`;
