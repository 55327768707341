import React from "react";
import { SliderBody, SliderComponent, SliderLabels, Wrapper } from "./style";
import { ReactComponent as PointerIcon } from "./icons/pointer.svg";
import { SliderOptions } from "../../../../../../../../pages/Design/types";

interface BodyProps {
  options: SliderOptions;
}

const Slider = ({ options }: BodyProps) => {
  return (
    <Wrapper>
      <PointerIcon />

      <SliderBody>
        <SliderComponent />
      </SliderBody>
      <SliderLabels>
        <p>{options.labels.left}</p>
        <p>{options.labels.right}</p>
      </SliderLabels>
    </Wrapper>
  );
};

export default Slider;
