import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  & > div {
    margin-bottom: 8px;
  }
  @media (min-width: 820px) {
    flex-wrap: nowrap;
    & > div {
      margin-bottom: 0;
    }
  }
`;

export const Add = styled.div`
  margin-right: 5px;
`;

export const Condition = styled.div`
  margin-right: 18px;
`;

export const Options = styled.div`
  margin-right: 18px;
`;

export const Action = styled.div`
  margin-right: 18px;
`;

export const Destination = styled.div`
  margin-right: 18px;
`;

export const Label = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 8px;
  color: var(--primary-text-black);
`;

// export const Right = styled.div`
//   @media (min-width: 1440px) {
//   }
// `;
