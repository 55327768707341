import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 15px;
  justify-content: flex-end;
  padding: 0 8px;
`;

export const CancelButton = styled.button`
  border: 1px solid var(--primary-red-color);
  background-color: #ffffff;
  color: var(--primary-red-color);
  height: 40px;
  width: 100px;
  border-radius: 5px;
  margin-right: 20px;
`;

export const SaveButton = styled.button`
  background-color: var(--primary-red-color);
  color: #ffffff;
  height: 40px;
  width: 100px;
  border-radius: 5px;
  border: none;
`;
