import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useAppSelector } from "../../../../../../app/hooks";
import Question from "../Question";
import { useParams } from "react-router-dom";

const QuestionList = ({ questionKeys }: { questionKeys: string[] }) => {
  const params = useParams();
  const { questionsById } = useAppSelector((state) => state.design);

  const [quesList, setQuesList] = useState({});
  const [quesKeys, setQuesKeys] = useState([]);

  useEffect(() => {
    if (questionsById.constructor === Object) {
      const questionKeysCopy = [...questionKeys];
      const sortedQuestionKeys = questionKeysCopy.sort();
      const questionsListKeys = Object.keys(questionsById).sort();
      // check if sortedQuestionKeys is a subset of questionsListKeys
      const questionKeysEqualsQuestionListKeys = sortedQuestionKeys.every(
        (val) => questionsListKeys.includes(val)
      );
    }
    setQuesList(questionsById);
    setQuesKeys(questionKeys);
  }, [questionKeys, questionsById]);

  return (
    <Wrapper>
      {quesKeys.map((queId, index) => {
        return (
          <Question
            key={queId}
            surveyId={params.surveyId}
            questionId={queId}
            questionType={quesList[queId]?.type}
            questionNo={quesList[queId]?.questionNum || index + 1}
            questionText={quesList[queId]?.text}
            questionObj={quesList[queId]}
          />
        );
      })}
    </Wrapper>
  );
};

export default QuestionList;
