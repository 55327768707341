import React, { useContext, useEffect, useState } from "react";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import {
  Wrapper,
  FormBody,
  Section,
  SectionDualChild,
  RadioContainer,
} from "./style";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FormHeader from "../../FormHeader";
import QuestionHeader from "../../QuestionHeader";
import { useConfiguration } from "../../../contexts/Configuration";
import QuestionFormCheckbox from "../../QuestionFormCheckbox";
import RichTextField from "../../RichTextField";
import RequiredCheckbox from "../../RequiredCheckbox";
import DateRowOptions from "../../RowOptions/DateRowOptions";
import { Radio } from "antd";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import { useParams } from "react-router-dom";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";
import { Inputs } from "../types";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";

const dateFormatOptions = [
  { label: "DD/ MM/ YYYY", value: "DMY" },
  { label: "MM/ DD/ YYYY", value: "MDY" },
];

const DateTimeForm = ({ onCancel }: { onCancel: () => void }) => {
  const dispatch = useAppDispatch();
  const { selectedQuestion } = useAppSelector((state) => state.design);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { text: selectedQuestion.text } });
  const { surveyId } = useParams();

  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();
  const { hasRandomizedChoices, isRequired, requiredMessage } = configState;
  const { hasDateInfo, hasTimeInfo, dateRowOptions, dateFormat } =
    configState.dateOptions;

  const [dateInfo, setDateInfo] = useState(hasDateInfo || true);
  const [timeInfo, setTimeInfo] = useState(hasTimeInfo || false);
  const [selectedDateFormat, setSelectedDateFormat] = useState(
    dateFormat || "DMY"
  );
  useEffect(() => {
    if (selectedQuestion.text) {
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const mcValues = {
        type: "DT",
        text: data.text,
        isRequired,
        requiredMessage: undefined,
        hasLogic: false,
        hasRandomizedChoices,
        dateOptions: {
          hasDateInfo: dateInfo,
          hasTimeInfo: timeInfo,
          dateFormat: selectedDateFormat,
          dateRowOptions,
        },
      };
      if (isRequired) {
        mcValues.requiredMessage = requiredMessage;
      }
      if (!selectedQuestion?.questionId) {
        const result = await addQuestion({
          surveyId,
          questionDetail: mcValues,
        }).unwrap();
        await dispatch(setNewQuestion(result?.question));
        await dispatch(setSelectedQuestion(null));
        onSuccessShowAlert("New question added");
      } else {
        const { questionId } = selectedQuestion;
        const mcQuesInitialObj = pick(selectedQuestion, [
          "type",
          "text",
          "rowOptions",
          "isRequired",
          "hasLogic",
          "hasRandomizedChoices",
          "requiredMessage",
          "dateOptions",
        ]);
        const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
        if (!hasQuestionNotChanged) {
          await editQuestion({
            surveyId,
            questionId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(
            setUpdatedQuestion({
              questionId,
              updatedQues: { ...mcValues, questionId },
            })
          );
        }
        onSuccessShowAlert("Question updated");
      }
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  const onDateIncludeChange = (e: any) => {
    setDateInfo(e.target.checked);
  };

  const onTimeIncludeChange = (e: any) => {
    setTimeInfo(e.target.checked);
  };

  const onChangeDateFormat = (e: any) => {
    setSelectedDateFormat(e.target.value);
  };

  useEffect(() => {
    if (selectedQuestion.text) {
      localDispatch({
        type: "UPDATE_DATE_ROW_OPTIONS",
        payload: selectedQuestion?.dateOptions?.dateRowOptions,
      });
      localDispatch({
        type: "UPDATE_IS_REQUIRED",
        payload: selectedQuestion.isRequired,
      });
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType="DT" />
          <Controller
            name="text"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RichTextField
                hasError={errors.text?.type === "required"}
                initialValue={selectedQuestion.text || configState.questionText}
                field={field}
              />
            )}
          />
          <Section>
            <DateRowOptions rowList={dateRowOptions} />
          </Section>

          <Section>
            <RequiredCheckbox />
          </Section>

          <Section>
            <p>Collect</p>
            <SectionDualChild>
              <QuestionFormCheckbox
                checked={dateInfo}
                onChange={onDateIncludeChange}
              >
                Date info
              </QuestionFormCheckbox>
              <QuestionFormCheckbox
                checked={timeInfo}
                onChange={onTimeIncludeChange}
              >
                Time info
              </QuestionFormCheckbox>
            </SectionDualChild>
          </Section>

          <Section>
            <p>Date format</p>
            <RadioContainer>
              <Radio.Group
                options={dateFormatOptions}
                onChange={onChangeDateFormat}
                value={selectedDateFormat}
              />
            </RadioContainer>
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default DateTimeForm;
