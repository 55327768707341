import { combineReducers } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import authReducer from "../pages/Login/loginSlice";
import designReducer from "../pages/Design/designSlice";
import collectorsReducer from "../pages/CollectResponses/collectorSlice";
import contactReducer from "../pages/Contacts/contactSlice";
import { apiSlice } from "./apiSlice";

const appReducer = combineReducers({
  auth: authReducer,
  design: designReducer,
  contact: contactReducer,
  collectors: collectorsReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "persist/PURGE") {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
