import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import groupBy from "lodash/groupBy";
import { Wrapper, TableContainer } from "./style";
import CollectorTable from "../CollectorTable";
import CollectorSettings from "../CollectorSettings";
import NoResponses from "./NoResponses";
import type {
  CollectorType,
  ResponsesByCollectors,
} from "../../../../pages/CollectResponses/types";
import { Modal } from "antd";
import ResponseSettings from "../ResponseSettings";
import StackedChart from "./StackedChart";
import type { SurveyResponse } from "../../../Analytics/types";

interface ChartData {
  name: string;
  data: [string, number][];
}
const AllResponses = ({
  isEmpty,
  collectors = [],
  responsesByCollectors,
  allResponses,
}: {
  isEmpty: boolean;
  collectors: CollectorType[];
  responsesByCollectors: ResponsesByCollectors;
  allResponses: SurveyResponse[];
}) => {
  const [selectedCollector, setSelectedCollector] =
    useState<CollectorType>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [chartData, setChartData] = useState<ChartData[]>([]);

  const onConfigureClick = (theCollector: CollectorType) => {
    setSelectedCollector(theCollector);
    setIsModalVisible(true);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmitSettings = () => {
    setIsModalVisible(false);
  };

  const tableAction = (collectorItem) => (
    <CollectorSettings
      onConfigureClick={onConfigureClick}
      collectorItem={collectorItem}
    />
  );

  if (isEmpty) {
    return <NoResponses />;
  }

  const keys = useMemo(() => {
    const formattedDateResponses = allResponses.map((option) =>
      dayjs(option.createdAt).format("MMM DD, YY")
    );
    // unique keys
    const uniqueKeys = formattedDateResponses.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );
    // sort in ascending order
    const sortedKeys = uniqueKeys.sort((a, b) => dayjs(a).diff(dayjs(b)));
    return sortedKeys;
  }, [allResponses]);

  useEffect(() => {
    if (responsesByCollectors && Object.keys(responsesByCollectors)) {
      const newChartData = [];
      for (const [key, value] of Object.entries(responsesByCollectors)) {
        const collectorItem = collectors.find((col) => col.collectorId === key);
        const collectorName = collectorItem ? collectorItem.name : key;

        const formattedValue = value.map((col) => ({
          createdAt: dayjs(col.createdAt).format("MMM DD, YY"),
          collectorId: col.collectorId,
        }));
        const groupedValue = groupBy(formattedValue, "createdAt");
        const chartGroupingValue = keys.map((key, index) => {
          if (groupedValue[key]) {
            return [index, groupedValue[key].length];
          } else {
            return [index, 0];
          }
        });
        newChartData.push({ name: collectorName, data: chartGroupingValue });
      }
      setChartData(newChartData);
    }
  }, [responsesByCollectors, collectors]);

  return (
    <Wrapper>
      <StackedChart chartData={chartData} keys={keys} />

      <TableContainer>
        <CollectorTable
          menuComponent={tableAction}
          title="All collectors"
          collectorsData={collectors}
          responsesByCollectors={responsesByCollectors}
        />
      </TableContainer>

      {isModalVisible && (
        <Modal
          title="All"
          visible={isModalVisible}
          onCancel={onCancel}
          footer={null}
        >
          <ResponseSettings
            collector={selectedCollector}
            onSave={onSubmitSettings}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default AllResponses;
