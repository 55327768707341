import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  padding-bottom: 40px;

  & div[class="ant-table ant-table-bordered"] {
    background-color: transparent;
  }

  & thead[class="ant-table-thead"] > tr > th[class="ant-table-cell"] {
    background-color: transparent;
    border-bottom: 1px solid #c7c7c7;
  }
`;

export const MenuButton = styled.button`
  border: none;
  padding: 5px 10px;
  display: flex;
  align-items: center;
`;

export const EllipsisContainer = styled.div`
  padding: 10px 5px;
  background: rgba(9, 30, 66, 0.08);
  border-radius: 3px;
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TableTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;
