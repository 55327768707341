import React, { useState } from "react";
import { Wrapper, Content } from "./style";
import DeleteModal from "../DeleteModal";

const ItemBox = ({
  itemType,
  content,
  id,
}: {
  itemType: "color" | "logo";
  content: string;
  id: string;
}) => {
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Wrapper>
      <Content $itemType={itemType} $content={content}>
        {itemType === "logo" && <img src={content} />}
        <button onClick={openModal}>X</button>
        {showModal && (
          <DeleteModal
            itemId={id}
            itemType={itemType}
            onCancel={handleModalClose}
          />
        )}
      </Content>
      {itemType === "color" && <p>{content.toUpperCase()}</p>}
    </Wrapper>
  );
};

export default ItemBox;
