import sanitizeHtml from "sanitize-html";
import intersection from "lodash/intersection";
import formatAnswersForAnalytics from "./format";

const getFilterQuestions = ({ questions }) => {
  const acceptedQuesTypes = ["MC", "Ch", "Dd", "IC", "SR"];
  return questions.filter((ques) => acceptedQuesTypes.includes(ques.type));
};

export const questionsOptions = ({ questions }) => {
  const responseQuestions = getFilterQuestions({ questions });
  return responseQuestions.map((que) => ({
    value: que.questionId,
    label: sanitizeHtml(que.text, { allowedTags: [] }),
  }));
};

export const updateParamObjectItem = ({
  params,
  keyToReplace,
  identifierKeyValue,
  newValue,
}) => {
  const selectedParamIndex = params.findIndex(
    (que) => que.key === identifierKeyValue
  );
  const newFilterParams = [...params];
  if (selectedParamIndex !== -1) {
    const selectedParam = params[selectedParamIndex];
    selectedParam[keyToReplace] = newValue;
    newFilterParams.splice(selectedParamIndex, 1, selectedParam);
  }
  return newFilterParams;
};

const groupAnswersById = (responses) => {
  const responsesByQuesId = {};
  responses.forEach((res) => {
    const {
      answers: resAnswers,
      // collectorId,
      createdAt,
      responseId,
      updatedAt,
      userId,
    } = res;
    for (const [key, value] of Object.entries(resAnswers)) {
      const newEntry = {
        result: value,
        createdAt,
        responseId,
        updatedAt,
        userId,
      };
      if (responsesByQuesId[key]) {
        responsesByQuesId[key].push(newEntry);
      } else {
        responsesByQuesId[key] = [newEntry];
      }
    }
  });
  return responsesByQuesId;
};

const onResponseFilterOptionChange = ({
  questionsById,
  responsesList,
  selectedOptions,
  quesId,
  filterOperation,
}) => {
  if (selectedOptions.length !== 0) {
    const filteredResult = responsesList.filter((ele) => {
      const questionResponse = ele.answers[quesId];
      const questionObj = questionsById[quesId];
      if (questionResponse) {
        const selectedResponseOptions = questionResponse;
        let hasSelectedOptions;
        if (Array.isArray(selectedResponseOptions)) {
          if (filterOperation === "OR") {
            // OR
            hasSelectedOptions = selectedOptions.some((val) =>
              selectedResponseOptions.includes(val)
            );
          } else {
            // AND
            if (questionObj.type === "Ch") {
              hasSelectedOptions =
                selectedResponseOptions.includes(selectedOptions);
            } else {
              hasSelectedOptions = selectedOptions.every((val) =>
                selectedResponseOptions.includes(val)
              );
            }
          }
        } else {
          if (["SR", "IC"].includes(questionObj.type)) {
            if (filterOperation === "OR") {
              hasSelectedOptions = selectedOptions.some(
                (val) => selectedResponseOptions === val
              );
            } else {
              hasSelectedOptions = selectedResponseOptions === selectedOptions;
            }
          } else {
            hasSelectedOptions = selectedOptions.includes(
              selectedResponseOptions
            ); // OR and AND for single answer questions
          }
        }
        return hasSelectedOptions;
      }
    });
    return filteredResult;
  }
  return responsesList;
};

const formatAndSetAnswersForAnalytics = ({
  respByQuesId,
  questionsById,
  totalResponses,
}) => {
  let fAnswers = [];
  if (Object.keys(respByQuesId).length !== 0) {
    fAnswers = formatAnswersForAnalytics(
      respByQuesId,
      questionsById,
      totalResponses
    );
  }
  return fAnswers;
};

export const filterByAllParams = ({
  responsesList,
  filterParams,
  questionsById,
}) => {
  let filteredResults;
  if (
    responsesList &&
    responsesList.length &&
    filterParams &&
    filterParams.length
  ) {
    const combinedFilteredResultsIds = [];
    // for each filter obj
    filterParams.forEach((param) => {
      if (param.selectedOptions && param.questionId && param.operation) {
        // get the filtered result for the single filter param
        const filterResult = onResponseFilterOptionChange({
          questionsById,
          responsesList,
          selectedOptions: param.selectedOptions,
          quesId: param.questionId,
          filterOperation: param.operation,
        });
        // add the result's ids(an array) to an array
        combinedFilteredResultsIds.push(
          filterResult.map((res) => res.responseId)
        );
      }
    });
    // get all the common ids i.e intersection
    const intersectedResultIds = intersection(...combinedFilteredResultsIds);
    filteredResults = intersectedResultIds.map((resId) =>
      responsesList.find((res) => res.responseId === resId)
    );
  } else {
    filteredResults = responsesList;
  }
  // const groupFilteredAnsById = filteredResults;
  const groupFilteredAnsById = groupAnswersById(filteredResults);
  return formatAndSetAnswersForAnalytics({
    respByQuesId: groupFilteredAnsById,
    questionsById,
    totalResponses: responsesList.length,
  });
};
