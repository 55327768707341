import React, { FC, useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Nav from "../../components/common/Nav";
import Loader from "../../components/common/Loader";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";

import { useAddNewSurveyMutation } from "../../app/apiSlice";
import {
  Sidebar,
  Wrapper,
  Body,
  Title,
  TitleContainer,
  Paragraph,
  QuestionNum,
  UseButton,
  ButtonContainer,
  LookingFor,
  LowerDiv,
  TemplateContainer,
  CreateScratch,
  Container,
} from "./style";
import {
  useCopySurveyFromTemplateMutation,
  useGetTemplateMutation,
} from "../../app/apiSlice";

const TemplatePreview: FC = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [template, setTemplate] = useState<Record<string, any>>({});

  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const [copySurveyFromTemplate, { isLoading: isUpdating }] =
    useCopySurveyFromTemplateMutation();

  const [getTemplateData, { isLoading: isLoadingTemplate }] =
    useGetTemplateMutation();

  const fetchTemplateData = async () => {
    const res: any = await getTemplateData(templateId);
    console.log(res);
    setTemplate(res?.data);
  };

  const onLoadTemplate = async () => {
    try {
      const surveyData = {
        templateSurveyId: template?.surveyRef,
        name: template?.title,
        instruction: template?.description,
      };

      await copySurveyFromTemplate({
        surveyId: template?.surveyRef,
        surveyDetail: surveyData,
      }).unwrap();
      navigate(`/${template?.surveyRef}/design/questions`);
      onSuccessShowAlert("Template copied");
      // name
    } catch (err: any) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  const [addNewSurvey, { isLoading: isAddingNew }] = useAddNewSurveyMutation();

  const onFromScratchClick = async () => {
    const surveyBody = { name: "New Survey" };
    const { survey: newSurvey } = await addNewSurvey(surveyBody).unwrap();
    navigate(`/${newSurvey.surveyId}/design/questions`);
  };

  useEffect(() => {
    fetchTemplateData();
  }, []);

  return (
    <Container>
      <Nav title="Back to list" url="/templates" />
      {isLoadingTemplate ? (
        <Loader isLoading={isLoadingTemplate} />
      ) : (
        <Wrapper>
          <Body>
            <TemplateContainer>
              <iframe
                src={`https://answer.surveylake.com/tp/${templateId}`}
                title={template?.title}
                width="100%"
                height="800px"
              />
            </TemplateContainer>
          </Body>
          <Sidebar>
            <TitleContainer>
              <Title>{template?.title}</Title>
            </TitleContainer>
            <Paragraph>{template?.about}</Paragraph>

            <QuestionNum>
              <Title>Questions</Title>
              <Title>11</Title>
            </QuestionNum>
            <ButtonContainer>
              <UseButton onClick={onLoadTemplate}>Use this template</UseButton>
            </ButtonContainer>
            <LowerDiv>
              <LookingFor>Not what you’re looking for?</LookingFor>
              <CreateScratch onClick={onFromScratchClick}>
                Create your survey from scratch{" "}
              </CreateScratch>
            </LowerDiv>
          </Sidebar>
        </Wrapper>
      )}
    </Container>
  );
};

export default TemplatePreview;
