import React from "react";
import type { ColumnsType } from "antd/lib/table";
import { CustomQuestionTable, RadioDisplay, Wrapper } from "./style";
import type { RowOption } from "../../../../../../../../pages/Design/types";
import { ColumnOption } from "../../../../../../../../pages/Design/types";

interface BodyProps {
  rowOptions: RowOption[];
  colOptions: ColumnOption[];
  allowMultiple: boolean;
}

interface DataType {
  key: string;
  blank: string | number;
  radio: React.ReactNode;
}

const MatrixRatingScale = ({
  rowOptions,
  colOptions,
  allowMultiple,
}: BodyProps) => {
  const columns: ColumnsType<DataType> = colOptions.map((col, index) => ({
    title: col.value,
    dataIndex: "radio",
    key: index + 1,
    width: 100,
  }));

  columns.unshift({
    title: "",
    // width: 150,
    width: "25%",
    dataIndex: "blank",
    key: "blank",
    fixed: "left",
    responsive: ["sm"],
  });

  const data = rowOptions.map((option) => ({
    key: option.index,
    blank: option.value,
    radio: <RadioDisplay $isCheckbox={allowMultiple} />,
  }));
  return (
    <Wrapper>
      <CustomQuestionTable
        columns={columns}
        dataSource={data}
        // scroll={{ x: 800 }}
        pagination={false}
        // bottom="none"
      />
    </Wrapper>
  );
};

export default MatrixRatingScale;
