import groupBy from "lodash/groupBy";
import cloneDeep from "lodash/cloneDeep";

const groupByRange = (ary, rangeCount) => {
  const r = {};
  ary.forEach((x) => {
    const y = Math.floor(x / rangeCount);
    r[y] = (r[y] || []).concat(x);
  });
  return Object.keys(r).map((y) => {
    const countInRange = r[y].length;
    const rangeStart = y * rangeCount;
    const rangeEnd = rangeStart + (rangeCount - 1);
    const theRange = `${rangeStart} - ${rangeEnd}`; // rangeStart to rangeEnd
    return { name: theRange, responses: countInRange, value: theRange };
  });
};

const getResultByQuestionType = (question, answerValuesObj) => {
  const { type: questionType, rowOptions, colOptions } = question;
  let result = [];
  switch (questionType) {
    case "ST":
    case "LT":
      result = answerValuesObj.map((ans, index) => ({
        answer: ans.result,
        responseId: ans.responseId,
        createdAt: ans.createdAt,
        updatedAt: ans.updatedAt,
        index,
      }));
      return result;
    case "DT":
      result = answerValuesObj.map((ans) => {
        const res = {};
        for (const [key, value] of Object.entries(ans.result)) {
          res[key] = {
            dateValue: value,
            responseId: ans.responseId,
            createdAt: ans.createdAt,
          };
        }
        return res;
      });
      return result;
    case "SR": {
      const SRValuesByValue = groupBy(answerValuesObj, "result");
      const SRValuesByValueList = Object.values(SRValuesByValue);
      result = SRValuesByValueList.map((val) => ({
        name: val[0].result,
        responses: val.length,
      }));
      return result;
    }
    case "MC":
    case "Dd": {
      const rowOptionsCopy = cloneDeep(rowOptions);
      const MCValuesByValue = groupBy(answerValuesObj, "result");
      const MCValuesByValueList = Object.values(MCValuesByValue);
      result =
        answerValuesObj.length > 0
          ? rowOptionsCopy.map((opt) => {
              const newOpt = { ...opt };
              newOpt.name = opt.value;
              newOpt.responses = 0;
              const rowOpt = MCValuesByValueList.find(
                // (val) => val[0].value === opt.id // TODO: change to id
                (val) => val[0].result === opt.value
              );
              if (rowOpt) {
                newOpt.responses = rowOpt.length;
              } else {
                newOpt.responses = 0;
              }
              return newOpt;
            })
          : [];
      return result;
    }
    case "Ch": {
      const rowOptionsCopy3 = cloneDeep(rowOptions);
      const newAnswers = [];
      answerValuesObj.forEach((ans) => {
        ans.result.forEach((sel) => {
          const newAns = { ...ans, result: sel };
          newAnswers.push(newAns);
        });
      });
      const CHValuesByValue = groupBy(newAnswers, "result");
      const CHValuesByValueList = Object.values(CHValuesByValue);
      result =
        answerValuesObj.length > 0
          ? rowOptionsCopy3.map((opt) => {
              const newOpt = { ...opt };
              newOpt.name = opt.value;
              newOpt.responses = 0;
              const rowOpt = CHValuesByValueList.find(
                (val) => val[0].result === opt.value.toString()
              );
              if (rowOpt) {
                newOpt.responses = rowOpt.length;
              } else {
                newOpt.responses = 0;
              }
              return newOpt;
            })
          : [];
      return result;
    }
    case "IC": {
      if (!question?.imageOptions?.imageRowOptions) return [];
      const rowOptionsCopy2 = cloneDeep(question.imageOptions.imageRowOptions);
      const ICValuesByValue = groupBy(answerValuesObj, "result");
      const ICValuesByValueList = Object.values(ICValuesByValue);
      result =
        answerValuesObj.length > 0
          ? rowOptionsCopy2.map((opt) => {
              const newOpt = { ...opt };
              newOpt.link = opt.url;
              newOpt.name = opt.value;
              newOpt.responses = 0;
              const rowOpt = ICValuesByValueList.find(
                (val) => val[0].result === opt.index
              );
              if (rowOpt) {
                newOpt.responses = rowOpt.length;
              } else {
                newOpt.responses = 0;
              }
              return newOpt;
            })
          : [];
      return result;
    }
    case "Sl": {
      const selectedValues = answerValuesObj.map((val) => val.result);
      const rangeCount = question.sliderOptions.maxValue
        ? question.sliderOptions.maxValue / 10
        : 10;
      const groups = groupByRange(selectedValues, rangeCount);
      return groups;
    }
    case "Rk":
      // console.log('Rk')
      // console.log(answerValuesObj)
      return [];
    case "MRS": {
      const qOptionsMap = {};
      rowOptions.forEach((opt) => {
        const { index } = opt;
        qOptionsMap[index] = opt.value;
      });

      const colOptionsMap = {};
      colOptions.forEach((opt) => {
        const { index } = opt;
        colOptionsMap[index] = opt.value;
      });

      let allSelectedOptions = [];
      // console.log(answerValuesObj)
      answerValuesObj.forEach((ans) => {
        allSelectedOptions = allSelectedOptions.concat(ans.result);
      });
      const groupByCol = groupBy(allSelectedOptions, "colSelected");

      // console.log('groupByCol')
      // console.log(groupByCol)
      const groupByRow = [];

      Object.entries(groupByCol).forEach(([key, value]) => {
        const newObj = { name: colOptionsMap[key] };
        const newRow = groupBy(value, "rowIndex");

        const seriesData = [];

        Object.entries(qOptionsMap).forEach(([rowK, rowValue]) => {
          const innerKeyValArray = [];
          const objVal = newRow[rowK];
          // const mappedKey = qOptionsMap[rowK]
          innerKeyValArray[0] = rowValue;
          innerKeyValArray[1] = objVal ? objVal.length : 0;
          seriesData.push(innerKeyValArray);
          // newObj[mappedKey] = rowValue.length
        });
        newObj.data = seriesData;
        groupByRow.push(newObj);
      });
      return groupByRow;

      // const groupByRow = groupBy(allSelectedOptions, 'rowIndex')
      //
      // console.log('groupByRow')
      // console.log(groupByRow)
      // let groupByCol = []
      // for (const [key, value] of Object.entries(groupByRow)) {
      //     console.log('value')
      //     console.log(value)
      //     // const newObj = {name: qOptionsMap[key]}
      //     const newObj = {rowName: qOptionsMap[key]}
      //     const newCol = groupBy(value, 'colSelected')
      //     console.log('newCol')
      //     console.log(newCol)
      //
      //     const seriesData = []
      //     for (const [colK, colValue] of Object.entries(newCol)) {
      //         const innerKeyValArray = []
      //         const mappedKey = colOptionsMap[colK]
      //         // innerKeyValArray[0] = mappedKey
      //         // innerKeyValArray[1] = colValue.length
      //         // seriesData.push(innerKeyValArray)
      //         newObj[mappedKey] = colValue.length
      //     }
      //     // newObj['data'] = seriesData
      //     groupByCol.push(newObj)
      // }
      // return groupByCol
    }
    default:
      return [];
  }
};

const formatAnswersForAnalytics = (answers, questionList, totalRespondents) => {
  const formattedAnswers = [];

  Object.entries(questionList).forEach(([key, value]) => {
    const formattedAns = {};
    formattedAns.qId = key;
    formattedAns.qNum = value.questionNum;
    formattedAns.qType = value.type;
    formattedAns.qText = value.text;
    if (value.type === "DT") {
      formattedAns.qOptions = value.dateOptions?.dateRowOptions;
    } else if (value.type === "IC") {
      formattedAns.qOptions = value.imageOptions?.imageRowOptions;
    } else {
      formattedAns.qOptions = value.rowOptions;
    }
    formattedAns.colOptions = value.colOptions;
    formattedAns.extraOptions = { srOptions: value.starRatingOptions ?? {} };
    const answersArray = answers[key] ?? [];
    const result = getResultByQuestionType(value, answersArray);
    formattedAns.result = result;
    const numAnswered = answers[key] ? answers[key].length : 0;
    formattedAns.respondents = {
      answered: numAnswered,
      skipped: totalRespondents - numAnswered,
    };
    formattedAnswers.push(formattedAns);
  });
  return formattedAnswers;
};

export default formatAnswersForAnalytics;
