import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;

export const Body = styled.div`
  padding: 20px 32px;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 64px;
`;

export const ScreenButtons = styled.div`
  display: flex;
  margin: 20px 0;
`;

export const ScreenButton = styled.button`
  border: none;
  padding: 5px 10px;
  color: var(--primary-black-color);
  background-color: #ffffff;
  ${(props: { $selected?: boolean }) => {
    if (props.$selected) {
      return css`
        color: #ffffff;
        background-color: var(--primary-red-color);
      `;
    }
  }};
`;

export const IframeContainer = styled.iframe`
  border: none;
  min-height: 780px;
  max-height: 1400px;
  z-index: 1;
  background-color: #ffffff;
  max-width: min(95%, 1200px) !important;
`;

export const BackgroundLoader = styled.div`
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  height: calc(100vh - 200px);
  margin-top: 200px;
  width: 100%;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: transparent;
  z-index: -1;
  & img {
    width: 70px;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
  }
`;
