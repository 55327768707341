import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 64px);
  & > div {
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    overflow-y: auto;
  }
`;

export const SortMenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props: { $isCancel?: boolean }) =>
    !props.$isCancel ? "#000000" : "#ce375c"};
`;

export const Container = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem /* 16px */;
  padding-left: 1rem /* 16px */;
  & > h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 45px;
    color: #222222;
    margin-top: 3rem;
    & > span {
      font-weight: 700;
    }

    & > p {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #4f4f4f;
    }
  }
  @media (min-width: 640px) {
    max-width: 640px;
    padding-right: 2rem /* 32px */;
    padding-left: 2rem /* 32px */;
    & > h1 {
      font-size: 36px;
    }
    & > p {
      margin-bottom: 0px;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #222222;
    }
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 1024px) {
    max-width: 1024px;
    padding-right: 2rem /* 32px */;
    padding-left: 2rem /* 32px */;
  }
  @media (min-width: 1280px) {
    max-width: 1280px;
    padding-right: 2rem /* 32px */;
    padding-left: 2rem /* 32px */;
  }
  @media (min-width: 1536px) {
    max-width: 1536px;
    padding-right: 2rem /* 96px */;
    padding-left: 2rem /* 96px */;
  }
`;

export const PageActions = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1.5rem;
  margin-top: 3rem;
  & > button {
    border: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background: #f8f9fa;
    padding: 16px 24px;
    box-shadow: inset 0px -1px 0px rgba(97, 117, 134, 0.2);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const ActionFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  & p {
    margin-bottom: 0px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
  }

  & span {
    display: inline-flex;
    height: 50px;
    width: 50px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
  }
  ${(props: { $iconType: "INFO" | "SUCCESS" | "PRIMARY" }) => {
    if (props.$iconType === "INFO") {
      return css`
        & span {
          background: rgba(227, 179, 76, 0.15);
        }
      `;
    } else if (props.$iconType === "SUCCESS") {
      return css`
        & span {
          background: rgba(52, 168, 83, 0.1);
        }
      `;
    }
    return css`
      & span {
        background: rgba(100, 195, 235, 0.15);
      }
    `;
  }}
`;

export const GridContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;

  @media (min-width: 640px) {
    display: flex;
  }
  @media (min-width: 1024px) {
    display: flex;
  }

  & h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #222222;
  }
`;

export const StatsGridContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 1100px) {
    & div[class="ant-spin-nested-loading"] {
      width: 100%;
    }
  }

  @media (min-width: 1400px) {
    width: 860px;
    margin-bottom: 0;
  }
`;

export const BlogGridContainer = styled.div`
  margin-left: 3em;
  flex: 1;
  width: unset;
  display: block;
  @media (max-width: 1399px) {
    width: 100%;
    margin-left: 0;
  }
`;

export const StatContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  margin-top: 2rem;
  gap: 1rem;
  @media (min-width: 640px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: 1280px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

export const StatCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 24px;
  gap: 1rem;

  & > h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #222222;
    margin-bottom: 0px;
  }

  & > p {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    color: #4f4f4f;
  }
`;

export const TableContainer = styled.div`
  margin-top: 3rem;
  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  & h2 {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    color: #222222;
    margin-top: 2rem;
  }
  & button {
    background-color: transparent;
    border: none;
  }
  & button:hover {
    color: #ce375c;
  }
  & a {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #4f4f4f;
    cursor: pointer;
    text-decoration: none;
  }
  & a:hover {
    color: #ce375c;
    cursor: pointer;
  }
`;

export const SortButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 1px solid #9ca3af !important;
  background-color: transparent;
  border-radius: 6px;
`;

export const LinkBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border: 1px solid #9ca3af !important;
  background-color: transparent;
  border-radius: 6px;
`;
export const ShareBtn = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: auto;
  padding: 0px 20px;
  border: 1px solid #9ca3af !important;
  background-color: transparent;
  border-radius: 6px;
`;

export const ActionContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const PostContainer = styled.div`
  width: 100%;
  & h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    color: #121127;
  }
  & > div {
    margin-bottom: 20px;
  }
  @media (max-width: 1399px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    & > div {
      margin-right: 20px;
      width: 300px;
    }
  }
`;

export const PostBody = styled.a`
  text-decoration: none;
  color: var(--primary-black-color);
  &:hover {
    color: var(--primary-black-color);
  }
`;

export const PostContent = styled.p`
  color: var(--primary-black-color);
`;

export const PostDate = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 180%;
  color: rgba(18, 17, 39, 0.56);
`;

export const PostFooter = styled.div`
  display: flex;

  & img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
`;

export const PostTag = styled.div`
  background: #bbf3f7;
  border-radius: 100px;
  display: inline-flex;
  padding: 4px 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 180%;
  text-align: center;
  color: #2a7576;
`;

export const AuthorInfo = styled.div`
  & h6 {
    font-weight: 600;
    font-size: 14px;
    color: #121127;
  }
`;
export const Body = styled.div``;
