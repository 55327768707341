import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
`;

export const Section = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  &:not(:last-child) {
    margin-bottom: 14px;
  }
`;

export const Left = styled.div`
  width: 50%;
`;

export const Right = styled.div`
  flex: 1 1;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #222222;
`;

export const Body = styled.div`
  padding: 2px;
`;

export const SubDiv = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  ${(props) => {
    if (props.$orientation === "vertical") {
      return css`
        display: block;
        & p {
          margin-bottom: 5px;
        }
      `;
    }
    return css`
      display: flex;
      align-items: center;
      & p {
        margin: 0 10px;
      }
    `;
  }}
`;

export const ColorLabel = styled.span`
  margin-right: 5px;
`;

export const ColorIcon = styled.span``;

export const ColorListContainer = styled.div`
  margin: 10px 0 5px;
  & > p {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const ColorListBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > div {
    margin-right: 10px;
    margin-bottom: 10px;
  }
`;
