import React from "react";
import { Wrapper, Header, SaveButton, CancelButton } from "./style";

interface HeaderProps {
  onCancel: () => void;
}
const FormHeader = ({ onCancel }: HeaderProps) => {
  return (
    <Wrapper>
      <Header>
        <CancelButton type="button" onClick={onCancel}>
          Cancel
        </CancelButton>
        <SaveButton type="submit">Save</SaveButton>
      </Header>
    </Wrapper>
  );
};

export default FormHeader;
