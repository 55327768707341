import React, { useState } from "react";
import {
  Body,
  FontDiv,
  Header,
  SelectDropdown,
  Wrapper,
  Section,
  AlignmentButtons,
  AlignmentButton,
  OtherColor,
} from "./style";
import { ReactComponent as LeftAlignIcon } from "../../icons/align-text-left.svg";
import { ReactComponent as CenterAlignIcon } from "../../icons/align-text-center.svg";
import { ReactComponent as RightAlignIcon } from "../../icons/align-text-right.svg";
import { ReactComponent as DownArrowIcon } from "../../../../assets/icons/down-arrow.svg";
import { fontFamilyOptions, fontSizeOptions } from "../../utils";
import EditColorPicker from "./components/ColorPicker";
import { useEdit } from "../../context/EditContext";
import EditAutoSave from "../AutoSave";
import UploadBox from "../../../../features/edit/components/UploadBox";

const Elements = () => {
  const [alignmentSelected, setAlignmentSelected] = useState("left");
  const { state: editState, dispatch: localDispatch } = useEdit();
  const {
    textFontSize,
    font,
    titleColor,
    textFontColor,
    buttonColor,
    buttonTextColor,
    backgroundColor,
    answerColor,
    headerColor,
  } = editState;

  const onAlignmentClick = (value: string) => {
    setAlignmentSelected(value);
  };

  const onDesignItemChange = (name: string, value: any) => {
    localDispatch({
      type: "UPDATE_INDIVIDUAL_PROPERTY",
      payload: { propName: name, propValue: value },
    });
  };

  return (
    <Wrapper>
      <Header>
        <p>Elements</p>
      </Header>
      <Body>
        <Section>
          <p>Text</p>
          <FontDiv>
            <SelectDropdown
              options={fontFamilyOptions}
              defaultValue="Mulish"
              value={font || "Mulish"}
              suffixIcon={<DownArrowIcon />}
              onChange={(value) => onDesignItemChange("font", value)}
            />
            <SelectDropdown
              $width="80px"
              options={fontSizeOptions}
              value={textFontSize || "14"}
              defaultValue="14"
              suffixIcon={<DownArrowIcon />}
              onChange={(value) => onDesignItemChange("textFontSize", value)}
            />
          </FontDiv>
          <EditColorPicker
            name="textFontColor"
            defaultColor={textFontColor || "#000000"}
          />
          <AlignmentButtons>
            <AlignmentButton
              $selected={alignmentSelected === "left"}
              onClick={() => onAlignmentClick("left")}
            >
              <LeftAlignIcon />
            </AlignmentButton>
            <AlignmentButton
              $selected={alignmentSelected === "center"}
              onClick={() => onAlignmentClick("center")}
            >
              <CenterAlignIcon />
            </AlignmentButton>
            <AlignmentButton
              $selected={alignmentSelected === "right"}
              onClick={() => onAlignmentClick("right")}
            >
              <RightAlignIcon />
            </AlignmentButton>
          </AlignmentButtons>
        </Section>
        <Section>
          <p>Logo</p>
          <UploadBox uploadType="logo" />
        </Section>
        <Section>
          <p>Header</p>
          {/*<UploadDiv>*/}
          {/*  <UploadButton>*/}
          {/*    <SystemUploadIcon />*/}
          {/*    <span>Upload</span>*/}
          {/*  </UploadButton>*/}
          {/*  <UploadButton>*/}
          {/*    <LibraryUploadIcon />*/}
          {/*    <span>From Library</span>*/}
          {/*  </UploadButton>*/}
          {/*</UploadDiv>*/}
          <OtherColor>
            <span>Background</span>
            {/*Header background*/}
            <EditColorPicker
              name="headerColor"
              defaultColor={headerColor || "#ce375c"}
            />
          </OtherColor>
          <OtherColor>
            {/*Header title*/}
            <span>Title</span>
            <EditColorPicker
              name="titleColor"
              defaultColor={titleColor || "#ffffff"}
            />
          </OtherColor>
        </Section>
        <Section>
          <p>Other</p>
          <OtherColor>
            <span>Page background</span>
            <EditColorPicker
              name="backgroundColor"
              defaultColor={backgroundColor || "#ffffff"}
            />
          </OtherColor>
          <OtherColor>
            <span>Button</span>
            <EditColorPicker
              name="buttonColor"
              defaultColor={buttonColor || "#ce375c"}
            />
          </OtherColor>
          <OtherColor>
            <span>Button text</span>
            <EditColorPicker
              name="buttonTextColor"
              defaultColor={buttonTextColor || "#fffffe"}
            />
          </OtherColor>
          <OtherColor>
            <span>Answer</span>
            <EditColorPicker
              name="answerColor"
              defaultColor={answerColor || "#000000"}
            />
          </OtherColor>
        </Section>
        <EditAutoSave />
      </Body>
    </Wrapper>
  );
};

export default Elements;
