import React, { useContext, useEffect, useState } from "react";
import parse from "html-react-parser";
import { Steps } from "intro.js-react";
import { useCookies } from "react-cookie";
import {
  Wrapper,
  QuestionDiv,
  Header,
  MainContainer,
  Footer,
  FooterControls,
  FooterControl,
  DragDots,
  DraggerContainer,
  DragDotsBox,
  DragDot,
  MainContent,
  QuestionBodyContainer,
} from "./style";
import {
  QuestionKeyToIcon,
  QuestionKeyToName,
} from "../../../../../common/utils/questionType";
import { ReactComponent as DuplicateIcon } from "./assets/copy.svg";
import { ReactComponent as DeleteIcon } from "./assets/delete.svg";
import QuestionBody from "./QuestionBody";
import type { SelectedQuestion } from "../../../../../../pages/Design/types";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import {
  setConfigurationType,
  setSelectedQuestion,
  setSelectedSection,
} from "../../../../../../pages/Design/designSlice";
import {
  useDeleteQuestionMutation,
  useDuplicateQuestionMutation,
} from "../../../../../../app/apiSlice";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import Loader from "../../../../../common/Loader";

interface HeaderProps {
  questionType:
    | "ST"
    | "LT"
    | "MC"
    | "Ch"
    | "Dd"
    | "SR"
    | "IC"
    | "Sl"
    | "Rk"
    | "MRS"
    | "DT";
  questionNo: number;
  questionId: string;
  surveyId: string;
  questionText: string;
  questionObj: SelectedQuestion;
}

const DraggerBox = () => {
  return (
    <DraggerContainer>
      <DragDots>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
        <DragDotsBox>
          <DragDot />
        </DragDotsBox>
      </DragDots>
    </DraggerContainer>
  );
};

const Question = ({
  questionType,
  questionNo,
  questionText,
  questionObj,
  questionId,
  surveyId,
}: HeaderProps) => {
  const { selectedQuestion } = useAppSelector((state) => state.design);

  const [selectedQuestionId, setSelectedQuestionId] = useState(null);
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [cookies, setCookie] = useCookies(["config-intro"]);

  const steps = [
    {
      element: '[data-config="editing"]',
      intro:
        "Use the editing pane to apply the specific options available for each unique question type (e.g., suggested choices, vertical or horizontal positioning, force response, etc.).",
      position: "right",
    },
    {
      element: '[data-config="answer-types"]',
      intro:
        "Answer Wizard: Available for multiple choice questions, use the Answer Wizard to automatically add a set of prewritten answer choices to your question. Saves you lots of time!",
      position: "right",
    },
    // {
    //   element: '[data-config="editing"]',
    //   intro:
    //     "Use the editing pane to apply the specific options available for each unique question type (e.g., suggested choices, vertical or horizontal positioning, force response, etc.).",
    //   position: "left",
    // },
    // {
    //   // element: '[data-config="answer-types"]',
    //   intro:
    //     "Answer Wizard: Available for multiple choice questions, use the Answer Wizard to automatically add a set of prewritten answer choices to your question. Saves you lots of time!",
    //   position: "left",
    // },
  ];

  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const [deleteQuestion, { isLoading: isDeleting }] =
    useDeleteQuestionMutation();

  const [duplicateQuestion, { isLoading: isDuplicating }] =
    useDuplicateQuestionMutation();

  useEffect(() => {
    setSelectedQuestionId(selectedQuestion?.questionId);
  }, [selectedQuestion]);

  const onClick = () => {
    dispatch(setSelectedQuestion(questionObj));
    dispatch(setSelectedSection(null));
    dispatch(setConfigurationType("QUESTION"));
    setStepsEnabled(true);
  };

  const onDeleteClick = async (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
  }) => {
    try {
      e.preventDefault();
      e.stopPropagation();
      await deleteQuestion({
        surveyId,
        questionId,
      }).unwrap();
      onSuccessShowAlert("Question deleted");
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be deleted");
    }
  };

  const onDuplicateClick = async () => {
    try {
      await duplicateQuestion({
        surveyId,
        questionId,
      }).unwrap();
      onSuccessShowAlert("Question duplicated");
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be duplicated");
    }
  };

  return (
    <Wrapper onClick={onClick} $isSelected={selectedQuestionId === questionId}>
      <Loader isLoading={isDeleting || isDuplicating} />
      {!cookies["config-intro"] && (
        <Steps
          enabled={stepsEnabled}
          steps={steps}
          initialStep={0}
          onExit={() => {
            setStepsEnabled(false);
          }}
          onComplete={() =>
            setCookie("config-intro", "config-intro", { path: "/" })
          }
          options={{
            showStepNumbers: true,
            exitOnEsc: true,
            disableInteraction: true,
            doneLabel: "Get started",
            autoPosition: true,
          }}
        />
      )}

      <MainContainer>
        <DraggerBox />
        <MainContent>
          <Header>
            <span>{questionNo}. </span>
            <div>{parse(questionText || "")}</div>
          </Header>
          <QuestionBodyContainer>
            <QuestionBody
              questionType={questionType}
              questionObj={questionObj}
            />
          </QuestionBodyContainer>
          <Footer>
            <QuestionDiv>
              {QuestionKeyToIcon[questionType]}
              <span>{QuestionKeyToName[questionType]}</span>
            </QuestionDiv>
            <FooterControls>
              <FooterControl onClick={onDuplicateClick}>
                <DuplicateIcon />
              </FooterControl>
              <FooterControl onClick={onDeleteClick}>
                <DeleteIcon />
              </FooterControl>
            </FooterControls>
          </Footer>
        </MainContent>
      </MainContainer>
    </Wrapper>
  );
};

export default Question;
