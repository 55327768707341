import styled from "styled-components";

export const NavContainer = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  column-gap: 1rem;
  cursor: pointer;
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #222222;
  margin-bottom: 0px;
`;
