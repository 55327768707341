import React from "react";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import {
  Wrapper,
  MediaButtons,
  MediaButton,
  MediaButtonImage,
  MediaButtonText,
  MediaButtonTitle,
} from "./style";
import twitterIcon from "../../../../assets/twitter.png";
import facebookIcon from "../../../../assets/facebook.png";
import whatsappIcon from "../../../../assets/whatsapp.png";
import linkedinIcon from "../../../../assets/linkedin.png";
import slackIcon from "../../../../assets/slack.png";
import { useAddNewCollectorMutation } from "../../../../../../app/apiSlice";
import type { CollectorType } from "../../../../../../pages/CollectResponses/types";
import { capitalizeFirstLetter } from "../../../../../../utils/helpers";
import Loader from "../../../../../common/Loader";

interface MediaButtonBoxProps {
  id: string;
  name: string;
  image: any;
  onClick: (e) => void;
  footerText: string;
}

const MediaButtonBox = ({
  id,
  name,
  image,
  onClick,
  footerText,
}: MediaButtonBoxProps) => {
  return (
    <MediaButton data-media={id} onClick={onClick}>
      <MediaButtonTitle>{name}</MediaButtonTitle>
      <MediaButtonImage src={image} />
      <MediaButtonText>{footerText}</MediaButtonText>
    </MediaButton>
  );
};

const MediaOptions = ({ collectors = [] }: { collectors: CollectorType[] }) => {
  const { surveyId } = useParams();

  const [addNewCollector, { isLoading: isAddingCollector }] =
    useAddNewCollectorMutation();

  const onMediaClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const mediaType = e.currentTarget.getAttribute("data-media");
    const newCollectorId = nanoid();
    await addNewCollector({
      collectorId: newCollectorId,
      name: `${capitalizeFirstLetter(mediaType)} ${collectors.length + 1}`,
      collectorType: "SOCIAL",
      socialType: mediaType.toUpperCase(),
      startDate: dayjs().format("DD-MM-YYYY"),
      endDate: null,
      endTime: "12:00:00",
      surveyId,
      isManuallyClosed: false,
      manualStatus: "ACTIVE",
    }).unwrap();
  };

  return (
    <Wrapper>
      <Loader isLoading={isAddingCollector} />
      <MediaButtons>
        <MediaButtonBox
          id="twitter"
          data-media="twitter"
          onClick={onMediaClick}
          name="Twitter"
          footerText="Post survey link to Twitter"
          image={twitterIcon}
        />
        <MediaButtonBox
          id="facebook"
          data-media="facebook"
          onClick={onMediaClick}
          name="Facebook"
          footerText="Post survey link to Facebook"
          image={facebookIcon}
        />
        <MediaButtonBox
          id="whatsapp"
          data-media="whatsapp"
          onClick={onMediaClick}
          name="WhatsApp"
          footerText="Share survey link to your WhatsApp contacts"
          image={whatsappIcon}
        />
        <MediaButtonBox
          id="linkedin"
          data-media="linkedin"
          onClick={onMediaClick}
          name="Linkedin"
          footerText="Post survey link to Linkedin page"
          image={linkedinIcon}
        />
        <MediaButtonBox
          id="slack"
          data-media="slack"
          onClick={onMediaClick}
          name="Slack"
          footerText="Post survey link as Slack message"
          image={slackIcon}
        />
      </MediaButtons>
    </Wrapper>
  );
};

export default MediaOptions;
