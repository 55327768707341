import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 28px 30px 100px;
  overflow-y: auto;
`;

export const Header = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-text-black);
`;

export const Subheader = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-text-grey);
  margin-bottom: 48px;
`;

export const Content = styled.form`
  width: min(700px, 94%);
  height: 100%;
  //overflow-y: auto;
`;

export const Section = styled.div`
  margin-bottom: 32px;
`;

export const SectionTitle = styled.p`
  font-weight: 200;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-red-color);
  margin-bottom: 10px;
`;

export const SectionSubtitle = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-text-grey);
`;

export const FormatBody = styled.div`
  margin-top: 15px;
  & label[class~="ant-radio-button-wrapper"] {
    border-color: unset !important;
    border-right-color: unset !important;
    background-color: transparent !important;
    color: var(--primary-text-grey) !important;
    border: none !important;
    width: 120px !important;
    height: 66px !important;
  }

  & div[aria-label="classic"],
  & div[aria-label="onetime"] {
    border: 1px solid #b3b0ad;
    border-radius: 4px;
    width: 120px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & span[title="radio-label"] {
    margin-top: 8px;
  }
  & span[class~="ant-radio-checked"]::after {
    border: 1px solid var(--primary-red-color) !important;
  }
  & span[class~="ant-radio-inner"] {
    border-color: var(--primary-red-color) !important;
  }
  & span[class~="ant-radio-inner"]::after {
    background-color: var(--primary-red-color) !important;
  }
`;

export const LanguageBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  & p {
    margin-right: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }

  & div[class~="ant-select"] {
    width: 200px;
  }
`;

export const NotificationsBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: min(100%, 430px);
  justify-content: space-between;
  & p {
    margin-right: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`;

export const DisclaimerDisqualifyBody = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
  width: min(100%, 430px);
  justify-content: space-between;
  & p {
    margin-right: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
`;

export const PostBody = styled.div`
  padding-bottom: 40px;
`;

export const TextAreaContainer = styled.div`
  width: min(98%, 700px);
  margin-top: 16px;
  & textarea[class="ant-input"] {
    border: none;
    border-radius: 0;
    padding: 5px 7px;

    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
  }

  & textarea::placeholder,
  & textarea::-webkit-input-placeholder,
  & textarea:-ms-input-placeholder,
  & textarea::-ms-input-placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export const ButtonContainer = styled.div`
  padding-bottom: 40px;
  //margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
