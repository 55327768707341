import React from "react";
import { NavLink, useParams } from "react-router-dom";
import { Left, NavLinks, Right, Wrapper } from "./style";
import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left.svg";
import styles from "./DesignNav.module.css";

export const DesignNav = () => {
  const params = useParams();
  const { surveyId } = params;

  const onPresentClick = (e) => {
    e.preventDefault();
  };
  return (
    <Wrapper>
      <Left>
        <NavLink to={"/user/dashboard"}>
          <ArrowLeft />
        </NavLink>
      </Left>
      <NavLinks>
        <NavLink
          to={`/${surveyId}/design/questions`}
          className={({ isActive }) => (isActive ? styles.isActive : undefined)}
        >
          Design survey
        </NavLink>
        <NavLink
          to={`/${surveyId}/preview`}
          className={({ isActive }) => (isActive ? styles.isActive : undefined)}
        >
          Preview
        </NavLink>
        <NavLink
          to={`/${surveyId}/collect`}
          className={({ isActive }) => (isActive ? styles.isActive : undefined)}
        >
          Collect responses
        </NavLink>
        <NavLink
          to={`/${surveyId}/analytics`}
          className={({ isActive }) => (isActive ? styles.isActive : undefined)}
        >
          Analyse results
        </NavLink>
        <NavLink
          style={{ opacity: 0.4 }}
          aria-disabled
          onClick={onPresentClick}
          to={`/${surveyId}/present`}
          className={({ isActive }) => (isActive ? styles.isActive : undefined)}
        >
          Present results
        </NavLink>
      </NavLinks>
      <Right></Right>
    </Wrapper>
  );
};
