import React, { useContext, useEffect } from "react";
import { ChromePicker } from "react-color";
import { ColorDiv, CoverDiv, PopoverDiv, SwatchDiv, Wrapper } from "./style";
import { ChartContext } from "../../../Analytics/context/ChartContext";

const AnalyticsColorPicker = ({
  defaultColor = "#2fff00",
  index = null,
}: {
  defaultColor: string;
  index: number;
}) => {
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [selectedColorHex, setSelectedColorHex] = React.useState(defaultColor);

  const { onSingleColorChange } = useContext(ChartContext);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color) => {
    setSelectedColorHex(color.hex);
    onSingleColorChange(index, color.hex);
  };

  useEffect(() => {
    setSelectedColorHex(defaultColor);
  }, [defaultColor]);

  return (
    <Wrapper>
      <SwatchDiv onClick={handleClick}>
        <ColorDiv $backgroundColor={selectedColorHex} />
      </SwatchDiv>
      {displayColorPicker ? (
        <PopoverDiv>
          <CoverDiv onClick={handleClose} />
          <ChromePicker color={selectedColorHex} onChange={handleChange} />
        </PopoverDiv>
      ) : null}
    </Wrapper>
  );
};

export default AnalyticsColorPicker;
