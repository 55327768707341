import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

export const Radio = styled.div`
  border-radius: ${(props: { $isCheckbox: boolean }) =>
    props.$isCheckbox ? "none" : "50%"};
  width: 20px;
  height: 20px;
  border: 1px solid #000000;
  margin-right: 15px;
`;

export const ImageDiv = styled.div`
  width: 100px;
  height: 100px;
  & > img {
    width: 100%;
    height: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Text = styled.p`
  margin-bottom: unset;
  text-align: left;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
`;
