import { SelectedQuestion } from "../../../../pages/Design/types";

export enum QuestionTypeEnum {
  ST = "ST",
  LT = "LT",
  MC = "MC",
  Ch = "Ch",
  Dd = "Dd",
  SR = "SR",
  IC = "IC",
  Sl = "Sl",
  Rk = "Rk",
  Rs = "MRS",
  DT = "DT",
}

export const initialQuestion: SelectedQuestion = {
  questionId: undefined,
  hasLogic: false,
  text: "Input question text",
  type: undefined,
  isRequired: false,
  requiredMessage: "This question requires an answer.",
  hasRandomizedChoices: false,
  rowOptions: [
    { index: 0, value: "" },
    { index: 1, value: "" },
  ],
  colOptions: [
    { index: 0, value: "", weight: 1 },
    { index: 1, value: "", weight: 2 },
  ],
  sliderOptions: {
    labels: {
      left: "0",
      center: "",
      right: "100",
    },
    scale: {
      min: 0,
      max: 100,
      step: 1,
    },
    showAdjustScale: false,
    showInput: true,
    startPosition: "LEFT",
  },
  dateOptions: {
    hasDateInfo: true,
    hasTimeInfo: false,
    dateFormat: "DMY",
    dateRowOptions: [{ index: 0, value: "" }],
  },
  starRatingOptions: {
    starRatingColor: "#ce375c",
    // starRatingColor: "#f5a623",
    starRatingCount: 5,
    starRatingShape: "Star",
    labels: [
      { index: 0, value: "" },
      { index: 1, value: "" },
      { index: 2, value: "" },
      { index: 3, value: "" },
      { index: 4, value: "" },
    ],
    hasLabels: false,
  },
  imageOptions: {
    imageRowOptions: [
      { index: 0, value: "", url: "" },
      { index: 1, value: "", url: "" },
    ],
    isCheckbox: false,
  },
  matrixScaleOptions: {
    isMatrixRatingWeighted: true,
    allowsMultiResponsesPerRow: false,
  },
  checkboxOptions: { choicesType: undefined, choicesNumber: undefined },
};
