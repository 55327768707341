import React, { useContext, useEffect, useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ChartContext } from "../../../context/ChartContext";
import type { SingleChoiceChartData } from "../../../context/types";
import type { ChartRef } from "../types";

function AreaChart({ theRef }: ChartRef) {
  const [cData, setCData] = useState([]);

  const {
    loading,
    headerText,
    resultData: chartData,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    legendLayout,
    dataLabelsVisible,
    selectedColorList,
    sortType,
    dataLabelsPosition,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
    headerTextAlignment,
  } = useContext(ChartContext);
  const options = useMemo(() => {
    if (!loading) {
      return {
        chart: {
          type: "area",
          style: {
            fontFamily: "Mulish",
          },
        },
        colors: selectedColorList,
        title: {
          text: headerText,
          align: headerTextAlignment,
        },
        tooltip: {
          headerFormat: "",
          backgroundColor: "#222222",
          pointFormat:
            '<div><p style="font-size: 10px;margin-bottom: 8px;">{point.name}</p></br>' +
            '<p style="font-size: 14px;">{point.y}</p></div>',
          style: { color: "#ffffff" },
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          area: {
            // pointStart: 1940,
            marker: {
              enabled: false,
              symbol: "circle",
              radius: 2,
              states: {
                hover: {
                  enabled: true,
                },
              },
            },
            dataLabels: {
              enabled: dataLabelsVisible,
              // eslint-disable-next-line consistent-return
              formatter() {
                // eslint-disable-next-line react/no-this-in-sfc
                if (this.y > 0) return this.y;
              },
            },
          },
        },
        series: [
          {
            name: "Responses",
            data: cData,
          },
        ],

        xAxis: {
          type: "category",
          title: { text: xAxisLabel },
          visible: showXaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
          // gridLineWidth: 1,
        },
        yAxis: {
          labels: {
            style: "none",
          },
          title: { text: yAxisLabel },
          visible: showYaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
        },
        legend: {
          align: legendHorPosition,
          verticalAlign: legendVerPosition,
          layout: legendLayout,
          enabled: legendVisible,
          symbolRadius: legendSymbolShape === "square" ? 0 : null,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
      };
    }
    return {};
  }, [
    loading,
    legendLayout,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    dataLabelsVisible,
    chartData,
    headerText,
    selectedColorList,
    sortType,
    cData,
    dataLabelsPosition,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
  ]);

  useEffect(() => {
    const formattedData = chartData.map((da: SingleChoiceChartData, index) => ({
      id: da.key,
      name: da.value,
      y: da.responses,
      x: index,
    }));
    setCData(formattedData);
  }, [JSON.stringify(chartData)]);

  return (
    <>
      <HighchartsReact ref={theRef} highcharts={Highcharts} options={options} />
    </>
  );
}

export default AreaChart;
