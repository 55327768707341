import React, { useState } from "react";
import { ButtonWrapper, Content, Wrapper } from "./style";
import { ReactComponent as VisiblePassword } from "./assets/visible-password.svg";
import { ReactComponent as InvisiblePassword } from "./assets/invisible-password.svg";
import { InputProps, PasswordIconProps } from "./types";

const PasswordIcon = ({ isVisible, onPasswordToggle }: PasswordIconProps) => {
  return (
    <ButtonWrapper type="button" onClick={onPasswordToggle}>
      {isVisible ? <VisiblePassword /> : <InvisiblePassword />}
    </ButtonWrapper>
  );
};

export const Input = ({
  label,
  type = "text",
  field,
  onClick,
  onChange,
  disableLabel = false,
  readonly = false,
  required = false,
  defaultValue,
  placeholder,
}: InputProps) => {
  const [passWordIsVisible, setPassWordIsVisible] = useState(false);
  const [passwordTypeState, setPasswordTypeState] = useState("password");
  const onPasswordToggle = () => {
    setPassWordIsVisible(!passWordIsVisible);
    setPasswordTypeState(!passWordIsVisible ? "text" : "password");
  };

  return (
    <Wrapper>
      {!disableLabel && (
        <p>
          {label}
          {required && "*"}
        </p>
      )}
      <Content>
        <input
          placeholder={placeholder || label}
          defaultValue={defaultValue}
          readOnly={readonly}
          type={type === "password" ? passwordTypeState : type}
          {...field}
          onClick={onClick && onClick}
          value={field?.value || ""}
          onChange={(e) => {
            field.onChange(e);
            onChange && onChange(e);
          }}
        />
        {type === "password" && (
          <PasswordIcon
            isVisible={passWordIsVisible}
            onPasswordToggle={onPasswordToggle}
          />
        )}
      </Content>
    </Wrapper>
  );
};
