import styled from "styled-components";
// import { Rate } from "antd";

export const Wrapper = styled.div`
  width: min(1000px, 90%);
`;

export const ContentBox = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.07);
  width: 100%;
  padding: 15px 30px;
  margin-bottom: 25px;
`;

export const ContentBoxTop = styled.div``;

export const ContentBoxFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  background-color: transparent;
  //border: 1px solid #d1d1d1;
  display: flex;
  align-items: center;
  padding: 3px;
  cursor: pointer;
  border: none;
  & span {
    margin-left: 5px;
  }
`;

export const RespondentBox = styled.div`
  display: flex;
  align-items: center;

  & p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #3f4252;
  }

  & > p:first-child {
    margin-right: 5px;
  }
`;

export const ChartContainer = styled.div`
  min-height: 150px;
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const QuestionText = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #222539;
  margin-right: 20px;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
`;

export const DashboardButton = styled.button`
  //background: #374e63;
  background: #ffffff;
  border-radius: 4px;
  height: 36px;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  color: #000000;
  border: 1px solid black;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ExportButton = styled.button`
  background: #008060;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.08),
    inset 0px -1px 0px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  padding: 8px 8px 8px 8px;
  cursor: pointer;
  color: #ffffff;
  border: unset;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
