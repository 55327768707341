import React, { useState, createContext, useMemo, ReactNode } from "react";
import { Dayjs } from "dayjs";
import type {
  QuestionChartData,
  QuestionOption,
  QuestionType,
  RespondentAnswerCount,
  StarRatingOptions,
} from "../../components/Analytics/context/types";

export interface FormattedAnswer {
  qNum: number;
  qText: string;
  qId: string;
  qType: QuestionType;
  result: QuestionChartData[];
  qOptions?: QuestionOption[];
  respondents: RespondentAnswerCount;
  extraOptions: StarRatingOptions;
}

export const AnalyticsContext = createContext<
  | {
      filterDateRange: string[];
      filterDateRangeObj: any;
      onDateChange: (a, b) => void;
      formattedAnalyses: FormattedAnswer[];
      onUpdateAnalysesList: (a) => void;
      onReset: () => void;
    }
  | undefined
>(undefined);

const AnalyticsProvider = ({ children }: { children: ReactNode }) => {
  const [formattedAnalyses, setFormattedAnalyses] = useState([]);
  const [filterDateRangeObj, setFilterDateRangeObj] = useState<Dayjs[]>([]);
  const [filterDateRange, setFilterDateRange] = useState<string[]>([]);

  const onUpdateAnalysesList = (newAnalyses) => {
    setFormattedAnalyses(newAnalyses);
  };

  const onDateChange = (dates: Dayjs[], dateStrings: [string, string]) => {
    setFilterDateRange(dateStrings);
    setFilterDateRangeObj(dates);
  };

  const onReset = () => {
    setFilterDateRangeObj([]);
  };

  const value = useMemo(
    () => ({
      formattedAnalyses,
      onUpdateAnalysesList,
      onDateChange,
      filterDateRange,
      filterDateRangeObj,
      onReset,
    }),
    [formattedAnalyses, filterDateRange, filterDateRangeObj]
  );

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export default AnalyticsProvider;

export {};
