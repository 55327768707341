import React, { useContext } from "react";
import { Input, Radio, Select } from "antd";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  ButtonContainer,
  Content,
  DisclaimerDisqualifyBody,
  FormatBody,
  Header,
  LanguageBody,
  NotificationsBody,
  PostBody,
  Section,
  SectionSubtitle,
  SectionTitle,
  Subheader,
  TextAreaContainer,
  Wrapper,
} from "./style";
import { ReactComponent as ClassicFormat } from "./icons/classic.svg";
import { ReactComponent as OneFormat } from "./icons/one-time.svg";
import CustomSwitch from "../common/CustomSwitch";
import { Button } from "../common/Button";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { useUpdateSurveyMutation } from "../../app/apiSlice";
import { useParams } from "react-router-dom";
import Loader from "../common/Loader";
import { useAppSelector } from "../../app/hooks";

type Inputs = {
  format: "classic" | "one-time";
  closingMessage: string;
  disclaimer: string;
  notifications: boolean;
  disclaimerDisqualify: boolean;
  language: "english" | "french" | "mandarin";
};

const languageOptions = [
  { label: "English", value: "english" },
  { label: "French", value: "french" },
  { label: "Mandarin", value: "mandarin" },
];

const SurveySettings = () => {
  const { surveyId } = useParams();
  const { surveySettings } = useAppSelector((state) => state.design);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      format: surveySettings?.displayFormat,
      closingMessage: surveySettings?.closingMessage,
      language: surveySettings?.language,
      disclaimer: surveySettings?.disclaimer,
      disclaimerDisqualify: surveySettings?.disclaimerDisqualify || false,
      notifications: surveySettings?.notification || false,
    },
  });
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const [editSurvey, { isLoading: isUpdatingSurvey }] =
    useUpdateSurveyMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const configVals = {
        closingMessage: data.closingMessage,
        displayFormat: data.format,
        language: data.language,
        notifEmail: "",
        notification: data.notifications,
        disclaimerDisqualify: data.disclaimerDisqualify,
        disclaimer: data.disclaimer,
      };
      const surveyValues = { surveyConfig: configVals };

      await editSurvey({
        surveyId,
        updatedSurvey: surveyValues,
      }).unwrap();
      onSuccessShowAlert("Settings updated");
    } catch (err) {
      onShowErrorAlert(err, "The settings couldn't be saved");
    }
  };

  return (
    <Wrapper>
      <Loader isLoading={isUpdatingSurvey} />
      <Content onSubmit={handleSubmit(onSubmit)}>
        <Header>Survey settings</Header>
        <Subheader>
          Survey options allow you to change many default survey settings,
        </Subheader>
        <Section>
          <SectionTitle>Survey format</SectionTitle>
          <SectionSubtitle>
            Refers to the way your survey displays to people and how they
            interact with it
          </SectionSubtitle>
          <FormatBody>
            <Controller
              name="format"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => (
                <Radio.Group {...field} value={field.value || "classic"}>
                  <Radio value="classic">
                    <div aria-label="classic">
                      <ClassicFormat />
                    </div>
                    <span title="radio-label">Classic</span>
                  </Radio>
                  <Radio value="one-time">
                    <div aria-label="onetime">
                      <OneFormat />
                    </div>
                    <span title="radio-label">One question at a time</span>
                  </Radio>
                </Radio.Group>
              )}
            />
          </FormatBody>
        </Section>
        <Section>
          <SectionTitle>Language</SectionTitle>
          <SectionSubtitle>
            Switches certain default survey text—like navigation buttons and the
            survey end page—to another language.
          </SectionSubtitle>
          <LanguageBody>
            <p>Language</p>
            <Controller
              name="language"
              control={control}
              render={({ field }) => (
                <Select {...field} options={languageOptions} />
              )}
            />
          </LanguageBody>
        </Section>
        <Section>
          <SectionTitle>Notification</SectionTitle>
          <SectionSubtitle>
            Get notified of your survey status and progress
          </SectionSubtitle>
          <NotificationsBody>
            <p>Get email notifications</p>
            <Controller
              name="notifications"
              control={control}
              render={({ field }) => <CustomSwitch field={field} />}
            />
          </NotificationsBody>
        </Section>
        <Section>
          <SectionTitle>Disclaimer</SectionTitle>
          <SectionSubtitle>
            Outline what participants can expect from volunteering their
            information for this survey.
          </SectionSubtitle>
          <TextAreaContainer>
            <Controller
              name="disclaimer"
              control={control}
              render={({ field }) => (
                <Input.TextArea rows={3} placeholder="Disclaimer" {...field} />
              )}
            />
          </TextAreaContainer>
          <DisclaimerDisqualifyBody>
            <p>Survey disqualification:</p>
            <Controller
              name="disclaimerDisqualify"
              control={control}
              render={({ field }) => <CustomSwitch field={field} />}
            />
          </DisclaimerDisqualifyBody>
          <p>
            Toggling on will automatically disqualify a participant from taking
            the survey if they object to the above disclaimer.
          </p>
        </Section>
        <Section>
          <SectionTitle>Post survey</SectionTitle>
          <SectionSubtitle>
            Send a message to respondents after they finish your survey.
          </SectionSubtitle>
          <PostBody>
            <TextAreaContainer>
              <Controller
                name="closingMessage"
                control={control}
                render={({ field }) => (
                  <Input.TextArea
                    rows={3}
                    placeholder="Closing message"
                    {...field}
                  />
                )}
              />
            </TextAreaContainer>
          </PostBody>
        </Section>
        <ButtonContainer>
          <Button label="Save" width="80px" height="40px" type="submit" />
        </ButtonContainer>
      </Content>
    </Wrapper>
  );
};

export default SurveySettings;
