import React, { useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Header,
  Wrapper,
  Title,
  Footer,
  Content,
  FooterButtons,
  ModalSelect,
  SelectWrapper,
  FooterButtonsLeft,
} from "./style";
import { Input } from "../../../../components/common/Input";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import type { CreateGroupInterface, FormInputs } from "./type";
import { useAddNewContactGroupMutation } from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";

const CreateGroup = ({
  onSaveExitGroup,
  onSubmit,
  onCancel,
}: CreateGroupInterface) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInputs>();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const [addNewGroup, { isLoading: isAddingNew }] =
    useAddNewContactGroupMutation();

  const onSaveAndExit: SubmitHandler<FormInputs> = async (data) => {
    try {
      await addNewGroup(data).unwrap();
      onSuccessShowAlert("Group created");
      onSaveExitGroup();
    } catch (err) {
      onShowErrorAlert(err, "Failed to create group");
    }
  };

  const onContinue: SubmitHandler<FormInputs> = async (data) => {
    try {
      const res = await addNewGroup(data).unwrap();
      onSuccessShowAlert("Group created");
      onSubmit(res?.contacts?.contactsId);
    } catch (err) {
      onShowErrorAlert(err, "Failed to create group");
    }
  };

  return (
    <Wrapper>
      <Loader isLoading={isAddingNew} />
      <Header>
        <Title>Create group</Title>
      </Header>
      <Content>
        <Controller
          name="name"
          control={control}
          render={({ field }) => <Input label="Group name" field={field} />}
        />
        <Controller
          name="category"
          control={control}
          render={({ field }) => (
            <SelectWrapper>
              <p>Category</p>
              <ModalSelect
                $width="100%"
                {...field}
                options={[
                  { value: "market_product", label: "Market/ Product" },
                  { value: "hr", label: "Human resources" },
                  { value: "customer", label: "Customer" },
                  { value: "others", label: "Others" },
                ]}
              />
            </SelectWrapper>
          )}
        />
      </Content>
      <Footer>
        <FooterButtonsLeft>
          <p>Not ready to import?</p>
          <button type="button" onClick={handleSubmit(onSaveAndExit)}>
            Save & exit
          </button>
        </FooterButtonsLeft>
        <FooterButtons>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <button type="button" onClick={handleSubmit(onContinue)}>
            Continue
          </button>
        </FooterButtons>
      </Footer>
    </Wrapper>
  );
};

export default CreateGroup;
