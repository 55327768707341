import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background: linear-gradient(0deg, #ffffff, #ffffff), #e0e0e0;
  border: 1px solid #e0e0e0;
  padding: 15px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const QuestionTitle = styled.div`
  border: 1px solid #e4e7ec;
  border-radius: 8px;
  height: 40px;
  width: min(70%, 850px);
  display: flex;
  align-items: center;
  padding: 0 10px;
  & span {
    font-weight: 800;
    font-size: 14px;
    line-height: 26px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const DeleteButton = styled.button`
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Body = styled.div`
  margin-top: 5px;
`;

// export const Body = styled.div``;
