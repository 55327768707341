import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0 40px;
  height: unset;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-black-color);
  margin-bottom: 16px;
`;

export const Weblinks = styled.div`
  margin-top: 30px;
  & > div {
    margin-bottom: 20px;
  }
`;

export const NewLinkButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 12px;
  border: none;
  margin-bottom: 40px;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
`;
