import React, { ReactElement, useEffect, useState } from "react";
import { Table, Dropdown } from "antd";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/ellipsis.svg";
import { EllipsisContainer, TableTitle, Wrapper } from "./style";
import type { CollectorType } from "../../../../pages/CollectResponses/types";
import type { ResponsesByCollectors } from "../../../../pages/CollectResponses/types";

type TableData = {
  key: string | number;
  url: string;
  name: string;
  responses?: number;
  start_date: string;
  end_date: string;
  status: string;
};

interface CollectorTableProps {
  menuComponent: (value: any) => ReactElement;
  collectorsData: CollectorType[];
  title?: string;
  responsesByCollectors: ResponsesByCollectors;
}

const CollectorTable = ({
  menuComponent,
  collectorsData,
  title = "Collectors",
  responsesByCollectors,
}: CollectorTableProps) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (collectorsData && collectorsData.length) {
      const formattedData = collectorsData.map((col) => {
        const responseCount =
          responsesByCollectors[col.collectorId]?.length || 0;
        return {
          ...col,
          key: col.collectorId,
          name: col.name,
          responses: responseCount,
          start_date: col.startDate,
          end_date: col.endDate || "--",
          status: col.manualStatus,
        };
      });
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [collectorsData, responsesByCollectors]);
  const columns = [
    {
      title: "Collector name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Responses",
      key: "responses",
      dataIndex: "responses",
    },
    {
      title: "Start date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <span>{value}</span>,
    },
    // {
    //   title: "Last updated",
    //   dataIndex: "modified",
    //   key: "modified",
    // },
    {
      title: "",
      key: "action",
      render: (record: TableData) => (
        <Dropdown overlay={menuComponent(record)}>
          <EllipsisContainer>
            <EllipsisIcon />
          </EllipsisContainer>
        </Dropdown>
      ),
    },
  ];

  return (
    <Wrapper>
      <TableTitle>{title}</TableTitle>
      <Table
        columns={columns}
        bordered
        dataSource={tableData}
        pagination={false}
      />
    </Wrapper>
  );
};

export default CollectorTable;
