import React, { useState } from "react";
import { Popover } from "antd";
import { Option, OptionList, OptionsContainer, Wrapper } from "./style";
import { ReactComponent as DeleteIcon } from "../../../../icons/delete-circle.svg";
import type { OptionType } from "../../../../../common/CustomSelect";

interface Props {
  options: OptionType[];
  onHide: () => void;
}

const OptionsList = ({ options, onHide }: Props) => {
  return (
    <OptionsContainer>
      <button onClick={onHide}>Content</button>
      <button onClick={onHide}>Content</button>
    </OptionsContainer>
  );
};

const OptionsDropdown = ({ options }: { options: OptionType[] }) => {
  const [isVisible, setIsVisible] = useState(false);

  const onClick = () => {
    setIsVisible(!isVisible);
  };

  const onHide = () => {
    setIsVisible(false);
  };

  return (
    <Popover
      content={<OptionsList options={[]} onHide={onHide} />}
      title={null}
      trigger="click"
      placement="bottomLeft"
      visible={isVisible}
    >
      <Wrapper onClick={onClick}>
        <OptionList>
          <Option>
            <span>Very likely</span>
            <button>
              <DeleteIcon />
            </button>
          </Option>
          <Option>
            <span>Very unlikely</span>
            <button>
              <DeleteIcon />
            </button>
          </Option>
          <Option>
            <span>Extremely likely</span>
            <button>
              <DeleteIcon />
            </button>
          </Option>
        </OptionList>
      </Wrapper>
    </Popover>
  );
};

export default OptionsDropdown;
