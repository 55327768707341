import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const AnswerBox = styled.div`
  & svg {
    margin-right: 10px;
  }
`;
