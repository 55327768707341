import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const AnswerBox = styled.div`
  width: min(200px, 90%);
  height: 40px;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
`;
