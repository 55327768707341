import React, { ChangeEventHandler } from "react";
import { Wrapper } from "./style";

const QuestionFormInput = (props: any) => {
  const onChange = (e: any) => {
    props.onChange(props.index, e.target.value);
  };
  return <Wrapper {...props} onChange={onChange} />;
};

export default QuestionFormInput;
