import React, { useContext, useEffect, useState } from "react";
import { Radio, Select } from "antd";
import { ChartContext } from "../../../../context/ChartContext";
import {
  Body,
  ColorIcon,
  ColorLabel,
  ColorListBox,
  ColorListContainer,
  Left,
  Right,
  Section,
  SubDiv,
  Title,
  Wrapper,
} from "./style";
import {
  distinctive,
  diverging,
  multiSequential,
  singleSequential,
} from "./colors";
import { legendPositionOptions } from "../../../../utils";
import CustomSlider from "../../../../../common/CustomSlider";
import CustomRadio from "../../../../../common/CustomRadio";
import Switch from "../../../../../common/Switch";
import Dropdown from "../../../../../common/Dropdown";
import AnalyticsColorPicker from "../../../../../common/colorPickers/Analytics";

const { Option, OptGroup } = Select;
const Design = () => {
  const {
    chartSelected,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    colorFamilySelected,
    onSetLegendPosition,
    setLegendSymbolShape,
    setLegendVisible,
    selectedColorListObj,
    donutInnerRadius,
    countPercentageFormat,
    onDataLabelFormatChange,
    setDonutInnerRadius,
    onColorFamilyChange,
  } = useContext(ChartContext);

  const [legendPosition, setLegendPosition] = useState(null);

  useEffect(() => {
    setLegendPosition(`${legendVerPosition}-${legendHorPosition}`);
  }, [legendVerPosition, legendHorPosition]);

  const onShowLegendToggle = (value) => {
    setLegendVisible(value);
  };

  const onLegendSymbolChange = (value) => {
    setLegendSymbolShape(value);
  };

  const onLegendPositionChange = (value) => {
    const [verticalPos, horizontalPos] = value.split("-");
    onSetLegendPosition({
      verticalPosition: verticalPos,
      horizontalPosition: horizontalPos,
    });
  };

  return (
    <Wrapper>
      <Left>
        <Section $show={["donut"].includes(chartSelected)}>
          <Body>
            <Title>Inner radius</Title>
            <SubDiv $orientation="horizontal">
              <CustomSlider
                max={0.9}
                onChange={setDonutInnerRadius}
                width="70%"
              />
              <p>{donutInnerRadius}</p>
            </SubDiv>
          </Body>
        </Section>
        <Section $show={["donut", "pie", "image"].includes(chartSelected)}>
          <Title>Label format</Title>
          <Body>
            <SubDiv $orientation="vertical">
              <Radio.Group
                onChange={onDataLabelFormatChange}
                value={countPercentageFormat}
              >
                <CustomRadio value="count">Count</CustomRadio>
                <CustomRadio value="percentage">Percentage</CustomRadio>
              </Radio.Group>
            </SubDiv>
          </Body>
        </Section>
        <Section
          $show={
            !["cloud", "star", "list", "image", "dtList"].includes(
              chartSelected
            )
          }
        >
          <Title>LEGEND</Title>
          <Body>
            <SubDiv $orientation="horizontal">
              <Switch onChange={onShowLegendToggle} checked={legendVisible} />
              <p>Show legend</p>
            </SubDiv>
            <SubDiv $orientation="vertical">
              <p>Legend shape</p>
              <Dropdown
                value={legendSymbolShape}
                options={[
                  { label: "Circle", value: "circle" },
                  { label: "Square", value: "square" },
                ]}
                onChange={onLegendSymbolChange}
              />
            </SubDiv>
            <SubDiv $orientation="vertical">
              <p>Label position</p>
              <Dropdown
                value={legendPosition}
                options={legendPositionOptions}
                onChange={onLegendPositionChange}
              />
            </SubDiv>
          </Body>
        </Section>
      </Left>
      <Right>
        <Section $show={!["list", "dtList"].includes(chartSelected)}>
          <Title>Color</Title>
          <Body>
            <SubDiv $orientation="vertical">
              <p>Family:</p>
              <Dropdown
                value={colorFamilySelected}
                dropdownStyle={{ width: 250 }}
                onChange={onColorFamilyChange}
              >
                {[
                  "ver",
                  "hor",
                  "cloud",
                  "star",
                  "image",
                  "line",
                  "area",
                ].includes(chartSelected) && (
                  <OptGroup label="">
                    <Option key="single" value="single">
                      <ColorLabel>Single</ColorLabel>
                    </Option>
                  </OptGroup>
                )}
                <OptGroup label="Sequential Single">
                  {singleSequential.map((col) => (
                    <Option key={col.name} value={col.name}>
                      <ColorLabel>{col.label}</ColorLabel>
                      <ColorIcon>{col.icon}</ColorIcon>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label="Sequential Multi">
                  {multiSequential.map((col) => (
                    <Option key={col.name} value={col.name}>
                      <ColorLabel>{col.label}</ColorLabel>
                      <ColorIcon>{col.icon}</ColorIcon>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label="Diverging">
                  {diverging.map((col) => (
                    <Option key={col.name} value={col.name}>
                      <ColorLabel>{col.label}</ColorLabel>
                      <ColorIcon>{col.icon}</ColorIcon>
                    </Option>
                  ))}
                </OptGroup>
                <OptGroup label="Distinctive">
                  {distinctive.map((col) => (
                    <Option key={col.name} value={col.name}>
                      <ColorLabel>{col.label}</ColorLabel>
                      <ColorIcon>{col.icon}</ColorIcon>
                    </Option>
                  ))}
                </OptGroup>
              </Dropdown>
            </SubDiv>
            <ColorListContainer>
              <p>Click on charts color to change</p>
              <ColorListBox>
                {selectedColorListObj.map((color, index) => (
                  <AnalyticsColorPicker
                    key={color.key}
                    defaultColor={color.value}
                    index={index}
                  />
                ))}
              </ColorListBox>
            </ColorListContainer>
          </Body>
        </Section>
      </Right>
    </Wrapper>
  );
};

export default Design;
