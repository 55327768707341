import styled from "styled-components";
import { Rate } from "antd";

export const Wrapper = styled.div`
  margin: auto 5% 30px;
`;

export const ItemDivWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: min(100%, 350px);
  & p {
    margin-bottom: unset;
  }
`;

export const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
  justify-content: space-between;
  :last-child {
    margin-bottom: 0;
  }
`;

export const RateContainer = styled.div`
  margin-right: 20px;
`;

export const RateBody = styled(Rate)`
  font-size: 24px;
  color: ${(props: { $color: string }) => props.$color ?? "#4285F4"};
`;

export const ValueText = styled.p`
  font-size: 18px;
  line-height: 23px;
  color: #000000;
`;

export const HeaderText = styled.p`
  text-align: ${(props: { $align: string }) => props.$align ?? "left"};
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  min-height: 25px;
`;
