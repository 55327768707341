import React from "react";
import { SelectAnswerType, Wrapper } from "./style";
import { useConfiguration } from "../../contexts/Configuration";
import { answerOptionsKeys, answerOptionsValues } from "./utils";
import countriesList from "./countries.json";
import { ReactComponent as DropdownIcon } from "../assets/chevron-down-0.svg";

const AnswerTypesField = ({
  dispatchType = "UPDATE_ROW_OPTIONS",
}: {
  dispatchType?: "UPDATE_ROW_OPTIONS" | "UPDATE_COLUMN_OPTIONS";
}) => {
  const { dispatch } = useConfiguration();

  const onAnswerOptionChange = (value: string[]) => {
    if (value.length > 0) {
      const selectedOption = value[value.length - 1];
      let theOptionValues;
      if (selectedOption === "countries") {
        theOptionValues = countriesList;
      } else {
        theOptionValues = answerOptionsValues[selectedOption];
      }
      const linkertOptionValues = [];
      theOptionValues.forEach((linkertValue, index) => {
        return linkertOptionValues.push({
          index: index,
          // key: linkertValue.value,
          value: linkertValue.label,
        });
      });
      dispatch({ type: dispatchType, payload: linkertOptionValues });
    }
  };

  return (
    <Wrapper data-config="answer-types">
      <p>Answer types</p>
      <SelectAnswerType
        options={answerOptionsKeys}
        onChange={onAnswerOptionChange}
        placeholder="Select type"
        bordered={false}
        suffixIcon={<DropdownIcon />}
      />
    </Wrapper>
  );
};

export default AnswerTypesField;
