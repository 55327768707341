import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
`;

export const Content = styled.div`
  display: block;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  & > button {
    width: 100px;
    height: 30px;
  }
  & > button:first-child {
    background-color: var(--primary-red-color);
    color: #ffffff;
    margin-right: 10px;
    border: none;
  }
  & > button:last-child {
    background-color: #ffffff;
    color: #000000;
    border: 1px solid #000000;
  }
`;
