import { Action, ConfigurationState } from "./types";
import cloneDeep from "lodash/cloneDeep";

export default function configurationReducer(
  state: ConfigurationState,
  action: Action
) {
  switch (action.type) {
    case "initialise": {
      return { ...state, ...action.payload };
    }
    case "UPDATE_ROW_OPTIONS": {
      return {
        ...state,
        rowOptions: action.payload,
      };
    }
    case "UPDATE_IS_REQUIRED": {
      return {
        ...state,
        isRequired: action.payload,
      };
    }
    case "UPDATE_IS_RANDOM": {
      return {
        ...state,
        hasRandomizedChoices: action.payload,
      };
    }
    case "UPDATE_REQUIRED_MESSAGE": {
      return {
        ...state,
        requiredMessage: action.payload,
      };
    }
    case "UPDATE_QUESTION_TEXT": {
      return {
        ...state,
        questionText: action.payload,
      };
    }
    case "UPDATE_QUESTION_TYPE": {
      return {
        ...state,
        questionType: action.payload,
      };
    }
    case "UPDATE_CHOICES_RANGE_TYPE": {
      const newCheckboxOptions = { ...state.checkboxOptions };
      newCheckboxOptions.choicesType = action.payload;
      return {
        ...state,
        checkboxOptions: newCheckboxOptions,
      };
    }
    case "UPDATE_CHOICES_RANGE_NUMBER": {
      const newCheckboxOptions = { ...state.checkboxOptions };
      newCheckboxOptions.choicesNumber = action.payload;
      return {
        ...state,
        checkboxOptions: newCheckboxOptions,
      };
    }
    case "UPDATE_DATE_ROW_OPTIONS": {
      const newDateOptions = { ...state.dateOptions };
      newDateOptions.dateRowOptions = action.payload;
      return {
        ...state,
        dateOptions: newDateOptions,
      };
    }
    case "UPDATE_COLUMN_OPTIONS": {
      return {
        ...state,
        colOptions: action.payload,
      };
    }
    case "UPDATE_IMAGE_ROW_OPTIONS": {
      const newImageOptions = { ...state.imageOptions };
      newImageOptions.imageRowOptions = action.payload;
      return {
        ...state,
        imageOptions: newImageOptions,
      };
    }
    case "UPDATE_IMAGE_CHECKBOX_OPTION": {
      // const newCheckboxOptions = { ...state.imageOptions };
      const newCheckboxOptions = cloneDeep(state.imageOptions);
      newCheckboxOptions.isCheckbox = action.payload;
      return {
        ...state,
        imageOptions: newCheckboxOptions,
      };
    }
    // case "UPDATE_SHOW_ADJUST_SCALE": {
    //   const newSliderOptions = { ...state.sliderOptions };
    //   newSliderOptions.showAdjustScale = action.payload;
    //   return {
    //     ...state,
    //     sliderOptions: newSliderOptions,
    //   };
    // }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
