export const messageIncludesSurveyLink = ({
  value,
  link,
}: {
  value: string;
  link: string;
}) => {
  if (value.includes(link)) {
    return true;
  } else {
    return false;
  }
};

export const contactObjToSubstitutionObj = (contact) => {
  const newObj = {};
  const ketToTagMap = {
    firstName: "first_name",
    lastName: "last_name",
    email: "email",
  };
  for (const [key, value] of Object.entries(contact)) {
    // newObj[`-${ketToTagMap[key]}-`] = value;
    newObj[ketToTagMap[key]] = value;
  }
  return newObj;
};

export const contactObjToObj = (contact) => {
  return {
    name: `${contact?.firstName || ""} ${contact?.lastName || ""}`,
    email: contact.email,
  };
};
