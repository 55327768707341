import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardContent,
} from "./style";

const placeholderImages: string[] = [
  "https://firebasestorage.googleapis.com/v0/b/surveylake.appspot.com/o/SL%20brand%2FBlack.png?alt=media&token=fdc1eef5-df47-4178-a543-e6af93bbe052",
  "https://firebasestorage.googleapis.com/v0/b/surveylake.appspot.com/o/SL%20brand%2FBlue.png?alt=media&token=a73b56b1-917f-4392-936c-04eb08c0f4a1",
  "https://firebasestorage.googleapis.com/v0/b/surveylake.appspot.com/o/SL%20brand%2FGreen.png?alt=media&token=58ff7d8a-2267-40d5-92d8-658ba2818921",
  "https://firebasestorage.googleapis.com/v0/b/surveylake.appspot.com/o/SL%20brand%2FRed.png?alt=media&token=6fe0f524-ccaf-4a92-aa9f-4fa4a38ce0ed",
];

const getRandomImage = () => {
  const randomNum = Math.floor(Math.random() * 4);
  return placeholderImages[randomNum];
};

interface TemplateProps {
  image: string;
  title: string;
  subTitle: string;
  templateId: string;
}

const TemplateCard: FC<TemplateProps> = ({
  image,
  title,
  subTitle,
  templateId,
}) => {
  const navigate = useNavigate();
  const [templateImage, setTemplateImage] = useState(image);

  useEffect(() => {
    if (!image) {
      const randImage = getRandomImage();
      setTemplateImage(randImage);
    }
  }, [image]);

  const onClick = () => {
    navigate(`/t/${templateId}/preview`);
  };

  return (
    <CardContainer onClick={onClick}>
      <Card>
        <CardImg src={templateImage} alt="..." />
        <CardBody>
          <CardTitle>{title}</CardTitle>
          <CardContent>{subTitle.substring(0, 70)}...</CardContent>
        </CardBody>
      </Card>
    </CardContainer>
  );
};

export default TemplateCard;
