import React from "react";
import { Body, DeleteButton, Header, QuestionTitle, Wrapper } from "./style";
import { ReactComponent as DeleteBoxIcon } from "../../icons/delete.svg";
import Row from "./components/Row";

interface Props {
  questionNumber: string | number;
  questionTitle: string;
}

const LogicBox = ({ questionNumber, questionTitle }: Props) => (
  <Wrapper>
    <Header>
      <QuestionTitle>
        <span>
          {questionNumber} {questionTitle}
        </span>
      </QuestionTitle>
      <DeleteButton>
        <DeleteBoxIcon />
      </DeleteButton>
    </Header>
    <Body>
      <Row />
    </Body>
  </Wrapper>
);

export default LogicBox;
