import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding: 20px 0 40px;
  height: unset;
`;

export const TableContainer = styled.div`
  margin-top: 30px;
`;

export const TableWrapper = styled.div`
  width: 100%;
  margin: 30px 0;
  padding-bottom: 40px;

  & div[class="ant-table ant-table-bordered"] {
    background-color: transparent;
  }

  & thead[class="ant-table-thead"] > tr > th[class="ant-table-cell"] {
    background-color: transparent;
    border-bottom: 1px solid #c7c7c7;
  }
`;

export const TableTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
`;

export const EllipsisContainer = styled.div`
  padding: 10px 5px;
  background: rgba(9, 30, 66, 0.08);
  border-radius: 3px;
  width: 32px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmailLinks = styled.div`
  margin-top: 30px;
  & > div {
    margin-bottom: 20px;
  }
`;

export const NewLinkButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  height: 36px;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 8px 12px;
  border: none;
  margin-bottom: 40px;
`;
