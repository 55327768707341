import React, { useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import AnalyticBox from "./components/AnalyticBox";
import { filterByAllParams } from "./components/Filter/utils";
import Loader from "../common/Loader";
import {
  AnalyticsBoxes,
  EmptyDescription,
  EmptyDescriptionContainer,
  EmptySubDescription,
  HeadBox,
  NoDataBox,
  QuestionNumber,
  QuestionText,
  Wrapper,
} from "./style";
import { useGetQuestionsQuery, useGetResponsesQuery } from "../../app/apiSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AnalyticsContext } from "../../context/AnalyticsContext";
import { setQuestionsList } from "../../pages/Design/designSlice";
import NoFilterData from "./components/NoDataAnalytics/NoFilterData";

const Analytics = () => {
  const { surveyId } = useParams();
  const dispatch = useAppDispatch();

  const { formattedAnalyses, onUpdateAnalysesList } =
    useContext(AnalyticsContext);

  const { data: responsesList, isFetching } = useGetResponsesQuery(surveyId);

  const { data: questionsData, isFetching: isFetchingQuestions } =
    useGetQuestionsQuery(surveyId);

  useEffect(() => {
    if (questionsData) {
      dispatch(setQuestionsList(questionsData));
    }
  }, [questionsData]);

  const { questionsById } = useAppSelector((state) => state.design);

  useEffect(() => {
    if (
      questionsById &&
      Object.keys(questionsById).length !== 0 &&
      responsesList.length
    ) {
      const formattedAnalys = filterByAllParams({
        responsesList,
        questionsById,
        filterParams: [],
      });

      onUpdateAnalysesList(formattedAnalys);
    }
  }, [responsesList, questionsById]);

  if (!formattedAnalyses.length) {
    return <NoFilterData />;
  }
  return (
    <Wrapper>
      <Loader isLoading={isFetching || isFetchingQuestions} />
      <AnalyticsBoxes>
        {formattedAnalyses.map((answer) => {
          if (answer.result.length > 0) {
            return <AnalyticBox key={answer.qId} answer={answer} />;
          }
          return (
            <NoDataBox key={answer.qId}>
              <HeadBox>
                <QuestionNumber>Q{answer.qNum}.</QuestionNumber>
                <QuestionText>{parse(answer.qText)}</QuestionText>
              </HeadBox>
              <EmptyDescriptionContainer>
                <EmptyDescription>No Response for Question</EmptyDescription>
                <EmptySubDescription>
                  Once your respondents starts completing your surveys, the
                  vizualization will appear here.
                </EmptySubDescription>
              </EmptyDescriptionContainer>
            </NoDataBox>
          );
        })}
      </AnalyticsBoxes>
    </Wrapper>
  );
};

export default Analytics;
