import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import parse from "html-react-parser";
import Settings from "../Settings";
import { ReactComponent as EditLogo } from "./icons/edit_square.svg";
import ChartProvider, { ChartContext } from "../../context/ChartContext";
import ChartDisplay from "../ChartDisplay";
import Loader from "../../../common/Loader";
import {
  BoxTitle,
  Buttons,
  ChartContainer,
  ContentBox,
  ContentBoxFooter,
  ContentBoxTop,
  DashboardButton,
  EditButton,
  QuestionText,
  RespondentBox,
  Wrapper,
} from "./style";
import type { FormattedAnswer } from "../../../../context/AnalyticsContext";
import ExportButton from "../../../../features/analytics/components/ExportButton";

const BoxHead = ({
  questionNumber,
  questionText,
  chartComponent,
}: {
  questionNumber: number;
  chartComponent: any;
  questionText: ReactNode;
}) => {
  const { onAddToDashboard } = useContext(ChartContext);
  return (
    <BoxTitle>
      <QuestionText>
        {questionNumber} {questionText}
      </QuestionText>
      <Buttons>
        <DashboardButton type="button" onClick={onAddToDashboard}>
          <span>Add as view</span>
        </DashboardButton>
        <ExportButton chartComponent={chartComponent} />
      </Buttons>
    </BoxTitle>
  );
};

const ChartBody = ({ chartComponent }: { chartComponent: any }) => {
  const { loading, tableIsDisplayed } = useContext(ChartContext);

  const toggleTable = (checked) => {
    // if (chartComponent && chartComponent?.current?.chart) {
    //   // console.log("chartComponent?.current?.chart");
    //   // console.log({ ...chartComponent?.current });
    //   // // console.log({ ...chartComponent?.current?.chart });
    //   if (checked) {
    //     chartComponent.current.chart.viewData();
    //   } else {
    //     chartComponent.current.chart.hideData();
    //   }
    // }
  };

  useEffect(() => {
    toggleTable(tableIsDisplayed);
  }, [tableIsDisplayed]);

  return (
    <ChartContainer>
      <Loader isLoading={loading} />
      {!loading && <ChartDisplay theRef={chartComponent} />}
    </ChartContainer>
  );
};

const AnalyticBox = ({ answer }: { answer: FormattedAnswer }) => {
  const [isShowingChart, setIsShowingChart] = useState(false);
  const chartComponent = useRef(null);

  // const [data, setData] = useState({});
  // useEffect(() => {
  //   if (answer && Object.keys(answer).length) {
  //     setData(answer);
  //   }
  // }, [answer]);
  const onToggleEditChart = () => {
    setIsShowingChart(!isShowingChart);
  };

  return (
    <ChartProvider
      questionId={answer.qId}
      questionType={answer.qType}
      chartData={answer.result}
      questionOptions={answer.qOptions}
      respondentsDetails={answer.respondents}
      extraOptions={answer.extraOptions}
    >
      <Wrapper>
        <ContentBox>
          <ContentBoxTop>
            <BoxHead
              questionNumber={answer.qNum}
              questionText={parse(answer.qText)}
              chartComponent={chartComponent}
            />
          </ContentBoxTop>
          <ChartBody chartComponent={chartComponent} />
          <ContentBoxFooter>
            <RespondentBox>
              <p>{answer.respondents.answered} responses</p>
              <p>{answer.respondents.skipped} skipped</p>
            </RespondentBox>
            <EditButton type="button" onClick={onToggleEditChart}>
              <EditLogo />
              <span>Edit chart</span>
            </EditButton>
          </ContentBoxFooter>
        </ContentBox>
        {isShowingChart && <Settings />}
      </Wrapper>

      {/*<AutoSave*/}
      {/*  initialChartSetting={chartSettings}*/}
      {/*  surveyId={surveyId}*/}
      {/*  questionId={questionId}*/}
      {/*  chartType={chartSelected}*/}
      {/*  colorCategory={colorGroup}*/}
      {/*  colorPalette={colorSelected}*/}
      {/*  showTable={tableIsDisplayed}*/}
      {/*  showDataLabels={dataLabelsVisible}*/}
      {/*  dataLabelsFormatType={countPercentageFormat}*/}
      {/*  showLegend={legendVisible}*/}
      {/*  legendLayout={legendLayout}*/}
      {/*  legendHorPosition={legendHorPosition}*/}
      {/*  legendVerPosition={legendVerPosition}*/}
      {/*  legendSymbolShape={legendSymbolShape}*/}
      {/*  donutOptions={otherOptions}*/}
      {/*  headerText={headerText}*/}
      {/*/>*/}
    </ChartProvider>
  );
};

export default AnalyticBox;
