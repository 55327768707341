import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;
export const Section = styled.div`
  margin-bottom: 30px;
  & > div {
    margin-bottom: 15px;
  }
`;
