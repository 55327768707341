import React, { useContext } from "react";
import { ReactComponent as CopyIcon } from "../../../../assets/icons/copy.svg";
import {
  ConfigureButton,
  CopyButton,
  LinkContainer,
  LinkInput,
  Name,
  Wrapper,
  ShareLink,
  MobileShareLink,
  DesktopShareLink,
} from "./style";
import { copyText } from "../../../../../../utils/helpers";
import type { SocialShareButtonProps, LinkBoxProps } from "../../types";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";

const shareMessage = "Please take this survey. Your feedback is important!";
const shareTitle = "Can you spare a few moments to take my survey?";

const SocialShareButton = ({ socialType, url }: SocialShareButtonProps) => {
  const twitterQueryString = new URLSearchParams(`${url}&text=${shareMessage}`);
  const linkedinQueryString = new URLSearchParams(
    `${url}&title=${shareTitle}&summary=${shareMessage}`
  );
  switch (socialType) {
    case "TWITTER":
      return (
        <ShareLink
          target="_blank"
          role="button"
          href={`https://twitter.com/intent/tweet?url=${twitterQueryString}`}
        >
          Share
        </ShareLink>
      );
    case "LINKEDIN":
      return (
        <ShareLink
          target="_blank"
          role="button"
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${linkedinQueryString}`}
        >
          Share
        </ShareLink>
      );
    case "WHATSAPP":
      return (
        <>
          <MobileShareLink
            target="_blank"
            role="button"
            href={`whatsapp://send?text=${shareMessage}`}
            data-action="share/whatsapp/share"
          >
            <CopyIcon />
          </MobileShareLink>
          <DesktopShareLink
            target="_blank"
            role="button"
            href={`https://web.whatsapp.com/send?text=${shareMessage} ${url}`}
          >
            Share
          </DesktopShareLink>
        </>
      );
    default:
      return null;
  }
};

const LinkBox = ({
  onConfigureClick,
  linkValue,
  socialType,
  name = "Social 1",
  collector,
}: LinkBoxProps) => {
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const onCopy = async () => {
    await copyText(linkValue);
    onSuccessShowAlert("Link copied");
  };
  const onConfigure = () => {
    onConfigureClick(collector);
  };
  return (
    <Wrapper>
      <Name>{name}</Name>
      <LinkContainer>
        <LinkInput readOnly value={linkValue} />
        <SocialShareButton socialType={socialType} url={linkValue} />
        <CopyButton type="button" onClick={onCopy}>
          <CopyIcon />
        </CopyButton>
        <ConfigureButton type="button" onClick={onConfigure}>
          Configure link
        </ConfigureButton>
      </LinkContainer>
    </Wrapper>
  );
};

export default LinkBox;
