import React from "react";

import { ReactComponent as MultipleIcon } from "../../Build/icons/multi.svg";
import { ReactComponent as ShortIcon } from "../../Build/icons/short.svg";
import { ReactComponent as LongIcon } from "../../Build/icons/long.svg";
import { ReactComponent as DateTimeIcon } from "../../Build/icons/calendar.svg";
import { ReactComponent as RankingIcon } from "../../Build/icons/ranking.svg";
import { ReactComponent as SliderIcon } from "../../Build/icons/slider.svg";
import { ReactComponent as ImageIcon } from "../../Build/icons/image.svg";
import { ReactComponent as CheckboxIcon } from "../../Build/icons/checkbox.svg";
import { ReactComponent as StarIcon } from "../../Build/icons/star.svg";
import { ReactComponent as DropdownIcon } from "../../Build/icons/dropdown.svg";
import { ReactComponent as RatingIcon } from "../../Build/icons/multi.svg";

export const QuestionKeyToName = {
  ST: "Short answer",
  LT: "Long answer",
  MC: "Multiple choice",
  Ch: "Checkbox",
  Dd: "Dropdown",
  SR: "Star rating",
  IC: "Image choice",
  Sl: "Linear scale/Slider",
  Rk: "Ranking",
  MRS: "Rating scale",
  DT: "Date/Time",
};

export const QuestionKeyToIcon = {
  ST: <ShortIcon />,
  LT: <LongIcon />,
  MC: <MultipleIcon />,
  Ch: <CheckboxIcon />,
  Dd: <DropdownIcon />,
  SR: <StarIcon />,
  IC: <ImageIcon />,
  Sl: <SliderIcon />,
  Rk: <RankingIcon />,
  MRS: <RatingIcon />,
  DT: <DateTimeIcon />,
};

export type QuestionOptionTypes =
  | "ST"
  | "LT"
  | "MC"
  | "Ch"
  | "Dd"
  | "SR"
  | "IC"
  | "Sl"
  | "Rk"
  | "MRS"
  | "DT";

export const questionOptionList: QuestionOptionTypes[] = [
  "ST",
  "LT",
  "MC",
  "Ch",
  "Dd",
  "SR",
  "IC",
  "Sl",
  "Rk",
  "MRS",
  "DT",
];
