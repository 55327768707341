import React, { useContext, useEffect, useState } from "react";
import { Wrapper, FormBody, Section } from "./style";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import FormHeader from "../../FormHeader";
import QuestionHeader from "../../QuestionHeader";
import { useConfiguration } from "../../../contexts/Configuration";
import RowOptions from "../../RowOptions";
import QuestionFormCheckbox from "../../QuestionFormCheckbox";
import RichTextField from "../../RichTextField";
import RequiredCheckbox from "../../RequiredCheckbox";
import AnswerTypesField from "../../AnswerTypesField";
import ColumnOptions from "../../RowOptions/ColumnOptions";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import ErrorMessage from "../../../../../../common/ErrorMessage";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { useParams } from "react-router-dom";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";

type Inputs = {
  text: string;
};
const MatrixRatingForm = ({ onCancel }: { onCancel: () => void }) => {
  const { surveyId } = useParams();
  const dispatch = useAppDispatch();
  const { selectedQuestion } = useAppSelector((state) => state.design);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { text: selectedQuestion.text } });

  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();

  const {
    rowOptions,
    isRequired,
    requiredMessage,
    hasRandomizedChoices,
    matrixScaleOptions,
    colOptions,
  } = configState;

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const rowOptionsNotEmpty = rowOptions.filter(
      (opt) => opt.value?.length !== 0
    );
    if (rowOptionsNotEmpty.length < 2) {
      setHasOptionsError(true);
    } else {
      setHasOptionsError(false);
      try {
        const mcValues = {
          type: "MRS",
          text: data.text,
          rowOptions,
          colOptions,
          isRequired,
          requiredMessage: undefined,
          hasLogic: false,
          hasRandomizedChoices,
          allowsMultiResponsesPerRow:
            matrixScaleOptions.allowsMultiResponsesPerRow,
          isMatrixRatingWeighted: matrixScaleOptions.isMatrixRatingWeighted,
        };
        if (isRequired) {
          mcValues.requiredMessage = requiredMessage;
        }
        if (!selectedQuestion?.questionId) {
          const result = await addQuestion({
            surveyId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(setNewQuestion(result?.question));
          await dispatch(setSelectedQuestion(null));
          onSuccessShowAlert("New question added");
        } else {
          const { questionId } = selectedQuestion;
          const mcQuesInitialObj = pick(selectedQuestion, [
            "type",
            "text",
            "rowOptions",
            "colOptions",
            "isRequired",
            "hasLogic",
            "hasRandomizedChoices",
            "requiredMessage",
            "allowsMultiResponsesPerRow",
            "isMatrixRatingWeighted",
          ]);
          const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
          if (!hasQuestionNotChanged) {
            await editQuestion({
              surveyId,
              questionId,
              questionDetail: mcValues,
            }).unwrap();
            await dispatch(
              setUpdatedQuestion({
                questionId,
                updatedQues: { ...mcValues, questionId },
              })
            );
          }
          onSuccessShowAlert("Question updated");
        }
      } catch (err) {
        onShowErrorAlert(err, "The question couldn't be saved");
      }
    }
  };
  const [hasOptionsError, setHasOptionsError] = useState(false);
  const [randomize, setRandomize] = useState(hasRandomizedChoices || false);
  const [allowMultiple, setAllowMultiple] = useState(
    matrixScaleOptions.allowsMultiResponsesPerRow || false
  );

  const onToggleRequired = (e: any) => {
    setRandomize(e.target.checked);
  };

  const onMultipleAllowed = (e: any) => {
    setAllowMultiple(e.target.checked);
  };

  useEffect(() => {
    if (selectedQuestion.text) {
      localDispatch({
        type: "UPDATE_ROW_OPTIONS",
        payload: selectedQuestion.rowOptions,
      });
      localDispatch({
        type: "UPDATE_COLUMN_OPTIONS",
        payload: selectedQuestion.colOptions,
      });
      localDispatch({
        type: "UPDATE_IS_REQUIRED",
        payload: selectedQuestion.isRequired,
      });
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType="MRS" />
          <Controller
            name="text"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RichTextField
                hasError={!!errors.text}
                initialValue={selectedQuestion.text}
                field={field}
              />
            )}
          />
          <Section>
            <RowOptions rowList={rowOptions} />
          </Section>

          <Section>
            <RequiredCheckbox />
            <div>
              <QuestionFormCheckbox
                checked={randomize}
                onChange={onToggleRequired}
              >
                Randomize options
              </QuestionFormCheckbox>
            </div>
            <div>
              <QuestionFormCheckbox
                checked={allowMultiple}
                onChange={onMultipleAllowed}
              >
                Allow Multiple Responses Per Row
              </QuestionFormCheckbox>
            </div>
          </Section>

          <Section>
            <p>Columns</p>
            <AnswerTypesField dispatchType="UPDATE_COLUMN_OPTIONS" />
            <ColumnOptions rowList={colOptions} />
            {hasOptionsError && (
              <ErrorMessage message="Minimum of two options" />
            )}
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default MatrixRatingForm;
