import React from "react";
import { ReactComponent as BarChartLogo } from "./icons/vertical-bar.svg";
import { ReactComponent as HorizontalBarChartLogo } from "./icons/horizontal-bar.svg";
import { ReactComponent as PieChartLogo } from "./icons/pie-chart.svg";
import { ReactComponent as DonutChartLogo } from "./icons/doughnut.svg";
import { ReactComponent as LineChartLogo } from "./icons/line.svg";
import { ReactComponent as AreaChartLogo } from "./icons/area.svg";
import { ReactComponent as StarLogo } from "./icons/duotone - chart 33.svg"; // Todo: replace
import styles from "./Chart.module.css";
import { ReactComponent as GroupedBarLogo } from "./icons/vertical-bar-2.svg";
import { ReactComponent as HorizontalBarChartSpacedLogo } from "./icons/horizontal-bar-2.svg";
import { ReactComponent as StackedLogo } from "./icons/duotone - chart 34.svg"; // Todo: replace

export const multiItems = [
  { name: "ver", label: "Vertical Barchart", icon: <BarChartLogo /> },
  {
    name: "hor",
    label: "Horizontal Barchart",
    icon: <HorizontalBarChartLogo />,
  },
  { name: "pie", label: "Pie chart", icon: <PieChartLogo /> },
  { name: "donut", label: "Donut chart", icon: <DonutChartLogo /> },
  { name: "line", label: "Line chart", icon: <LineChartLogo /> },
  { name: "area", label: "Area chart", icon: <AreaChartLogo /> },
];

export const starItems = [
  {
    name: "star",
    label: "Star Diagram",
    icon: <StarLogo className={styles.starLogo} />,
  },
  { name: "ver", label: "Vertical Barchart", icon: <BarChartLogo /> },
  {
    name: "hor",
    label: "Horizontal Barchart",
    icon: <HorizontalBarChartLogo />,
  },
];

export const rankingItems = [
  { name: "grBar", label: "Grouped Barchart", icon: <GroupedBarLogo /> },
  {
    name: "divStBar",
    label: "Diverging Stacked Barchart",
    icon: <HorizontalBarChartSpacedLogo />,
  },
];

export const dateItems = [
  {
    name: "dtList",
    label: "Date Time List",
    icon: (
      <span className={styles.radarLogo}>
        {/*<UnorderedListOutlined />*/}
        <p>DT</p>
      </span>
    ),
  },
];

export const mrsItems = [
  {
    name: "horDivStBar",
    label: "Horizontal Diverging Stacked Barchart",
    icon: <HorizontalBarChartSpacedLogo />,
  },
  {
    name: "divStBar",
    label: "Diverging Stacked Barchart",
    icon: <StackedLogo className={styles.logoResize} />,
  },
  {
    name: "horGrBar",
    label: "Grouped Barchart",
    icon: <GroupedBarLogo />,
  },
  {
    name: "grBar",
    label: "Grouped Barchart",
    icon: <GroupedBarLogo className={styles.logoResize} />,
  },
];

export const imageItems = [
  { name: "image", label: "Image Diagram", icon: <BarChartLogo /> },
  { name: "ver", label: "Vertical Barchart", icon: <BarChartLogo /> },
  {
    name: "hor",
    label: "Horizontal Barchart",
    icon: <HorizontalBarChartLogo />,
  },
  { name: "pie", label: "Pie chart", icon: <PieChartLogo /> },
  { name: "donut", label: "Donut chart", icon: <DonutChartLogo /> },
];

export const textItems = [
  {
    name: "list",
    label: "Text List",
    icon: (
      <span className={styles.radarLogo}>
        {/*<UnorderedListOutlined />*/}
        <p>Li</p>
      </span>
    ),
  },
  {
    name: "cloud",
    label: "Word Cloud",
    icon: (
      <span className={styles.radarLogo}>
        {/*<CloudOutlined />*/}
        <p>Cl</p>
      </span>
    ),
  },
];

export const questionChartOptions = (questionType) => {
  const questionTypeToChartsListMap = {
    Ch: multiItems,
    SR: starItems,
    Rk: rankingItems,
    DT: dateItems,
    MRS: mrsItems,
    IC: imageItems,
    ST: textItems,
    LT: textItems,
  };
  return questionTypeToChartsListMap[questionType] ?? multiItems;
};
