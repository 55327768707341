import React from "react";
import { Wrapper, Body, Content } from "./style";
import QuestionTypes from "./components/QuestionTypes";
import Configuration from "./components/Configuration";
import SurveyDisplay from "./components/SurveyDisplay";

const CreateQuestions = () => {
  return (
    <Wrapper>
      <QuestionTypes />
      <Content>
        <Body $backgroundColor={"#fff"}>
          <SurveyDisplay />
        </Body>
      </Content>
      <Configuration />
    </Wrapper>
  );
};

export default CreateQuestions;
