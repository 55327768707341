import React, { Dispatch, SetStateAction, useRef, useState } from "react";

interface Props {
  setVisible?: Dispatch<SetStateAction<boolean>>;
  setCookie?: any;
  cookieName?: string;
  cookieValue?: string;
}

const SlideFunction = ({
  setVisible,
  setCookie,
  cookieName,
  cookieValue,
}: Props) => {
  const onboardingView = useRef<any>();
  const [count, setCount] = useState(1);
  const [dotIndex, setDotIndex] = useState(0);

  const handleCountAndSlide = (event: any) => {
    const { textContent } = event.target;

    const scrollWidth = onboardingView.current.clientWidth;

    if (textContent.toLowerCase() === "next") {
      onboardingView.current.scrollBy({
        top: 0,
        behavior: "smooth",
        left: scrollWidth,
      });
      setTimeout(() => {
        setCount((prev) => prev + 1);
      }, 600);
    } else if (textContent.toLowerCase() === "skip") {
      setVisible(false);
      setCookie(`${cookieName}`, cookieValue, { path: "/" });
    } else {
      setVisible(false);
      setCookie(`${cookieName}`, cookieValue, { path: "/" });
    }
  };

  return { handleCountAndSlide, count, onboardingView, dotIndex };
};

export default SlideFunction;
