import styled, { css } from "styled-components";
import { UncheckIcon } from "../../../../components/CreateQuestions/components/EmptyPage/components/Templates/style";

export const Wrapper = styled.div`
  height: 530px;
  width: 100%;

  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #1a141f;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  flex-wrap: wrap;
  height: 85%;
`;

export const LogoButton = styled.button`
  width: 250px;
  height: 120px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 15px 15px 0;
  ${(props: { $isSelected?: boolean }) => {
    if (props.$isSelected) {
      return css`
        border: 1px solid var(--primary-red-color);
        & ${UncheckIcon} {
          display: none;
        }
      `;
    }
    return css`
      border: 1px solid #eaecf0;
      & svg {
        display: none;
      }
    `;
  }}

  & > img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
`;

export const SelectButton = styled.button`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  width: 129px;
  height: 40px;
`;
