import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { CustomUpload } from "./index";
import uploadImage from "../../../utils/uploadToStorage";

type ProgressStatus = "idle" | "progress" | "done";

const ImageQuestionUpload = ({
  onUpload,
  rowIndex,
  uploadUrl,
  uploadPath,
}: {
  onUpload: (index, url) => void;
  uploadUrl: string;
  rowIndex: number;
  uploadPath?: string;
}) => {
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [uploadingStatus, setUploadingStatus] =
    useState<ProgressStatus>("idle");
  const [documentUrl, setDocumentUrl] = useState("");

  const onUploadDocument = (file) => {
    const imageId = uuidv4();
    const imagePath = `${uploadPath}${imageId}`;
    uploadImage({
      file,
      setUploadingProgress: setUploadingProgress,
      useDownloadUrl: setDocumentUrl,
      setUploadingStatus: setUploadingStatus,
      storagePath: imagePath,
      onUploadFinish: onUpload,
      rowIndex,
    });
  };

  useEffect(() => {
    if (uploadUrl?.length !== 0) {
      setUploadingStatus("done");
    } else {
      setUploadingStatus("idle");
    }
  }, [uploadUrl]);

  return (
    <>
      <CustomUpload
        name="id"
        customRequest={({ file }) => onUploadDocument(file)}
        progressPercent={uploadingProgress}
        uploadingStatus={uploadingStatus}
        doneText={<span>Done</span>}
      />
    </>
  );
};

export default ImageQuestionUpload;
