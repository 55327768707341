export const templateCategories = [
  { label: "Education", value: "Education" },
  { label: "Customers", value: "Customers" },
  { label: "Employees", value: "Employees" },
  { label: "Events", value: "Events" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Market research", value: "Market-research" },
  { label: "Non profit", value: "Non-profit" },
  { label: "Other", value: "Other" },
];
