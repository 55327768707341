import React from "react";
import { useParams } from "react-router-dom";
import {
  EmptyWrapper,
  EmptyWrapperBody,
  EmptyWrapperButton,
  EmptyWrapperSubTitle,
  EmptyWrapperTitle,
} from "./style";
import { ReactComponent as EmptyImage } from "../../../assets/empty-email.svg";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { useAddNewCollectorMutation } from "../../../../../app/apiSlice";
import Loader from "../../../../common/Loader";

const EmptyMail = () => {
  const { surveyId } = useParams();
  const [addNewCollector, { isLoading: isAddingCollector }] =
    useAddNewCollectorMutation();

  const onNewClick = async () => {
    const newCollectorId = nanoid();
    await addNewCollector({
      collectorId: newCollectorId,
      name: "Email 1",
      collectorType: "EMAIL",
      startDate: dayjs().format("DD-MM-YYYY"),
      endDate: null,
      endTime: "12:00:00",
      surveyId,
      isManuallyClosed: false,
      manualStatus: "ACTIVE",
      emailProvider: "SENDGRID",
    }).unwrap();
  };

  return (
    <EmptyWrapper>
      <Loader isLoading={isAddingCollector} />
      <EmptyWrapperBody>
        <EmptyImage />
        <EmptyWrapperTitle>No active email campaign</EmptyWrapperTitle>
        <EmptyWrapperSubTitle>
          Click the “Compose Email” button below to get started
        </EmptyWrapperSubTitle>
        <EmptyWrapperButton onClick={onNewClick}>
          Compose email
        </EmptyWrapperButton>
      </EmptyWrapperBody>
    </EmptyWrapper>
  );
};

export default EmptyMail;
