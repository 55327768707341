const getXDataLabelPosition = (position, layout) => {
  if (layout === "column") {
    return 0;
  }
  return position === "outside" ? 10 : -10;
};

const getYDataLabelPosition = (position, layout) => {
  if (layout === "column") {
    return position === "outside" ? -20 : 10;
  }
  return 0;
};

export const singleBarDataLabelConfig = ({ layout, isVisible, position }) => {
  return {
    enabled: isVisible,
    rotation: layout === "column" ? -90 : 0,
    color: position === "outside" ? "#000000" : "#FFFFFF",
    align: layout === "column" ? "right" : "center",
    // format: "{point.y:.1f}", // one decimal
    x: getXDataLabelPosition(position, layout),
    y: getYDataLabelPosition(position, layout),
    style:
      position === "outside"
        ? { fontWeight: "unset", fontSize: "unset", fontFamily: "unset" }
        : {
            fontWeight: "bold",
            // fontSize: "12px",
            // fontFamily: "Verdana, sans-serif",
          },
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      if (this.y > 0) {
        return this.y;
      }
      return null;
    },
  };
};
