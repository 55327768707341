import React, { FC, memo } from "react";
import {
  Wrapper,
  IntroBanner,
  Footer,
  Title,
  Content,
  DotContainer,
  Dot,
} from "./styles";

interface Props {
  bgColor: "DANGER" | "INFO" | "WARNING" | "SUCCESS";
  icon: string;
  title?: string;
  content?: string;
  prevBtn?: React.ReactNode;
  nextBtn?: React.ReactNode;
  pageLength?: number;
  count?: number;
}

const IntroModal: FC<Props> = ({
  bgColor,
  icon,
  title,
  content,
  prevBtn,
  nextBtn,
  pageLength,
  count,
}) => {
  return (
    <Wrapper>
      <IntroBanner $bgColor={bgColor}>
        <img src={icon} alt={icon} />
      </IntroBanner>
      <div>
        <Title>{title}</Title>
        <Content>{content}</Content>
      </div>
      <DotContainer>
        {[...new Array(pageLength)].map((dot, index) => (
          <Dot key={dot} $bgColor={bgColor} $active={count === index + 1} />
        ))}
      </DotContainer>
      <Footer>
        {prevBtn}

        {nextBtn}
      </Footer>
    </Wrapper>
  );
};

export default memo(IntroModal);
