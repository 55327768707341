import styled from "styled-components";
import { Input, InputNumber, Select } from "antd";

const { TextArea } = Input;

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const Textbox = styled(TextArea)`
  width: 100%;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
`;

export const CheckboxChoiceOptions = styled(Select)`
  & div[class~="ant-select-selector"] {
    //height: 40px;
    border: 0.5px solid #c4c4c4;
    box-sizing: border-box;
    border-radius: 5px;
  }
  width: ${(props: { $width?: string }) => props.$width || "150px"};
  & span[class~="ant-select-selection-item"] {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI";
  }
`;

export const CheckboxChoiceNumber = styled(InputNumber)`
  border: 1px solid #e0e0e0;
  width: 60px;
  height: 32px;
  margin-left: 10px;
`;

export const CheckboxChoiceOptionsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const SubSection = styled.div`
  margin-top: 10px;
  & > p {
    margin-bottom: 2px;
    font-size: 10px;
    font-weight: 700;
  }
`;
