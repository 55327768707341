import React from "react";
import { Body, Header, Wrapper, ButtonWrapper } from "./style";
import {
  QuestionKeyToIcon,
  QuestionKeyToName,
  questionOptionList,
  QuestionOptionTypes,
} from "../../../common/utils/questionType";
import {
  setConfigurationType,
  setNewQuestion,
  setSelectedQuestion,
  setSelectedSection,
} from "../../../../pages/Design/designSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  initialQuestion as emptyQuestionState,
  QuestionTypeEnum,
} from "./utils";

interface QuestionButtonProps {
  isSelected: boolean;
  questionType: QuestionOptionTypes;
  onQuestionClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const QuestionButton = ({
  isSelected,
  questionType,
  onQuestionClick,
}: QuestionButtonProps) => {
  return (
    <ButtonWrapper
      onClick={onQuestionClick}
      aria-label={QuestionKeyToName[questionType]}
      data-value={questionType}
      $isSelected={isSelected}
    >
      {QuestionKeyToIcon[questionType]}
      <span>{QuestionKeyToName[questionType]}</span>
    </ButtonWrapper>
  );
};

const QuestionTypes = () => {
  const dispatch = useAppDispatch();
  const { selectedQuestion } = useAppSelector((state) => state.design);

  const onQuestionClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const selQuestionType = e.currentTarget.getAttribute(
      "data-value"
    ) as QuestionTypeEnum;
    const newQuestionObject = {
      ...emptyQuestionState,
      questionId: null,
      type: selQuestionType,
    };
    dispatch(setSelectedSection(null));
    dispatch(setConfigurationType("QUESTION"));

    dispatch(setNewQuestion(newQuestionObject));
    dispatch(setSelectedQuestion(newQuestionObject));
  };

  return (
    <Wrapper $show data-panel="questions">
      <Header>
        <p>Question types</p>
      </Header>
      <Body>
        {questionOptionList.map((ques) => (
          <QuestionButton
            key={ques}
            questionType={ques}
            onQuestionClick={onQuestionClick}
            isSelected={
              selectedQuestion?.type === ques && !selectedQuestion.questionId
            }
          />
        ))}
      </Body>
    </Wrapper>
  );
};

export default QuestionTypes;
