import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  box-sizing: border-box;
  width: 100%;
`;

export const OutlinedButton = styled.button`
  width: 100%;
  padding: 10px 18px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;

export const ColoredButton = styled.button`
  padding: 10px 18px;
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  border: none;
  background-color: #ce375c;
`;

export const Body = styled.div`
  display: flex;
  gap: 2rem;
  overflow: auto;
  flex: none;
  scroll-snap-align: center;
  flex-flow: column nowrap;
  width: 100%;
`;

export const IntroBanner = styled.div`
  height: 254px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #101828;
`;

export const Content = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #667085;
`;

export const Footer = styled.div`
  display: flex;
  gap: 1rem;
`;
