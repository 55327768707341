import React, { useContext, useState } from "react";
import Portal from "@reach/portal";
import { v4 as uuidv4 } from "uuid";
import { ChromePicker } from "react-color";
import { Buttons, Content, Wrapper } from "./style";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import {
  useAddNewColorMutation,
  useGetUserLibraryQuery,
} from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";

const ColorModal = ({ onCancel }: { onCancel: () => void }) => {
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const [selectedColor, setSelectedColor] = useState<{ hex: string }>({
    hex: "#ce375c",
  });

  const [addNewColor, { isLoading: isAddingNew }] = useAddNewColorMutation();
  const { isLoading } = useGetUserLibraryQuery({});

  const onChange = (color, event) => {
    setSelectedColor(color);
  };

  const onSave = async () => {
    // check if color already exists in lists. Return error if it does
    try {
      await addNewColor({
        colorId: uuidv4(),
        colorCode: selectedColor?.hex || "#ce375c",
      }).unwrap();
      onCancel();
      onSuccessShowAlert("Color added");
    } catch (error) {
      onShowErrorAlert(error, "Failed to add color");
    }
  };

  return (
    <Portal>
      <Wrapper>
        <Loader isLoading={isAddingNew || isLoading} />
        <Content>
          <ChromePicker color={selectedColor.hex} onChange={onChange} />
          <Buttons>
            <button type="button" onClick={onSave}>
              Add color
            </button>
            <button type="button" onClick={onCancel}>
              Cancel
            </button>
          </Buttons>
        </Content>
      </Wrapper>
    </Portal>
  );
};

export default ColorModal;
