import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
`;

export const Content = styled.div`
  width: min(94%, 440px);
  display: flex;
  flex-direction: column;
  align-items: center;
  & form {
    width: 100%;
  }
  & svg {
    margin-bottom: 40px;
  }
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: var(--primary-text-black);
  margin-bottom: 16px;
`;

export const Subtitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--secondary-text-grey);
  margin-bottom: 26px;
`;

export const InputBox = styled.div`
  margin-bottom: 24px;
`;

export const NameDiv = styled.div`
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
  flex-wrap: wrap;
  & > div {
    width: 100%;
    margin-bottom: 24px;
  }
  @media (min-width: 1024px) {
    margin-bottom: 24px;
    & > div {
      width: 48%;
      margin-bottom: 0;
    }
  }
`;

export const SubmitButtonWrapper = styled.div`
  & button {
    margin-bottom: 24px;
  }
`;

export const NoAccount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    margin-left: 5px;
    color: var(--primary-red-color);
  }
`;

export const LeftDiv = styled.div`
  padding: 0 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1024px) {
    width: 50%;
  }
`;
export const RightDiv = styled.div`
  display: none;
  background-image: url("https://assets.surveylake.com/signup-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (min-width: 1024px) {
    display: block;
    height: 100%;
    flex: 1 1;
  }
`;

export const ActionBtns = styled.div`
  width: 100%;
  & > button {
    width: 100%;
    margin-bottom: 12px;
  }
`;
