import React from "react";
import { Wrapper, QuestionDiv } from "./style";
import {
  QuestionKeyToIcon,
  QuestionKeyToName,
} from "../../../../../common/utils/questionType";

interface HeaderProps {
  questionType:
    | "ST"
    | "LT"
    | "MC"
    | "Ch"
    | "Dd"
    | "SR"
    | "IC"
    | "Sl"
    | "Rk"
    | "MRS"
    | "DT";
}

const QuestionHeader = ({ questionType }: HeaderProps) => {
  return (
    <Wrapper>
      <p>Question</p>
      <QuestionDiv>
        {QuestionKeyToIcon[questionType]}
        <span>{QuestionKeyToName[questionType]}</span>
      </QuestionDiv>
    </Wrapper>
  );
};

export default QuestionHeader;
