import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackArrow } from "../../../assets/icons/arrow-left.svg";
import { NavContainer, IconContainer, MenuItem } from "./style";

interface NavProps {
  title: string;
  url?: string;
}

const Nav: FC<NavProps> = ({ title, url }) => {
  const navigate = useNavigate();
  return (
    <NavContainer onClick={url ? () => navigate(url) : () => navigate(-1)}>
      <IconContainer>
        <BackArrow />
      </IconContainer>
      <MenuItem>{title}</MenuItem>
    </NavContainer>
  );
};

export default Nav;
