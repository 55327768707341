import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #000000;
  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 128%;
`;

export const ColorList = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;

export const ColorBox = styled.div`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  margin-right: 10px;
`;

export const AddBox = styled.button`
  width: 55px;
  height: 55px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 1px solid black;
`;
