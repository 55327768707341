import styled from "styled-components";

export const Wrapper = styled.div`
  width: min(100%, 1440px);
  padding: min(15px, 10%) min(10px, 10%);
  background-color: #ffffff;
  margin: 0 auto 45px;
`;

export const FilterHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const FilterOpenButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: unset;
  padding: 5px;
  & > p {
    margin-right: 5px;
    margin-bottom: unset;
  }

  & > svg {
    transform: ${(props: { $show: boolean }) =>
      props.$show ? "rotate(180deg)" : "unset"};
  }
`;

export const FilterBox = styled.div`
  display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};
`;

export const ResponseFilterDiv = styled.div`
  margin-bottom: 45px;
`;

export const FilterDivItems = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: min(100%, 500px);
  }
  @media (min-width: 1024px) {
    & > div {
      width: min(50%, 635px);
    }
  }
`;

export const ResponseFilterButtons = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  & > button {
    height: 36px;
    width: 70px;
    border: unset;
  }

  & > button:first-child {
    margin-right: 15px;
    border: 1px solid #dedede;
  }
`;

export const ResetResponseFilterButton = styled.button`
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const ApplyResponseFilterButton = styled.button`
  background-color: var(--primary-red-color);
  color: #ffffff;
  border-radius: 4px;
`;

export const AudienceFilterDiv = styled.div`
  padding-top: 10px;
  border-top: 6px solid #ce375c; ;
`;

export const AudienceFilterHeader = styled.p`
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 20px;
  & span {
    color: var(--sl-button-color);
    font-weight: 200;
  }
`;
