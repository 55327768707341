import styled from "styled-components";
import { Radio } from "antd";

const RadioWrapper = styled(Radio)`
  & > span > span[class="ant-radio-inner"] {
    border-color: var(--sl-button-color) !important;
  }
  & > span > span[class="ant-radio-inner"]:after {
    background-color: var(--sl-button-color) !important;
  }
`;
export default RadioWrapper;
