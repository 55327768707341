import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Dropdown, Menu, MenuProps } from "antd";
import {
  Left,
  Right,
  Wrapper,
  ProfileContainer,
  ProfilePic,
  Username,
  UserEmail,
  UserMobileDropdown,
  AccountBtn,
  ProfileLink,
  UserDropdown,
  UserDetails,
  Email,
  LogoContainer,
  MenuItem,
  IconContainer,
  MenuButton,
} from "./style";
import "./style.css";
import { ReactComponent as SlLogo } from "../../../../../assets/logo-full.svg";
import { ReactComponent as DropdownCaret } from "../../../../../assets/icons/dropdown-caret.svg";
import { ReactComponent as UserIcon } from "../../../../../assets/icons/user.svg";
import { ReactComponent as UserGroup } from "../../../../../assets/icons/users.svg";
import { ReactComponent as Settings } from "../../../../../assets/icons/settings.svg";
import { ReactComponent as Zap } from "../../../../../assets/icons/zap.svg";
import { ReactComponent as Help } from "../../../../../assets/icons/help-circle.svg";
import { ReactComponent as LogoutIcon } from "../../../../../assets/icons/log-out.svg";
import { logoutUser } from "../../../../../pages/Login/loginSlice";
import { useAppDispatch } from "../../../../../app/hooks";

type MenuItemType = Required<MenuProps>["items"][number];

export const Header = () => {
  const dispatch = useAppDispatch();

  const onLogoutClick = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("u-1-a");
    await dispatch(logoutUser());
  };

  const profileSubmenu: MenuItemType[] = [
    {
      label: (
        <MenuItem>
          <IconContainer>
            <UserIcon />
          </IconContainer>
          <span>View Profile</span>
        </MenuItem>
      ),
      key: "0",
    },
    { type: "divider" },
    {
      label: (
        <MenuItem>
          <IconContainer>
            <Settings />
          </IconContainer>
          <span>Settings</span>
        </MenuItem>
      ),
      key: "1",
    },
    { type: "divider" },
    {
      label: (
        <MenuItem>
          <IconContainer>
            <Help />
          </IconContainer>
          <span>Support</span>
        </MenuItem>
      ),
      key: "2",
    },
    { type: "divider" },
    {
      label: (
        <MenuItem type="button" onClick={onLogoutClick}>
          <IconContainer>
            <LogoutIcon />
          </IconContainer>
          <span>Log out</span>
        </MenuItem>
      ),
      key: "3",
    },
  ];

  const profileDropdown = <Menu className="py-4" items={profileSubmenu} />;

  return (
    <Wrapper>
      <Left>
        <LogoContainer>
          <SlLogo />
        </LogoContainer>
        {/*<NavLink to={"/user/dashboard"}>Dashboard</NavLink>*/}
      </Left>
      <Right>
        <MenuButton>
          <Zap /> Upgrade now
        </MenuButton>
        <Dropdown overlay={profileDropdown} overlayClassName="profile-dropdown">
          <UserDropdown>
            <MenuButton>
              <UserIcon />
            </MenuButton>
            {/* <UserDetails>
              <Username>Temitayo Ogunsusi</Username>
              <Email>samtaylek@gmail.com</Email>
            </UserDetails> */}
            {/* <span>
              <DropdownCaret />
            </span> */}
          </UserDropdown>
        </Dropdown>
      </Right>
    </Wrapper>
  );
};
