import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  position: relative;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Left = styled.div`
  display: block;
  & > button {
    height: 40px;
    background-color: transparent;
    border: 1px solid black;
    color: #000000;
    padding: 0 10px;
    display: flex;
    align-items: center;
  }
  & span {
    margin-left: 5px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  & > button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0 10px;
    & > svg {
      margin-right: 5px;
    }
  }
  & > button:first-child {
    height: 40px;
    background-color: var(--primary-red-color);
    color: #ffffff;
    margin-right: 15px;
  }
  & > button:last-child {
    height: 40px;
    background-color: #ffffff;
    border: 1px solid black;
    color: #000000;
  }
`;

export const Content = styled.div`
  display: block;
  margin-top: 20px;
`;

export const About = styled.div`
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  background: #f9f9fc;
  padding: 10px;
  align-items: center;
  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }

  & > p > span {
    font-weight: normal;
    margin-left: 5px;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  & > div > button {
    display: flex;
    align-items: center;
    border: none;
    width: 30px;
    height: 30px;
    background-color: transparent;
    :hover {
      background-color: var(--primary-red-color);
      //color: #ffffff;
      & svg,
      & path {
        stroke: #ffffff;
        fill: #ffffff;
      }
    }
  }

  & > div > button:first-child {
    margin-right: 10px;
  }
`;

export const TableWrapper = styled.div`
  margin-top: 20px;
`;
