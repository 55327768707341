import React, { useEffect } from "react";
import { CreateNewButton, EmptyTableWrapper } from "./style";
import { ReactComponent as EmptyState } from "../../assets/empty-icon.svg";
import { ReactComponent as AddIcon } from "../../assets/plus.svg";
import { useAddNewSurveyMutation } from "../../../../app/apiSlice";
import { useNavigate } from "react-router-dom";

const EmptyTable = ({ onAddNew }: { onAddNew: (isNew: boolean) => void }) => {
  const navigate = useNavigate();
  const [addNewSurvey, { isLoading: isAddingNew }] = useAddNewSurveyMutation();

  const onFromScratchClick = async () => {
    const surveyBody = { name: "New Survey" };
    const { survey: newSurvey } = await addNewSurvey(surveyBody).unwrap();
    navigate(`/${newSurvey.surveyId}/design/questions`);
  };

  useEffect(() => {
    onAddNew(isAddingNew);
  }, [isAddingNew]);

  return (
    <EmptyTableWrapper>
      <EmptyState />
      <p>Nothing here</p>
      <CreateNewButton type="button" onClick={onFromScratchClick}>
        <span>
          <AddIcon />
        </span>
        Create
      </CreateNewButton>
    </EmptyTableWrapper>
  );
};

export default EmptyTable;
