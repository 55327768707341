import React, { useMemo } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ChartContainer } from "./style";

// const colors = Highcharts.getOptions().colors;
// const colors = [
//   "#FACC14",
//   "#2FC25B",
//   "#1890FF",
//   "#e15759",
//   "#4e79a7",
//   "#f28e2c",
//   "#76b7b2",
//   "#59a14f",
//   "#edc949",
//   "#af7aa1",
//   "#ff9da7",
//   "#9c755f",
//   "#bab0ab",
// ];

const colors = [
  "#058DC7",
  "#50B432",
  "#ED561B",
  "#DDDF00",
  "#24CBE5",
  "#64E572",
  "#FF9655",
  "#FFF263",
  "#6AF9C4",
];
function StackedChart({ chartData, keys }: { chartData: any; keys: string[] }) {
  const options = useMemo(() => {
    return {
      chart: {
        type: "column",
        style: {
          fontFamily: "Mulish",
        },
        height: (5 / 16) * 100 + "%", // 16:5 ratio
      },
      colors: colors,
      title: {
        text: "",
      },
      legend: {
        align: "right",
        verticalAlign: "middle",
        layout: "vertical",
        floating: false,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || "white",
        borderColor: "#FFF",
        borderWidth: 0,
        shadow: false,
        margin: 25,
        reversed: true,
        enabled: true,
        symbolRadius: 0,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
      },
      plotOptions: {
        series: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
            style: {
              textOutline: "none",
            },
            formatter() {
              return this.y || "";
            },
          },
        },
      },
      series: chartData,
      xAxis: {
        categories: keys,
        title: { text: "" },
        visible: true,
        gridLineColor: "#D3D6DF",
        gridLineDashStyle: "ShortDash",
      },
      yAxis: {
        labels: {
          style: "none",
        },
        title: { text: "" },
        visible: true,
        gridLineColor: "#D3D6DF",
        gridLineDashStyle: "ShortDash",
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      credits: {
        enabled: false,
      },
    };

    return {};
  }, [keys, chartData]);

  return (
    <ChartContainer>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </ChartContainer>
  );
}

export default StackedChart;
