import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  margin: auto 5% 40px;
  max-height: 370px;
  overflow-y: scroll;
  & p {
    margin-bottom: unset;
  }
  & table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
    font-family: Mulish, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    text-align: center;
    width: 100%;
    & th,
    & td {
      text-align: left;
      font-size: 14px;
      font-family: "Mulish", sans-serif;
      padding: 0.5em;
      font-weight: normal;
    }

    & > tr {
      background: #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }

  & thead {
    & > tr {
      background: #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
    }
  }
  & tbody {
    & > tr {
      border-bottom: 1px solid #f0f0f0;
    }
    & > tr > td {
      border: 1px solid #f0f0f0;
      vertical-align: baseline;
    }
  }
`;

export const HeaderText = styled.p`
  text-align: ${(props: { $align: string }) => props.$align ?? "left"};
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  min-height: 25px;
`;

export const TableCell = styled.div`
  display: block;
`;

export const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

export const UserLink = styled(NavLink)`
  text-decoration: underline;
  text-decoration-color: var(--sl-button-color);
  color: var(--sl-button-color);
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
`;

export const DateAnswered = styled.p`
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #737373;
`;

export const ValueText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #32363a;
`;
