import React from "react";
import {
  CheckboxChoiceNumber,
  CheckboxChoiceOptions,
  CheckboxChoiceOptionsContainer,
  SubSection,
  Textbox,
  Wrapper,
} from "./style";
import QuestionFormCheckbox from "../QuestionFormCheckbox";
import { useConfiguration } from "../../contexts/Configuration";
import type { QuestionType } from "../../../../../../pages/Design/types";

const RequiredCheckbox = ({
  questionType = "MC",
}: {
  questionType?: QuestionType;
}) => {
  const { state: configState, dispatch } = useConfiguration();

  const { isRequired, requiredMessage, rowOptions } = configState;
  const onToggleRequired = (e: any) => {
    dispatch({ type: "UPDATE_IS_REQUIRED", payload: e.target.checked });
  };

  const onRequiredMessageChange = (e: any) => {
    dispatch({ type: "UPDATE_REQUIRED_MESSAGE", payload: e.target.value });
  };

  const onCheckboxChoiceTypeChange = (value: any) => {
    dispatch({ type: "UPDATE_CHOICES_RANGE_TYPE", payload: value });
  };

  const onCheckboxChoiceNumChange = (value: number | string | null) => {
    dispatch({
      type: "UPDATE_CHOICES_RANGE_NUMBER",
      payload: value ? +value : 1,
    });
  };

  return (
    <Wrapper>
      <QuestionFormCheckbox checked={isRequired} onChange={onToggleRequired}>
        Question is mandatory
      </QuestionFormCheckbox>
      {isRequired && questionType === "Ch" && (
        <SubSection>
          <p>Number of choices respondents must answer</p>
          <CheckboxChoiceOptionsContainer>
            <CheckboxChoiceOptions
              options={[
                { value: "at_least", label: "At least" },
                { value: "at_most", label: "At most" },
                { value: "exactly", label: "Exactly" },
              ]}
              onChange={onCheckboxChoiceTypeChange}
            />
            <CheckboxChoiceNumber
              min={1}
              max={rowOptions.length > 2 ? rowOptions.length - 1 : 1}
              onChange={onCheckboxChoiceNumChange}
            />
          </CheckboxChoiceOptionsContainer>
        </SubSection>
      )}
      {isRequired && (
        <SubSection>
          <p>Display this error message when this question is not answered</p>
          <Textbox
            rows={2}
            value={requiredMessage}
            onChange={onRequiredMessageChange}
          />
        </SubSection>
      )}
    </Wrapper>
  );
};

export default RequiredCheckbox;
