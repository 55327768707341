import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Wrapper } from "./style";
import { useAppDispatch } from "../../app/hooks";
import { DesignNav } from "../../components/DesignNav";
import AnalyticsProvider from "../../context/AnalyticsContext";
import Analytics from "../../components/Analytics";
import Filter from "../../components/Analytics/components/Filter";
import {
  useGetChartSettingsQuery,
  useGetResponsesQuery,
} from "../../app/apiSlice";
import { setChartSettingsList } from "../Design/designSlice";
import NoDataAnalytics from "../../components/Analytics/components/NoDataAnalytics";
import Loader from "../../components/common/Loader";

const DisplayContent = () => {
  const { surveyId } = useParams();
  const {
    data: responsesList,
    isFetching,
    refetch,
  } = useGetResponsesQuery(
    surveyId,
    // this overrules the api definition setting,
    // forcing the query to always fetch when this component is mounted
    { refetchOnMountOrArgChange: true }
  );

  function handleRefetchOne() {
    // force re-fetches the data
    refetch();
  }

  if (isFetching) {
    return <Loader isLoading />;
  }

  if (!responsesList || responsesList.length === 0) {
    return <NoDataAnalytics onRefresh={handleRefetchOne} />;
  } else {
    return (
      <>
        <Filter />
        <Analytics />
      </>
    );
  }
};

const AnalyticsPage = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { data: chartSettingsData, isFetching: isFetchingSettings } =
    useGetChartSettingsQuery(params.surveyId);

  // console.log("chartSettingsData");
  // console.log(chartSettingsData);
  useEffect(() => {
    if (chartSettingsData) {
      dispatch(setChartSettingsList(chartSettingsData));
    }
  }, [chartSettingsData]);

  return (
    <Wrapper>
      <Loader isLoading={isFetchingSettings} />
      <DesignNav />
      <AnalyticsProvider>
        <DisplayContent />
      </AnalyticsProvider>
    </Wrapper>
  );
};

export default AnalyticsPage;
