import React, { useState } from "react";
import {
  Wrapper,
  Title,
  Header,
  Content,
  LogoButton,
  Footer,
  SelectButton,
} from "./style";
import { useGetUserLibraryQuery } from "../../../../app/apiSlice";

const LogoList = ({
  onSelectLogo,
}: {
  onSelectLogo: (url: string) => void;
}) => {
  const [selectedLogoId, setSelectedLogoId] = useState(null);
  const [selectedLogoUrl, setSelectedLogoUrl] = useState("");

  const { data: library } = useGetUserLibraryQuery({});

  const onLogoClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const logoUrlValue = e.currentTarget.getAttribute("data-logourl");
    const logoIdValue = e.currentTarget.getAttribute("data-logoid");
    setSelectedLogoUrl(logoUrlValue);
    setSelectedLogoId(logoIdValue);
  };

  const onLoadClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSelectLogo(selectedLogoUrl);
  };

  return (
    <Wrapper>
      <Header>
        <Title>Brand logos</Title>
      </Header>
      <Content>
        {library?.logos?.map((logo) => (
          <LogoButton
            type="button"
            key={logo.id}
            data-logourl={logo.url}
            data-logoid={logo.id}
            $isSelected={selectedLogoId === logo.id}
            onClick={onLogoClick}
          >
            <img src={`${process.env.REACT_APP_ASSETS_URL}${logo.url}`} />
          </LogoButton>
        ))}
      </Content>
      <Footer>
        <SelectButton onClick={onLoadClick}>Select logo</SelectButton>
      </Footer>
    </Wrapper>
  );
};

export default LogoList;
