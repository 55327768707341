import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as HideIcon } from "./icons/arrow-up-circle.svg";
import {
  AudienceFilterDiv,
  AudienceFilterHeader,
  ResetResponseFilterButton,
  ApplyResponseFilterButton,
  ResponseFilterDiv,
  Wrapper,
  FilterHead,
  FilterOpenButton,
  FilterBox,
  ResponseFilterButtons,
  FilterDivItems,
} from "./style";
import QuestionAnswerFilter from "./components/QuestionAnswerFilter";
import ResponseDateFilter from "./components/ResponseDateFilter";
import { filterByAllParams } from "./utils";
import { AnalyticsContext } from "../../../../context/AnalyticsContext";
import { useAppSelector } from "../../../../app/hooks";
import { useGetResponsesQuery } from "../../../../app/apiSlice";
import Loader from "../../../common/Loader";
import { filterResultByDateRange } from "../../utils";
import type { FilterParamType } from "./types";

const Filter = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const {
    onUpdateAnalysesList,
    filterDateRange,
    onReset: onResetFilter,
  } = useContext(AnalyticsContext);
  const [showFilter, setShowFilter] = useState(false);
  const [filterQaParams, setFilterQaParams] = useState<FilterParamType[]>([]);
  const onToggleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const { data: responsesList, isFetching } = useGetResponsesQuery(
    params.surveyId
  );
  const { questionsById } = useAppSelector((state) => state.design);

  const onReset = () => {
    onResetFilter();
    const formattedAnalys = filterByAllParams({
      responsesList,
      questionsById,
      filterParams: [],
    });
    onUpdateAnalysesList(formattedAnalys);
    onToggleShowFilter();
  };

  const onApply = () => {
    setLoading(true);
    let filteredResult = responsesList;
    if (filterDateRange && filterDateRange.length) {
      filteredResult = filterResultByDateRange(filterDateRange, responsesList);
    }
    const formattedAnalys = filterByAllParams({
      responsesList: filteredResult,
      questionsById,
      filterParams: filterQaParams,
    });
    onUpdateAnalysesList(formattedAnalys);
    setLoading(false);
    onToggleShowFilter();
  };

  const onFilterParamsChange = (filParams: FilterParamType[]) => {
    setFilterQaParams(filParams);
  };

  return (
    <Wrapper>
      <Loader isLoading={isFetching || loading} />
      <FilterHead>
        <FilterOpenButton onClick={onToggleShowFilter} $show={showFilter}>
          <p>{`${showFilter ? "Hide" : "Show"} details`}</p>
          <HideIcon />
        </FilterOpenButton>
      </FilterHead>
      <FilterBox $show={showFilter}>
        <ResponseFilterDiv>
          <FilterDivItems>
            <QuestionAnswerFilter
              filterParams={filterQaParams}
              onUpdateFilterParams={onFilterParamsChange}
            />
            <ResponseDateFilter />
          </FilterDivItems>
          <ResponseFilterButtons>
            <ResetResponseFilterButton onClick={onReset}>
              Reset
            </ResetResponseFilterButton>
            <ApplyResponseFilterButton onClick={onApply}>
              Apply
            </ApplyResponseFilterButton>
          </ResponseFilterButtons>
        </ResponseFilterDiv>
        <AudienceFilterDiv>
          <AudienceFilterHeader>
            Advanced Filters <span>(for Audience only)</span>
          </AudienceFilterHeader>
        </AudienceFilterDiv>
      </FilterBox>
    </Wrapper>
  );
};

export default Filter;
