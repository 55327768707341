import React from "react";
import SliderWrapper from "./style";

const CustomSlider = ({
  min = 0,
  max = 1,
  value = undefined,
  step = 0.1,
  width = "100%",
  onChange,
}: {
  min: number;
  max: number;
  value?: number;
  step: number;
  width: string;
  onChange: (value: number) => void;
}) => (
  <SliderWrapper
    min={min}
    max={max}
    style={{ width }}
    onChange={(value) => {
      onChange && onChange(value);
    }}
    value={value}
    step={step}
  />
);

export default CustomSlider;
