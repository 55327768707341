export const fontSizeOptions = [
  { value: "8", label: "8" },
  { value: "10", label: "10" },
  { value: "12", label: "12" },
  { value: "14", label: "14" },
  { value: "16", label: "16" },
  { value: "18", label: "18" },
  { value: "20", label: "20" },
  { value: "24", label: "24" },
  { value: "28", label: "28" },
  { value: "32", label: "32" },
  { value: "36", label: "36" },
];

export const fontFamilyOptions = [
  { value: "Arial", label: "Arial" },
  { value: "Mark Pro", label: "Mark Pro" },
  { value: "Mulish", label: "Mulish" },
  { value: "Roboto", label: "Roboto" },
];

export const colorThemes = [
  {
    id: "monk",
    name: "Monk",
    colors: {
      title: "#fffffe",
      text: "#a7a9be",
      button: "#ff8906",
      buttonText: "#fffffe",
      background: "#0f0e17",
    },
  },
  {
    id: "rango",
    name: "Rango",
    colors: {
      title: "#001858",
      text: "#172c66",
      button: "#f582ae",
      buttonText: "#001858",
      background: "#fef6e4",
    },
  },
  {
    id: "yaba",
    name: "Yaba",
    colors: {
      title: "#fffffe",
      text: "#abd1c6",
      button: "#f9bc60",
      buttonText: "#001e1d",
      background: "#004643",
    },
  },
  {
    id: "jiggy",
    name: "Jiggy",
    colors: {
      title: "#00214d",
      text: "#1b2d45",
      button: "#00ebc7",
      buttonText: "#00214d",
      background: "#fffffe",
    },
  },
  {
    id: "dusk",
    name: "Dusk",
    colors: {
      title: "#fffffe",
      text: "#94a1b2",
      button: "#7f5af0",
      buttonText: "#fffffe",
      background: "#16161a",
    },
  },
  {
    id: "lamba",
    name: "Lamba",
    colors: {
      title: "#0d0d0d",
      text: "#2a2a2a",
      button: "#ff8e3c",
      buttonText: "#0d0d0d",
      background: "#eff0f3",
    },
  },
  {
    id: "petal",
    name: "Petal",
    colors: {
      title: "#00473e",
      text: "#475d5b",
      button: "#faae2b",
      buttonText: "#00473e",
      background: "#f2f7f5",
    },
  },
  {
    id: "pinky",
    name: "Pinky",
    colors: {
      title: "#0e172c",
      text: "#0e172c",
      button: "#0e172c",
      buttonText: "#fffffe",
      background: "#fec7d7",
    },
  },

  {
    id: "earth",
    name: "Earth",
    colors: {
      title: "#272343",
      text: "#2d334a",
      button: "#ffd803",
      buttonText: "#272343",
      background: "#fffffe",
    },
  },
  {
    id: "pine",
    name: "Pine",
    colors: {
      title: "#232323",
      text: "#222525",
      button: "#078080",
      buttonText: "#232323",
      background: "#f8f5f2",
    },
  },
];
