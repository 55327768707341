import styled from "styled-components";

export const Wrapper = styled.div`
  & div[class="tox-statusbar"] {
    display: none;
  }
  & button[class~="tox-tbtn--bespoke"] span[class="tox-tbtn__select-label"] {
    width: 3em !important;
  }
`;
