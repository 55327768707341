import React, { useContext } from "react";
import Portal from "@reach/portal";
import { Buttons, Content, Wrapper } from "./style";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import {
  useDeleteColorMutation,
  useDeleteLogoMutation,
  useGetUserLibraryQuery,
} from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";

const DeleteModal = ({
  itemId,
  itemType,
  onCancel,
}: {
  onCancel: () => void;
  itemType: "color" | "logo";
  itemId: string;
}) => {
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const [deleteColor, { isLoading: isDeletingColor }] =
    useDeleteColorMutation();
  const [deleteLogo, { isLoading: isDeletingLogo }] = useDeleteLogoMutation();
  const { isLoading } = useGetUserLibraryQuery({});

  const onDelete = async () => {
    try {
      if (itemType === "logo") {
        await deleteLogo({
          logoId: itemId,
        }).unwrap();
        onSuccessShowAlert("Color deleted");
      } else if (itemType === "color") {
        await deleteColor({
          colorId: itemId,
        }).unwrap();
        onSuccessShowAlert("Logo deleted");
      }
      onCancel();
    } catch (error) {
      onShowErrorAlert(error, "Failed to delete");
    }
  };

  return (
    <Portal>
      <Loader isLoading={isDeletingColor || isDeletingLogo || isLoading} />
      <Wrapper>
        <Content>
          <p>Are you sure you want to delete?</p>
          <Buttons>
            <button type="button" onClick={onDelete}>
              Yes, delete
            </button>
            <button type="button" onClick={onCancel}>
              No, cancel
            </button>
          </Buttons>
        </Content>
      </Wrapper>
    </Portal>
  );
};

export default DeleteModal;
