import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  gap: 2rem;
  overflow: auto;
  flex: none;
  scroll-snap-align: center;
  flex-flow: column nowrap;
  width: 100%;
`;

export const IntroBanner = styled.div`
  height: 254px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Title = styled.h3`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #101828;
`;

export const Content = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #667085;
`;

export const Footer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const DotContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

export const Dot = styled.span`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  ${(props: {
    $bgColor?: "DANGER" | "INFO" | "SUCCESS" | "WARNING";
    $active?: boolean;
  }) => {
    if (props.$bgColor === "DANGER" && props.$active) {
      return css`
        background-color: #ce375c;
      `;
    } else if (props.$bgColor === "INFO" && props.$active) {
      return css`
        background-color: #64c3eb;
      `;
    } else if (props.$bgColor === "SUCCESS" && props.$active) {
      return css`
        background-color: #5bb381;
      `;
    } else if (props.$bgColor === "WARNING" && props.$active) {
      return css`
        background-color: #e3b34c;
      `;
    } else {
      return css`
        background-color: #f4ebff;
      `;
    }
  }}
`;
