import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useWindowSize } from "@reach/window-size";
import {
  Content,
  Wrapper,
  Body,
  IframeContainer,
  ScreenButtons,
  ScreenButton,
  BackgroundLoader,
} from "./style";
import { DesignNav } from "../../components/DesignNav";

const PreviewSurvey = () => {
  const { height } = useWindowSize();
  const { surveyId } = useParams();
  const [screenMode, setScreenMode] = useState<"MOBILE" | "DESKTOP">("DESKTOP");
  const [screenWidth, setScreenWidth] = useState(1200);
  const [screenHeight, setScreenHeight] = useState(780);

  const onMobileClick = () => {
    setScreenMode("MOBILE");
    setScreenWidth(340);
    setScreenHeight(660);
  };
  const onDesktopClick = () => {
    setScreenMode("DESKTOP");
    setScreenWidth(1200);
    setScreenHeight(height - 240);
  };

  return (
    <Wrapper>
      <DesignNav />
      <Content>
        <BackgroundLoader>
          <img src="https://assets.surveylake.com/loader.png" alt="loader" />
        </BackgroundLoader>
        <ScreenButtons>
          <ScreenButton
            $selected={screenMode === "MOBILE"}
            type="button"
            onClick={onMobileClick}
          >
            Mobile
          </ScreenButton>
          <ScreenButton
            $selected={screenMode === "DESKTOP"}
            type="button"
            onClick={onDesktopClick}
          >
            Desktop
          </ScreenButton>
        </ScreenButtons>
        <Body>
          <IframeContainer
            title="Survey preview"
            width={screenWidth}
            height={screenHeight}
            src={`${process.env.REACT_APP_SURVEY_PREVIEW_URL}preview/${surveyId}`}
          />
        </Body>
      </Content>
    </Wrapper>
  );
};

export default PreviewSurvey;
