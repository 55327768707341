import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  //width: 585px;
  height: 530px;
  width: 100%;

  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const Title = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #1a141f;
  flex: 1;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: none;
  margin-left: 5px;
  background-color: #ffffff;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  flex-wrap: wrap;
  height: 85%;
`;

export const UncheckIcon = styled.div`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  //border-radius: 50%;
`;

export const CheckDiv = styled.div`
  display: flex;
  align-items: center;
`;

export const TemplateButton = styled.button`
  width: 258px;
  height: 154px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;

  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 15px 15px 0;
  ${(props: { $isSelected?: boolean }) => {
    if (props.$isSelected) {
      return css`
        border: 1px solid var(--primary-red-color);
        & ${UncheckIcon} {
          display: none;
        }
      `;
    }
    return css`
      border: 1px solid #eaecf0;
      & svg {
        display: none;
      }
    `;
  }}
`;

export const TemplateButtonHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  & p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 5px;
    text-align: left;
  }
`;

export const TemplateContent = styled.div`
  text-align: left;
  & p {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #667085;
    //white-space: nowrap;
    //width: 200px;

    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
`;

export const LoadButton = styled.button`
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  width: 129px;
  height: 40px;
`;

export const CategoriesWrapper = styled.div`
  & label {
    margin-bottom: 5px;
    display: flex;
  }

  & div[class~="ant-checkbox-group"] {
    display: block;
  }

  & span[class~="ant-checkbox-checked"] span[class~="ant-checkbox-inner"] {
    background-color: var(--primary-red-color) !important;
    border-color: var(--primary-red-color) !important;
  }

  & span[class~="ant-checkbox-checked"]::after {
    border-color: var(--primary-red-color) !important;
  }

  & label:hover span[class~="ant-checkbox"] span[class~="ant-checkbox-inner"],
  & label span[class~="ant-checkbox"]:hover span[class~="ant-checkbox-inner"] {
    border-color: var(--primary-red-color) !important;
  }
`;

export const CategoriesWrapperHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  & p {
    margin-bottom: 0;
  }
  & button {
    border: none;
    background-color: #ffffff;
    padding: 5px;
  }
`;
