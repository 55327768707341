import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row nowrap;
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  box-sizing: border-box;
  width: 100%;
`;

export const OutlinedButton = styled.button`
  width: 100%;
  padding: 10px 18px;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
`;

export const ColoredButton = styled.button`
  width: 100%;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: #ffffff;
  border: none;
  ${(props: { $bgColor: "DANGER" | "INFO" | "SUCCESS" | "WARNING" }) => {
    if (props.$bgColor === "DANGER") {
      return css`
        background-color: #ce375c;
      `;
    }
    if (props.$bgColor === "INFO") {
      return css`
        background-color: #64c3eb;
      `;
    }
    if (props.$bgColor === "SUCCESS") {
      return css`
        background-color: #5bb381;
      `;
    }
    if (props.$bgColor === "WARNING") {
      return css`
        background-color: #e3b34c;
      `;
    }
  }};
`;
