import React, { useState } from "react";
import { Modal } from "antd";
import { Wrapper, Text, TableContainer, Weblinks } from "./style";
import CollectorTable from "../CollectorTable";
import CollectorSettings from "../CollectorSettings";
import ResponseSettings from "../ResponseSettings";
import {
  CollectorType,
  ResponsesByCollectors,
} from "../../../../pages/CollectResponses/types";
import MediaOptions from "./components/MediaOptions";
import LinkBox from "./components/LinkBox";

const SocialMedia = ({
  collectors = [],
  responsesByCollectors,
}: {
  collectors: CollectorType[];
  responsesByCollectors: ResponsesByCollectors;
}) => {
  const [selectedCollector, setSelectedCollector] =
    useState<CollectorType>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const onConfigureClick = (theCollector: CollectorType) => {
    setSelectedCollector(theCollector);
    setIsModalVisible(true);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmitSettings = () => {
    setIsModalVisible(false);
  };

  const tableAction = (collectorItem) => (
    <CollectorSettings
      onConfigureClick={onConfigureClick}
      collectorItem={collectorItem}
    />
  );

  return (
    <Wrapper>
      <Text>If you aren’t logged in, log in to that social network.</Text>
      <Text>
        Add any message you would like to the post and then submit your post
      </Text>
      <Weblinks>
        {collectors.map((link) => (
          <LinkBox
            onConfigureClick={onConfigureClick}
            linkValue={`${process.env.REACT_APP_SURVEY_PREVIEW_URL}r/${link.collectorId}`}
            name={link.name}
            key={link.collectorId}
            socialType={link.socialType}
            collector={link}
          />
        ))}
      </Weblinks>
      <MediaOptions collectors={collectors} />
      <TableContainer>
        <CollectorTable
          menuComponent={tableAction}
          title="Social media collectors"
          collectorsData={collectors}
          responsesByCollectors={responsesByCollectors}
        />
      </TableContainer>

      {isModalVisible && (
        <Modal
          title="Social media"
          visible={isModalVisible}
          onCancel={onCancel}
          footer={null}
        >
          <ResponseSettings
            collector={selectedCollector}
            onSave={onSubmitSettings}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default SocialMedia;
