import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 35px 25px;
  overflow-y: auto;

  scrollbar-width: thin;
  scrollbar-color: var(--primary-red-color) #ffffff;

  /* width */
  ::-webkit-scrollbar {
    width: 7px;
  }
  //
  ///* Track */
  //::-webkit-scrollbar-track {
  //  //box-shadow: inset 0 0 5px grey;
  //  box-shadow: inset 0 0 5px #ffffff;
  //}

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--primary-red-color);
    border-radius: 20px;
    opacity: 0.4;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b30000;
  }
`;
