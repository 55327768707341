import React, { useContext } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import styled from "styled-components";
import AuthContextProvider from "./context/AuthContext";
import Dashboard from "./pages/Dashboard";
import Create from "./pages/Design";
import { NetworkErrorAlertContext } from "./context/NetworkErrorAlert";
import { SuccessAlertContext } from "./context/SuccessAlertContext";
import NetworkErrorAlert from "./components/common/alerts/NetworkErrorAlert";
import SuccessAlert from "./components/common/alerts/SuccessAlert";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import CollectResponses from "./pages/CollectResponses";
import AnalyticsPage from "./pages/Analytics";
import PreviewSurvey from "./pages/PreviewSurvey";
import Templates from "./pages/Templates";
import TemplatePreview from "./pages/TemplatePreview";
import AppShell from "./components/common/AppShell";
import Library from "./pages/Library";
import GroupListPage from "./pages/Contacts/GroupListPage";
import ContactListPage from "./pages/Contacts/ContactListPage";
import MySurveys from "./pages/MySurveys";
import WaitList from "./pages/WaitList";

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

// function PrivateRoute({ component: Component, ...rest }) {
//   const location = useLocation();
//   const token = localStorage.getItem("token");
//
//   const user = useAppSelector(selectUser);
//   const isAuthenticated = useAppSelector(selectIsAuthenticated);
//
//   return (
//     <Route
//       {...rest}
//       render={({ location }) =>
//         isAuthenticated && user && token ? (
//           <Component />
//         ) : (
//           <Navigate to="/login" state={{ from: location }} replace />
//         )
//       }
//     />
//   );
// }
//
// function OnboardingRoute({ component, path }) {
//   const user = useAppSelector(selectUser);
//   if (user?.userCredentials?.onboarded) {
//     return (
//       <Navigate
//         to={{
//           pathname: "/user/dashboard",
//         }}
//       />
//     );
//   }
//   return <PrivateRoute component={component} path={path} />;
// }
//
// function OnboardRequiredRoute({ component, path }) {
//   const user = useAppSelector(selectUser);
//   if (!user?.userCredentials?.onboarded) {
//     return (
//       <Navigate
//         to={{
//           pathname: "/account-type",
//         }}
//       />
//     );
//   }
//   return <PrivateRoute component={component} path={path} />;
// }

function App() {
  const { showAlert: showErrorAlert, alertMessage: errorAlertMessage } =
    useContext(NetworkErrorAlertContext);
  const { showAlert, alertMessage } = useContext(SuccessAlertContext);

  return (
    <Wrapper>
      {showErrorAlert && <NetworkErrorAlert message={errorAlertMessage} />}
      {showAlert && <SuccessAlert message={alertMessage} />}
      <BrowserRouter>
        <AuthContextProvider>
          <Routes>
            {/*<Route path="/auth/action" element={<AuthenticationAction />} />*/}
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/190wait687fdg34" element={<WaitList />} />
            <Route path="/" element={<AppShell />}>
              <Route path=":surveyId/design/:tab" element={<Create />} />
              <Route path=":surveyId/collect" element={<CollectResponses />} />
              <Route path=":surveyId/preview" element={<PreviewSurvey />} />
              <Route path=":surveyId/analytics" element={<AnalyticsPage />} />
              {/*<Route path="/forgot-password" element={<Login />} />*/}
              <Route path="user/dashboard" element={<Dashboard />} />
              <Route path="user/surveys" element={<MySurveys />} />
              <Route path="templates" element={<Templates />} />
              <Route
                path="t/:templateId/preview"
                element={<TemplatePreview />}
              />
              <Route path="library" element={<Library />} />
              <Route path="contacts" element={<GroupListPage />} />
              <Route
                path="contacts/users/:groupId"
                element={<ContactListPage />}
              />
            </Route>
          </Routes>
        </AuthContextProvider>
      </BrowserRouter>
    </Wrapper>
  );
}

export default App;
