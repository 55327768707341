import React from "react";
import PageForm from "../PageForm";
import SurveyTitleForm from "../SurveyTitleForm";
import MultipleChoiceForm from "../forms/MultipleChoiceForm";
import ShortLongAnswerForm from "../forms/ShortLongAnswerForm";
import SliderForm from "../forms/SliderForm";
import CheckboxForm from "../forms/CheckboxForm";
import DropdownForm from "../forms/DropdownForm";
import StarRatingForm from "../forms/StarRatingForm";
import DateTimeForm from "../forms/DateTimeForm";
import MatrixRatingForm from "../forms/MatrixRatingForm";
import { useAppDispatch } from "../../../../../../app/hooks";
import { setSelectedQuestion } from "../../../../../../pages/Design/designSlice";
import ImageChoiceForm from "../forms/ImageChoiceForm";
import RankingForm from "../forms/RankingForm";

const ConfigurationForm = ({ formType }: { formType: string }) => {
  const dispatch = useAppDispatch();
  const onCancel = () => {
    dispatch(setSelectedQuestion(null));
  };
  switch (formType) {
    case "page":
      return <PageForm />;
    // case "section":
    //   return <SurveyTitleForm />;
    // case "question":
    case "MC":
      return <MultipleChoiceForm onCancel={onCancel} />;
    case "Ch":
      return <CheckboxForm onCancel={onCancel} />;
    case "Dd":
      return <DropdownForm onCancel={onCancel} />;
    case "ST":
    case "LT":
      return (
        <ShortLongAnswerForm questionType={formType} onCancel={onCancel} />
      );
    case "Sl":
      return <SliderForm onCancel={onCancel} />;
    case "SR":
      return <StarRatingForm onCancel={onCancel} />;
    case "DT":
      return <DateTimeForm onCancel={onCancel} />;
    case "MRS":
      return <MatrixRatingForm onCancel={onCancel} />;
    case "Rk":
      return <RankingForm onCancel={onCancel} />;
    case "IC":
      return <ImageChoiceForm onCancel={onCancel} />;
    default:
      // return <SurveyTitleForm />;
      return null;
  }
};

export default ConfigurationForm;
