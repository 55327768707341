import React, { useContext, useState } from "react";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import {
  ConfigureButton,
  CopyButton,
  LinkContainer,
  LinkInput,
  Name,
  Wrapper,
  ComposeButton,
} from "./style";
import { copyText } from "../../../../../utils/helpers";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import type { CollectorType } from "../../../../../pages/CollectResponses/types";
import { Modal } from "antd";
import Mailbox from "../Mailbox";

interface LinkBoxProps {
  name?: string;
  onConfigureClick: (collector: CollectorType) => void;
  linkValue: string;
  collector: CollectorType;
}

const LinkBox = ({
  onConfigureClick,
  linkValue,
  name = "Email 1",
  collector,
}: LinkBoxProps) => {
  const [isComposeModalVisible, setIsComposeModalVisible] = useState(false);
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const onCopy = async () => {
    await copyText(linkValue);
    onSuccessShowAlert("Link copied");
  };
  const onConfigure = () => {
    onConfigureClick(collector);
  };

  const onComposeClick = () => {
    setIsComposeModalVisible(true);
  };

  const onComposeCancel = () => {
    setIsComposeModalVisible(false);
  };

  return (
    <Wrapper>
      <Name>{name}</Name>
      <LinkContainer>
        <LinkInput readOnly value={linkValue} />
        <CopyButton type="button" onClick={onCopy}>
          <CopyIcon />
        </CopyButton>
        {!collector.emailSent && (
          <ComposeButton onClick={onComposeClick}>
            Compose new email
          </ComposeButton>
        )}
        <ConfigureButton type="button" onClick={onConfigure}>
          Configure link
        </ConfigureButton>
      </LinkContainer>
      <>
        <Modal
          title="Compose email"
          visible={isComposeModalVisible}
          onCancel={onComposeCancel}
          footer={null}
          className="emailModal"
        >
          <Mailbox
            surveyLink={linkValue}
            collectorId={collector.collectorId}
            onClose={onComposeCancel}
          />
        </Modal>
      </>
    </Wrapper>
  );
};

export default LinkBox;
