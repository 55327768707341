import React, { useContext } from "react";
import {
  ItemDiv,
  ItemDivWrapper,
  RateBody,
  RateContainer,
  ValueText,
  Wrapper,
} from "./style";
import { HeaderText } from "../DateTimeList/style";
import { ChartContext } from "../../../context/ChartContext";
import type { StarRatingChartData } from "../../../context/types";

export default function StarDiagram({ theRef }: { theRef: any }) {
  const {
    headerText,
    headerTextAlignment,
    resultData: chartData,
    countPercentageFormat,
    extraOptions,
    respondentsDetails,
    selectedColorList,
  } = useContext(ChartContext);

  const starRatingOptions = extraOptions?.srOptions;

  return (
    <Wrapper ref={theRef}>
      <HeaderText $align={headerTextAlignment}>{headerText}</HeaderText>
      <ItemDivWrapper>
        {chartData.map((option: StarRatingChartData) => (
          <ItemDiv key={option.name}>
            <RateContainer>
              <RateBody
                count={starRatingOptions?.starRatingCount}
                disabled
                defaultValue={option.name}
                $color={selectedColorList[0]}
              />
            </RateContainer>
            <ValueText>
              {countPercentageFormat === "percentage"
                ? `${(
                    (option.responses / respondentsDetails.answered) *
                    100
                  ).toFixed(1)}%`
                : option.responses}
            </ValueText>
          </ItemDiv>
        ))}
      </ItemDivWrapper>
    </Wrapper>
  );
}
