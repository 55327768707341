import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 10px;
  width: 210px;
  background-color: #ffffff;
  display: ${(props: { $show: boolean }) => (props.$show ? "block" : "none")};
  height: 100%;
  overflow-y: auto;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  & > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    margin: unset;
  }
`;

export const Body = styled.div`
  width: 100%;
  overflow-y: auto;
  & > button {
    background-color: transparent;
    border: none;
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    & > svg {
      margin-right: 10px;
    }
    &:hover span {
      color: var(--primary-red-color);
    }

    &[data-value="ST"]:hover path,
    &[data-value="Dd"]:hover path,
    &[data-value="LT"]:hover path,
    &[data-value="Sl"]:hover path,
    &:hover svg circle {
      stroke: var(--primary-red-color);
    }
    &[data-value="MC"]:hover svg circle:last-child,
    &[data-value="MRS"]:hover svg circle:last-child,
    &[data-value="Sl"]:hover svg circle,
    &[data-value="SR"]:hover svg path,
    &[data-value="IC"]:hover svg path,
    &[data-value="Rk"]:hover svg path,
    &[data-value="DT"]:hover svg path,
    &[data-value="Ch"]:hover svg path {
      fill: var(--primary-red-color);
    }
  }
`;

export const ButtonWrapper = styled.button`
  ${(props: { $isSelected: boolean }) => {
    if (props.$isSelected) {
      return css`
        & span {
          color: var(--primary-red-color);
        }

        &[data-value="ST"] path,
        &[data-value="Dd"] path,
        &[data-value="LT"] path,
        &[data-value="Sl"] path,
        & svg circle {
          stroke: var(--primary-red-color);
        }
        &[data-value="MC"] svg circle:last-child,
        &[data-value="MRS"] svg circle:last-child,
        &[data-value="Sl"] svg circle,
        &[data-value="SR"] svg path,
        &[data-value="IC"] svg path,
        &[data-value="Rk"] svg path,
        &[data-value="DT"] svg path,
        &[data-value="Ch"] svg path {
          fill: var(--primary-red-color);
        }
      `;
    }
    return css`
      border-bottom: none;
    `;
  }}
`;
