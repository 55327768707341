import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 40px;
`;

export const NewSectionDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
`;

export const NewSectionButton = styled.button`
  width: 200px;
  background-color: var(--primary-red-color);
  border-radius: 6px;
  height: 40px;
  color: #ffffff;
  border: none;
  :disabled {
    background-color: #cacaca;
    color: black;
    cursor: no-drop;
  }
`;
