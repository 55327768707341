import React from "react";
import { RatingShapeType } from "../../index";
import { BsEmojiSmile, BsHeart, BsHandThumbsUp } from "react-icons/bs";
import { AiOutlineStar } from "react-icons/ai";

const ShapeIcon = ({
  shape,
  color,
}: {
  shape: RatingShapeType;
  color: string;
}) => {
  switch (shape) {
    case "Star":
      return (
        <AiOutlineStar style={{ fontSize: "24px", color }} color={color} />
      );
    case "Heart":
      return <BsHeart style={{ fontSize: "24px", color }} color={color} />;
    case "Thumb":
      return (
        <BsHandThumbsUp style={{ fontSize: "24px", color }} color={color} />
      );
    case "Smiley":
      return <BsEmojiSmile style={{ fontSize: "24px", color }} />;
    default:
      return null;
  }
};

export default ShapeIcon;
