import React, { useState } from "react";
import { Header, Content, Wrapper, HeadButtons, PageTitle } from "./style";
import LogicBox from "./components/LogicBox";
import { Button } from "../common/Button";

const SurveyLogic = () => {
  const [isShowingChart, setIsShowingChart] = useState(false);

  const onToggleEditChart = () => {
    setIsShowingChart(!isShowingChart);
  };

  return (
    <Wrapper>
      <Header>
        <PageTitle>Survey flow</PageTitle>
        <HeadButtons>
          <Button label="Test flow" theme="alternate" type="button" />
          <Button label="Save" theme="primary" type="button" />
        </HeadButtons>
      </Header>
      <Content>
        <LogicBox questionNumber={1} questionTitle="Test" />
      </Content>
    </Wrapper>
  );
};

export default SurveyLogic;
