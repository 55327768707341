import React from "react";
import { Wrapper } from "./style";
import QuestionFormCheckbox from "../QuestionFormCheckbox";
import { useConfiguration } from "../../contexts/Configuration";

const RandomCheckbox = () => {
  const { state: configState, dispatch } = useConfiguration();

  const { hasRandomizedChoices } = configState;
  const onToggleRequired = (e: any) => {
    dispatch({ type: "UPDATE_IS_RANDOM", payload: e.target.checked });
  };

  return (
    <Wrapper>
      <QuestionFormCheckbox
        checked={hasRandomizedChoices}
        onChange={onToggleRequired}
      >
        Randomize options
      </QuestionFormCheckbox>
    </Wrapper>
  );
};

export default RandomCheckbox;
