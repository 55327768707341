import React, { useContext, useEffect, useState } from "react";
import { Table } from "antd";
import dayjs from "dayjs";
import { HeaderText, ItemHead, TableCell, UserLink, Wrapper } from "./style";
import { ChartContext } from "../../../context/ChartContext";
import { DateAnswered, ValueText } from "../TextList/style";
import type { ChartRef } from "../types";

export default function DateTimeList({ theRef }: ChartRef) {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const {
    headerText,
    headerTextAlignment,
    resultData: chartData,
    questionOptions,
  } = useContext(ChartContext);

  useEffect(() => {
    const columnsList = questionOptions.map((option) => ({
      title: option.value,
      dataIndex: option.index,
      key: option.index,
      render: (value) => (
        <TableCell>
          <ItemHead>
            <UserLink to={`/individual/${value.responseId}`}>
              View respondent
            </UserLink>
            <DateAnswered>{dayjs(value.createdAt).fromNow()}</DateAnswered>
          </ItemHead>
          <ValueText>{value.dateValue}</ValueText>
        </TableCell>
      ),
    }));
    setColumns(columnsList);

    const dataS = chartData.map((data, index) => {
      const row = { key: index };
      Object.entries(data).forEach(([key, value]) => {
        row[key] = { ...value };
      });
      return row;
    });
    setDataSource(dataS);
  }, [chartData]);

  dataSource.push();

  return (
    <Wrapper ref={theRef}>
      <HeaderText $align={headerTextAlignment}>{headerText}</HeaderText>
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        bordered
        size="small"
        scroll={{ x: 600 }}
      />
    </Wrapper>
  );
}
