import styled from "styled-components";

export const Wrapper = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
`;

export const Table = styled.table`
  min-width: 310px;
  max-width: 800px;
  margin: 1em auto;
  font-family: Mulish, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  text-align: center;
  width: 100%;
  & th,
  & td {
    text-align: left;
    font-size: 14px;
    font-family: "Mulish", sans-serif;
    padding: 0.5em;
    font-weight: normal;
  }

  & > tr {
    background: #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const Thead = styled.thead`
  & > tr {
    background: #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
  }
`;

export const TBody = styled.tbody`
  & > tr {
    border-bottom: 1px solid #f0f0f0;
  }
  & > tr > td {
    border: 1px solid #f0f0f0;
  }
`;
