import React, { useContext, useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import isEqual from "lodash/isEqual";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import "../editor.css";
import {
  Content,
  Wrapper,
  Title,
  SubtitleContainer,
  Button,
  SubtitleBox,
  Buttons,
  SubtitleDisclaimer,
} from "./style";
import { ReactComponent as CheckIcon } from "./assets/check.svg";
import { ReactComponent as CancelIcon } from "./assets/cancel.svg";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import { useAppDispatch } from "../../../../../../app/hooks";
import {
  setInstructions,
  setName,
} from "../../../../../../pages/Design/designSlice";
import { useUpdateSurveyMutation } from "../../../../../../app/apiSlice";
import Loader from "../../../../../common/Loader";

type Inputs = {
  title: string;
  description?: string;
};

const SurveyTitle = ({
  surveyId,
  title = "<p>Survey title</p>",
  description = "<p></p>",
}: {
  surveyId: string;
  title?: string;
  description?: string;
}) => {
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();

  const [editSurvey, { isLoading: isUpdatingSurvey }] =
    useUpdateSurveyMutation();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setValue("title", title);
    setValue("description", description);
  }, [title, description]);

  const onClick = () => {
    setIsEditing(true);
  };

  const onSaveClick = () => {
    setIsEditing(false);
  };
  const onSaveSurvey: SubmitHandler<Inputs> = async (data) => {
    try {
      const initialSurveyValues = {
        name: title,
        instruction: description,
      };
      const surveyValues = {
        name: data.title,
        instruction: data.description,
      };

      const hasSurveyNotChanged = isEqual(initialSurveyValues, surveyValues);
      if (!hasSurveyNotChanged) {
        await editSurvey({
          surveyId,
          updatedSurvey: surveyValues,
        }).unwrap();
        await dispatch(setInstructions(data.description));
        await dispatch(setName(data.title));
      }
      onSaveClick();
      onSuccessShowAlert("Survey title updated");
    } catch (err) {
      onShowErrorAlert(err, "The survey title couldn't be saved");
      setValue("title", title);
      setValue("description", description);
    }
  };

  const onCancelClick = () => {
    setValue("title", title);
    setValue("description", description);
    setIsEditing(false);
  };
  return (
    <Wrapper $isEditing={isEditing}>
      <Loader isLoading={isUpdatingSurvey} />
      <Content onSubmit={handleSubmit(onSaveSurvey)}>
        <Title>
          <Controller
            name="title"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <Editor
                onBlur={field.onBlur}
                ref={field.ref}
                value={field.value || ""}
                onEditorChange={field.onChange}
                apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                initialValue={title}
                inline
                onClick={onClick}
                init={{
                  menubar: false,
                  inline: true,
                  plugins: ["link", "autolink"],
                  toolbar: ["bold italic underline | forecolor"],
                  valid_elements:
                    "p[style],strong,em,span[style],a[href],ul,ol,li",
                  valid_styles: {
                    "*": "font-size,font-family,color,text-decoration,text-align",
                  },
                  content_style:
                    "body { font-family:Mulish,Helvetica,Arial,sans-serif; font-size:18px }",
                }}
              />
            )}
          />
        </Title>
        <SubtitleContainer>
          <SubtitleBox>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Editor
                  ref={field.ref}
                  onBlur={field.onBlur}
                  value={field.value || ""}
                  onEditorChange={field.onChange}
                  apiKey={process.env.REACT_APP_TINY_MCE_KEY}
                  inline
                  initialValue={description}
                  onClick={onClick}
                  init={{
                    menubar: false,
                    inline: true,
                    plugins: ["link", "autolink"],
                    toolbar: ["bold italic underline | forecolor"],
                    valid_elements:
                      "p[style],strong,em,span[style],a[href],ul,ol,li",
                    valid_styles: {
                      "*": "font-size,font-family,color,text-decoration,text-align",
                    },
                    content_style:
                      "body { font-family:Mulish,Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              )}
            />
          </SubtitleBox>
          <SubtitleDisclaimer>
            This field is optional. You can use it for survey disclaimer,
            messages, or instructions.
          </SubtitleDisclaimer>
          <Buttons>
            <Button onClick={onSaveClick} type="submit">
              <CheckIcon />
            </Button>
            <Button onClick={onCancelClick} type="button">
              <CancelIcon />
            </Button>
          </Buttons>
        </SubtitleContainer>
      </Content>
    </Wrapper>
  );
};

export default SurveyTitle;
