import React, { useState } from "react";
import type { MenuProps } from "antd";
import { Menu } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { Body, Content, Wrapper } from "./style";
import { ReactComponent as HomeIcon } from "./assets/home.svg";
import { ReactComponent as MySurveysIcon } from "./assets/box.svg";
import { ReactComponent as NotificationIcon } from "./assets/bell.svg";
import { ReactComponent as TemplatesIcon } from "./assets/file-text.svg";
import { ReactComponent as ContactIcon } from "./assets/layers.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import { ReactComponent as LibraryIcon } from "./assets/sliders.svg";
import { ReactComponent as SupportIcon } from "./assets/help-circle.svg";
import { ReactComponent as ToggleIcon } from "./assets/dropdown.svg";
import { Header } from "./components/Header";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  className?: string,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    className,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Home", "home", <HomeIcon />, "home"),
  getItem("My surveys", "surveys", <MySurveysIcon />, "surveys"),
  getItem("Templates", "templates", <TemplatesIcon />, "templates"),
  getItem("Contact list", "contacts", <ContactIcon />, "contacts"),
  getItem("Library", "library", <LibraryIcon />, "library"),
  getItem("Notification", "notification", <NotificationIcon />, "notification"),
  getItem("Support", "support", <SupportIcon />, "support"),
  getItem("Settings", "settings", <SettingsIcon />, "settings"),
  getItem(
    // <ToggleButton type="button">Toggle</ToggleButton>,
    "",
    "collapse",
    <ToggleIcon />,
    "collapse"
  ),
];

const AppShell = () => {
  const navigate = useNavigate();

  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const onClickMenuItem = ({ key, domEvent }) => {
    switch (key) {
      case "home":
        navigate("/user/dashboard");
        break;
      case "surveys":
        navigate("/user/surveys");
        break;
      case "templates":
        navigate("/templates");
        break;
      case "contacts":
        navigate("/contacts");
        break;
      case "library":
        navigate("/library");
        break;
      case "notification":
        navigate("/notification");
        break;
      case "support":
        navigate("/support");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "collapse":
        domEvent.stopPropagation();
        domEvent.preventDefault();
        toggleCollapsed();
        break;
      default:
        navigate("/user/dashboard");
    }
  };

  return (
    <Wrapper>
      <Header />
      <Content $collapsed={collapsed}>
        <Menu
          defaultSelectedKeys={["home"]}
          mode="inline"
          theme="dark"
          inlineCollapsed={collapsed}
          items={items}
          onClick={onClickMenuItem}
        />
        <Body>
          <Outlet />
        </Body>
      </Content>
    </Wrapper>
  );
};

export default AppShell;
