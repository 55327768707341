import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const SideBar = styled.div`
  padding: 26px 20px;
`;

export const Body = styled.div`
  padding: 20px 32px;
  flex: 1;
`;

export const TabsBody = styled.div`
  position: relative;
  height: 100%;

  & div[class~="ant-tabs"],
  & div[class~="ant-tabs-content"] {
    height: 100%;
    overflow-y: auto;
  }
  & div[class~="ant-tabs-content-holder"] {
    height: 100%;
    overflow-y: auto;
  }

  & div[class~="ant-tabs-top"] > div[class~="ant-tabs-nav"]:before {
    border-bottom: 1px solid #e1e3e5;
  }

  &
    div[class~="ant-tabs-nav-list"]
    > div[class~="ant-tabs-tab"]
    > div[class~="ant-tabs-tab-btn"] {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #6d7175;
  }

  &
    div[class~="ant-tabs-nav-list"]
    > div[class~="ant-tabs-tab-active"]
    > div[class~="ant-tabs-tab-btn"] {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #202223;
  }

  & div[class~="ant-tabs-ink-bar"] {
    background-color: #008060;
  }
`;
