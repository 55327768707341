import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  padding: 30px;
`;

export const Section = styled.div`
  display: ${(props) => (props.$show ? "block" : "none")};
  &:first-child {
    margin-bottom: 20px;
  }
`;

export const Left = styled.div`
  width: 50%;
`;

export const Right = styled.div`
  flex: 1 1;
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 16px;
  color: #222222;
`;

export const Body = styled.div`
  padding: 2px;
  & > label {
    margin-bottom: 10px;
  }
`;

export const AxisInputContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
  & > div:first-child {
    margin-right: 10px;
  }
`;

export const AxisInputBox = styled.div`
  display: block;
  & > p {
    margin-bottom: 5px;
  }
`;

export const SubDiv = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }

  & > *:first-child {
    margin-bottom: 10px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  ${(props) => {
    if (props.$orientation === "vertical") {
      return css`
        display: block;
        & p {
          margin-bottom: 5px;
        }
      `;
    }
    return css`
      display: flex;
      align-items: center;
      & p {
        margin: 0 10px;
      }
    `;
  }}

  ${(props) => {
    if (props?.$hide) {
      return css`
        display: none !important;
        margin-bottom: 0;
      `;
    }
    return css`
      margin-bottom: 20px;
    `;
  }}
`;

export const DataRadioBox = styled.div`
  margin-left: 40px;
`;
