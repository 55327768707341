import React, { FC, useEffect, useState } from "react";
import { useGetAllWaitListQuery } from "../../app/apiSlice";
import Loader from "../../components/common/Loader";
import { Wrapper, Row } from "./style";

const WaitList: FC = () => {
  const { data, isFetching } = useGetAllWaitListQuery({});
  const [waitList, setWaitList] = useState([]);

  useEffect(() => {
    if (!data || (Array.isArray(data) && data.length !== 0)) {
      setWaitList(data);
    }
  }, [data]);

  return (
    <Wrapper>
      <Loader isLoading={isFetching} />
      <div>
        {waitList.map((wait) => (
          <Row key={wait.date}>
            <div>{wait.email}</div>
            <div>{wait.date}</div>
          </Row>
        ))}
      </div>
    </Wrapper>
  );
};

export default WaitList;
