import React, { useState } from "react";
import { Modal } from "antd";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import {
  Wrapper,
  TableContainer,
  Text,
  NewLinkButton,
  Weblinks,
} from "./style";
import ResponseSettings from "../../ResponseSettings";
import CollectorSettings from "../../CollectorSettings";
import CollectorTable from "../../CollectorTable";
import LinkBox from "../LinkBox";
import type { CollectorType } from "../../../../../pages/CollectResponses/types";
import { useAddNewCollectorMutation } from "../../../../../app/apiSlice";
import Loader from "../../../../common/Loader";
import { useParams } from "react-router-dom";
import { ResponsesByCollectors } from "../../../../../pages/CollectResponses/types";

const Weblink = ({
  collectors = [],
  responsesByCollectors,
}: {
  collectors: CollectorType[];
  responsesByCollectors: ResponsesByCollectors;
}) => {
  const [selectedCollector, setSelectedCollector] =
    useState<CollectorType>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { surveyId } = useParams();

  const [addNewCollector, { isLoading: isAddingCollector }] =
    useAddNewCollectorMutation();

  const onNewClick = async () => {
    const newCollectorId = nanoid();
    await addNewCollector({
      collectorId: newCollectorId,
      name: `Weblink ${collectors.length + 1}`,
      collectorType: "WEBLINK",
      startDate: dayjs().format("DD-MM-YYYY"),
      endDate: null,
      endTime: "12:00:00",
      surveyId,
      isManuallyClosed: false,
      manualStatus: "ACTIVE",
    }).unwrap();
  };

  const onConfigureClick = (theCollector: CollectorType) => {
    setSelectedCollector(theCollector);
    setIsModalVisible(true);
  };

  const onCancel = () => {
    setIsModalVisible(false);
  };

  const onSubmitSettings = () => {
    setIsModalVisible(false);
  };

  const tableAction = (collectorItem) => (
    <CollectorSettings
      onConfigureClick={onConfigureClick}
      collectorItem={collectorItem}
    />
  );

  if (isAddingCollector) {
    return <Loader isLoading />;
  }

  return (
    <Wrapper>
      <Text>
        You can share your survey link anywhere—like on social media, in an
        email (using your own email client), or print it on a newsletter.
      </Text>

      <Weblinks>
        {collectors.map((link) => (
          <LinkBox
            onConfigureClick={onConfigureClick}
            linkValue={`${process.env.REACT_APP_SURVEY_PREVIEW_URL}r/${link.collectorId}`}
            name={link.name}
            key={link.collectorId}
            collector={link}
          />
        ))}
      </Weblinks>
      <NewLinkButton type="button" onClick={onNewClick}>
        Add new link
      </NewLinkButton>
      <Text>
        You can share your survey link anywhere—like on social media, in an
        email (using your own email client), or print it on a newsletter.{" "}
      </Text>
      <TableContainer>
        <CollectorTable
          menuComponent={tableAction}
          title="Weblink collectors"
          collectorsData={collectors}
          responsesByCollectors={responsesByCollectors}
        />
      </TableContainer>

      {isModalVisible && (
        <Modal
          title="Weblink"
          visible={isModalVisible}
          onCancel={onCancel}
          footer={null}
        >
          <ResponseSettings
            collector={selectedCollector}
            onSave={onSubmitSettings}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default Weblink;
