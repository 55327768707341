import React, { Dispatch, FC, SetStateAction } from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import SlideFunction from "../../../../components/Onboarding/SlideFunction";
import {
  Wrapper,
  OutlinedButton,
  ColoredButton,
  Body,
  IntroBanner,
  Title,
  Content,
  Footer,
} from "./styles";

const dashboardMockup = "https://assets.surveylake.com/dashboard.png";

interface OnboardingProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const OnboardingScreen: FC<OnboardingProps> = ({ visible, setVisible }) => {
  const [cookies, setCookie] = useCookies(["contact-intro"]);
  const { handleCountAndSlide, count, onboardingView } = SlideFunction({
    setVisible,
    setCookie,
    cookieName: "contact-intro",
    cookieValue: "contact-intro",
  });

  return (
    <>
      {!cookies["contact-intro"] && (
        <Modal
          visible={visible}
          centered
          closable={false}
          footer={false}
          bodyStyle={{
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Wrapper ref={onboardingView}>
            <Body>
              <IntroBanner>
                <img
                  src={dashboardMockup}
                  alt="mockup"
                  style={{ width: "100%" }}
                />
              </IntroBanner>
              <div>
                <Title>Manage your Contact</Title>
                <Content>
                  Contacts integrates with Email collectors so you can easily
                  send surveys to groups of contacts you create
                </Content>
              </div>
              <Footer>
                <OutlinedButton onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>

                <ColoredButton onClick={handleCountAndSlide}>
                  Okay
                </ColoredButton>
              </Footer>
            </Body>
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default OnboardingScreen;
