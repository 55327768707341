import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 10px;
  width: 66px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
  border-right: 1px solid #e0e0e0;

  overflow-y: auto;
  & > button {
    background-color: transparent;
    border: none;
    padding: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  & > button:hover svg rect,
  & > button:nth-child(4):hover svg path {
    stroke: var(--primary-red-color);
  }

  & > button:hover svg circle,
  & > button:first-child:hover svg path,
  & > button:nth-child(3):hover rect,
  & > button:nth-child(5):hover svg path {
    fill: var(--primary-red-color);
  }
  & > button > span {
    margin-top: 2px;
    font-size: 10px;
    line-height: 12px;
    color: var(--primary-text-grey);
    font-weight: 700;
  }
  & > button:hover > span {
    color: var(--primary-red-color);
  }
`;

export const ButtonWrapper = styled.button`
  ${(props: { $selected?: boolean }) => {
    if (props.$selected) {
      return css`
        & svg rect,
        &:nth-child(4) svg path {
          stroke: var(--primary-red-color) !important;
        }

        & svg circle,
        &:first-child svg path,
        &:nth-child(3) rect,
        &:nth-child(5) svg path {
          fill: var(--primary-red-color) !important;
        }
        & > span {
          color: var(--primary-red-color) !important;
        }
      `;
    }
  }};
`;
