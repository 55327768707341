import React from "react";
import { Text, Wrapper, AnswerBox } from "./style";

const LongText = () => {
  return (
    <Wrapper>
      <AnswerBox>
        <Text>Type your answer</Text>
      </AnswerBox>
    </Wrapper>
  );
};

export default LongText;
