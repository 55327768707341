import React, { useContext, useEffect, useState } from "react";
import intersection from "lodash/intersection";
import {
  Content,
  Wrapper,
  Title,
  Header,
  TemplateButton,
  LoadButton,
  Footer,
  CheckDiv,
  TemplateContent,
  UncheckIcon,
  FilterButton,
  TemplateButtonHead,
  CategoriesWrapper,
  CategoriesWrapperHead,
} from "./style";
import { ReactComponent as CheckIcon } from "../../../../../../assets/icons/check-select.svg";
import { ReactComponent as FilterIcon } from "../../../../../../assets/icons/filter.svg";
import {
  useCopySurveyFromTemplateMutation,
  useGetTemplateMutation,
  useGetTemplatesQuery,
} from "../../../../../../app/apiSlice";
import Loader from "../../../../../common/Loader";
import { Checkbox, Popover } from "antd";
import { templateCategories } from "./utils";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useAppSelector } from "../../../../../../app/hooks";
import { useParams } from "react-router-dom";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";

const CategoryMenu = ({
  onChange,
  values,
  onClear,
}: {
  onChange: (values) => void;
  onClear: () => void;
  values: string[];
}) => (
  <CategoriesWrapper>
    <CategoriesWrapperHead>
      <p>Filter ({values.length})</p>
      <button type="button" onClick={onClear}>
        Clear
      </button>
    </CategoriesWrapperHead>
    <Checkbox.Group
      onChange={onChange}
      value={values}
      options={templateCategories}
    />
  </CategoriesWrapper>
);

const Templates = ({ onClose }: { onClose: () => void }) => {
  const { surveyId } = useParams();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [filteredTemplateList, setFilteredTemplateList] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const {
    data: templatesData,
    isFetching,
    isSuccess,
  } = useGetTemplatesQuery({});

  const [copySurveyFromTemplate, { isLoading: isUpdating }] =
    useCopySurveyFromTemplateMutation();

  const [getTemplateData, { isLoading: isLoadingTemplate }] =
    useGetTemplateMutation();

  const { name, instruction } = useAppSelector((state) => state.design);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const onTemplateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const tempIdValue = e.currentTarget.getAttribute("data-template");
    setSelectedTemplateId(tempIdValue);
  };

  const onLoadClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    try {
      const templateData = await getTemplateData(selectedTemplateId).unwrap();
      const surveyData = {
        templateSurveyId: templateData.surveyRef,
        name,
        instruction,
      };
      await copySurveyFromTemplate({
        surveyId,
        surveyDetail: surveyData,
      }).unwrap();
      onSuccessShowAlert("Template copied");
      onClose();
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  useEffect(() => {
    if (templatesData) {
      setFilteredTemplateList(templatesData);
    }
  }, [templatesData]);

  const onCategoryChange = (checkedValues: CheckboxValueType[]) => {
    if (checkedValues.length === 0) {
      setFilteredTemplateList(templatesData);
    } else {
      const found = (templateCategories) =>
        intersection(templateCategories, checkedValues).length > 0;
      const newFilteredList = templatesData.filter((temp) =>
        found(temp.categories)
      );
      setFilteredTemplateList(newFilteredList);
    }
    setSelectedCategories(checkedValues);
  };

  const onClear = () => {
    setSelectedCategories([]);
    setFilteredTemplateList(templatesData);
  };

  return (
    <Wrapper>
      <Loader isLoading={isLoadingTemplate || isFetching || isUpdating} />
      <Header>
        <Title>Templates</Title>
        <Popover
          placement="rightBottom"
          content={
            <CategoryMenu
              onClear={onClear}
              values={selectedCategories}
              onChange={onCategoryChange}
            />
          }
          trigger="click"
        >
          <FilterButton>
            <FilterIcon />
            <span>Filter</span>
          </FilterButton>
        </Popover>
      </Header>
      <Content>
        {filteredTemplateList.map((temp) => (
          <TemplateButton
            data-template={temp.templateId}
            key={temp.templateId}
            $isSelected={selectedTemplateId === temp.templateId}
            onClick={onTemplateClick}
          >
            <TemplateButtonHead>
              <p>{temp.title}</p>
              <CheckDiv>
                <CheckIcon />
                <UncheckIcon />
              </CheckDiv>
            </TemplateButtonHead>
            <TemplateContent>
              <p>{temp.about}</p>
            </TemplateContent>
          </TemplateButton>
        ))}
      </Content>
      <Footer>
        <LoadButton onClick={onLoadClick}>Load template</LoadButton>
      </Footer>
    </Wrapper>
  );
};

export default Templates;
