import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Wrapper } from "./style";

const EmailMessageEditor = ({
  field,
  surveyLinkButton,
}: {
  field?: any;
  surveyLinkButton: string;
}) => {
  return (
    <Wrapper>
      <Editor
        initialValue={`<p>Hi there,</p><p>Please take the survey below:</p>
        <p>${surveyLinkButton}</p>`}
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        //{...field}
        onEditorChange={(value) => {
          field.onChange(value);
        }}
        ref={field.ref}
        onBlur={field.onBlur}
        value={field.value || ""}
        init={{
          height: 380,
          menubar: false,
          skin_url: "/assets/skins/ui/sl",
          content_css: "/assets/skins/content/sl/content.min.css",
          plugins: [
            "image",
            "media",
            "table",
            "help",
            "link",
            "lists",
            "advlist",
            "preview",
            "code",
          ],
          // menubar: "custom",
          // menu: {
          //   custom: {
          //     title: "Insert custom data",
          //     items: "firstname lastname",
          //   },
          // },
          toolbar:
            "custom_data surveyLink | fontsize bold italic underline | link numlist bullist | forecolor image",
          toolbar_mode: "wrap",
          image_caption: true,
          images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
          setup: (editor) => {
            // editor.ui.registry.addMenuItem("firstname", {
            //   text: "First name",
            //   onAction: function () {
            //     editor.insertContent("<span>{{first_name}}</span>");
            //   },
            // });
            // editor.ui.registry.addMenuItem("lastname", {
            //   text: "Last name",
            //   onAction: function () {
            //     editor.insertContent("<span>{{last_name}}</span>");
            //   },
            // });

            // /* Basic button that just inserts the date */
            // editor.ui.registry.addButton("surveyLink", {
            //   text: "Insert link",
            //   tooltip: "Insert survey link",
            //   onAction: (_) =>
            //     editor.insertContent(
            //       `<a target="_blank" href="https://home.com">Home</a>`
            //     ),
            // });

            editor.ui.registry.addSplitButton("surveyLink", {
              text: "Insert survey link",
              tooltip: "Insert survey link",
              onAction: (_) =>
                editor.insertContent(
                  `<span> </span>${surveyLinkButton}<span> </span>`
                ),
              onItemAction: (buttonApi, value) => editor.insertContent(value),
              fetch: (callback: any) => {
                const items = [
                  {
                    type: "choiceitem",
                    text: "Insert as button",
                    value: `<span> </span>${surveyLinkButton}<span> </span>`,
                  },
                  {
                    type: "choiceitem",
                    text: "Insert as link",
                    value: `<span> </span><a target="_blank" href="https://home.com">Begin survey</a><span> </span>`,
                  },
                ];
                callback(items);
              },
            });

            editor.ui.registry.addMenuButton("custom_data", {
              text: "Insert custom data",
              // onItemAction: (buttonApi, value) => editor.insertContent(value),
              fetch: (callback: any) => {
                const items = [
                  {
                    type: "menuitem",
                    text: "[FirstName]",
                    onAction: (_) =>
                      editor.insertContent("<span>[FirstName]</span>"),
                  },
                  {
                    type: "menuitem",
                    text: "[LastName]",
                    onAction: (_) =>
                      editor.insertContent("<span>[LastName]</span>"),
                  },
                  {
                    type: "menuitem",
                    text: "[Email]",
                    onAction: (_) =>
                      editor.insertContent("<span>[Email]</span>"),
                  },
                ];
                callback(items);
              },
            });
          },
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.addEventListener("change", (e) => {
              // const file = e.target.files[0];
              // const reader = new FileReader();
              // reader.addEventListener("load", () => {
              //   /*
              //             Note: Now we need to register the blob in TinyMCEs image blob
              //             registry. In the next release this part hopefully won't be
              //             necessary, as we are looking to handle it internally.
              //           */
              //   const id = "blobid" + new Date().getTime();
              //   const blobCache = tinymce.activeEditor.editorUpload.blobCache;
              //   const base64 = reader.result.split(",")[1];
              //   const blobInfo = blobCache.create(id, file, base64);
              //   blobCache.add(blobInfo);
              //
              //   /* call the callback and populate the Title field with the file name */
              //   cb(blobInfo.blobUri(), { title: file.name });
              // });
              // reader.readAsDataURL(file);
            });

            input.click();
          },
          content_style:
            "body { font-family:Mulish,Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </Wrapper>
  );
};

export default EmailMessageEditor;
