import React from "react";
import { Option, Radio, Text, Wrapper } from "./style";
import { RowOption } from "../../../../../../../../pages/Design/types";

interface BodyProps {
  options: RowOption[];
}

const Checkbox = ({ options }: BodyProps) => {
  return (
    <Wrapper>
      {options.map((option) => {
        return (
          <Option key={option.index}>
            <Radio />
            <Text>{option.value}</Text>
          </Option>
        );
      })}
    </Wrapper>
  );
};

export default Checkbox;
