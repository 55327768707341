import styled from "styled-components";

export const Wrapper = styled.form`
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 40px;
  border-bottom: 1px solid #d5dae1;
`;

export const Title = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #1a141f;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  & input {
    height: 40px !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: flex-end;
  height: 40px;
  border-top: 1px solid #d5dae1;
  padding-top: 15px;
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  & > button {
    height: 32px;
    padding: 0px 10px;
    border: none;
  }
  & > button:first-child {
    background-color: #ffffff;
    border: 1px solid black;
    color: #000000;
    margin-right: 15px;
  }
  & > button:last-child {
    background-color: var(--primary-red-color);
    color: #ffffff;
  }
`;

export const DualInputs = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  width: 100%;
  & > div {
    width: 46%;
  }
`;
