import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import lodashForEach from "lodash/forEach";
import { Tabs, Table, Dropdown } from "antd";
import { Wrapper, Container, Title } from "./style";
import { ReactComponent as Ellipsis } from "../../pages/Dashboard/assets/ellipsis.svg";
import { useGetSurveysQuery } from "../../app/apiSlice";
import TableAction from "../../features/dashboard/components/TableAction";
import { SortButton } from "../Dashboard/style";
import EmptyTable from "../../features/dashboard/components/EmptyTable";
import Loader from "../../components/common/Loader";
import type { SurveyStatusItem } from "../Dashboard/types";

const { TabPane } = Tabs;

export default function MySurveys() {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [draftSurveys, setDraftSurveys] = useState([]);
  const [completedSurveys, setCompletedSurveys] = useState([]);
  const [submittedSurveys, setSubmittedSurveys] = useState([]);
  const [openSurveys, setOpenSurveys] = useState([]);
  const [sortedInfo, setSortedInfo] = useState<{
    columnKey?: any;
    order?: any;
  }>({});

  const navigate = useNavigate();
  const {
    data: surveyList,
    isFetching: isFetchingSurveys,
    isError,
  } = useGetSurveysQuery({});

  console.log("surveyList");
  console.log(surveyList);
  const viewSurvey = (survId) => {
    navigate(`/${survId}/preview`);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const editSurvey = (survId) => {
    navigate(`/${survId}/design/questions`);
  };

  const sortableSurvey = useCallback(() => {
    if (surveyList) {
      const sorted: SurveyStatusItem = {};
      lodashForEach(surveyList, (item: { status }) => {
        sorted[item.status] = sorted[item.status] || [];
        sorted[item.status].push(item);
      });

      setDraftSurveys(sorted.DRAFT);
      setCompletedSurveys(sorted.SUBMITTED);
      setOpenSurveys(sorted.OPEN);
      setSubmittedSurveys(sorted.SUBMITTED);
    }
  }, [surveyList]);

  useEffect(() => {
    sortableSurvey();
  }, []);

  const onAddNew = (isNew) => {
    setIsAddingNew(isNew);
  };

  const locale = {
    emptyText: <EmptyTable onAddNew={onAddNew} />,
  };

  const COLUMNS = [
    {
      title: "Name",
      Footer: "NAME",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
    },
    {
      title: "Status",
      Footer: "STATUS",
      dataIndex: "status",
      render: (value) => <span>{value}</span>,
      key: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Responses",
      Footer: "RESPONSES",
      dataIndex: "totalResponses",
      key: "responses",
      sorter: (a, b) => a.responses - b.responses,
      sortOrder: sortedInfo.columnKey === "responses" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Date",
      Footer: "CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        if (value) {
          return dayjs(value).format("MMM D, YYYY");
        }
        return "--";
      },
      sorter: (a, b) =>
        (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any),
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Actions",
      dataIndex: "surveyId",
      key: "surveyId",
      render: (value) => (
        <>
          <Dropdown
            overlay={
              <TableAction
                value={value}
                editSurvey={editSurvey}
                viewSurvey={viewSurvey}
              />
            }
          >
            <SortButton type="button">
              <Ellipsis />
            </SortButton>
          </Dropdown>
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Loader isLoading={isAddingNew || (isFetchingSurveys && !isError)} />
      <Container>
        <Title>My Surveys</Title>

        <div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="All" key="1">
              <div>
                {surveyList ? (
                  <Table
                    locale={locale}
                    columns={COLUMNS}
                    dataSource={surveyList}
                    pagination={{ position: ["bottomCenter"] }}
                    onChange={handleTableChange}
                  />
                ) : (
                  "No record found"
                )}
              </div>
            </TabPane>
            <TabPane tab="Draft" key="2">
              {draftSurveys ? (
                <Table
                  columns={COLUMNS}
                  dataSource={draftSurveys}
                  pagination={{ position: ["bottomCenter"] }}
                  onChange={handleTableChange}
                />
              ) : (
                "No record found"
              )}
            </TabPane>
            <TabPane tab="Open" key="3">
              {openSurveys ? (
                <Table
                  columns={COLUMNS}
                  dataSource={openSurveys}
                  pagination={{ position: ["bottomCenter"] }}
                  onChange={handleTableChange}
                />
              ) : (
                "No record found"
              )}
            </TabPane>
            {/*<TabPane tab="Completed" key="4">*/}
            {/*  {completedSurveys ? (*/}
            {/*    <Table*/}
            {/*      columns={COLUMNS}*/}
            {/*      dataSource={completedSurveys}*/}
            {/*      pagination={{ position: ["bottomCenter"] }}*/}
            {/*      onChange={handleTableChange}*/}
            {/*    />*/}
            {/*  ) : (*/}
            {/*    "No record found"*/}
            {/*  )}*/}
            {/*</TabPane>*/}
          </Tabs>
        </div>
      </Container>
    </Wrapper>
  );
}
