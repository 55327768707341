import styled from "styled-components";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@reach/tabs";

export const Wrapper = styled.div`
  width: 100%;
`;

export const TabsBody = styled(Tabs)``;

export const SlTabsList = styled(TabList)`
  background-color: #ffffff;
  display: flex;
`;

export const SlTabPanels = styled(TabPanels)`
  background-color: rgba(196, 196, 196, 0.38);
  padding: 13px 7px;
`;

export const SlTabPanel = styled(TabPanel)``;

export const SlTab = styled(Tab)`
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  padding: 12px 16px;
  background-color: transparent;
  border: none;
  border-bottom: ${(props: {
    $borderColor: string;
    $color: string;
    $hide: boolean;
  }) => `3px solid ${props.$borderColor}`};
  color: ${(props) => props.$color};
  display: ${(props) => (props.$hide ? "none" : "block")};
`;
