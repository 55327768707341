import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { Cookies, CookiesProvider } from "react-cookie";
import "intro.js/introjs.css";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import NetworkErrorAlertContextProvider from "./context/NetworkErrorAlert";
import SuccessAlertContextProvider from "./context/SuccessAlertContext";
import { persistor, store } from "./app/store";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SuccessAlertContextProvider>
          <NetworkErrorAlertContextProvider>
            <CookiesProvider>
              <App />
            </CookiesProvider>
          </NetworkErrorAlertContextProvider>
        </SuccessAlertContextProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
