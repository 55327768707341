import React, { useContext, useEffect, useState } from "react";
import { Wrapper, FormBody, Section, SubSection, ColorDiv } from "./style";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import FormHeader from "../../FormHeader";
import QuestionHeader from "../../QuestionHeader";
import { useConfiguration } from "../../../contexts/Configuration";
import QuestionFormDropdown from "../../QuestionFormDropdown";
import ShapeIcon from "./components/ShapeIcon";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import RichTextField from "../../RichTextField";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import { useParams } from "react-router-dom";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { Inputs } from "../types";
import RequiredCheckbox from "../../RequiredCheckbox";
import SingleInput from "../../QuestionFormInput/SingleInput";

export type RatingShapeType = "Star" | "Heart" | "Thumb" | "Smiley";
const StarRatingForm = ({ onCancel }: { onCancel: () => void }) => {
  const dispatch = useAppDispatch();
  const { selectedQuestion } = useAppSelector((state) => state.design);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { text: selectedQuestion.text } });
  const { surveyId } = useParams();

  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();
  const { rowOptions, hasRandomizedChoices, isRequired, requiredMessage } =
    configState;
  const {
    hasLabels,
    starRatingCount,
    starRatingShape,
    starRatingColor,
    labels,
  } = configState.starRatingOptions;

  useEffect(() => {
    if (selectedQuestion.text) {
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const mcValues = {
        type: "SR",
        text: data.text,
        rowOptions: rowOptions,
        isRequired,
        requiredMessage: undefined,
        hasLogic: false,
        hasRandomizedChoices,
        starRatingOptions: {
          starRatingColor,
          starRatingCount,
          starRatingShape,
        },
      };
      if (isRequired) {
        mcValues.requiredMessage = requiredMessage;
      }
      if (!selectedQuestion?.questionId) {
        const result = await addQuestion({
          surveyId,
          questionDetail: mcValues,
        }).unwrap();
        await dispatch(setNewQuestion(result?.question));
        await dispatch(setSelectedQuestion(null));
        onSuccessShowAlert("New question added");
      } else {
        const { questionId } = selectedQuestion;
        const mcQuesInitialObj = pick(selectedQuestion, [
          "type",
          "text",
          "rowOptions",
          "isRequired",
          "hasLogic",
          "hasRandomizedChoices",
          "requiredMessage",
          "starRatingOptions",
        ]);
        const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
        if (!hasQuestionNotChanged) {
          await editQuestion({
            surveyId,
            questionId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(
            setUpdatedQuestion({
              questionId,
              updatedQues: { ...mcValues, questionId },
            })
          );
        }
        onSuccessShowAlert("Question updated");
      }
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  useEffect(() => {
    if (selectedQuestion.text) {
      localDispatch({
        type: "UPDATE_IS_REQUIRED",
        payload: selectedQuestion.isRequired,
      });
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const [ratingColor, setRatingColor] = useState(starRatingColor);
  const [ratingCount, setRatingCount] = useState(starRatingCount);
  const [ratingShape, setRatingShape] =
    useState<RatingShapeType>(starRatingShape);

  const onScaleChange = (value) => {
    setRatingCount(value);
  };

  const onShapeChange = (value) => {
    setRatingShape(value);
  };

  const onColorChange = (event) => {
    setRatingColor(event.target.value);
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType="SR" />

          <Controller
            name="text"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RichTextField
                hasError={errors.text?.type === "required"}
                initialValue={selectedQuestion.text || configState.questionText}
                field={field}
              />
            )}
          />
          <Section>
            <RequiredCheckbox />
          </Section>

          <Section>
            <div>
              <SubSection>
                <p>Scale</p>
                <SingleInput
                  type="number"
                  min={2}
                  value={ratingCount}
                  onChange={onScaleChange}
                />
              </SubSection>
              <SubSection>
                <p>Shape</p>
                <QuestionFormDropdown
                  value={ratingShape}
                  onChange={onShapeChange}
                  $width="120px"
                  size="large"
                  options={[
                    { value: "Star", label: "Star" },
                    { value: "Heart", label: "Heart" },
                    { value: "Thumb", label: "Thumb" },
                    { value: "Smiley", label: "Smiley" },
                  ]}
                />
              </SubSection>
              <SubSection>
                <p>Color</p>

                <ColorDiv>
                  <input
                    value={ratingColor}
                    type="color"
                    name="profilesecondarycolor"
                    list="profilecolorslist"
                    onChange={onColorChange}
                  />
                  <ShapeIcon shape={ratingShape} color={ratingColor} />
                </ColorDiv>
                <datalist id="profilecolorslist">
                  <option value="#0000ff" />
                  <option value="#ff0000" />
                  <option value="#008000" />
                  <option value="#000000" />
                  <option value="#ffaa00" />
                </datalist>
              </SubSection>
            </div>
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default StarRatingForm;
