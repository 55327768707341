import React from "react";
import { Content, ImageDiv, Option, Radio, Text, Wrapper } from "./style";
import type { ImageOption } from "../../../../../../../../pages/Design/types";

interface BodyProps {
  imageRowOptions: ImageOption[];
  isCheckbox: boolean;
}

const ImageChoice = ({ imageRowOptions, isCheckbox }: BodyProps) => {
  if (!imageRowOptions) {
    return null;
  }
  return (
    <Wrapper>
      {imageRowOptions.map((option) => {
        return (
          <Option key={option.index}>
            <Radio $isCheckbox={isCheckbox} />
            <Content>
              <ImageDiv>
                <img src={option.url} alt={option.value} />
              </ImageDiv>
              <Text>{option.value}</Text>
            </Content>
          </Option>
        );
      })}
    </Wrapper>
  );
};

export default ImageChoice;
