import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
`;

export const ColorDiv = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 4px;
  background: ${(props: { $backgroundColor: string }) =>
    props.$backgroundColor};
  box-shadow: rgb(0 0 0 / 15%) 0px 2px 8px;
`;

export const EditColorButton = styled.button`
  background-color: #ffffff;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  width: 110px;
  height: 40px;
  & > ${ColorDiv} {
    margin-left: 12px;
  }
`;

export const CoverDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const PopoverDiv = styled.div`
  position: absolute;
  z-index: 2;
`;
