import styled from "styled-components";

export const Wrapper = styled.div`
  height: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

export const PageTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-black-color);
`;

export const HeadButtons = styled.div`
  display: flex;
  align-items: center;
  & button {
    width: 100px;
    height: 40px;
    border-radius: 5px;
  }
  & button:first-child {
    margin-right: 12px;
  }
`;
export const Content = styled.div`
  width: min(90%, 930px);
  margin: auto;
`;

// export const Right = styled.div`
//   @media (min-width: 1440px) {
//   }
// `;
