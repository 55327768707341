import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: min(94%, 360px);
  display: flex;
  flex-direction: column;
  align-items: center;
  & form {
    width: 100%;
  }
  & svg {
    margin-bottom: 40px;
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: var(--primary-text-black);
  margin-bottom: 16px;
`;

export const Subtitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: var(--secondary-text-grey);
  margin-bottom: 26px;
`;

export const InputBox = styled.div`
  margin-bottom: 24px;
`;

export const RememberForgot = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  & a {
    color: var(--primary-red-color);
  }
`;

export const SubmitButtonWrapper = styled.div`
  & button {
    margin-bottom: 24px;
  }
`;

export const NoAccount = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  & a {
    margin-left: 5px;
    color: var(--primary-red-color);
  }
`;
