import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  //justify-content: space-between;
  height: 56px;
  border-bottom: 1px solid #c4c4c4;
`;

export const Left = styled.div`
  padding: 10px 20px;
  width: min(35%, 300px);
  & > a {
    display: flex;
    align-items: center;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & a {
    margin-left: 16px;
    color: #999999;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    height: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
  }
`;
export const Right = styled.div`
  padding: 10px 20px;
`;
