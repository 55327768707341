export const copyText = async (text: string) => {
  if ("clipboard" in navigator) {
    const copied = await navigator.clipboard.writeText(text);
    return copied;
  }
  return document.execCommand("copy", true, text);
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};
