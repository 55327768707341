import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useParams } from "react-router-dom";
import { Wrapper, Buttons, ImageOption } from "./style";
import QuestionFormInput from "../QuestionFormInput";
import { useConfiguration } from "../../contexts/Configuration";
import { ReactComponent as RemoveIcon } from "../assets/remove-input.svg";
import { ReactComponent as AddIcon } from "../assets/add-input.svg";
import ImageQuestionUpload from "../../../../../common/CustomUpload/ImageQuestionUpload";

interface ImageRowOptionsProps {
  rowList: any[];
}
const ImageRowOptions = ({ rowList }: ImageRowOptionsProps) => {
  const [rowOptions, setRowOptions] = useState(rowList || []);
  const params = useParams();

  // console.log("rowList");
  // console.log(rowList);
  useEffect(() => {
    setRowOptions(rowList || [{ index: 0, value: "", url: "" }]);
  }, [rowList]);

  const { dispatch } = useConfiguration();

  const onAddOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rowIndex = Number(e.currentTarget.getAttribute("data-num"));

    const newOptionValues = cloneDeep(rowOptions);
    const newValue = { index: rowIndex + 1, value: "" };
    newOptionValues.splice(rowIndex + 1, 0, newValue);
    for (let i = rowIndex + 1; i <= newOptionValues.length; i++) {
      if (newOptionValues[i] !== undefined) {
        newOptionValues[i].index = i;
      }
    }

    dispatch({ type: "UPDATE_IMAGE_ROW_OPTIONS", payload: newOptionValues });
  };

  const onRemoveOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (rowOptions.length > 1) {
      const rowIndex = Number(e.currentTarget.getAttribute("data-num"));
      const newOptionsList = rowOptions.filter(
        (option) => option.index !== rowIndex
      );

      for (let i = rowIndex; i <= newOptionsList.length; i++) {
        if (newOptionsList[i] !== undefined) {
          newOptionsList[i].index = i;
        }
      }

      dispatch({ type: "UPDATE_IMAGE_ROW_OPTIONS", payload: newOptionsList });
    }
  };

  const onInputChange = (index, newValue) => {
    const newOptionValues = cloneDeep(rowOptions);
    newOptionValues[index].value = newValue;
    dispatch({ type: "UPDATE_IMAGE_ROW_OPTIONS", payload: newOptionValues });
  };

  const onUpload = (index, url) => {
    const newOptionValues = cloneDeep(rowOptions);
    newOptionValues[index].url = url;
    dispatch({ type: "UPDATE_IMAGE_ROW_OPTIONS", payload: newOptionValues });
  };

  return (
    <Wrapper>
      {rowOptions.map((row) => (
        <ImageOption key={row.index}>
          <div>
            <p>Label {row.index}</p>
            <QuestionFormInput
              index={row.index}
              value={row.value}
              $width="60%"
              onChange={onInputChange}
            />
            <Buttons>
              <button type="button" data-num={row.index} onClick={onAddOption}>
                <AddIcon />
              </button>
              <button
                type="button"
                data-num={row.index}
                onClick={onRemoveOption}
              >
                <RemoveIcon />
              </button>
            </Buttons>
          </div>
          <ImageQuestionUpload
            onUpload={onUpload}
            uploadUrl={row.url}
            rowIndex={row.index}
            uploadPath={`survey/${params.surveyId}/images/`}
          />
        </ImageOption>
      ))}
    </Wrapper>
  );
};

export default ImageRowOptions;
