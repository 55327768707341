import styled from "styled-components";
import { Button, Upload } from "antd";

export const UploadWrapper = styled(Upload)`
  width: 100%;
  & div[class~="ant-upload"] {
    width: 100%;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }
  & button:hover {
    color: black !important;
    border-color: black !important;
  }
`;

export const UploadButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  height: 40px;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 60%;
  padding: 0 5px;
`;

export const UploadDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  justify-content: center;
  & > span {
    font-family: Mulish;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    letter-spacing: -0.02em;
    color: #000000;
    margin-right: 8px;
  }
`;

export const RightWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  & > p {
    margin-bottom: 0;
  }
`;

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  & > p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin-left: 6px;
  }
  & div[class~="ant-progress-inner"] {
    background-color: #e9e9e9;
  }
  & div[class~="ant-progress-bg"] {
    background-color: var(--primary-red-color);
    height: 4px !important;
  }
  & div[class~="ant-progress-text"] {
    display: none;
  }
`;

export const DoneText = styled.div`
  & > span {
    margin-bottom: 0;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #717070;
  }
`;
