import styled from "styled-components";

export const Wrapper = styled.div`
  width: 180px;
  background: #ffffff;
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 3px;
`;

export const MenuButton = styled.button`
  border: none;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #ffffff;
  & > p {
    margin-left: 8px;
  }
`;
