import React, { useContext, useEffect } from "react";
import { Wrapper, FormBody, Section, FormTextarea, FormInput } from "./style";
import { SubmitHandler, useForm } from "react-hook-form";
import FormHeader from "../FormHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../context/SuccessAlertContext";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import {
  useAddSectionMutation,
  useGetSurveyQuery,
  useUpdateSectionMutation,
} from "../../../../../../app/apiSlice";
import { useParams } from "react-router-dom";

type Inputs = {
  title: string;
  description: string;
};
const PageForm = () => {
  const params = useParams();
  const { selectedSection, sectionsList } = useAppSelector(
    (state) => state.design
  );
  const dispatch = useAppDispatch();

  const { data: surveyData } = useGetSurveyQuery(params.surveyId);

  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const [addSection, { isLoading: isLoadingAddSect }] = useAddSectionMutation();

  const [editSection, { isLoading: isLoadingUpdateSect }] =
    useUpdateSectionMutation();

  useEffect(() => {
    setValue("title", selectedSection.title);
    setValue("description", selectedSection.description);
  }, [selectedSection]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const mcValues = {
        title: data.title,
        description: data.description,
        sortIndex: sectionsList?.length ?? 0,
        currentSectionOrder: surveyData.sectionOrder,
      };
      if (!selectedSection?.sectionId) {
        await addSection({
          surveyId: params.surveyId,
          sectionDetail: mcValues,
        });
        onSuccessShowAlert("Section added");
      } else {
        const { sectionId } = selectedSection;
        const initialSectionValues = pick(selectedSection, [
          "title",
          "description",
        ]);
        const newSectionValues = pick(mcValues, ["title", "description"]);
        const hasSectionNotChanged = isEqual(
          initialSectionValues,
          newSectionValues
        );
        if (!hasSectionNotChanged) {
          await editSection({
            surveyId: params.surveyId,
            sectionId,
            sectionDetail: newSectionValues,
          });
        }
        onSuccessShowAlert("Section updated");
      }
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  const onCancel = () => {
    // onSaveClick(onSaveSurvey);
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <Section>
            <p>Page title</p>
            <FormInput
              // value={title}
              {...register("title")}
              // onChange={(e) => setTitleValue(e.target.value)}
            />
          </Section>
          <Section>
            <p>Page description</p>
            <FormTextarea
              // value={description}
              {...register("description")}
              id="description"
              name="description"
              rows={4}
              cols={33}
              // onChange={(e) => setDescValue(e.target.value)}
            />
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default PageForm;
