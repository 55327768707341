import styled from "styled-components";
import { Table } from "antd";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Option = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
`;

export const RadioDisplay = styled.div`
  border-radius: ${(props: { $isCheckbox: boolean }) =>
    props.$isCheckbox ? "none" : "50%"};
  //width: 20px;
  //height: 20px;
  border: 1px solid #000000;
  margin-right: 15px;

  height: 16px;
  width: 16px;
  //border: 1px solid #999999;
  //margin: auto;
`;

export const Text = styled.p`
  margin-bottom: unset;
  text-align: left;
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
`;

export const CustomQuestionTable = styled(Table)`
  & table th {
    border-bottom: unset;
    padding: 8px 8px;
    background-color: #ffffff;
  }

  & table td {
    border-bottom: unset;
    padding: 8px 8px;
  }
`;
