import React, { ReactNode } from "react";
import { Progress } from "antd";
import {
  UploadButton,
  UploadWrapper,
  UploadDiv,
  ProgressWrapper,
  RightWrapper,
  DoneText,
} from "./style";

interface CustomUploadProps {
  name?: string;
  customRequest?: any;

  uploadingStatus?: "idle" | "progress" | "done";
  progressPercent?: number;
  doneText?: ReactNode;
}

interface UploadRightProps {
  uploadingStatus?: "idle" | "progress" | "done";
  progressPercent?: number;
  doneText?: string | ReactNode;
}

export const UploadRight = ({
  progressPercent,
  uploadingStatus,
  doneText = "Uploaded",
}: UploadRightProps) => {
  return (
    <RightWrapper>
      {uploadingStatus === "idle" && (
        <UploadDiv>
          <span>Upload</span>
        </UploadDiv>
      )}
      {uploadingStatus === "progress" && (
        <ProgressWrapper>
          <Progress percent={progressPercent} showInfo={false} />
          <p>{progressPercent}%</p>
        </ProgressWrapper>
      )}
      {uploadingStatus === "done" && <DoneText>{doneText}</DoneText>}
    </RightWrapper>
  );
};

export const CustomUpload = (props: CustomUploadProps) => {
  return (
    <UploadWrapper {...props} accept="image/*">
      <UploadButton>
        <UploadRight
          progressPercent={props.progressPercent}
          uploadingStatus={props.uploadingStatus}
          doneText={props.doneText}
        />
      </UploadButton>
    </UploadWrapper>
  );
};
