import React from "react";
import { Link, useParams } from "react-router-dom";
import {
  Wrapper,
  EmptyWrapperBody,
  EmptyWrapperSubTitle,
  EmptyWrapperTitle,
  EmptyWrapperButton,
} from "./style";
import { ReactComponent as EmptyImage } from "../../../../assets/icons/no-data.svg";

const NoDataAnalytics = ({ onRefresh }: { onRefresh: () => void }) => {
  const params = useParams();
  const { surveyId } = params;
  return (
    <Wrapper>
      <EmptyWrapperBody>
        <EmptyImage />
        <EmptyWrapperTitle>No data to display</EmptyWrapperTitle>
        <EmptyWrapperSubTitle>
          There’s currently no survey data to display. Check the{" "}
          <Link to={`/${surveyId}/collect`}>Collect response</Link> page to
          ensure your distribution channels are active or use other available
          channels to expand your survey reach.
        </EmptyWrapperSubTitle>
        <EmptyWrapperButton onClick={onRefresh}>Refresh</EmptyWrapperButton>
      </EmptyWrapperBody>
    </Wrapper>
  );
};

export default NoDataAnalytics;
