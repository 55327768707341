import React, { useContext } from "react";
import { Input, Radio } from "antd";
import {
  Wrapper,
  Body,
  Left,
  Right,
  Title,
  Section,
  AxisInputBox,
  AxisInputContainer,
  SubDiv,
  DataRadioBox,
} from "./style";
import { ChartContext } from "../../../../context/ChartContext";
import CustomCheckbox from "../../../../../common/CustomCheckbox";
import CustomRadio from "../../../../../common/CustomRadio";

const Refine = () => {
  const {
    sortType,
    chartSelected,
    dataLabelsPosition,
    onSortChange,
    onDataLabelPositionChange,
    labelVisible,
    dataLabelsVisible,
    tableIsDisplayed,
    setLabelVisible,
    setDataLabelsVisible,
    setTableIsDisplayed,
    setXAxisLabel,
    setYAxisLabel,
    setShowXaxis,
    setShowYaxis,
    showXaxis,
    showYaxis,
    xAxisLabel,
    yAxisLabel,
  } = useContext(ChartContext);

  const onShowXaxisToggle = (value) => {
    setShowXaxis(value);
  };

  const onShowYaxisToggle = (value) => {
    setShowYaxis(value);
  };

  const onSetXaxisLabel = (e) => {
    setXAxisLabel(e.target.value);
  };

  const onSetYaxisLabel = (e) => {
    setYAxisLabel(e.target.value);
  };

  const onShowTableToggle = (value) => {
    setTableIsDisplayed(value);
  };

  const onShowDataLabelsToggle = (value) => {
    setDataLabelsVisible(value);
  };

  const onShowLabelToggle = (value) => {
    setLabelVisible(value);
  };

  return (
    <Wrapper>
      <Left>
        <Section
          $show={["ver", "hor", "area", "line", "image"].includes(
            chartSelected
          )}
        >
          <Title>SORT</Title>
          <Body>
            <Radio.Group onChange={onSortChange} value={sortType}>
              <CustomRadio value="default">Default</CustomRadio>
              <CustomRadio value="desc">Descending</CustomRadio>
              <CustomRadio value="asc">Ascending</CustomRadio>
            </Radio.Group>
          </Body>
        </Section>
      </Left>
      <Right>
        <Section
          $show={
            ![
              "pie",
              "donut",
              "cloud",
              "star",
              "list",
              "image",
              "dtList",
            ].includes(chartSelected)
          }
        >
          <Title>EDIT AXIS LABELS</Title>
          <Body>
            <AxisInputContainer>
              <AxisInputBox>
                <p>X-axis</p>
                <Input value={xAxisLabel} onChange={onSetXaxisLabel} />
              </AxisInputBox>
              <CustomCheckbox
                value="xaxis"
                label="Show x-axis"
                initialChecked={showXaxis}
                onChange={onShowXaxisToggle}
              />
            </AxisInputContainer>
            <AxisInputContainer>
              <AxisInputBox>
                <p>Y-axis</p>
                <Input value={yAxisLabel} onChange={onSetYaxisLabel} />
              </AxisInputBox>
              <CustomCheckbox
                value="yaxis"
                label="Show y-axis"
                initialChecked={showYaxis}
                onChange={onShowYaxisToggle}
              />
            </AxisInputContainer>
          </Body>
        </Section>
        <Section $show>
          <Body>
            <SubDiv $orientation="vertical">
              <CustomCheckbox
                value="data-table"
                label="Show data table"
                initialChecked={tableIsDisplayed}
                onChange={onShowTableToggle}
              />
            </SubDiv>
            <SubDiv
              $orientation="vertical"
              $hide={[
                "line",
                "cloud",
                "star",
                "list",
                "image",
                "dtList",
              ].includes(chartSelected)}
            >
              <CustomCheckbox
                value="data-labels"
                label="Show labels"
                initialChecked={dataLabelsVisible}
                onChange={onShowDataLabelsToggle}
              />
              {dataLabelsVisible && (
                <DataRadioBox>
                  <Radio.Group
                    onChange={onDataLabelPositionChange}
                    value={dataLabelsPosition}
                  >
                    <CustomRadio value="outside">Outside</CustomRadio>
                    {!["area"].includes(chartSelected) && (
                      <CustomRadio value="inside">Inside</CustomRadio>
                    )}
                  </Radio.Group>
                </DataRadioBox>
              )}
            </SubDiv>

            {/*<SubDiv $orientation="vertical">*/}
            {/*  <CustomCheckbox*/}
            {/*    value="labels"*/}
            {/*    label="Show labels"*/}
            {/*    initialChecked={labelVisible}*/}
            {/*    onChange={onShowLabelToggle}*/}
            {/*  />*/}
            {/*</SubDiv>*/}
          </Body>
        </Section>
      </Right>
    </Wrapper>
  );
};

export default Refine;
