import React from "react";
import MultipleChoice from "./body/MultipleChoice";
import LongText from "./body/LongText";
import ShortText from "./body/ShortText";
import Slider from "./body/Slider";
import Checkbox from "./body/Checkbox";
import type { QuestionType } from "../../../../../../pages/Design/types";
import { SelectedQuestion } from "../../../../../../pages/Design/types";
import Dropdown from "./body/Dropdown";
import DateTime from "./body/DateTime";
import StarRating from "./body/StarRating";
import ImageChoice from "./body/ImageChoice";
import MatrixRatingScale from "./body/MatrixRatingScale";
import Ranking from "./body/Ranking";

interface QuestionBodyProps {
  questionObj: SelectedQuestion;
  questionType: QuestionType;
}
const QuestionBody = ({ questionType, questionObj }: QuestionBodyProps) => {
  switch (questionType) {
    case "MC":
      return <MultipleChoice options={questionObj.rowOptions} />;
    case "Rk":
      return <Ranking options={questionObj.rowOptions} />;
    case "IC":
      return (
        <ImageChoice
          imageRowOptions={questionObj.imageOptions?.imageRowOptions}
          isCheckbox={questionObj.imageOptions?.isCheckbox}
        />
      );
    case "Ch":
      return <Checkbox options={questionObj.rowOptions} />;
    case "Sl":
      return (
        <Slider
          options={{
            ...questionObj.sliderOptions,
            labels: {
              left: questionObj.sliderOptions.labels.left,
              right: questionObj.sliderOptions.labels.right,
            },
          }}
        />
      );
    case "ST":
      return <ShortText />;
    case "Dd":
      return <Dropdown />;
    case "LT":
      return <LongText />;
    case "DT":
      return <DateTime questionObj={questionObj} />;
    case "SR":
      return <StarRating questionObj={questionObj} />;
    case "MRS":
      return (
        <MatrixRatingScale
          rowOptions={questionObj.rowOptions}
          colOptions={questionObj.colOptions || []}
          allowMultiple={
            questionObj.matrixScaleOptions?.allowsMultiResponsesPerRow
          }
        />
      );
    default:
      return null;
  }
};

export default QuestionBody;
