import styled from "styled-components";

export const Container = styled.div``;

export const HeaderText = styled.p`
  text-align: ${(props: { $align: string }) => props.$align ?? "left"};
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  min-height: 25px;
`;
