import styled from "styled-components";

export const Wrapper = styled.button`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  background: #ffffff;
  border: 1px dashed #ce375c;
  border-radius: 5px;
`;

export const OptionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  & > div {
    margin-bottom: 5px;
  }
  & > div:not(:last-child) {
    margin-right: 5px;
  }
`;

export const Option = styled.div`
  padding: 2px 4px;
  background: #d2d2d2;
  border-radius: 2px;
  display: flex;
  align-items: center;
  & > span {
    color: var(--primary-text-black);
    margin-right: 5px;
  }

  & > button {
    padding: 2px;
    border: unset;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
  }
`;

export const OptionsContainer = styled.div`
  width: 250px;
  & button {
    border: none;
    background-color: #ffffff;
    width: 100%;
    padding: 8px;
  }
  & button:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
`;
