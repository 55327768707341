import { initializeApp } from "firebase/app";
import "firebase/storage";
import "firebase/auth";
// import "firebase/analytics";
import config from "./firebaseConfig";

const app = initializeApp(config);
// const analytics = firebase.analytics();

// if (
//     window.location.hostname === "localhost" &&
//     process.env.REACT_APP_AUTH_EMULATOR
// ) {
//     app.auth().useEmulator("http://localhost:9099");
// }

export default app;
