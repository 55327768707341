import React from "react";
import { Wrapper } from "./style";
import { ReactComponent as DownArrowIcon } from "../../../assets/icons/down-arrow.svg";

export type OptionType = { value: string; label: string };
interface Props {
  options: OptionType[];
  defaultValue?: string;
  $width?: string;
}

const CustomSelect = (props: Props) => (
  <Wrapper suffixIcon={<DownArrowIcon />} $width={props.$width} {...props} />
);

export default CustomSelect;
