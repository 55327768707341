import React from "react";
import { CustomCheckboxContainer, CustomCheckboxInput } from "@reach/checkbox";
import "@reach/checkbox/styles.css";
import { CustomSpan, Wrapper } from "./style";

function getContainerStyle() {
  return {
    background: "rgba(240, 240, 250, 0.8)",
    border: "2px solid rgba(0, 0, 0, 0.4)",
    borderRadius: "3px",
    height: 26,
    width: 26,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  };
}

const CustomCheckbox = ({
  initialChecked = false,
  onChange,
  value = "",
  label = "",
}: {
  onChange?: (value: boolean) => void;
  initialChecked?: boolean;
  value?: string;
  label?: string;
}) => {
  const [checkedState, setChecked] = React.useState(initialChecked);

  const onCheckChange = (event) => {
    onChange && onChange(event.target.checked);
    setChecked(event.target.checked);
  };

  return (
    <Wrapper>
      <CustomCheckboxContainer
        defaultChecked={initialChecked}
        onChange={onCheckChange}
        style={getContainerStyle()}
      >
        <CustomCheckboxInput value={value} />
        <CustomSpan aria-hidden $checked={checkedState} />
      </CustomCheckboxContainer>
      <p>{label}</p>
    </Wrapper>
  );
};

export default CustomCheckbox;
