import React from "react";
import {
  Body,
  Header,
  Wrapper,
  ThemeBody,
  ColorsContainer,
  ColorTooltip,
  ColorBox,
} from "./style";
import { colorThemes } from "../../utils";
import { useEdit } from "../../context/EditContext";

const Themes = () => {
  const { dispatch: localDispatch } = useEdit();

  const onClick = (colors) => {
    const { title, text, button, buttonText, background } = colors;
    localDispatch({
      type: "UPDATE_COLORS_FROM_THEME",
      payload: {
        title,
        text,
        button,
        buttonText,
        background,
      },
    });
  };

  return (
    <Wrapper>
      <Header>
        <p>Themes</p>
      </Header>
      <Body>
        {colorThemes.map((theme) => (
          <ThemeBody
            key={theme.id}
            $backgroundColor={theme.colors.background}
            $textColor={theme.colors.text}
            onClick={() => {
              onClick(theme.colors);
            }}
          >
            <p>{theme.name}</p>
            <ColorsContainer>
              <ColorTooltip title="Title">
                <ColorBox $backgroundColor={theme.colors.title} />
              </ColorTooltip>
              <ColorTooltip title="Text">
                <ColorBox $backgroundColor={theme.colors.text} />
              </ColorTooltip>
              <ColorTooltip title="Button">
                <ColorBox $backgroundColor={theme.colors.button} />
              </ColorTooltip>
              <ColorTooltip title="Button text">
                <ColorBox $backgroundColor={theme.colors.buttonText} />
              </ColorTooltip>
            </ColorsContainer>
          </ThemeBody>
        ))}
      </Body>
    </Wrapper>
  );
};

export default Themes;
