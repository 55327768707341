import React, { useContext } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Header,
  Wrapper,
  Title,
  Footer,
  Content,
  FooterButtons,
  DualInputs,
} from "./style";
import { Input } from "../../../../components/common/Input";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import type { AdUserProps } from "../../type";
import { useUpdateGroupContactsMutation } from "../../../../app/apiSlice";
import { useParams } from "react-router-dom";
import Loader from "../../../../components/common/Loader";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
};

const AddUser = ({ onSave, onCancel, oldData }: AdUserProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();
  const { groupId } = useParams();

  const [updateContacts, { isLoading: isAddingNew }] =
    useUpdateGroupContactsMutation();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const combinedData = [data].concat(oldData);
      await updateContacts({
        groupId,
        updatedGroupContacts: { contacts: combinedData },
      }).unwrap();
      onSave();
      onSuccessShowAlert("Contact added");
    } catch (err) {
      onShowErrorAlert(err, "Failed to add user");
    }
  };
  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={isAddingNew} />
      <Header>
        <Title>Add user</Title>
      </Header>
      <Content>
        <DualInputs>
          <Controller
            name="firstName"
            control={control}
            render={({ field }) => <Input label="First name" field={field} />}
          />
          <Controller
            name="lastName"
            control={control}
            render={({ field }) => <Input label="Last name" field={field} />}
          />
        </DualInputs>
        <Controller
          name="email"
          control={control}
          rules={{
            required: true,
            pattern: /[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}/i,
          }}
          render={({ field }) => (
            <Input type="email" label="Email" field={field} />
          )}
        />
      </Content>
      <Footer>
        <FooterButtons>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <button type="submit">Save</button>
        </FooterButtons>
      </Footer>
    </Wrapper>
  );
};

export default AddUser;
