import React, { useEffect, useState } from "react";
import { Modal, Table } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import {
  Wrapper,
  Buttons,
  Content,
  Header,
  Left,
  About,
  TableWrapper,
} from "./style";
import { ReactComponent as CreateIcon } from "../../assets/plus.svg";
import { ReactComponent as ImportIcon } from "../../assets/import.svg";
import { ReactComponent as BackIcon } from "../../assets/back-arrow.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/arrow-down.svg";
import { ReactComponent as EllipsisIcon } from "../../../../assets/icons/ellipsis.svg";
import ImportRecipient from "../ImportRecipient";
import AddUser from "../AddUser";
import { useAppSelector } from "../../../../app/hooks";
import { useGetMyGroupContactsQuery } from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";

const ContactList = () => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { selectedGroupName } = useAppSelector((state) => state.contact);
  const [isUserModalVisible, setIsUserModalVisible] = useState(false);
  const [isRecipientModalVisible, setIsRecipientModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);

  const { data: contactList, isFetching } = useGetMyGroupContactsQuery({
    groupId,
  });

  const showUserModal = () => {
    setIsUserModalVisible(true);
  };

  const handleUserCancel = () => {
    setIsUserModalVisible(false);
  };

  const showRecipientModal = () => {
    setIsRecipientModalVisible(true);
  };

  const handleRecipientCancel = () => {
    setIsRecipientModalVisible(false);
  };

  const backToGroup = () => {
    navigate("/contacts");
  };

  useEffect(() => {
    if (contactList && contactList.items.length) {
      const formattedData = contactList.items.map((col) => {
        return {
          firstName: col.firstName,
          lastName: col.lastName,
          email: col.email,
          key: col.email,
        };
      });
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [contactList]);

  const columns = [
    {
      title: "First name",
      key: "firstName",
      dataIndex: "firstName",
    },
    {
      title: "Last name",
      key: "lastName",
      dataIndex: "lastName",
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
    },
  ];

  return (
    <Wrapper>
      <Loader isLoading={isFetching} />
      <Header>
        <Left>
          <button type="button" onClick={backToGroup}>
            <BackIcon />
            <span>Groups</span>
          </button>
        </Left>
        <Buttons>
          <button type="button" onClick={showUserModal}>
            <CreateIcon />
            <span>Add user</span>
          </button>
          <button type="button" onClick={showRecipientModal}>
            <ImportIcon />
            <span>Import</span>
          </button>
        </Buttons>
      </Header>
      <Content>
        <About>
          <p>
            Group:<span>{selectedGroupName}</span>
          </p>
          <div>
            <button type="button">
              <DownArrowIcon />
            </button>
            <button type="button">
              <EllipsisIcon />
            </button>
          </div>
        </About>
        <TableWrapper>
          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            pagination={false}
          />
        </TableWrapper>
      </Content>

      {isUserModalVisible && (
        <Modal
          title=""
          width={600}
          visible={isUserModalVisible}
          footer={null}
          onCancel={handleUserCancel}
        >
          <AddUser
            onSave={handleUserCancel}
            onCancel={handleUserCancel}
            oldData={contactList?.items || []}
          />
        </Modal>
      )}
      {isRecipientModalVisible && (
        <Modal
          title=""
          width={600}
          visible={isRecipientModalVisible}
          footer={null}
          onCancel={handleRecipientCancel}
        >
          <ImportRecipient
            onSave={handleRecipientCancel}
            onCancel={handleRecipientCancel}
            oldData={contactList?.items || []}
            groupId={groupId}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default ContactList;
