import React from "react";
import EditSurvey from "../../../../../Edit";
import CreateQuestions from "../../../../../CreateQuestions";
import SurveySettings from "../../../../../Settings";
import SurveyLogic from "../../../../../Logic";

const Content = ({ tab }: { tab: string }) => {
  switch (tab) {
    case "questions":
      return <CreateQuestions />;
    case "edit":
      return <EditSurvey />;
    case "logic":
      return <SurveyLogic />;
    case "settings":
      return <SurveySettings />;
    default:
      return <CreateQuestions />;
  }
};

export default Content;
