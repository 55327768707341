import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 0;
  height: 100%;
  & form {
    height: calc(100% - 40px);
  }
`;

export const FormBody = styled.div`
  padding: 0 8px;
  overflow-y: auto;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 30px;
  & > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-black-color);
    margin-bottom: 10px;
  }
`;
