import React, { ChangeEventHandler } from "react";
import { Wrapper } from "./style";

const SingleInput = (props: any) => {
  const onChange = (e: any) => {
    props.onChange(e.target.value);
  };
  return <Wrapper {...props} onChange={onChange} />;
};

export default SingleInput;
