import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Content,
  InputBox,
  LeftDiv,
  NameDiv,
  NoAccount,
  RightDiv,
  SubmitButtonWrapper,
  Subtitle,
  Title,
  Wrapper,
} from "./style";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ReactComponent as SlLogo } from "../../assets/logo-only.svg";
import { Button } from "../../components/common/Button";
import { Input } from "../../components/common/Input";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";
import { registerUser } from "../Login/loginSlice";
import SignupSuccess from "./SignupSuccess";
import { useResendEmailVerificationMutation } from "../../app/apiSlice";

type Inputs = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

const Signup = () => {
  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [signupEmail, setSignupEmail] = useState("");
  const { status } = useAppSelector((state) => state.auth);
  const [resendEmailVerification, { isLoading }] =
    useResendEmailVerificationMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await dispatch(
        registerUser({
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          password: data.password,
        })
      );
      setIsSubmitted(true);
    } catch (err) {
      onShowErrorAlert(err, "Signup failed. Please try again");
    }
  };

  const handleEmailChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSignupEmail(value);
  };

  const onLoginClick = () => {
    navigate("/login");
  };

  const onResendClick = async () => {
    try {
      await resendEmailVerification({
        email: signupEmail,
        firstName: null,
      }).unwrap();
      onSuccessShowAlert("The email has been resent");
    } catch (err) {
      onShowErrorAlert(
        err,
        "The email failed to resend. Please contact support"
      );
    }
  };

  return (
    <Wrapper>
      <LeftDiv>
        <Content>
          {!isSubmitted ? (
            <>
              <SlLogo />
              <Title>Create an account</Title>
              <Subtitle>Take the last step to valuable insights</Subtitle>
              <form onSubmit={handleSubmit(onSubmit)}>
                <NameDiv>
                  <InputBox>
                    <Controller
                      name="firstName"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Input type="text" label="First name" field={field} />
                      )}
                    />
                    {errors.firstName?.type === "required" && (
                      <span>The first name is required</span>
                    )}
                  </InputBox>
                  <InputBox>
                    <Controller
                      name="lastName"
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field }) => (
                        <Input type="text" label="Last name" field={field} />
                      )}
                    />
                    {errors.lastName?.type === "required" && (
                      <span>The last name is required</span>
                    )}
                  </InputBox>
                </NameDiv>
                <InputBox>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: true,
                      pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/i,
                    }}
                    render={({ field }) => (
                      <Input
                        type="email"
                        label="Email"
                        field={field}
                        onChange={handleEmailChange}
                      />
                    )}
                  />
                  {errors.email?.type === "required" && (
                    <span>The email is required</span>
                  )}
                  {errors.email?.type === "pattern" && (
                    <span>The email is not a valid email pattern</span>
                  )}
                </InputBox>
                <InputBox>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Input type="password" label="Password" field={field} />
                    )}
                  />
                </InputBox>
                <SubmitButtonWrapper>
                  <Button label="Sign up" type="submit" theme="primary" />
                </SubmitButtonWrapper>

                <NoAccount>
                  <span>Already have an account?</span>
                  <NavLink to="/login">Sign in</NavLink>
                </NoAccount>
              </form>
            </>
          ) : (
            <SignupSuccess
              onResendClick={onResendClick}
              onLoginClick={onLoginClick}
            />
          )}
        </Content>
      </LeftDiv>
      <RightDiv />
    </Wrapper>
  );
};

export default Signup;
