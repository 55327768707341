import styled from "styled-components";
import { DatePicker } from "../../../../../common/DatePicker";

const { RangePicker } = DatePicker;

export const Wrapper = styled.div``;

export const QaWrapper = styled.div`
  padding: 20px 14px;
  background-color: #fafafa;
`;

export const Title = styled.p`
  font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const QaBody = styled.div`
  padding: 10px 4px;
`;

export const QaItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    width: min(70%, 260px);
  }

  & > p {
    margin-bottom: 0;
    font-size: 16px;
    margin-right: 10px;
  }
`;

export const RangeFilterInput = styled(RangePicker)`
  background: #ffffff;
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 6px;
`;
