import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type ContactState = { selectedGroupName: string };
const initialState: ContactState = {
  selectedGroupName: null,
};

export const ContactSlice = createSlice({
  name: "Contact",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setSelectedGroup: (state, action: PayloadAction<string>) => {
      state.selectedGroupName = action.payload;
    },
  },
});

export const { setSelectedGroup } = ContactSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.Contact.value)`

export default ContactSlice.reducer;
