import React from "react";
import { Wrapper } from "./style";

interface InputProps {
  message?: string;
}
const ErrorMessage = ({ message = "Field is required" }: InputProps) => {
  return <Wrapper>{message}</Wrapper>;
};

export default ErrorMessage;
