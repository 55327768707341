import styled from "styled-components";

export const Wrapper = styled.label`
  display: flex;
  align-items: center;
  padding-left: 0;
  cursor: pointer;
  & > p {
    margin-left: 10px;
    margin-bottom: unset;
  }
`;

export const CustomSpan = styled.span`
  display: block;
  position: absolute;
  width: 60%;
  height: 60%;
  top: 50%;
  left: 50%;
  transform: ${(props: { $checked: boolean }) =>
    props.$checked
      ? "translate(-50%, -50%) scaleX(1) scaleY(1)"
      : "translate(-50%, -50%) scaleX(0) scaleY(0)"};

  transition: transform 200ms ease-out, background 200ms ease-out;
  z-index: 1;
  background: ${(props: { $checked }) =>
    props.$checked ? "#CE375C" : "transparent"};
`;

export const Content = styled.div``;
