import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { getAuth } from "firebase/auth";
import firebaseApp from "./firebase";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_SURVEY_API_URL}`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

api.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => Promise.reject(error)
);

const fireBaseAuth = getAuth(firebaseApp);

const tokenExpiryHandler = async (error) => {
  if (
    error.response.data.code !== "auth/id-token-expired" &&
    error.response.data.error !== "Unauthorized"
  ) {
    console.log("failure not caused by expired token");
    await fireBaseAuth.signOut();
    return Promise.reject(error);
  }

  if (!fireBaseAuth.currentUser) {
    console.log("no current user", fireBaseAuth.currentUser);
    localStorage.removeItem("token");
    await fireBaseAuth.signOut();
    return Promise.reject(error);
  }
  return fireBaseAuth.currentUser.getIdToken(true).then((token) => {
    if (token) {
      console.log("refreshed token successfully");
      localStorage.setItem("token", token);
      return Promise.resolve(token);
    }
    console.log("failed to refresh token");
    localStorage.removeItem("token");
    fireBaseAuth.signOut();
    throw new Error("no token");
  });
};

const refreshAuthLogic = (failedRequest) =>
  tokenExpiryHandler(failedRequest).then((token) => {
    console.log("token at refresh interceptor library");
    failedRequest.response.config.headers["Authorization"] = "Bearer " + token;
    return Promise.resolve();
  });

// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(api, refreshAuthLogic, {
  statusCodes: [401, 403], // default: [ 401 ]
});
export default api;
