import styled from "styled-components";

export const SortMenuButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: ${(props: { $isCancel?: boolean }) =>
    !props.$isCancel ? "#000000" : "#ce375c"};
`;
