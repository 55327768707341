export const answerOptionsKeys = [
  {
    value: "likert",
    label: "Likert",
    children: [
      {
        value: "age",
        label: "Age",
      },
      {
        value: "acceptability",
        label: "Acceptable - Not acceptable",
      },
      {
        value: "agreement",
        label: "Agree - Disagree",
      },
      {
        value: "appealing",
        label: "Appealing - Not appealing",
      },
      {
        value: "appropriateness",
        label: "Appropriate - Inappropriate",
      },
      {
        value: "consideration",
        label: "Considerable - Not considerable",
      },
      {
        value: "friendliness",
        label: "Friendly - Not friendly",
      },
      {
        value: "modernity",
        label: "Modern - Traditional",
      },
      {
        value: "fair_unfair",
        label: "Fair - Unfair",
      },
      {
        value: "true_false",
        label: "True - False",
      },
      {
        value: "true_not_true",
        label: "True - Not True",
      },
      {
        value: "true_about_not_true_about",
        label: "True about me - Not true about me ",
      },
      {
        value: "yes_no",
        label: "Yes - No",
      },
      {
        value: "ease",
        label: "Easy - Difficult",
      },
      {
        value: "effectiveness",
        label: "Effect - No effect",
      },
      {
        value: "expectations",
        label: "Meet Expectations - Does not meet expectations",
      },
      {
        value: "extent",
        label: "Extent",
      },
      {
        value: "high_low",
        label: "High - Low",
      },
      {
        value: "familiarity",
        label: "Familiar - Not familiar",
      },
      {
        value: "frequency_no_set_time",
        label: "Always - Never",
      },
      {
        value: "frequency_general",
        label: "Daily - Never",
      },
      {
        value: "healthiness",
        label: "Healthy - Unhealthy",
      },
      {
        value: "helpfulness",
        label: "Helpful - Not helpful",
      },
      {
        value: "importance",
        label: "Important - Not important",
      },
      {
        value: "income_monthly",
        label: "Income (Monthly)",
      },
      {
        value: "influence",
        label: "Influential - Not influential",
      },
      {
        value: "inform_level",
        label: "Informed - Uninformed",
      },
      {
        value: "interest",
        label: "Interested - Not interested",
      },
      {
        value: "likelihood",
        label: "Likely - Not likely",
      },
      {
        value: "quality",
        label: "Excellent - Poor ",
      },
      {
        value: "satisfaction",
        label: "Satisfied - Not satisfied",
      },
      {
        value: "level_of_problem",
        label: "Problem - Not a problem",
      },
      {
        value: "positivity",
        label: "Positive - Negative",
      },
      {
        value: "priority_level",
        label: "Priority - Not a priority",
      },
      {
        value: "probability",
        label: "Probable - Not probable ",
      },
      {
        value: "level_of_barrier",
        label: "Barrier - Not a barrier",
      },
      {
        value: "level_of_desirability",
        label: "Desirable - Undesirable",
      },
      {
        value: "level_of_support",
        label: "Support - Oppose",
      },
      {
        value: "level_of_concern",
        label: "Concern - Not concerned",
      },
      {
        value: "level_of_awareness",
        label: "Aware - Unaware",
      },
      {
        value: "level_of_responsibility",
        label: "Responsible - Not responsible",
      },
      {
        value: "comparison",
        label: "Better - Worse",
      },
      {
        value: "rarity",
        label: "Rare - Not rare",
      },
      {
        value: "uniqueness",
        label: "Unique - Not unique",
      },
      {
        value: "usage",
        label: "Use - Not use ",
      },
    ],
  },
  {
    value: "date",
    label: "Date/ Time",
    children: [
      {
        value: "period_of_time",
        label: "Morning - Night",
      },
      {
        value: "frequency",
        label: "Daily - Annually",
      },
      {
        value: "weekdays",
        label: "Monday - Sunday",
      },
      {
        value: "months",
        label: "January - December",
      },
    ],
  },
  {
    value: "demographics",
    label: "Demographics",
    children: [
      {
        value: "age",
        label: "Age",
      },
      {
        value: "gender",
        label: "Gender",
      },
      {
        value: "os",
        label: "Operating system",
      },
      {
        value: "income_monthly",
        label: "Income (Monthly)",
      },
      {
        value: "education",
        label: "Education",
      },
      {
        value: "employment_status",
        label: "Employment status",
      },
      {
        value: "religion",
        label: "Religion",
      },
      {
        value: "industry",
        label: "Industry",
      },
      {
        value: "job_level",
        label: "Job level",
      },
      {
        value: "job_function",
        label: "Job function",
      },
    ],
  },
  {
    value: "geography",
    label: "Geography",
    children: [
      {
        value: "states",
        label: "States",
      },
      {
        value: "countries",
        label: "Countries",
      },
    ],
  },
];

export const answerOptionsValues = {
  age: [
    { value: "<18", label: "Less Than 18" },
    { value: "18_24", label: "18 - 24" },
    { value: "25_34", label: "25 - 34" },
    { value: "35_44", label: "35 - 44" },
    { value: "45_54", label: "45 - 54" },
    { value: ">55", label: "55 and older" },
  ],
  acceptability: [
    { value: "totally_unacceptable", label: "Totally Unacceptable" },
    { value: "unacceptable", label: "Unacceptable" },
    { value: "neutral", label: "Neutral" },
    { value: "acceptable", label: "Acceptable" },
    { value: "perfectly_acceptable", label: "Perfectly Acceptable" },
  ],
  agreement: [
    { value: "strongly_agree", label: "Strongly agree" },
    { value: "moderately_agree", label: "Moderately agree" },
    {
      value: "neither_agree_nor_disagree",
      label: "Neither agree nor disagree",
    },
    { value: "moderately_disagree", label: "Moderately disagree" },
    { value: "strongly_disagree", label: "Strongly disagree" },
  ],
  appealing: [
    { value: "extremely_helpful", label: "Extremely appealing" },
    { value: "very_helpful", label: "Very appealing" },
    { value: "moderately_helpful", label: "Moderately appealing" },
    { value: "slightly_helpful", label: "Slightly appealing" },
    { value: "not_at_all_helpful", label: "Not at all appealing" },
  ],
  appropriateness: [
    { value: "abs_inappropriate", label: "Absolutely inappropriate" },
    { value: "inappropriate", label: "inappropriate" },
    { value: "slightly_inappropriate", label: "Slightly inappropriate" },
    { value: "appropriate", label: "Appropriate" },
    { value: "abs_appropriate", label: "Absolutely Appropriate" },
  ],
  consideration: [
    { value: "not_consider", label: "Would not consider" },
    { value: "might_consider", label: "Might or might not consider" },
    { value: "definitely_consider", label: "Definitely consider" },
  ],
  friendliness: [
    { value: "very_friendly", label: "Very Friendly" },
    { value: "friendly", label: "Friendly" },
    { value: "neutral", label: "Somewhat friendly" },
    { value: "professional", label: "Unfriendly" },
    { value: "very_professional", label: "Very unfriendly" },
  ],
  modernity: [
    { value: "very_modern", label: "Very Modern" },
    { value: "modern", label: "Modern" },
    { value: "neutral", label: "Neutral" },
    { value: "traditional", label: "Traditional" },
    { value: "very_traditional", label: "Very Traditional" },
  ],
  fair_unfair: [
    { value: "fair", label: "Fair" },
    { value: "unfair", label: "Unfair" },
    { value: "not_sure", label: "Not Sure" },
  ],
  true_false: [
    { value: "true", label: "True" },
    { value: "false", label: "False" },
    { value: "not_sure", label: "Not Sure" },
  ],
  true_not_true: [
    { value: "never_true", label: "Never true" },
    { value: "rarely_true", label: "Rarely true" },
    { value: "Neutral", label: "Neutral" },
    { value: "sometimes_true", label: "Sometimes true" },
    { value: "always_true", label: "Always true" },
  ],
  true_about_not_true_about: [
    { value: "very_untrue", label: "Very untrue of me" },
    { value: "untrue_of_me", label: "Untrue of me" },
    { value: "Neutral", label: "Neutral" },
    { value: "true_of_me", label: "True of me" },
    { value: "very_true", label: "Very true of me" },
  ],
  yes_no: [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: "not_sure", label: "Not Sure" },
  ],
  ease: [
    { value: "very_easy", label: "Very easy" },
    { value: "moderately_easy", label: "Easy" },
    {
      value: "neither_easy_nor_difficult",
      label: "Neither easy nor difficult",
    },
    { value: "moderately_difficult", label: "Difficult" },
    { value: "very_difficult", label: "Very difficult" },
  ],
  effectiveness: [
    { value: "no_effect", label: "No effect" },
    { value: "minor_effect", label: "Minor effect" },
    { value: "neutral", label: "Neutral" },
    { value: "moderate_effect", label: "Moderate effect" },
    { value: "major_effect", label: "Major effect" },
  ],
  expectations: [
    { value: "exceeds_expectations", label: "Exceeds expectations" },
    { value: "fully_meets_expectations", label: "Fully meets expectations" },
    {
      value: "does_not_fully_meet_expectations",
      label: "Does not fully meet expectations",
    },
    {
      value: "does_not_meet_expectations_at_all",
      label: "Does not meet expectations at all",
    },
  ],
  extent: [
    { value: "significantly", label: "Significantly" },
    { value: "moderately", label: "Moderately" },
    { value: "slightly", label: "Slightly" },
    { value: "not_at_all", label: "Not at all" },
  ],
  high_low: [
    { value: "high", label: "High" },
    { value: "moderate", label: "Moderate" },
    { value: "low", label: "Low" },
    { value: "none", label: "None" },
  ],
  familiarity: [
    { value: "not_at_all_familiar", label: "Not at all familiar" },
    { value: "slightly_familiar", label: "Slightly familiar" },
    { value: "somewhat_familiar", label: "Somewhat familiar" },
    { value: "moderately_familiar", label: "Moderately familiar" },
    { value: "extremely_familiar", label: "Extremely familiar" },
  ],
  frequency_no_set_time: [
    { value: "always", label: "Always" },
    { value: "often", label: "Often" },
    { value: "occasionally", label: "Occasionally" },
    { value: "rarely", label: "Rarely" },
    { value: "never", label: "Never" },
  ],
  frequency_general: [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "once_a_semester", label: "Once a semester" },
    { value: "once_a_year", label: "Once a year" },
    { value: "never", label: "Never" },
  ],
  healthiness: [
    { value: "very_unhealthy", label: "Very unhealthy" },
    { value: "unhealthy", label: "Unhealthy" },
    { value: "somewhat_healthy", label: "Somewhat healthy" },
    { value: "healthy", label: "Healthy" },
    { value: "very_healthy", label: "Very healthy" },
  ],
  helpfulness: [
    { value: "extremely_helpful", label: "Extremely helpful" },
    { value: "very_helpful", label: "Very helpful" },
    { value: "moderately_helpful", label: "Moderately helpful" },
    { value: "slightly_helpful", label: "Slightly helpful" },
    { value: "not_at_all_helpful", label: "Not at all helpful" },
  ],
  importance: [
    { value: "extremely_important", label: "Extremely important" },
    { value: "very_important", label: "Very important" },
    { value: "moderately_important", label: "Moderately important" },
    { value: "slightly_important", label: "Slightly important" },
    { value: "not_at_all_important", label: "Not at all important" },
  ],
  income_monthly: [
    { value: "<30", label: "Less than N30,000" },
    { value: "30_49", label: "Between N30,000 and N49,999" },
    { value: "50_99", label: "Between N50,000 and N99,999" },
    { value: "100_199", label: "Between N100,000 and N199,999" },
    { value: "200_349", label: "Between N200,000 and  N349,999" },
    { value: "350_499", label: "Between N350,000 - N499,999" },
    { value: ">500", label: "Over N500,000" },
  ],
  influence: [
    { value: "extremely_influential", label: "Extremely influential" },
    { value: "very_influential", label: "Very influential" },
    { value: "moderately_influential", label: "Moderately influential" },
    { value: "slightly_influential", label: "Slightly influential" },
    { value: "not_at_all_influential", label: "Not at all influential" },
  ],
  inform_level: [
    { value: "extremely_uninformed", label: "Extremely uninformed" },
    { value: "somewhat_uninformed", label: "Somewhat uninformed" },
    { value: "somewhat_informed", label: "Somewhat informed" },
    { value: "extremely_uninformed", label: "Extremely informed" },
  ],
  interest: [
    { value: "extremely_interested", label: "Extremely interested" },
    { value: "very_interested", label: "Very interested" },
    { value: "moderately_interested", label: "Moderately interested" },
    { value: "slightly_interested", label: "Slightly interested" },
    { value: "not_interested", label: "Not interested" },
  ],
  likelihood: [
    { value: "very_likely", label: "Very likely" },
    { value: "moderately_likely", label: "Moderately likely" },
    {
      value: "neither_likely_nor_unlikely",
      label: "Neither likely nor unlikely",
    },
    { value: "moderately_unlikely", label: "Moderately unlikely" },
    { value: "very_unlikely", label: "Very unlikely" },
  ],
  quality: [
    { value: "excellent", label: "Excellent" },
    { value: "good", label: "Good" },
    { value: "average", label: "Average" },
    { value: "below_average", label: "Below average" },
    { value: "poor", label: "Poor" },
  ],
  satisfaction: [
    { value: "very_satisfied", label: "Very satisfied" },
    { value: "moderately_satisfied", label: "Moderately satisfied" },
    {
      value: "neither_satisfied_nor_unsatisfied",
      label: "Neither satisfied nor dissatisfied",
    },
    { value: "moderately_dissatisfied", label: "Moderately dissatisfied" },
    { value: "very_dissatisfied", label: "Very dissatisfied" },
  ],
  level_of_problem: [
    { value: "not_at_all_a_problem", label: "Not at all a problem" },
    { value: "minor_problem", label: "Minor problem" },
    { value: "moderate_problem", label: "Moderate problem" },
    { value: "serious_problem", label: "Serious problem" },
  ],
  positivity: [
    { value: "very_negative", label: "Very Negative" },
    { value: "negative", label: "Negative" },
    { value: "neutral", label: "Neutral" },
    { value: "positive", label: "Positive" },
    { value: "very_positive", label: "Very Positive" },
  ],
  priority_level: [
    { value: "not_a_priority", label: "Not a priority" },
    { value: "low_priority", label: "Low priority" },
    { value: "medium_priority", label: "Medium priority" },
    { value: "high_priority", label: "High priority" },
    { value: "essential", label: "Essential" },
  ],
  probability: [
    { value: "not_probable", label: "Not probable" },
    { value: "somewhat_improbable", label: "Somewhat Improbable" },
    { value: "neutral", label: "Neutral" },
    { value: "somewhat_probable", label: "Somewhat probable" },
    { value: "very_probable", label: "Very probable" },
  ],
  level_of_barrier: [
    { value: "not_barrier", label: "Not a barrier" },
    { value: "somewhat_barrier", label: "Somewhat of a barrier" },
    { value: "moderate_barrier", label: "Moderate barrier" },
    { value: "extreme_barrier", label: "Extreme barrier" },
  ],
  level_of_desirability: [
    { value: "very_undesirable", label: "Very undesirable" },
    { value: "undesirable", label: "Undesirable" },
    { value: "neutral", label: "Neutral" },
    { value: "desirable", label: "Desirable" },
    { value: "very_desirable", label: "Very desirable" },
  ],
  level_of_support: [
    { value: "strongly_oppose", label: "Strongly oppose" },
    { value: "somewhat_oppose", label: "Somewhat oppose" },
    { value: "neutral", label: "Neutral" },
    { value: "somewhat_favor", label: "Somewhat favor" },
    { value: "strongly_favor", label: "Strongly favor" },
  ],
  level_of_concern: [
    { value: "not_at_all_concerned", label: "Not at all concerned" },
    { value: "slightly_concerned", label: "Slightly concerned" },
    { value: "somewhat_concerned", label: "Somewhat concerned" },
    { value: "moderately_concerned", label: "Moderately concerned" },
    { value: "extremely_concerned", label: "Extremely concerned" },
  ],
  level_of_awareness: [
    { value: "not_at_all_aware", label: "Not at all aware" },
    { value: "slightly_aware", label: "Slightly aware" },
    { value: "somewhat_aware", label: "Somewhat aware" },
    { value: "moderately_aware", label: "Moderately aware" },
    { value: "extremely_aware", label: "Extremely aware" },
  ],
  level_of_responsibility: [
    { value: "not_at_all_responsible", label: "Not at all responsible" },
    { value: "somewhat_responsible", label: "Somewhat responsible" },
    { value: "mostly_responsible", label: "Mostly responsible" },
    { value: "completely_responsible", label: "Completely responsible" },
  ],
  comparison: [
    { value: "much_worse", label: "Much worse" },
    { value: "somewhat_worse", label: "Somewhat worse" },
    { value: "about_the_same", label: "About the same" },
    { value: "somewhat_better", label: "Somewhat better" },
    { value: "much_better", label: "Much better" },
  ],
  rarity: [
    { value: "extremely_rare", label: "Extremely rare" },
    { value: "very_rare", label: "Very rare" },
    { value: "somewhat_rare", label: "Somewhat rare" },
    { value: "slightly_rare", label: "Slightly rare" },
  ],
  uniqueness: [
    { value: "extremely_unique", label: "Extremely unique" },
    { value: "very_unique", label: "Very unique" },
    { value: "moderate_unique", label: "Moderately unique" },
    { value: "slightly_unique", label: "Slightly unique" },
    { value: "not_at_all_unique", label: "Not at all unique" },
  ],
  usage: [
    { value: "never_use", label: "Never use" },
    { value: "almost_never", label: "Almost never" },
    { value: "occasionally", label: "Occasionally/Sometimes" },
    { value: "almost_every_time", label: "Almost every time" },
    { value: "frequently_use", label: "Frequently use" },
  ],
  period_of_time: [
    { value: "dawn", label: "Dawn" },
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
    { value: "night", label: "Night" },
  ],
  frequency: [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "bi_weekly", label: "Bi-Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "bi_annual", label: "Bi-annual" },
    { value: "annually", label: "Annually" },
  ],
  weekdays: [
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ],
  months: [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ],
  states: [
    { value: "FC", label: "Abuja" },
    { value: "AB", label: "Abia" },
    { value: "AD", label: "Adamawa" },
    { value: "AK", label: "Akwa Ibom" },
    { value: "AN", label: "Anambra" },
    { value: "BA", label: "Bauchi" },
    { value: "BY", label: "Bayelsa" },
    { value: "BE", label: "Benue" },
    { value: "BO", label: "Borno" },
    { value: "CR", label: "Cross River" },
    { value: "DE", label: "Delta" },
    { value: "EB", label: "Ebonyi" },
    { value: "ED", label: "Edo" },
    { value: "EK", label: "Ekiti" },
    { value: "EN", label: "Enugu" },
    { value: "GO", label: "Gombe" },
    { value: "IM", label: "Imo" },
    { value: "JI", label: "Jigawa" },
    { value: "KD", label: "Kaduna" },
    { value: "KN", label: "Kano" },
    { value: "KT", label: "Katsina" },
    { value: "KE", label: "Kebbi" },
    { value: "KO", label: "Kogi" },
    { value: "KW", label: "Kwara" },
    { value: "LA", label: "Lagos" },
    { value: "NA", label: "Nassarawa" },
    { value: "NI", label: "Niger" },
    { value: "OG", label: "Ogun" },
    { value: "ON", label: "Ondo" },
    { value: "OS", label: "Osun" },
    { value: "OY", label: "Oyo" },
    { value: "PL", label: "Plateau" },
    { value: "RI", label: "Rivers" },
    { value: "SO", label: "Sokoto" },
    { value: "TA", label: "Taraba" },
    { value: "YO", label: "Yobe" },
    { value: "ZA", label: "Zamfara" },
  ],
  gender: [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "other", label: "Other" },
    { value: "prefer_not", label: "Prefer not to say" },
  ],

  education: [
    { value: "no_school", label: "No schooling completed" },
    { value: "high", label: "Some high school, no diploma" },
    {
      value: "secondary",
      label: " Secondary school graduate, diploma, or the equivalent",
    },
    { value: "college_no_degree", label: "Some college credit, no degree" },
    { value: "training", label: " Trade/boot camp/vocational training" },
    { value: "associate", label: "Associate degree" },
    { value: "bachelor", label: "Bachelor’s degree" },
    { value: "masters", label: "Master’s degree" },
    { value: "professional", label: "Professional degree" },
    { value: "doctorate", label: "Doctorate degree" },
  ],
  os: [
    { value: "Android", label: "Android" },
    { value: "ios", label: "iOS" },
    { value: "other", label: "Other" },
  ],

  employment_status: [
    { value: "full", label: "Employed Full time" },
    { value: "part", label: "Employed (Temp, contractor, freelancer)" },
    { value: "self", label: "Self-employed" },
    { value: "un_and_seeking", label: "Unemployed, actively seeking" },
    { value: "un_not_seeking", label: "Unemployed, not actively seeking" },
    { value: "student", label: "A student" },
    { value: "retired", label: "Retired" },
    { value: "unable", label: "Unable to work" },
  ],

  religion: [
    { value: "christian", label: "Christian" },
    { value: "muslim", label: "Muslim" },
    { value: "jewish", label: "Jewish" },
    { value: "indigenous", label: "Indigenous religions" },
    { value: "no", label: "No Religion" },
    { value: "athiest", label: "Athiest" },
    { value: "any_other", label: "Any other religion" },
  ],

  industry: [
    { value: "religious", label: "Religious or Charity Organisation" },
    { value: "consulting", label: "Consulting" },
    { value: "finance", label: "Finance" },
    { value: "real_estate", label: "Real estate" },
    { value: "agriculture", label: "Agriculture" },
    { value: "healthcare", label: "Healthcare" },
    { value: "oil", label: "Oil/Gas" },
    { value: "technology", label: "Technology" },
    { value: "media", label: "Media" },
    { value: "consumer", label: "Consumer Goods" },
    { value: "government", label: "Government" },
    { value: "hospitality", label: "Hospitality" },
    { value: "manufacturing", label: "Manufacturing" },
    { value: "legal", label: "Legal" },
    { value: "other", label: "Other" },
  ],

  job_function: [
    { value: "accounting", label: "Accounting" },
    { value: "administrative", label: "Administrative" },
    { value: "arts", label: "Arts and Design" },
    { value: "business_dev", label: "Business Development" },
    { value: "consulting", label: "Consulting" },
    { value: "education", label: "Education" },
    { value: "engineering", label: "Engineering" },
    { value: "management", label: "Management" },
    { value: "finance", label: "Finance" },
    { value: "healthcare", label: "Healthcare Services" },
    { value: "human_resources", label: "Human Resources" },
    { value: "technology", label: "Information Technology/Software" },
    { value: "legal", label: "Legal" },
    { value: "marketing", label: "Marketing" },
    { value: "media", label: "Media & Communications" },
    { value: "military", label: "Military & Protective Services" },
    { value: "operations", label: "Operations" },
    { value: "product_management", label: "Product Management" },
    { value: "qa", label: "Quality Assurance" },
    { value: "researcher", label: "Researcher" },
    { value: "sales", label: "Sales/Support" },
    { value: "other", label: "Other" },
  ],

  job_level: [
    { value: "intern", label: "Intern" },
    { value: "entry", label: "Entry Level" },
    { value: "analyst", label: "Analyst / Associate" },
    { value: "manager", label: "Manager" },
    { value: "senior_manager", label: "Senior Manager" },
    { value: "director", label: "Director" },
    { value: "vp", label: "Vice President" },
    { value: "senior_vp", label: "Senior Vice President" },
    { value: "c_level", label: "C level executive (CIO, CTO, COO, CMO, Etc)" },
    { value: "president", label: "President or CEO" },
    { value: "owner", label: "Owner" },
  ],
};
