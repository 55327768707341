import styled from "styled-components";
import { Checkbox } from "antd";

export const Wrapper = styled.form`
  width: 100%;
  height: 100%;
  background: #ffffff;
`;

export const Body = styled.div`
  margin-top: 10px;
`;

export const BodyHead = styled.div`
  margin-top: 10px;
  margin-bottom: 16px;
`;

export const BodyTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--primary-text-black);
  margin-bottom: 3px;
`;

export const BodySubtitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--secondary-text-grey);
`;

export const CollectorContainer = styled.div`
  margin-bottom: 20px;
  padding-bottom: 25px;
  border-bottom: 1px solid #e0e0e0;
  & > p {
    margin-bottom: 16px;
  }
`;

export const Section = styled.div`
  margin-bottom: 25px;
`;

export const SectionTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: var(--secondary-text-black);
  margin-bottom: 4px;
`;

export const SectionSubtitle = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 14px;
  color: var(--secondary-text-grey);
`;

export const SectionBody = styled.div``;

export const DateTimeContainer = styled.div`
  display: flex;
  align-items: center;
  & > div {
    margin-right: 20px;
  }
  & div[class~="ant-picker-input"] > input[disabled] {
    color: transparent;
  }
`;

export const SettingsInput = styled.input`
  background: #f0f0f0;
  border-radius: 3px;
  border: none;
  height: 32px;
  width: 340px;
  &:disabled {
    color: transparent;
  }
`;

export const SettingsCheckbox = styled(Checkbox)`
  & span[class~="ant-checkbox-checked"] span[class~="ant-checkbox-inner"] {
    background-color: var(--primary-red-color) !important;
    border-color: var(--primary-red-color) !important;
  }
`;

export const SettingsButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const SettingsSaveButton = styled.button`
  background: var(--primary-red-color);
  border-radius: 4px;
  border: none;
  height: 32px;
  padding: 0 12px;
  color: white;
`;
