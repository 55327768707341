import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { colorGroups } from "./components/Settings/tabs/Design/colors";
import type { QuestionType } from "./context/types";
import type { ResultData } from "./types";

dayjs.extend(isBetween);

export const getChartTypeAndPalette = ({
  questionType,
}: {
  questionType: QuestionType;
}) => {
  let colorPalette;
  let selectedChart;
  if (["MC", "Ch", "Dd", "Sl"].includes(questionType)) {
    selectedChart = "ver";
    colorPalette = "accent";
  } else if (["MRS", "Rk"].includes(questionType)) {
    selectedChart = "horDivStBar";
    colorPalette = "cyan_orange";
  } else if (["IC"].includes(questionType)) {
    selectedChart = "image";
    colorPalette = "accent";
  } else if (["SR"].includes(questionType)) {
    selectedChart = "star";
    colorPalette = "accent";
  } else if (["ST", "LT"].includes(questionType)) {
    selectedChart = "list";
    colorPalette = "accent";
  } else if (["DT"].includes(questionType)) {
    selectedChart = "dtList";
    colorPalette = "accent";
  } else {
    selectedChart = "ver";
    colorPalette = "accent";
  }
  return { colorPalette, selectedChart };
};

export const legendShapeOptions = [
  { label: "Circle", value: "circle" },
  { label: "Square", value: "square" },
];

export const legendPositionOptions = [
  { label: "Top left", value: "top-left" },
  { label: "Top center", value: "top-center" },
  { label: "Top right", value: "top-right" },
  { label: "Middle left", value: "middle-left" },
  { label: "Middle right", value: "middle-right" },
  { label: "Bottom left", value: "bottom-left" },
  { label: "Bottom center", value: "bottom-center" },
  { label: "Bottom right", value: "bottom-right" },
];

export const getColorListByFamily = (colorFamilySelected, dataLength) => {
  const colorGroup = colorGroups.find((cg) => cg.key === colorFamilySelected);
  let colorList = [];
  if (colorGroup && dataLength) {
    const colorGroupList = colorGroup.colors;
    const groupListLength = colorGroupList.length;
    if (dataLength > groupListLength) {
      const lengthDiff = dataLength - groupListLength;
      // repeat colors and take the remaining length needed
      const nRepeatColors = Math.ceil(lengthDiff / groupListLength);
      const repeatedColorsFull = Array(nRepeatColors)
        .fill(colorGroupList)
        .flat(1);
      const repeatedColors = repeatedColorsFull.slice(0, lengthDiff);
      colorList = colorGroupList.concat(repeatedColors);
    } else if (dataLength === groupListLength) {
      colorList = colorGroupList;
    } else {
      colorList = colorGroupList.slice(0, dataLength);
    }
  }
  let colorListKeyValue;
  if (["single"].includes(colorFamilySelected)) {
    colorListKeyValue = colorList.map((col, index) => ({
      key: `${col}-${index}`,
      value: col,
    }));
    colorListKeyValue = [colorListKeyValue[0]];
  } else {
    colorListKeyValue = colorList.map((col, index) => ({
      key: `${col}-${index}`,
      value: col,
    }));
  }
  return { colorList, colorListKeyValue };
};

export const getOrderedData = (initialData, sortType) => {
  const initialDataCopy = [...initialData];
  if (sortType === "desc") {
    initialDataCopy.sort((a, b) => b.responses - a.responses);
  } else if (sortType === "asc") {
    initialDataCopy.sort((a, b) => a.responses - b.responses);
  }
  return initialDataCopy;
};

export const filterResultByDateRange = (
  dateRange: string[],
  data: ResultData[]
) => {
  return data.filter((res) =>
    dayjs(res.createdAt).isBetween(dateRange[0], dateRange[1], "day", "[]")
  );
};
