import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Table } from "antd";
import {
  Wrapper,
  TableContainer,
  TableWrapper,
  TableTitle,
  EllipsisContainer,
  EmailLinks,
  NewLinkButton,
} from "./style";
import CollectorSettings from "../../CollectorSettings";
import ResponseSettings from "../../ResponseSettings";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/ellipsis.svg";
import Mailbox from "../Mailbox";
import "./style.css";
import type {
  CollectorType,
  ResponsesByCollectors,
} from "../../../../../pages/CollectResponses/types";
import {
  useAddNewCollectorMutation,
  useGetContactGroupQuery,
  useGetMyGroupContactsQuery,
} from "../../../../../app/apiSlice";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import { useParams } from "react-router-dom";
import LinkBox from "../LinkBox";
import Loader from "../../../../common/Loader";

interface EmailListProps {
  collectors: CollectorType[];
  responsesByCollectors: ResponsesByCollectors;
}

const EmailList = ({ collectors, responsesByCollectors }: EmailListProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { surveyId } = useParams();
  const [addNewCollector, { isLoading: isAddingCollector }] =
    useAddNewCollectorMutation();

  const columns = [
    {
      title: "Collector name",
      dataIndex: "name",
      key: "name",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text) => <p>{text}</p>,
    },
    {
      title: "Responses",
      key: "responses",
      dataIndex: "responses",
    },
    {
      title: "Sent date",
      dataIndex: "start_date",
      key: "start_date",
    },
    {
      title: "End date",
      dataIndex: "end_date",
      key: "end_date",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value) => <span>{value}</span>,
    },
    {
      title: "",
      key: "action",
      render: (record: any) => (
        <Dropdown
          overlay={
            <CollectorSettings
              isEmail
              onEmailViewClick={onEmailClick}
              onConfigureClick={onConfigureClick}
              // onDeleteClick={onDeleteClick}
              // onPauseClick={onPauseClick}
              collectorItem={record}
            />
          }
        >
          <EllipsisContainer>
            <EllipsisIcon />
          </EllipsisContainer>
        </Dropdown>
      ),
    },
  ];

  useEffect(() => {
    if (collectors && collectors.length) {
      const formattedData = collectors.map((col) => {
        const responseCount =
          responsesByCollectors[col.collectorId]?.length || 0;
        return {
          ...col,
          key: col.collectorId,
          name: col.name,
          responses: responseCount,
          start_date: col.startDate,
          end_date: col.endDate || "--",
          status: col.manualStatus,
          subject: col.subject,
        };
      });
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [collectors, responsesByCollectors]);

  const [selectedCollector, setSelectedCollector] =
    useState<CollectorType>(null);

  const onConfigureClick = (theCollector: CollectorType) => {
    setSelectedCollector(theCollector);
    setIsModalVisible(true);
  };

  const onCancel = () => {
    console.log("configure");
    setIsModalVisible(false);
  };

  const onSubmitSettings = () => {
    console.log("submit");
  };

  const onEmailClick = () => {
    console.log("email");
  };

  const onNewClick = async () => {
    const newCollectorId = nanoid();
    await addNewCollector({
      collectorId: newCollectorId,
      name: `Email ${collectors.length + 1}`,
      collectorType: "EMAIL",
      startDate: dayjs().format("DD-MM-YYYY"),
      endDate: null,
      endTime: "12:00:00",
      surveyId,
      isManuallyClosed: false,
      manualStatus: "ACTIVE",
      emailProvider: "SENDGRID",
    }).unwrap();
  };

  return (
    <Wrapper>
      <Loader isLoading={isAddingCollector} />
      <EmailLinks>
        {collectors.map((link) => (
          <LinkBox
            onConfigureClick={onConfigureClick}
            linkValue={`${process.env.REACT_APP_SURVEY_PREVIEW_URL}r/${link.collectorId}`}
            name={link.name}
            key={link.collectorId}
            collector={link}
          />
        ))}
      </EmailLinks>
      <NewLinkButton type="button" onClick={onNewClick}>
        Add new link
      </NewLinkButton>
      <TableContainer>
        <TableWrapper>
          <TableTitle>Outbox</TableTitle>
          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            pagination={false}
          />
        </TableWrapper>
      </TableContainer>

      <Modal
        title="Email"
        visible={isModalVisible}
        onCancel={onCancel}
        footer={null}
      >
        <ResponseSettings
          collector={selectedCollector}
          onSave={onSubmitSettings}
        />
      </Modal>
    </Wrapper>
  );
};

export default EmailList;
