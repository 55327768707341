import React, { useState, createContext, useMemo, useEffect } from "react";
import {
  getChartTypeAndPalette,
  getColorListByFamily,
  getOrderedData,
} from "../utils";
import { useAppSelector } from "../../../app/hooks";
import type { ChartContextType, ChartProps } from "./types";

export const ChartContext = createContext<ChartContextType | undefined>(
  undefined
);

const Chart = ({
  questionId,
  questionType,
  chartData,
  questionOptions,
  respondentsDetails,
  extraOptions,
  children,
}: ChartProps) => {
  const [resultData, setOrderedData] = useState(chartData);
  const [loading, setLoading] = useState(true);
  const [donutInnerRadius, setDonutInnerRadius] = useState(0);
  const { chartSettingsById, isLoading: settingsLoading } = useAppSelector(
    (state) => state.design
  );

  const [isCustomizing, setIsCustomizing] = useState(false);
  const [otherOptions, setOtherOptions] = useState({
    padValue: 0.7,
    cornerValue: 3,
    innerValue: 0.5,
  });

  const [chartSelected, setChartSelected] = useState("");
  const [colorFamilySelected, setColorFamilySelected] = useState("accent");
  const [sortType, setSortType] = useState("default");
  const [countPercentageFormat, setCountPercentageFormat] = useState<
    "percentage" | "count"
  >("percentage");

  // axis
  const [showXaxis, setShowXaxis] = useState(true);
  const [showYaxis, setShowYaxis] = useState(true);
  const [xAxisLabel, setXAxisLabel] = useState(undefined);
  const [yAxisLabel, setYAxisLabel] = useState("Responses");

  // data labels
  const [dataLabelsVisible, setDataLabelsVisible] = useState(false);
  const [dataLabelsPosition, setDataLabelsPosition] = useState("outside");

  const [labelVisible, setLabelVisible] = useState(true);
  const [tableIsDisplayed, setTableIsDisplayed] = useState(false);

  const [legendVisible, setLegendVisible] = useState(true);
  const [legendLayout, setLegendLayout] = useState("horizontal");
  const [legendVerPosition, setLegendVerPosition] = useState("bottom");
  const [legendHorPosition, setLegendHorPosition] = useState("center");
  const [legendSymbolShape, setLegendSymbolShape] = useState("square");

  const [headerText, setHeaderText] = useState("");
  const [headerTextAlignment, setHeaderTextAlignment] = useState("left");

  const [selectedColorList, setSelectedColorList] = useState([]);
  const [selectedColorListObj, setSelectedColorListObj] = useState([]);

  useEffect(() => {
    setOrderedData(chartData);
  }, [chartData]);

  useEffect(() => {
    if (questionType && chartData && chartData.length && questionId) {
      let savedChartType,
        savedHeaderTextAlignment,
        savedColorPalette,
        savedShowTable,
        savedSortType,
        savedShowLegend,
        saveColorList,
        savedLegendSymbolShape;
      if (chartSettingsById && Object.keys(chartSettingsById).length) {
        savedChartType = chartSettingsById[questionId]?.chartType;
        savedHeaderTextAlignment =
          chartSettingsById[questionId]?.headerTextAlignment;
        savedColorPalette = chartSettingsById[questionId]?.colorPalette;
        savedShowTable = chartSettingsById[questionId]?.showTable;
        savedSortType = chartSettingsById[questionId]?.sortType;
        savedShowLegend = chartSettingsById[questionId]?.showLegend;
        savedLegendSymbolShape =
          chartSettingsById[questionId]?.legendSymbolShape;
        saveColorList = chartSettingsById[questionId]?.colorList;
        // const {
        //     showTable: savedShowTable,
        //     headerText: savedHeaderText,
        //     headerTextAlignment: savedHeaderTextAlignment,
        //     sortType: savedSortType,
        //     showLegend: savedShowLegend,
        //     legendSymbolShape: savedLegendSymbolShape,
        //     colorPalette: savedColorPalette,
        //     colorList: saveColorList,
        //     chartType: savedChartType,
        //     legendLayout: savedLegendLayout,
        //     legendHorPosition: savedLegendHorPosition,
        //     legendVerPosition: savedLegendVerPosition,
        //     dataLabelsFormatType: savedDataLabelsFormatType,
        //     showDataLabels: savedShowDataLabels,
        // } = chartSettingsById[questionId];
      }

      const { colorPalette, selectedChart } = getChartTypeAndPalette({
        questionType,
      });
      setChartSelected(savedChartType ?? selectedChart);
      setHeaderTextAlignment(savedHeaderTextAlignment ?? headerTextAlignment);
      setColorFamilySelected(savedColorPalette ?? colorPalette);
      setTableIsDisplayed(savedShowTable);
      setSortType(savedSortType ?? "default");
      setLegendVisible(savedShowLegend);
      setLegendSymbolShape(savedLegendSymbolShape);
      const { colorList, colorListKeyValue } = getColorListByFamily(
        savedColorPalette ?? colorPalette,
        chartData.length
      );
      setSelectedColorList(saveColorList ?? colorList);
      let newColorListKeyValue;
      if (saveColorList) {
        newColorListKeyValue = colorList.map((col, index) => ({
          key: `${col}-${index}`,
          value: col,
        }));
      }
      setSelectedColorListObj(newColorListKeyValue ?? colorListKeyValue);
      setLoading(false);
    }
  }, [chartSettingsById, questionId, questionType, chartData]);

  const onColorFamilyChange = (colorSelected) => {
    setColorFamilySelected(colorSelected);
    const { colorList, colorListKeyValue } = getColorListByFamily(
      colorSelected,
      resultData.length
    );
    setSelectedColorList(colorList);
    setSelectedColorListObj(colorListKeyValue);
  };

  const onChartChange = (colorPicked) => {
    setChartSelected(colorPicked);
    if (colorPicked === "donut") {
      setDonutInnerRadius(0.5);
    }
  };

  const onSingleColorChange = (index, colorHex) => {
    let newColorList;
    if (["single"].includes(colorFamilySelected)) {
      const selectedColorListCopy = [...selectedColorList];
      newColorList = selectedColorListCopy.map(() => colorHex);
    } else {
      newColorList = [...selectedColorList];
      newColorList.splice(index, 1, colorHex);
    }
    setSelectedColorList(newColorList);
  };

  const onHeaderAlignmentChange = (e) => {
    setHeaderTextAlignment(e.target.value);
  };

  const onSortChange = (e) => {
    const theOrderType = e.target.value;
    setSortType(e.target.value);
    const ordData = getOrderedData(chartData, theOrderType);
    setOrderedData(ordData);
  };

  const onDataLabelFormatChange = (e) => {
    setCountPercentageFormat(e.target.value);
  };

  const onDataLabelPositionChange = (e) => {
    setDataLabelsPosition(e.target.value);
  };

  const onSetLegendPosition = ({ verticalPosition, horizontalPosition }) => {
    setLegendVerPosition(verticalPosition);
    setLegendHorPosition(horizontalPosition);
  };

  const onAddToDashboard = () => {
    console.log("fff");
  };
  const value = useMemo(
    () => ({
      loading,
      resultData,
      showXaxis,
      setShowXaxis,
      showYaxis,
      setShowYaxis,
      xAxisLabel,
      setXAxisLabel,
      yAxisLabel,
      setYAxisLabel,
      chartSelected,
      onChartChange,
      colorFamilySelected,
      selectedColorList,
      tableIsDisplayed,
      sortType,
      headerText,
      labelVisible,
      setLabelVisible,
      dataLabelsVisible,
      setDataLabelsVisible,
      setTableIsDisplayed,
      legendVisible,
      legendSymbolShape,
      legendVerPosition,
      legendHorPosition,
      legendLayout,
      setLegendSymbolShape,
      setLegendVisible,
      setHeaderText,
      setDonutInnerRadius,
      donutInnerRadius,
      countPercentageFormat,
      dataLabelsPosition,
      onDataLabelFormatChange,
      onDataLabelPositionChange,
      onColorFamilyChange,
      onSetLegendPosition,
      onSortChange,
      onAddToDashboard,
      onSingleColorChange,
      onHeaderAlignmentChange,
      questionType,
      questionOptions,
      selectedColorListObj,
      respondentsDetails,
      headerTextAlignment,
      extraOptions,
    }),
    [
      loading,
      chartSelected,
      colorFamilySelected,
      tableIsDisplayed,
      sortType,
      legendVisible,
      legendSymbolShape,
      legendVerPosition,
      legendHorPosition,
      legendLayout,
      headerText,
      selectedColorList,
      labelVisible,
      dataLabelsVisible,
      showXaxis,
      showYaxis,
      xAxisLabel,
      yAxisLabel,
      chartData,
      resultData,
      donutInnerRadius,
      questionType,
      countPercentageFormat,
      dataLabelsPosition,
      questionOptions,
      selectedColorListObj,
      respondentsDetails,
      headerTextAlignment,
      extraOptions,
    ]
  );

  return (
    // @ts-ignore
    <ChartContext.Provider value={value}>{children}</ChartContext.Provider>
  );
};

export default Chart;
