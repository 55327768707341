import React, { useCallback, useEffect, useState } from "react";
import lodashForEach from "lodash/forEach";
import { Dropdown, Menu, Modal, Table } from "antd";
import dayjs from "dayjs";
import { Steps } from "intro.js-react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import {
  Wrapper,
  SortMenuButton,
  Container,
  PageActions,
  ActionFlex,
  GridContainer,
  StatsGridContainer,
  StatContainer,
  BlogGridContainer,
  StatCard,
  TableContainer,
  SortButton,
  ShareBtn,
  LinkBtn,
  ActionContainer,
  PostContainer,
  PostDate,
  PostFooter,
  PostContent,
  PostTag,
  AuthorInfo,
  PostBody,
} from "./style";
import OnboardingScreen from "../../components/Onboarding/Screens";
import { ReactComponent as PencilIcon } from "./assets/pencil-alt.svg";
import { ReactComponent as DocAlt } from "./assets/doc-alt.svg";
import { ReactComponent as ArrowRight } from "./assets/arrow-right.svg";
import { ReactComponent as ArrowRightSm } from "./assets/arrow-right-sm.svg";
import { ReactComponent as CopyIcon } from "./assets/copy.svg";
import { ReactComponent as FolderIcon } from "./assets/folder-icon.svg";
import { ReactComponent as EditSquare } from "./assets/edit-square.svg";
import { ReactComponent as FileIcon } from "./assets/doc-icon.svg";
import { ReactComponent as Histogram } from "./assets/histogram.svg";
import { ReactComponent as Ellipsis } from "./assets/ellipsis.svg";
import { ReactComponent as LinkIcon } from "./assets/link.svg";
import EmptyTable from "../../features/dashboard/components/EmptyTable";
import TableAction from "../../features/dashboard/components/TableAction";

import type { SurveyStatusItem } from "./types";
import {
  useAddNewSurveyMutation,
  useGetSurveysQuery,
} from "../../app/apiSlice";
import { useAppSelector } from "../../app/hooks";
import Loader from "../../components/common/Loader";

const SortMenu = ({
  setSort,
  clearSelection,
}: {
  setSort: (order, orderBy) => void;
  clearSelection: () => void;
}) => (
  <Menu>
    <Menu.Item>
      <SortMenuButton type="button" onClick={() => setSort("ascend", "name")}>
        Ascending: A to Z
      </SortMenuButton>
    </Menu.Item>
    <Menu.Item>
      <SortMenuButton type="button" onClick={() => setSort("descend", "name")}>
        Descending: Z to A
      </SortMenuButton>
    </Menu.Item>
    <Menu.Item>
      <SortMenuButton
        type="button"
        onClick={() => setSort("descend", "createdAt")}
      >
        Newest First
      </SortMenuButton>
    </Menu.Item>
    <Menu.Item>
      <SortMenuButton
        type="button"
        onClick={() => setSort("ascend", "createdAt")}
      >
        Oldest First
      </SortMenuButton>
    </Menu.Item>
    <hr />
    <Menu.Item>
      <SortMenuButton type="button" onClick={clearSelection} $isCancel>
        Clear Selection
      </SortMenuButton>
    </Menu.Item>
  </Menu>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const {
    data: surveyList,
    isFetching: isFetchingSurveys,
    isError,
  } = useGetSurveysQuery({});
  const [addNewSurvey, { isLoading: isAddingNew }] = useAddNewSurveyMutation();

  const onFromScratchClick = async () => {
    const surveyBody = { name: "New Survey" };
    const { survey: newSurvey } = await addNewSurvey(surveyBody).unwrap();
    navigate(`/${newSurvey.surveyId}/design/questions`);
  };

  const [isAddingNewSurvey2, setIsAddingNewSurvey2] = useState(false);

  const { user } = useAppSelector((state) => state.auth);
  // const {
  //   data: surveys = [],
  //   isLoading,
  //   isSuccess,
  //   isError,
  //   error,
  // } = useGetSurveysQuery();

  // const sortedSurveys = useMemo(() => {
  //   const sortedSurvs = surveys.slice();
  //   // Sort posts in descending chronological order
  //   sortedSurvs.sort((a, b) => b.date.localeCompare(a.date));
  //   return sortedSurvs;
  // }, [surveys]);

  // Intro Js Config

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [firstname, setFirstname] = useState("");
  const [totalSurveysCount, setTotalSurveysCount] = useState(0);
  const [draftSurveysCount, setDraftSurveysCount] = useState(0);
  const [openSurveysCount, setOpenSurveysCount] = useState(0);
  const [completedSurveysCount, setCompletedSurveysCount] = useState(0);
  const [sortedInfo, setSortedInfo] = useState<{
    columnKey?: any;
    order?: any;
  }>({});
  const [stepsEnabled, setStepsEnabled] = useState(false);
  const [initialStep, setInitialStep] = useState(0);

  const steps = [
    {
      element: ".collapse",
      intro: "Expand pane to see Dashboard menu",
      position: "right",
    },
    {
      element: "[data-action]",
      intro: "Select survey design option to get started",
      position: "top",
    },
    {
      element: ".surveys",
      intro: "My Surveys: View all your created surveys",
      position: "right",
    },
    {
      element: ".templates",
      intro: "Templates: Browse, preview, and template catalog.",
      position: "right",
    },
    {
      element: ".contacts",
      intro: "Contact: Manage your contacts and groups",
      position: "right",
    },
    {
      element: ".library",
      intro: "Library: Manage your digital assets",
      position: "right",
    },
  ];

  const onChooseTemplate = () => {
    navigate("/templates");
  };

  const shareSurvey = (survId) => {
    navigate(`/${survId}/collect`);
  };

  const viewSurvey = (survId) => {
    navigate(`/${survId}/preview`);
  };

  const editSurvey = (survId) => {
    navigate(`/${survId}/design/questions`);
  };

  const handleCopy = async (surveyUrl) => {
    // await copyLink(surveyUrl);
    // setCopied(true);
    // setTimeout(() => {
    //   setCopied(false);
    // }, 1500);
  };

  const sortableSurvey = useCallback(() => {
    if (surveyList) {
      const sorted: SurveyStatusItem = {};
      lodashForEach(surveyList, (item: { status }) => {
        sorted[item.status] = sorted[item.status] || [];
        sorted[item.status].push(item);
      });

      setDraftSurveysCount(sorted.DRAFT?.length || 0);
      setCompletedSurveysCount(sorted.COMPLETED?.length || 0);
      setOpenSurveysCount(sorted.OPEN?.length || 0);
      setTotalSurveysCount(surveyList.length);
    }
  }, [surveyList]);

  useEffect(() => {
    sortableSurvey();
  }, [sortableSurvey]);

  useEffect(() => {
    const myFirstname = user?.userCredentials?.firstName;
    setFirstname(myFirstname);
  }, [user?.userCredentials?.firstName]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
  };

  const clearSelection = () => {
    setSortedInfo({});
  };

  const setSort = (order, columnKey) => {
    setSortedInfo({ order, columnKey });
  };

  const onAddNew = (isNew) => {
    setIsAddingNewSurvey2(isNew);
  };

  const locale = {
    emptyText: <EmptyTable onAddNew={onAddNew} />,
  };

  const COLUMNS = [
    {
      title: "Name",
      Footer: "NAME",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      },
      sortOrder: sortedInfo.columnKey === "name" && sortedInfo.order,
      ellipsis: true,
      render: (value) => <div>{parse(value)}</div>,
    },
    {
      title: "Status",
      Footer: "STATUS",
      dataIndex: "status",
      render: (value) => <span>{value}</span>,
      key: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Responses",
      Footer: "RESPONSES",
      dataIndex: "totalResponses",
      key: "responses",
      sorter: (a, b) => a.responses - b.responses,
      sortOrder: sortedInfo.columnKey === "responses" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Date",
      Footer: "CREATED",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        if (value) {
          // return format(new Date(parseISO(value)), "MMMM d, yyyy");
          return dayjs(value).format("MMM D, YYYY");
        }
        return "--";
      },

      sorter: (a, b) =>
        (new Date(a.createdAt) as any) - (new Date(b.createdAt) as any),
      sortOrder: sortedInfo.columnKey === "createdAt" && sortedInfo.order,
      ellipsis: true,
      width: 150,
    },
    {
      title: "Actions",
      dataIndex: "surveyId",
      key: "surveyId",
      render: (value) => (
        <>
          {/*{copied && <Alert message="Link copied!" />}*/}
          <ActionContainer>
            <Dropdown
              overlay={
                <TableAction
                  value={value}
                  editSurvey={editSurvey}
                  viewSurvey={viewSurvey}
                />
              }
            >
              <SortButton type="button">
                <Ellipsis />
              </SortButton>
            </Dropdown>
            <LinkBtn
              type="button"
              onClick={() =>
                handleCopy(`${window.location.host}/answer/${value}`)
              }
            >
              <LinkIcon />
            </LinkBtn>
            <ShareBtn onClick={shareSurvey} type="button">
              Share
            </ShareBtn>
          </ActionContainer>
        </>
      ),
    },
  ];

  return (
    <Wrapper>
      <Loader
        isLoading={
          isAddingNew || isAddingNewSurvey2 || (isFetchingSurveys && !isError)
        }
      />
      <Steps
        enabled={stepsEnabled}
        steps={steps}
        onExit={() => setStepsEnabled(false)}
        initialStep={initialStep}
        options={{
          // showStepNumbers: true,
          exitOnEsc: true,
          disableInteraction: true,
          // dontShowAgain: true,
          scrollToElement: true,
          autoPosition: true,
        }}
        // ref={(steps) => (this.steps = steps)}
      />
      {/*<NavbarDark />*/}
      <div>
        <Container>
          <OnboardingScreen setNextIntro={setStepsEnabled} />
          <h1>
            Welcome back, <span>{firstname}!</span>
          </h1>
          <p>Here’s an overview of your account so far</p>
          <PageActions data-action>
            <button
              onClick={onFromScratchClick}
              onKeyDown={onFromScratchClick}
              role="button"
              tabIndex={0}
            >
              <ActionFlex $iconType="SUCCESS">
                <span>
                  <PencilIcon />
                </span>
                <p>Create a survey from scratch</p>
              </ActionFlex>
              <span>
                <ArrowRight />
              </span>
            </button>
            <button
              onClick={onChooseTemplate}
              onKeyDown={onChooseTemplate}
              role="button"
              tabIndex={0}
            >
              <ActionFlex $iconType="PRIMARY">
                <span>
                  <DocAlt />
                </span>
                <p>Choose from a template</p>
              </ActionFlex>
              <span>
                <ArrowRight />
              </span>
            </button>
            <button
              onClick={showModal}
              onKeyDown={showModal}
              role="button"
              tabIndex={0}
            >
              <ActionFlex $iconType="INFO">
                <span>
                  <CopyIcon />
                </span>
                <p>Copy a previous survey</p>
              </ActionFlex>
              <span>
                <ArrowRight />
              </span>
            </button>
          </PageActions>
          <GridContainer>
            <StatsGridContainer>
              <h1>My stats</h1>
              <StatContainer>
                <StatCard>
                  <FolderIcon />
                  <h3>{totalSurveysCount}</h3>
                  <p>
                    Total responses <ArrowRightSm />
                  </p>
                </StatCard>
                <StatCard>
                  <EditSquare />
                  <h3>{draftSurveysCount}</h3>
                  <p>
                    Draft <ArrowRightSm />
                  </p>
                </StatCard>
                <StatCard>
                  <FileIcon />
                  <h3>{openSurveysCount}</h3>
                  <p>
                    Open <ArrowRightSm />
                  </p>
                </StatCard>
                <StatCard>
                  <Histogram />
                  <h3>{completedSurveysCount}</h3>
                  <p>
                    Completed <ArrowRightSm />
                  </p>
                </StatCard>
              </StatContainer>
              <TableContainer>
                <div>
                  <h2>Recent surveys</h2>
                  <Dropdown
                    overlay={
                      <SortMenu
                        setSort={setSort}
                        clearSelection={clearSelection}
                      />
                    }
                  >
                    <button type="button" onClick={(e) => e.preventDefault()}>
                      Sort by:
                    </button>
                  </Dropdown>
                  <Link to="/user/my-surveys">See all</Link>
                </div>
                <Table
                  locale={locale}
                  columns={COLUMNS}
                  dataSource={surveyList}
                  pagination={{ position: ["bottomCenter"] }}
                  onChange={handleTableChange}
                  scroll={{ x: 850, y: 700 }}
                />
              </TableContainer>
            </StatsGridContainer>
            <BlogGridContainer>
              <PostContainer>
                <div>
                  <PostTag>How-to</PostTag>
                  <PostBody
                    target="_blank"
                    href="https://surveylake.ghost.io/survey-101/"
                  >
                    <h1>
                      Survey 101: How to Create a Survey that Collects Better
                      Data
                    </h1>
                    <PostContent>
                      Taking the time and investing in your survey design
                      process is crucial to obtaining quality responses. Here
                      are some helpful tips for creating an effective survey.
                    </PostContent>
                    <PostFooter>
                      {/*<img src={"Author"} alt="Author" />*/}
                      <AuthorInfo>
                        <h6>Taofeek Shittu</h6>
                        <PostDate>Aug 12, 2022 · 4 min read</PostDate>
                      </AuthorInfo>
                    </PostFooter>
                  </PostBody>
                </div>

                <div>
                  <PostTag>How-to</PostTag>
                  <PostBody
                    target="_blank"
                    href="https://surveylake.ghost.io/survey-101/"
                  >
                    <h1>
                      Survey 101: How to Create a Survey that Collects Better
                      Data
                    </h1>
                    <PostContent>
                      Taking the time and investing in your survey design
                      process is crucial to obtaining quality responses. Here
                      are some helpful tips for creating an effective survey.
                    </PostContent>
                    <PostFooter>
                      {/*<img src={"Author"} alt="Author" />*/}
                      <AuthorInfo>
                        <h6>Taofeek Shittu</h6>
                        <PostDate>Aug 12, 2022 · 4 min read</PostDate>
                      </AuthorInfo>
                    </PostFooter>
                  </PostBody>
                </div>

                <div>
                  <PostTag>Case Study</PostTag>
                  <PostBody
                    target="_blank"
                    href="https://surveylake.ghost.io/survey-sample-size-2/"
                  >
                    <h1>Survey Sample Size</h1>
                    <PostContent>
                      Your sample size is the number of individuals you’d need
                      for your survey, and feedback helps form your research
                      results. The sample size is crucial for getting accurate,
                      statistically significant results and running your
                      research successfully.
                    </PostContent>
                  </PostBody>
                </div>
              </PostContainer>
            </BlogGridContainer>
          </GridContainer>
        </Container>
        <Modal
          title="Surveys"
          visible={isModalVisible}
          footer={null}
          onCancel={handleCancel}
        >
          {/*<PreviousSurveyList surveyList={surveyList} />*/}
        </Modal>
      </div>
    </Wrapper>
  );
};

export default Dashboard;
