import React, { useEffect, useState } from "react";
import { Content, Wrapper, IconBox } from "./style";
import { ReactComponent as InfoIcon } from "./assets/info.svg";
import parse from "html-react-parser";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import {
  setConfigurationType,
  setSelectedQuestion,
  setSelectedSection,
} from "../../../../../pages/Design/designSlice";

const PageTitle = ({
  sectionId,
  title = "<p>Page title</p>",
  description = "<p>Page title and descriptions are optional. Toggle their visibility as needed.</p>",
}: {
  sectionId: string;
  title?: string;
  description?: string;
}) => {
  const dispatch = useAppDispatch();
  const { selectedSection } = useAppSelector((state) => state.design);
  const [selectedSectionId, setSelectedSectionId] = useState(null);

  useEffect(() => {
    setSelectedSectionId(selectedSection?.sectionId);
  }, [selectedSection]);
  const onClick = () => {
    dispatch(setSelectedSection({ sectionId, title, description }));
    dispatch(setSelectedQuestion(null));
    dispatch(setConfigurationType("PAGE"));
  };

  return (
    <Wrapper onClick={onClick} $isSelected={selectedSectionId === sectionId}>
      <IconBox>
        <InfoIcon />
      </IconBox>
      <Content>
        <div>{parse(title || "")}</div>
        <div>{parse(description || "")}</div>
      </Content>
    </Wrapper>
  );
};

export default PageTitle;
