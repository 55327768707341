import React, { ReactNode } from "react";
import { Select } from "antd";
import styles from "./Dropdown.module.css";
import { ReactComponent as Arrow } from "./icons/down-arrow.svg";

const Dropdown = ({
  options = null,
  value = null,
  dropdownStyle = null,
  onChange,
  children,
}: {
  options: { value: string; label: string }[];
  value: string | null;
  dropdownStyle?: { [prop: string]: string | number };
  onChange: () => void;
  children: ReactNode;
}) => (
  <Select
    className={styles.select}
    options={options}
    suffixIcon={<Arrow />}
    dropdownMatchSelectWidth={!dropdownStyle}
    dropdownStyle={dropdownStyle}
    value={value}
    onChange={onChange}
  >
    {children}
  </Select>
);

export default Dropdown;
