import styled, { css } from "styled-components";

export const ButtonWrapper = styled.button`
  width: ${(props) => props.$width || "100%"};
  font-size: ${(props) => props.$fontSize || "16px"};
  height: ${(props) => props.$height || "56px"};
  border: unset;
  border-radius: 8px;
  font-weight: 600;
  line-height: 24px;
  cursor: pointer;
  ${(props: {
    $theme: "primary" | "secondary" | "alternate";
    $width: string;
    $fontSize?: string;
    $height?: string;
  }) => {
    if (props.$theme === "primary") {
      return css`
        background: var(--primary-red-color);
        color: #ffffff;
        &:disabled {
          background-color: #f1f1f1;
          color: #717070;
        }
      `;
    } else if (props.$theme === "secondary") {
      return css`
        background: #000000;
        color: #ffffff;
      `;
    } else if (props.$theme === "alternate") {
      return css`
        //background: transparent;
        //border: 1px solid #000000;
        //color: #000000;

        border: 1px solid var(--primary-red-color);
        padding: 5px 10px;
        background-color: #ffffff;
        color: var(--primary-red-color);
        cursor: pointer;
        text-align: center;
      `;
    }
  }}
`;
