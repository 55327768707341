import styled from "styled-components";

export const Wrapper = styled.div`
  width: 85%;
  position: relative;
  & > svg {
    left: 45%;
    margin-bottom: -10px;
  }
`;

export const SliderBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SliderComponent = styled.div`
  width: 100%;
  height: 10px;
  background-color: #c4c4c4;
  border-radius: 10px;
`;

export const SliderLabels = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & p {
    font-size: 14px;
    font-weight: 400;
  }
`;
