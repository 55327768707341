import React, { useContext } from "react";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import {
  DateAnswered,
  HeaderText,
  ItemDiv,
  ItemHead,
  UserLink,
  ValueText,
  Wrapper,
} from "./style";
import { ChartContext } from "../../../context/ChartContext";
import type { TextChartData } from "../../../context/types";

dayjs.extend(relativeTime);

export default function TextList({ theRef }: { theRef: any }) {
  // export default function TextList({ theRef, questionId }) {
  const {
    headerText,
    headerTextAlignment,
    resultData: chartData,
  } = useContext(ChartContext);

  return (
    <Wrapper ref={theRef}>
      {/*<Wrapper ref={theRef} data-question-id={questionId}>*/}
      <HeaderText $align={headerTextAlignment}>{headerText}</HeaderText>
      {chartData.map((item: TextChartData) => (
        <ItemDiv key={item.index}>
          <ItemHead>
            <UserLink to={`/individual/${item.responseId}`}>
              View respondent
            </UserLink>
            <DateAnswered>{dayjs(item.createdAt).fromNow()}</DateAnswered>
          </ItemHead>
          <ValueText>{item.answer}</ValueText>
        </ItemDiv>
      ))}
    </Wrapper>
  );
}
