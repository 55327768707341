import React, { useContext, useEffect, useState } from "react";
import { Upload } from "antd";
import { S3Client, PutObjectCommand } from "@aws-sdk/client-s3";
import { v4 as uuidv4 } from "uuid";
import {
  Header,
  HeaderRight,
  ImageList,
  MoreButton,
  Title,
  UploadButton,
  Wrapper,
} from "./style";
import { ReactComponent as UploadIcon } from "../../assets/upload-cloud.svg";
import { ReactComponent as EllipsisIcon } from "../../../../assets/icons/ellipsis.svg";
import ItemBox from "../ItemBox";
import { useAppSelector } from "../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import {
  useAddNewLogoMutation,
  useGetUserLibraryQuery,
} from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";
import { RcFile } from "antd/es/upload";

const Logos = () => {
  const [activeLogos, setActiveLogos] = useState([]);
  const { user } = useAppSelector((state) => state.auth);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const client = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    },
  });
  const { data: library, isLoading } = useGetUserLibraryQuery({});
  const [addNewLogo, { isLoading: isAddingNew }] = useAddNewLogoMutation();

  useEffect(() => {
    if (library && library?.logos) {
      const logosCopy = [...library.logos];
      const filteredActiveLogos = logosCopy.filter(
        (log) => log.status === "ACTIVE"
      );
      setActiveLogos(filteredActiveLogos);
    }
  }, [library]);

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      onShowErrorAlert({}, "Image must smaller than 2MB!");
    }
    return isLt2M;
  };

  const onUploadDocument = async (file) => {
    const fileName = file.name;
    const photoKey =
      "library/" + user?.userCredentials?.userId + "/" + fileName;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: photoKey,
      Body: file,
    };
    try {
      const data = await client.send(new PutObjectCommand(uploadParams));
      await addNewLogo({
        logoId: uuidv4(),
        logoUrl: photoKey,
      }).unwrap();
      return data;
      onSuccessShowAlert("Logo added");
    } catch (error) {
      onShowErrorAlert(error, "Failed to add logo");
    }
  };

  return (
    <Wrapper>
      <Loader isLoading={isAddingNew || isLoading} />
      <Header>
        <Title>Logos</Title>
        <HeaderRight>
          <Upload
            accept="image/*"
            customRequest={({ file }) => onUploadDocument(file)}
            beforeUpload={beforeUpload}
          >
            <UploadButton type="button">
              <UploadIcon />
              <span>Import Logo</span>
            </UploadButton>
          </Upload>
          <MoreButton>
            <EllipsisIcon />
          </MoreButton>
        </HeaderRight>
      </Header>
      <ImageList>
        {activeLogos.map((log) => (
          <ItemBox
            key={log.id}
            id={log.id}
            itemType="logo"
            content={`${process.env.REACT_APP_ASSETS_URL}${log.url}`}
          ></ItemBox>
        ))}
      </ImageList>
    </Wrapper>
  );
};

export default Logos;
