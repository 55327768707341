import React from "react";
import { Wrapper, EmptyWrapperBody, EmptyWrapperTitle } from "./style";
import { ReactComponent as EmptyImage } from "../../../../assets/icons/no-data.svg";

const NoFilterData = () => {
  return (
    <Wrapper>
      <EmptyWrapperBody>
        <EmptyImage />
        <EmptyWrapperTitle>
          No data to display for this filter
        </EmptyWrapperTitle>
      </EmptyWrapperBody>
    </Wrapper>
  );
};

export default NoFilterData;
