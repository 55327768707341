import React, { useContext, useEffect, useState } from "react";
import { Select } from "antd";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  SectionBox,
  SectionLabel,
  ToBox,
  Wrapper,
  SubjectBox,
  FromBox,
  MailToSelect,
  SendButton,
  Footer,
  InputSubText,
  InputLabel,
  InputBox,
  InputWrapper,
  PreviewButton,
  Right,
  Left,
} from "./style";
import { ReactComponent as DownArrow } from "../../../../../assets/icons/down-arrow.svg";
import { NetworkErrorAlertContext } from "../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import EmailMessageEditor from "../EmailMessageEditor";
import {
  useGetContactGroupQuery,
  useGetMyGroupContactsByMutatingMutation,
  useSendEmailCollectorMutation,
} from "../../../../../app/apiSlice";
import ErrorMessage from "../../../../common/ErrorMessage";
import {
  contactObjToObj,
  contactObjToSubstitutionObj,
  messageIncludesSurveyLink,
} from "./utils";
import { useAppSelector } from "../../../../../app/hooks";
import Loader from "../../../../common/Loader";

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    tinymce: any;
  }
}

type Inputs = {
  subject: string;
  mailBody: string;
  fromAddress: string;
  fromName: string;
  replyToEmail: string;
  contactGroup: string;
};

const Mailbox = ({
  surveyLink,
  collectorId,
  onClose,
}: {
  surveyLink: string;
  collectorId: string;
  onClose: () => void;
}) => {
  const { user } = useAppSelector((state) => state.auth);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      subject: "",
      fromName: user?.userCredentials?.firstName || "SurveyLake",
      replyToEmail: user?.userCredentials?.email,
      fromAddress: user?.userCredentials?.email,
    },
  });
  const [groupOptions, setGroupOptions] = useState([]);
  const [surveyLinkButton, setSurveyLinkButton] = useState("");
  const { data: groupList, isFetching } = useGetContactGroupQuery({});

  const [sendEmail, { isLoading: isSending }] = useSendEmailCollectorMutation();
  const [getContact, { isLoading: isGettingContacts }] =
    useGetMyGroupContactsByMutatingMutation();

  useEffect(() => {
    if (surveyLink) {
      setSurveyLinkButton(`
        <a style="
            padding: 10px 20px;
            font-size: 18px; 
            color: #FFFFFF; 
            background-color: #000000; 
            text-decoration: none;
          " 
          target="_blank" 
          href=${surveyLink}
        >Begin survey</a>
      `);
    }
  }, [surveyLink]);

  useEffect(() => {
    if (groupList && groupList.length) {
      setGroupOptions(
        groupList.map((gr) => ({
          value: gr.contactsId,
          label: `${gr.name} (${gr?.itemsCount ?? "--"})`,
        }))
      );
    }
  }, [groupList]);

  const onOpenCodeEditor = () => {
    window.tinymce.activeEditor.execCommand("mceCodeEditor");
  };

  const onPreview = () => {
    window.tinymce.activeEditor.execCommand("mcePreview");
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { subject, mailBody, fromName, replyToEmail, contactGroup } = data;

    try {
      const contactListData = await getContact(contactGroup);

      // @ts-ignore
      const toAddresses = contactListData?.data?.items;

      let formatMessageBody = mailBody.replaceAll(
        "[FirstName]",
        "{{first_name}}"
      );
      formatMessageBody = formatMessageBody.replaceAll(
        "[LastName]",
        "{{last_name}}"
      );
      formatMessageBody = formatMessageBody.replaceAll("[Email]", "{{email}}");

      const personalizationData = toAddresses.map((sC) => ({
        // to: [contactObjToObj(sC)],
        to: sC.email,
        // subject,
        substitutions: contactObjToSubstitutionObj(sC),
      }));
      await sendEmail({
        collectorId,
        emailObj: {
          personalizations: personalizationData,
          fromName,
          fromEmail: data.fromAddress || "",
          replyToEmail,
          subject,
          html: formatMessageBody,
          toAddresses,
        },
      }).unwrap();
      onSuccessShowAlert("Emails sent");
      onClose();
    } catch (e) {
      onShowErrorAlert(e, "Error sending email");
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={isFetching || isSending || isGettingContacts} />
      <SectionBox>
        <SectionLabel>To</SectionLabel>
        <ToBox>
          <Controller
            name="contactGroup"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <MailToSelect
                options={groupOptions}
                {...field}
                suffixIcon={<DownArrow />}
              />
            )}
          />
        </ToBox>
      </SectionBox>
      <SectionBox>
        <SectionLabel>From</SectionLabel>
        <FromBox>
          <InputWrapper>
            <InputLabel>From Address</InputLabel>
            <Controller
              name="fromAddress"
              control={control}
              render={({ field }) => <InputBox {...field} />}
            />
            <InputSubText>
              We’ll use this address if we need to contact you about your
              account.
            </InputSubText>
          </InputWrapper>
          <InputWrapper>
            <InputLabel>From Name</InputLabel>
            <Controller
              name="fromName"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <InputBox {...field} />}
            />
          </InputWrapper>
          <InputWrapper>
            <InputLabel>Reply-to Email</InputLabel>
            <Controller
              name="replyToEmail"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <InputBox {...field} />}
            />
            <InputSubText>
              Your recipients can respond to this email address.
            </InputSubText>
          </InputWrapper>
        </FromBox>
      </SectionBox>
      <SectionBox>
        <SectionLabel>Subject</SectionLabel>
        <SubjectBox>
          <Controller
            name="subject"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => <InputBox {...field} />}
          />

          <Controller
            name="mailBody"
            control={control}
            rules={{
              required: true,
              validate: {
                checkSurveyLink: (value) =>
                  messageIncludesSurveyLink({
                    value,
                    link: surveyLink,
                  }),
              },
            }}
            render={({ field }) => (
              <EmailMessageEditor
                surveyLinkButton={surveyLinkButton}
                field={field}
              />
            )}
          />
          {errors.subject?.type === "required" && (
            <ErrorMessage message="Subject is required" />
          )}
          {errors.mailBody?.type === "required" && (
            <ErrorMessage message="Message is required" />
          )}
          {errors.mailBody?.type === "checkSurveyLink" && (
            <ErrorMessage message="Add survey link. You can add it by cilcking on the 'Insert survey link' button" />
          )}

          {errors.fromName?.type === "required" && (
            <ErrorMessage message="From name is required" />
          )}
          {errors.replyToEmail?.type === "required" && (
            <ErrorMessage message="Reply to email is required" />
          )}
          {errors.contactGroup?.type === "required" && (
            <ErrorMessage message="Contact group is required" />
          )}
        </SubjectBox>
      </SectionBox>
      <Footer>
        <Left>
          <PreviewButton type="button" onClick={onOpenCodeEditor}>
            Custom HTML
          </PreviewButton>
        </Left>
        <Right>
          <PreviewButton type="button" onClick={onPreview}>
            Preview
          </PreviewButton>
          <SendButton type="submit">Send</SendButton>
        </Right>
      </Footer>
    </Wrapper>
  );
};

export default Mailbox;
