import React, { useEffect, useState } from "react";
import { Input, Modal, Table } from "antd";
import dayjs from "dayjs";
import {
  Buttons,
  Content,
  CustomNav,
  Header,
  Left,
  TableActions,
  TableWrapper,
  Wrapper,
} from "./style";
import { ReactComponent as CreateIcon } from "../../assets/plus.svg";
import { ReactComponent as SearchIcon } from "../../assets/search.svg";
import CreateGroup from "../CreateGroup";
import CustomSelect from "../../../../components/common/CustomSelect";
import ImportRecipient from "../ImportRecipient";
import { useGetContactGroupQuery } from "../../../../app/apiSlice";
import Loader from "../../../../components/common/Loader";
import { keyToCategoryNameMap } from "../../utils";
import { useAppDispatch } from "../../../../app/hooks";
import { setSelectedGroup } from "../../../../pages/Contacts/contactSlice";
import OnboardingScreen from "../IntroScreen";

type TableData = {
  key: string;
  contactsId: string;
  category: string;
  name: string;
  members?: number;
  createdAt: string;
};

const GroupList = () => {
  const dispatch = useAppDispatch();
  const [showIntro, setShowIntro] = useState(true);

  const { data: groupList, isFetching } = useGetContactGroupQuery({});
  const [isGroupModalVisible, setIsGroupModalVisible] = useState(false);
  const [isRecipientModalVisible, setIsRecipientModalVisible] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    if (groupList && groupList.length) {
      const formattedData = groupList.map((col) => {
        return {
          ...col,
          key: col.contactsId,
          name: col.name,
          category: col.category,
          members: col?.itemsCount ?? "--",
          createdAt: col.createdAt || "--",
        };
      });
      setTableData(formattedData);
    } else {
      setTableData([]);
    }
  }, [groupList]);

  const showGroupModal = () => {
    setIsGroupModalVisible(true);
  };

  const handleGroupCancel = () => {
    setIsGroupModalVisible(false);
  };

  const showRecipientModal = () => {
    setIsRecipientModalVisible(true);
  };

  const handleRecipientCancel = () => {
    setIsRecipientModalVisible(false);
  };

  const onSaveExitGroup = () => {
    handleGroupCancel();
  };

  const onContinueGroup = (newGroupId) => {
    handleGroupCancel();
    showRecipientModal();
    setGroupId(newGroupId);
  };

  const onGroupNameSelect = (name: string) => {
    dispatch(setSelectedGroup(name));
  };

  const columns = [
    {
      title: "List name",
      key: "name",
      render: (record: TableData) => (
        <CustomNav
          to={`/contacts/users/${record.contactsId}`}
          onClick={() => onGroupNameSelect(record.name)}
        >
          {record.name}
        </CustomNav>
      ),
    },
    {
      title: "Type",
      key: "category",
      dataIndex: "category",
      render: (text) => <p>{keyToCategoryNameMap[text]}</p>,
    },
    {
      title: "Members",
      key: "members",
      dataIndex: "members",
    },
    {
      title: "Last updated",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => <p>{text ? dayjs(text).format("DD-MM-YYYY") : "--"}</p>,
    },
  ];

  return (
    <Wrapper>
      <Loader isLoading={isFetching} />
      <OnboardingScreen visible={showIntro} setVisible={setShowIntro} />
      <Header>
        <Left>
          <p>Contact groups</p>
          <p>
            Contact lists are mailing lists used to distribute surveys. Create
            new lists, and manage your existing lists here.
          </p>
        </Left>
        <Buttons>
          <button type="button" onClick={showGroupModal}>
            <CreateIcon />
            <span>Create Group</span>
          </button>
          {/*<button type="button">*/}
          {/*  <ImportIcon />*/}
          {/*  <span>Import</span>*/}
          {/*</button>*/}
        </Buttons>
      </Header>
      <Content>
        <TableActions>
          <CustomSelect
            $width="200px"
            options={[
              { value: "any", label: "Created by anyone" },
              { value: "me", label: "Created by me" },
              { value: "others", label: "Not created by me" },
            ]}
          />
          <Input placeholder="Search" prefix={<SearchIcon />} />
        </TableActions>
        <TableWrapper>
          <Table
            columns={columns}
            bordered
            dataSource={tableData}
            pagination={false}
          />
        </TableWrapper>
      </Content>

      {isGroupModalVisible && (
        <Modal
          title=""
          width={600}
          visible={isGroupModalVisible}
          footer={null}
          onCancel={handleGroupCancel}
        >
          <CreateGroup
            onCancel={handleGroupCancel}
            onSaveExitGroup={onSaveExitGroup}
            onSubmit={onContinueGroup}
          />
        </Modal>
      )}
      {isRecipientModalVisible && (
        <Modal
          title=""
          width={600}
          visible={isRecipientModalVisible}
          footer={null}
          onCancel={handleRecipientCancel}
        >
          <ImportRecipient
            onSave={handleRecipientCancel}
            onCancel={handleRecipientCancel}
            groupId={groupId}
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default GroupList;
