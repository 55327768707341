import React from "react";
import { Wrapper } from "./style";
import ConfigurationForm from "./components/ConfigurationForm";
import ConfigurationProvider from "./contexts/Configuration";
import { useAppSelector } from "../../../../app/hooks";

const Configuration = () => {
  const { selectedQuestion, configType } = useAppSelector(
    (state) => state.design
  );

  let formType = null;
  if (configType === "PAGE") {
    formType = "page";
  } else if (configType === "QUESTION") {
    formType = selectedQuestion?.type;
  }

  return (
    <ConfigurationProvider>
      <Wrapper data-config="editing">
        <ConfigurationForm formType={formType} />
      </Wrapper>
    </ConfigurationProvider>
  );
};

export default Configuration;
