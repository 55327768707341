import styled from "styled-components";
import { Cascader } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  & p {
    margin-bottom: 0;
  }
`;

export const SelectAnswerType = styled(Cascader)`
  background: #f4f5f7;
  border-radius: 3px;
  width: 200px;
  & div[class~="ant-select-selector"] {
    height: 32px;
  }
  & span[class~="ant-select-selection-item"] {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI";
  }
`;
