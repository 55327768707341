import React from "react";
import {
  ComponentTitle,
  DateBox,
  DateBoxInput,
  DateComponent,
  DatePlaceHolder,
  OptionComponents,
  OptionRow,
  OptionRowTitle,
  TimeBox,
  TimeBoxPlaceHolder,
  TimeComponents,
  TimeDiv,
  TimeDivBoxes,
  Wrapper,
} from "./style";

const DateTime = ({ questionObj }: { questionObj: any }) => {
  const { hasDateInfo, hasTimeInfo, dateRowOptions, dateFormat } =
    questionObj.dateOptions;
  const options = dateRowOptions || questionObj.rowOptions;
  return (
    <Wrapper>
      {options.map((val) => {
        return (
          <OptionRow key={val.index}>
            <OptionRowTitle>{val.value}</OptionRowTitle>
            <OptionComponents>
              {hasDateInfo ? (
                <DateComponent>
                  <ComponentTitle>Date</ComponentTitle>
                  <DateBox>
                    <DateBoxInput>
                      <DatePlaceHolder>
                        {dateFormat === "MDY" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                      </DatePlaceHolder>
                    </DateBoxInput>
                  </DateBox>
                </DateComponent>
              ) : null}
              {hasTimeInfo ? (
                <TimeComponents>
                  <TimeDiv>
                    <ComponentTitle>Time</ComponentTitle>
                    <TimeDivBoxes>
                      <TimeBox>
                        <TimeBoxPlaceHolder>hh</TimeBoxPlaceHolder>
                      </TimeBox>
                      <TimeBox>
                        <TimeBoxPlaceHolder>mm</TimeBoxPlaceHolder>
                      </TimeBox>
                    </TimeDivBoxes>
                  </TimeDiv>
                </TimeComponents>
              ) : null}
            </OptionComponents>
          </OptionRow>
        );
      })}
    </Wrapper>
  );
};

export default DateTime;
