export const keyToCategoryNameMap = {
  market_product: "Market/ Product",
  hr: "Human resources",
  customer: "Customer",
  others: "Others",
};

export const csvImportConfig = {
  headers: [
    {
      name: "First name",
      inputName: "firstName",
      required: true,
      requiredError: function (headerName, rowNumber, columnNumber) {
        return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
    {
      name: "Last name",
      inputName: "lastName",
      required: false,
    },
    {
      name: "Email",
      inputName: "email",
      unique: true,
      uniqueError: function (headerName) {
        return `${headerName} is not unique`;
      },
      validateError: function (headerName, rowNumber, columnNumber) {
        return `${headerName} is not valid in the ${rowNumber} row / ${columnNumber} column`;
      },
    },
  ],
};

export const downloadTemplate = () => {
  const csv = "First name,Last name,Email";

  const exportedFilename = "template.csv";

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  // if (navigator.msSaveBlob) {
  //   // IE 10+
  //   navigator.msSaveBlob(blob, exportedFilename);
  // } else {
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", exportedFilename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  // }
};
