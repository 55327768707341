import React from "react";
import { AlertBox, Wrapper } from "./style";
import { ReactComponent as CloseIcon } from "./close.svg";

const AlertComponent = ({ message }: { message: string }) => {
  return (
    <Wrapper>
      <AlertBox>
        <CloseIcon />
        <span>{message}</span>
      </AlertBox>
    </Wrapper>
  );
};
const NetworkErrorAlert = ({ message }: { message: string }) => {
  return <AlertComponent message={message} />;
};

export default NetworkErrorAlert;
