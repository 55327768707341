import React from "react";
import { ReactComponent as BlueSingleIcon } from "./icons/blue_single.svg";
import { ReactComponent as TealIcon } from "./icons/teal.svg";
import { ReactComponent as OrangeIcon } from "./icons/orange.svg";
import { ReactComponent as PurpleIcon } from "./icons/purple.svg";
import { ReactComponent as CyanIcon } from "./icons/cyan.svg";
import { ReactComponent as StringIcon } from "./icons/string.svg";
import { ReactComponent as GreenBlackIcon } from "./icons/green_black.svg";
import { ReactComponent as PurpleMultiIcon } from "./icons/purple_multi.svg";
import { ReactComponent as BlueGreenIcon } from "./icons/blue_green.svg";
import { ReactComponent as BlueMultiIcon } from "./icons/blue_multi.svg";
import { ReactComponent as CyanOrangeIcon } from "./icons/cyan_orange.svg";
import { ReactComponent as PurpleTealIcon } from "./icons/purple_teal.svg";
import { ReactComponent as RedBlueIcon } from "./icons/red_blue.svg";
import { ReactComponent as BluePinkIcon } from "./icons/blue_pink.svg";
import { ReactComponent as GreenRedIcon } from "./icons/green_red.svg";
import { ReactComponent as AccentIcon } from "./icons/accent.svg";
import { ReactComponent as AmberIcon } from "./icons/amber.svg";
import { ReactComponent as PairedIcon } from "./icons/paired.svg";
import { ReactComponent as IBMIcon } from "./icons/ibm.svg";
import { ReactComponent as SlTenIcon } from "./icons/sl_10.svg";
import { ReactComponent as SlYellowIcon } from "./icons/yellow.svg";
import { ReactComponent as SlGreenIcon } from "./icons/green.svg";
import { ReactComponent as SlBlueIcon } from "./icons/blue.svg";

export const individual = [
  { name: "green_ind", label: "Green", icon: <SlGreenIcon /> },
  {
    name: "yellow_ind",
    label: "Yellow",
    icon: <SlYellowIcon />,
  },
  {
    name: "blue_ind",
    label: "Blue",
    icon: <SlBlueIcon />,
  },
];

export const singleSequential = [
  { name: "blue_s", label: "Blue", icon: <BlueSingleIcon /> },
  { name: "teal", label: "Teal", icon: <TealIcon /> },
  { name: "orange", label: "Orange", icon: <OrangeIcon /> },
  { name: "purple_s", label: "Purple", icon: <PurpleIcon /> },
  { name: "cyan", label: "Cyan", icon: <CyanIcon /> },
];

export const multiSequential = [
  { name: "string", label: "String", icon: <StringIcon /> },
  { name: "green_black", label: "Green - Black", icon: <GreenBlackIcon /> },
  { name: "purple_m", label: "Purple", icon: <PurpleMultiIcon /> },
  { name: "blue_green", label: "Blue - Green", icon: <BlueGreenIcon /> },
  { name: "blue_m", label: "Blue", icon: <BlueMultiIcon /> },
];

export const diverging = [
  { name: "cyan_orange", label: "Cyan - Orange", icon: <CyanOrangeIcon /> },
  { name: "purple_teal", label: "Purple - Teal", icon: <PurpleTealIcon /> },
  { name: "red_blue", label: "Red - Blue", icon: <RedBlueIcon /> },
  { name: "blue_pink", label: "Blue - Pink", icon: <BluePinkIcon /> },
  { name: "green_red", label: "Green - Red", icon: <GreenRedIcon /> },
];

export const distinctive = [
  { name: "accent", label: "Accent", icon: <AccentIcon /> },
  { name: "amber", label: "Amber", icon: <AmberIcon /> },
  { name: "paired", label: "Paired", icon: <PairedIcon /> },
  { name: "ibm", label: "IBM Color Blind", icon: <IBMIcon /> },
  { name: "sl_10", label: "SL 10", icon: <SlTenIcon /> },
];

export const colorGroups = [
  {
    key: "single",
    typeName: "Green",
    group: "indv",
    colors: ["#CE375C"],
  },
  {
    key: "green_ind",
    typeName: "Green",
    group: "indv",
    colors: ["green"],
  },
  {
    key: "yellow_ind",
    typeName: "Yellow",
    group: "indv",
    colors: ["yellow"],
  },
  {
    key: "blue_ind",
    typeName: "Blue",
    group: "indv",
    colors: ["blue"],
  },
  {
    key: "blue_s",
    typeName: "Blue",
    group: "singleS",
    colors: [
      "#f7fbff",
      "#deebf7",
      "#c6dbef",
      "#9ecae1",
      "#6baed6",
      "#4292c6",
      "#2171b5",
      "#08519c",
      "#08306b",
    ],
  },
  {
    key: "teal",
    typeName: "Teal",
    group: "singleS",
    colors: [
      "#00e8c0",
      "#05c9ae",
      "#06ab9b",
      "#068f87",
      "#057370",
      "#03595a",
      "#024042",
    ],
  },
  {
    key: "purple_s",
    typeName: "Purple",
    group: "singleS",
    colors: [
      "#ffebff",
      "#f5c4f7",
      "#e1a0f1",
      "#c97cec",
      "#b251ef",
      "#942edc",
      "#6f1cb7",
      "#4a0297",
    ],
  },
  {
    key: "cyan",
    typeName: "Cyan",
    group: "singleS",
    colors: [
      "#e5f6ff",
      "#bae6ff",
      "#82cfff",
      "#33b1ff",
      "#1192e8",
      "#0072c3",
      "#00539a",
      "#003a6d",
      "#012749",
      "#1c0f30",
    ],
  },
  {
    key: "orange",
    typeName: "Orange",
    group: "singleS",
    colors: [
      "#FFF5EB",
      "#FEE8D3",
      "#FDD8B3",
      "#FDC28C",
      "#FDA762",
      "#FB8D3D",
      "#F2701D",
      "#E25609",
      "#C44103",
      "#9F3303",
      "#7F2704",
    ],
  },
  {
    key: "string",
    typeName: "String",
    group: "multiS",
    colors: [
      "#ffdad3",
      "#ffb4aa",
      "#ec938f",
      "#ca7a82",
      "#9c687b",
      "#6d576f",
      "#46445c",
      "#293042",
      "#161c25",
    ],
  },
  {
    key: "blue_green",
    typeName: "Blue - Green",
    group: "multiS",
    colors: [
      "#cddcf8",
      "#9abcdf",
      "#6d9eb2",
      "#49807b",
      "#30624c",
      "#1f432a",
      "#132415",
    ],
  },
  {
    key: "blue_m",
    typeName: "Blue",
    group: "multiS",
    colors: [
      "#cedbff",
      "#9fb7fc",
      "#7294f0",
      "#4d72d4",
      "#3553a9",
      "#273774",
      "#1b1e3c",
    ],
  },
  {
    key: "green_black",
    typeName: "Green - Black",
    group: "multiS",
    colors: [
      "#c9e2ce",
      "#9bc1ac",
      "#769f9c",
      "#597b93",
      "#415885",
      "#2c3965",
      "#1b1e39",
    ],
  },
  {
    key: "purple_m",
    typeName: "Purple",
    group: "multiS",
    colors: [
      "#f6f2ff",
      "#e8daff",
      "#d4bbff",
      "#be95ff",
      "#a56eff",
      "#8a3ffc",
      "#6929c4",
      "#491d8b",
    ],
  },
  {
    key: "cyan_orange",
    typeName: "Cyan - Orange",
    group: "diverging",
    colors: [
      "#025571",
      "#028dac",
      "#33cae6",
      "#f5f5f5",
      "#ffb976",
      "#fd841a",
      "#d86003",
    ],
  },
  {
    key: "blue_pink",
    typeName: "Blue - Pink",
    group: "diverging",
    colors: [
      "#263ee3",
      "#637dff",
      "#acbaff",
      "#f5f5f5",
      "#ffabdc",
      "#f965b1",
      "#d62779",
    ],
  },
  {
    key: "purple_teal",
    typeName: "Purple - Teal",
    group: "diverging",
    colors: [
      "#8c2cd3",
      "#c069f1",
      "#deb1ee",
      "#f5f5f5",
      "#06baa5",
      "#068f87",
      "#046665",
    ],
  },
  {
    key: "green_red",
    typeName: "Green - Red",
    group: "diverging",
    colors: [
      "#026400",
      "#039d01",
      "#3fd83a",
      "#f5f5f5",
      "#fe9da0",
      "#f9515b",
      "#cb0b15",
    ],
  },
  {
    key: "red_blue",
    typeName: "Red - Blue",
    group: "diverging",
    colors: [
      "#67001f",
      "#a61c2d",
      "#cf5349",
      "#ea9175",
      "#f9c6ad",
      "#f9e9df",
      "#e4edf2",
      "#b9d9e9",
      "#7cb6d6",
      "#418bbf",
      "#1f609f",
      "#053061",
    ],
  },
  {
    key: "accent",
    typeName: "Accent",
    group: "distinctive",
    colors: [
      "#003f5c",
      "#374c80",
      "#7a5195",
      "#bc5090",
      "#ef5675",
      "#ff764a",
      "#ffa600",
    ],
  },
  {
    key: "ibm",
    typeName: "IBM Color Blind",
    group: "distinctive",
    colors: [
      "#648fff",
      "#785ef0",
      "#dc267f",
      "#fe6100",
      "#ffb000",
      "#000000",
      "#40B0A6",
    ],
  },
  {
    key: "paired",
    typeName: "Paired",
    group: "distinctive",
    colors: [
      "#ff8b4f",
      "#7d5e90",
      "#d25f90",
      "#f9be00",
      "#00bf6f",
      "#507cb6",
      "#e392fe",
      "#d25f90",
    ],
  },
  {
    key: "amber",
    typeName: "Amber",
    group: "distinctive",
    colors: [
      "#85b6c5",
      "#004d80",
      "#1d253c",
      "#f2ba42",
      "#d34e23",
      "#653727",
      "#b88a57",
      "#D3623B",
      "#A84410",
      "#E67E22",
      "#005C01",
    ],
  },
  {
    key: "sl_10",
    typeName: "SL 10",
    group: "distinctive",
    colors: [
      "#4e79a7",
      "#f28e2c",
      "#e15759",
      "#76b7b2",
      "#59a14f",
      "#edc949",
      "#af7aa1",
      "#ff9da7",
      "#9c755f",
      "#bab0ab",
    ],
  },
];
