import styled from "styled-components";
import { Select } from "antd";

export const Wrapper = styled.form`
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 40px;
  border-bottom: 1px solid #d5dae1;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #1a141f;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 20px;
  & input {
    height: 40px !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: space-between;
  height: 40px;
  border-top: 1px solid #d5dae1;
  padding-top: 15px;
`;

export const FooterButtonsLeft = styled.div`
  display: flex;
  align-items: center;
  & > p {
    margin-right: 10px;
  }
  & > button {
    height: 32px;
    padding: 0 10px;
    border: none;
    background-color: #000000;
    color: #ffffff;
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  & > button {
    height: 32px;
    padding: 0 10px;
    border: none;
  }
  & > button:first-child {
    background-color: #ffffff;
    border: 1px solid black;
    color: #000000;
    margin-right: 15px;
  }
  & > button:last-child {
    background-color: var(--primary-red-color);
    color: #ffffff;
  }
`;

export const SelectWrapper = styled.div`
  margin-top: 15px;
  width: 100%;
  & > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333f51;
    margin-bottom: 6px;
  }
`;
export const ModalSelect = styled(Select)`
  width: ${(props: { $width?: string }) => props.$width || "150px"};
  background: #ffffff;
  & div[class="ant-select-selector"] {
    padding: 0 0 0 16px !important;
    height: 40px !important;
    border: 1px solid #e4e7ec !important;
    border-radius: 8px !important;
  }

  & span[class="ant-select-arrow"] {
    width: unset !important;
  }

  & span[class="ant-select-selection-item"] {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #344054;
  }
`;
