import React from "react";
import { Menu } from "antd";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { TableActionType } from "./types";
import { SortMenuButton } from "./style";

const TableAction = ({ value, viewSurvey, editSurvey }: TableActionType) => (
  <Menu>
    <Menu.Item>
      <SortMenuButton type="button" onClick={() => viewSurvey(value)}>
        <EyeOutlined className="align-middle" /> View Survey
      </SortMenuButton>
    </Menu.Item>
    <Menu.Item>
      <SortMenuButton type="button" onClick={() => editSurvey(value)}>
        <EditOutlined className="align-middle" /> Edit Survey
      </SortMenuButton>
    </Menu.Item>
    <Menu.Item>
      <SortMenuButton $isCancel type="button">
        <DeleteOutlined className="align-middle" /> Delete Survey
      </SortMenuButton>
    </Menu.Item>
  </Menu>
);

export default TableAction;
