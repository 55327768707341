import styled from "styled-components";

export const Wrapper = styled.div`
  overflow-y: auto;
`;

export const AnalyticsBoxes = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > div {
    margin-bottom: 20px;
  }
`;
export const NoDataBox = styled.div`
  display: flex;
  flex-direction: column;
  width: min(1000px, 90%);
  height: 300px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.07);
  padding: 15px 48px;
`;
export const HeadBox = styled.div`
  display: flex;
  align-items: center;
  & p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: #222539;
  }
`;
export const QuestionNumber = styled.p`
  margin-right: 5px;
`;

export const QuestionText = styled.div``;

export const EmptyDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
`;
export const EmptyDescription = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 20px;
  color: #222539;
`;
export const EmptySubDescription = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  background: #f5f5f5;
  border-radius: 6px;
  padding: 20px;
`;
