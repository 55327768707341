import React, { useContext } from "react";
import { AlertBox, Content, Message, Wrapper } from "./style";
import { ReactComponent as CloseIcon } from "../assets/close_small.svg";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";

const AlertComponent = ({ message }: { message: string }) => {
  const { onClose } = useContext(SuccessAlertContext);

  return (
    <Wrapper>
      <AlertBox>
        <Content>
          <Message>{message}</Message>
        </Content>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </AlertBox>
    </Wrapper>
  );
};

const SuccessAlert = ({ message }: { message: string }) => {
  return <AlertComponent message={message} />;
};

export default SuccessAlert;
