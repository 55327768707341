import styled from "styled-components";
import Alert from "@reach/alert";

export const Wrapper = styled.div`
  position: absolute;
  top: 75px;
  right: 270px;
  z-index: 1;
`;

export const Message = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
`;

export const AlertBox = styled(Alert)`
  //width: min(90%, 350px);
  width: 250px;
  position: absolute;
  display: flex;

  background: #374e63;
  border-radius: 4px;
  padding: 8px;
  align-items: center;
  justify-content: space-between;
  & > button {
    background-color: transparent;
    border: none;
    padding: 0;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & > button > svg {
    width: 100%;
  }
  & > button > svg > path {
    fill: #ffffff;
  }
`;

export const Content = styled.div``;
