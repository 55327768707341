import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
`;

export const ButtonWrapper = styled.button`
  margin-right: 20px;
  padding: 5px;
  width: 50px;
  border: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: { $selected: boolean }) =>
    props.$selected ? "#FFFFFF" : "unset"};
`;
