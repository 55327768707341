import styled, { css } from "styled-components";
import { Select } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  background: #fafafa;
  font-size: 14px;
  height: 100%;
  background: #fafafa;
  //box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
  //  0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
`;

export const Header = styled.div`
  border-bottom: 1px solid #ebebeb;
  padding: 8px;
  text-align: center;
  & > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #344054;
  }
`;

export const Body = styled.div`
  overflow-y: auto;
  height: calc(100% - 50px);
`;

export const Section = styled.div`
  padding: 18px 20px 12px;
  border-bottom: 1px solid #ebebeb;
  & > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 109.02%;
    display: flex;
    align-items: center;
    color: #344054;
    margin-bottom: 15px;
  }
`;

export const SelectDropdown = styled(Select)`
  width: ${(props: { $width?: string }) => props.$width || "150px"};

  & div[class="ant-select-selector"] {
    padding: 0 0 0 16px !important;
    height: 40px;
  }

  & span[class="ant-select-arrow"] {
    width: unset !important;
  }

  & span[class="ant-select-selection-item"] {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #344054;
  }
`;

export const FontDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  & ${SelectDropdown}:first-child {
    margin-right: 16px;
  }
`;

export const AlignmentButtons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const AlignmentButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 6px 10px;
  width: 85px;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  &:first-child {
    border-radius: 5px 0 0 5px;
    border-right: unset;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
    border-left: unset;
  }
  ${(props: { $selected: boolean }) => {
    if (props.$selected) {
      return css`
        background-color: var(--primary-red-color);
        & path {
          stroke: #ffffff;
        }
      `;
    }
    return css`
      background-color: #ffffff;
    `;
  }}
`;

export const UploadDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > button:first-child {
    margin-right: 8px;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  width: 130px;
  height: 36px;
  padding: 6px;
  & svg {
    margin-right: 12px;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const OtherColor = styled.div`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 15px;
  }
  & > span {
    margin-right: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #344054;
    width: 80px;
  }
`;
