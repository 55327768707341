import React from "react";
import { SwitchWrapper } from "./style";

interface InputProps {
  field?: any;
  defaultChecked?: boolean;
  onChange?: any;
}
const CustomSwitch = ({
  field,
  defaultChecked = false,
  onChange,
}: InputProps) => {
  return (
    <SwitchWrapper
      {...field}
      defaultChecked={defaultChecked}
      checked={field.value}
      onChange={(e) => {
        field.onChange(e);
        onChange && onChange(e);
      }}
    />
  );
};
export default CustomSwitch;
