import React from "react";
import { ActionBtns, Subtitle, Title } from "./style";
import { Button } from "../../components/common/Button";

type SignupSuccessProps = {
  onResendClick: () => void;
  onLoginClick: () => void;
};

const SignupSuccess = ({ onResendClick, onLoginClick }: SignupSuccessProps) => {
  return (
    <div>
      <Title>Signup successful</Title>
      <Subtitle>Please proceed to your email to confirm your account</Subtitle>
      <ActionBtns>
        <Button
          label="Log in"
          type="button"
          theme="primary"
          onClick={onLoginClick}
        />
        <Button
          label="Resend email"
          type="button"
          theme="alternate"
          onClick={onResendClick}
        />
      </ActionBtns>
    </div>
  );
};

export default SignupSuccess;
