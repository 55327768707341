import styled from "styled-components";
import { Tooltip } from "antd";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: #fafafa;
  //box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.11),
  //  0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
`;

export const Header = styled.div`
  padding: 8px;
  text-align: center;
  & > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #344054;
  }
`;

export const Body = styled.div`
  overflow-y: auto;
  padding: 20px;
  height: calc(100% - 50px);
`;

export const ThemeBody = styled.button`
  width: 100%;
  padding: 10px 15px;
  background: ${(props: { $backgroundColor: string; $textColor: string }) =>
    props.$backgroundColor};
  margin-bottom: 14px;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.07),
    0px 1.6px 3.6px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  & > p {
    color: ${(props) => props.$textColor};
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
`;

export const ColorsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  & > div:not(:last-child) {
    margin-right: 10px;
  }
`;

export const ColorBox = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${(props: { $backgroundColor: string }) =>
    props.$backgroundColor};
`;

export const ColorTooltip = styled(Tooltip)`
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
`;
