import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 14px;
`;

export const Name = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 5px;
`;

export const LinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: min(100%, 1000px);
`;

export const LinkInput = styled.input`
  padding: 8px 12px;
  width: 814px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  flex: 1;
  border: none;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  margin-right: 10px;
`;

export const CopyButton = styled.button`
  width: 40px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  border: none;
  margin-right: 10px;
`;

export const ConfigureButton = styled.button`
  background: #ffffff;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  border: none;
`;
