import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { ResponsesByCollectors } from "./types";

export interface CollectorsState {
  status: "idle" | "loading" | "failed";
  responseCount: number;
  allResponsesByCollectors: ResponsesByCollectors;
}

const initialState: CollectorsState = {
  status: "idle",
  responseCount: null,
  allResponsesByCollectors: {},
};

export const collectorsSlice = createSlice({
  name: "collectors",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setResponseCount: (state, action: PayloadAction<number>) => {
      state.responseCount = action.payload;
    },
    setAllResponseByCollectors: (
      state,
      action: PayloadAction<ResponsesByCollectors>
    ) => {
      state.allResponsesByCollectors = action.payload;
    },
  },
});

export const { setResponseCount, setAllResponseByCollectors } =
  collectorsSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.collectors.value)`

export default collectorsSlice.reducer;
