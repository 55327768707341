import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Wrapper } from "./style";
import BuildSurvey from "../../components/Build";
import { useGetSurveyQuery } from "../../app/apiSlice";
import { useAppDispatch } from "../../app/hooks";
import {
  setAlignment,
  setDisplayOptions,
  setInstructions,
  setName,
  setSectionOrders,
  setSurveyStatus,
  setSurveySettings,
} from "./designSlice";
import { DesignNav } from "../../components/DesignNav";
import Loader from "../../components/common/Loader";
import "./intro.css";

const Create = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const { data: surveyData, isFetching } = useGetSurveyQuery(params.surveyId);

  useEffect(() => {
    if (surveyData) {
      const {
        displayOptions,
        sectionOrder,
        instruction,
        status: surveyStatus,
        alignment,
        name,
        surveyConfig,
      } = surveyData;
      dispatch(setDisplayOptions(displayOptions));
      dispatch(setInstructions(instruction));
      dispatch(setSectionOrders(sectionOrder));
      dispatch(setSurveyStatus(surveyStatus));
      dispatch(setAlignment(alignment));
      dispatch(setName(name));
      dispatch(setSurveySettings(surveyConfig));
    }
  }, [surveyData]);

  return (
    <Wrapper>
      <Loader isLoading={isFetching} />

      <DesignNav />
      <BuildSurvey />
    </Wrapper>
  );
};

export default Create;
