import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { EditState, Dispatch } from "./types";
import editReducer from "./reducer";

const initialState: EditState = {
  font: "Mulish",
  logo: null,
  textFontSize: "14px",
  textFontColor: "#ce375c",
  headerColor: "#000000",
  headerLogo: null,
  titleColor: "#000000",
  buttonColor: "#ce375c",
  buttonTextColor: "#ffffff",
  backgroundColor: "#ffffff",
  answerColor: "#000000",
};

const EditContext = createContext<
  | {
      state: EditState;
      dispatch: Dispatch;
      // updateToken: (x: string) => void;
    }
  | undefined
>(undefined);

const EditProvider = ({
  displayOptions,
  children,
}: {
  displayOptions;
  children: ReactNode;
}) => {
  const [state, dispatch] = React.useReducer(editReducer, initialState);

  useEffect(() => {
    if (displayOptions) {
      const initialObj = {
        font: displayOptions.fontFamily,
        logo: null,
        textFontSize: displayOptions?.fontSize,
        textFontColor: displayOptions?.colors?.text,
        headerColor: displayOptions?.colors?.headerBackground,
        headerLogo: displayOptions?.headerLogoImageUrl,
        titleColor: displayOptions?.colors?.title,
        buttonColor: displayOptions?.colors?.button,
        buttonTextColor: displayOptions?.colors?.buttonText,
        answerColor: displayOptions?.colors?.buttonText,
        backgroundColor: displayOptions?.colors?.pageBackground,
      };
      dispatch({ type: "INITIALISE", payload: initialObj });
    }
  }, [displayOptions]);

  const value = useMemo(() => ({ state, dispatch }), [state]);
  return <EditContext.Provider value={value}>{children}</EditContext.Provider>;
};

export default EditProvider;

export const useEdit = () => {
  const context = useContext(EditContext);
  if (context === undefined) {
    throw new Error("useEdit must be used within a CountProvider");
  }
  return context;
};
