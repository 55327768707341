import React, { useContext, useEffect, useState } from "react";
import { multiItems, questionChartOptions } from "./chartOptions";
import ChartButton from "./ChartButton";
import { ChartContext } from "../../../../context/ChartContext";
import { Wrapper } from "./style";

const ChartTypes = () => {
  const { chartSelected, onChartChange, questionType } =
    useContext(ChartContext);
  const [chartOptions, setChartOptions] = useState(multiItems);

  const onChartClick = (name) => {
    onChartChange(name);
  };

  useEffect(() => {
    const chartList = questionChartOptions(questionType);
    setChartOptions(chartList);
  }, [questionType]);

  return (
    <Wrapper>
      {chartOptions.map((item) => (
        <ChartButton
          key={item.name}
          name={item.name}
          icon={item.icon}
          onItemClick={onChartClick}
          chartSelected={chartSelected}
        />
      ))}
    </Wrapper>
  );
};

export default ChartTypes;
