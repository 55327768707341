import styled from "styled-components";

export const Wrapper = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
  padding: 10%;
  overflow-y: auto;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  //margin-bottom: 5px;
  padding: 10px;
  font-size: 14px;
  //border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  &:nth-of-type(odd) {
    background-color: #2a3342;
    color: #ffffff;
  }
  & > div {
    width: 48%;
  }
`;
