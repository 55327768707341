import styled from "styled-components";
import { Slider } from "antd";

const SliderWrapper = styled(Slider)`
  max-width: 300px;
  &:hover > div[class="ant-slider-track"] {
    background-color: var(--sl-button-color) !important;
  }
  &:hover > div[class="ant-slider-handle"] {
    border-color: var(--sl-button-color) !important;
  }
  & > div:first-child {
    height: 8px;
  }
  & > div:nth-child(2) {
    height: 8px;
    background-color: var(--sl-button-color);
  }
  & > div:nth-child(3) {
    height: 8px;
  }
  & > div:nth-child(4) {
    width: 20px;
    height: 20px;
    margin-top: -7px;
    border-color: var(--sl-button-color);
  }
`;
export default SliderWrapper;
