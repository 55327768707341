import React, { useContext, useEffect, useState } from "react";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import { Wrapper, FormBody, Section } from "./style";
import FormHeader from "../../FormHeader";
import QuestionHeader from "../../QuestionHeader";
import { useConfiguration } from "../../../contexts/Configuration";
import RichTextField from "../../RichTextField";
import RequiredCheckbox from "../../RequiredCheckbox";
import AnswerTypesField from "../../AnswerTypesField";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import { useParams } from "react-router-dom";
import RandomCheckbox from "../../RandomCheckbox";
import ErrorMessage from "../../../../../../common/ErrorMessage";
import ImageRowOptions from "../../RowOptions/ImageRowOptions";
import QuestionFormCheckbox from "../../QuestionFormCheckbox";

type Inputs = {
  text: string;
};
const ImageChoiceForm = ({ onCancel }: { onCancel: () => void }) => {
  const dispatch = useAppDispatch();
  const { selectedQuestion } = useAppSelector((state) => state.design);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { text: selectedQuestion.text } });
  const { surveyId } = useParams();

  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();
  const { hasRandomizedChoices, isRequired, requiredMessage } = configState;
  const { imageRowOptions, isCheckbox } = configState.imageOptions;
  const [hasOptionsError, setHasOptionsError] = useState(false);

  useEffect(() => {
    if (selectedQuestion.text) {
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const rowOptionsNotEmpty = imageRowOptions.filter(
      (opt) => opt.value?.length !== 0 && opt.url?.length !== 0
    );
    if (rowOptionsNotEmpty.length < 2) {
      setHasOptionsError(true);
    } else {
      setHasOptionsError(false);
      try {
        const mcValues = {
          type: "IC",
          text: data.text,
          imageOptions: { isCheckbox, imageRowOptions },
          isRequired,
          requiredMessage: undefined,
          hasLogic: false,
          hasRandomizedChoices,
        };
        if (isRequired) {
          mcValues.requiredMessage = requiredMessage;
        }
        if (!selectedQuestion?.questionId) {
          const result = await addQuestion({
            surveyId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(setNewQuestion(result?.question));
          await dispatch(setSelectedQuestion(null));
          onSuccessShowAlert("New question added");
        } else {
          const { questionId } = selectedQuestion;
          const mcQuesInitialObj = pick(selectedQuestion, [
            "type",
            "text",
            "imageOptions",
            "isRequired",
            "hasLogic",
            "hasRandomizedChoices",
            "requiredMessage",
          ]);
          const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
          if (!hasQuestionNotChanged) {
            await editQuestion({
              surveyId,
              questionId,
              questionDetail: mcValues,
            }).unwrap();
            await dispatch(
              setUpdatedQuestion({
                questionId,
                updatedQues: { ...mcValues, questionId },
              })
            );
          }
          onSuccessShowAlert("Question updated");
        }
      } catch (err) {
        onShowErrorAlert(err, "The question couldn't be saved");
      }
    }
  };

  useEffect(() => {
    if (selectedQuestion.text) {
      localDispatch({
        type: "UPDATE_IMAGE_ROW_OPTIONS",
        payload: selectedQuestion.imageOptions.imageRowOptions,
      });
      localDispatch({
        type: "UPDATE_IMAGE_CHECKBOX_OPTION",
        payload: selectedQuestion.imageOptions.isCheckbox,
      });
      localDispatch({
        type: "UPDATE_IS_REQUIRED",
        payload: selectedQuestion.isRequired,
      });
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onImageCheckboxToggle = (e: any) => {
    localDispatch({
      type: "UPDATE_IMAGE_CHECKBOX_OPTION",
      payload: e.target.checked,
    });
  };
  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType="IC" />
          <Controller
            name="text"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RichTextField
                hasError={errors.text?.type === "required"}
                initialValue={selectedQuestion.text || configState.questionText}
                field={field}
              />
            )}
          />
          <Section>
            <AnswerTypesField />
            <ImageRowOptions rowList={imageRowOptions} />
            {hasOptionsError && (
              <ErrorMessage message="Minimum of two options" />
            )}
          </Section>

          <Section>
            <RequiredCheckbox />
            <RandomCheckbox />
            <QuestionFormCheckbox
              checked={isCheckbox}
              onChange={onImageCheckboxToggle}
            >
              Allow multiple answers
            </QuestionFormCheckbox>
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default ImageChoiceForm;
