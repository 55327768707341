import styled, { css } from "styled-components";

export const Content = styled.form`
  background: #ebebeb;
`;

export const Title = styled.div`
  height: 40px;

  & p {
    font-size: 18px;
  }
  margin-bottom: 10px;
`;

export const SubtitleContainer = styled.div`
  //min-height: 220px;
  min-height: 80px;
  padding: 10px 10px;
`;

export const SubtitleBox = styled.div`
  background: #ffffff;
  min-height: 140px;
  padding: 0 0;

  & > div {
    height: 100%;
  }
  & > div:focus-visible {
    border: none;
    outline: none;
  }

  & p {
    font-size: 14px;
  }
`;

export const SubtitleDisclaimer = styled.p`
  font-family: "Mulish";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #6d7175;
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > button:first-child {
    margin-right: 5px;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background: rgba(9, 30, 66, 0.04);
  mix-blend-mode: normal;
  box-shadow: 0px 3px 5px rgba(9, 30, 66, 0.2),
    0px 0px 1px rgba(9, 30, 66, 0.31);
  border-radius: 3px;
  border: none;
`;

export const Wrapper = styled.div`
  background-color: transparent;
  width: 100%;
  margin: 20px 0;

  & div {
    padding: 10px 5px;
    border-radius: 0;
  }

  ${(props: { $isEditing?: boolean }) => {
    if (props.$isEditing) {
      return css`
        background-color: transparent;
      `;
    }
    return css`
      ${Buttons} {
        display: none;
      }
      ${SubtitleDisclaimer} {
        display: none;
      }
      ${Content} {
        background: #ffffff;
      }
      ${SubtitleContainer} {
        height: unset;
        padding: 0;
      }
      ${SubtitleBox} {
        height: unset;
      }
    `;
  }}
`;
