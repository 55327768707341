import React from "react";
import { AnswerBox, Wrapper } from "./style";
import ShapeIcon from "../../../../../Configuration/components/forms/StarRatingForm/components/ShapeIcon";

const StarRating = ({ questionObj }: { questionObj: any }) => {
  const { starRatingColor, starRatingCount, starRatingShape } =
    questionObj.starRatingOptions;
  return (
    <Wrapper>
      <AnswerBox>
        {Array(starRatingCount)
          .fill(0)
          .map((_, i) => (
            <ShapeIcon
              key={i}
              shape={starRatingShape}
              color={starRatingColor}
            />
          ))}
      </AnswerBox>
    </Wrapper>
  );
};

export default StarRating;
