import React from "react";
import { AnswerBox, Wrapper } from "./style";
import { ReactComponent as DownArrow } from "../../../../../../../../assets/icons/down-arrow.svg";

const Dropdown = () => {
  return (
    <Wrapper>
      <AnswerBox>
        <DownArrow />
      </AnswerBox>
    </Wrapper>
  );
};

export default Dropdown;
