import React, { useContext, useEffect, useMemo, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChartContext } from "../../../context/ChartContext";
import { singleBarDataLabelConfig } from "../utils";
import type { SingleChoiceChartData } from "../../../context/types";

function SingleSeriesBarChart({
  theRef,
  layout = "column",
}: {
  theRef: any;
  layout: string;
}) {
  const {
    loading,
    headerText,
    resultData: chartData,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    legendLayout,
    dataLabelsVisible,
    selectedColorList,
    sortType,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
    dataLabelsPosition,
    headerTextAlignment,
  } = useContext(ChartContext);
  const [cData, setCData] = useState([]);

  const options = useMemo(() => {
    if (!loading) {
      return {
        chart: {
          type: layout,
          style: {
            fontFamily: "Mulish",
          },
        },
        colors: selectedColorList,
        title: {
          text: headerText,
          align: headerTextAlignment,
        },
        tooltip: {
          // formatter() {
          //   // eslint-disable-next-line react/no-this-in-sfc
          //   if (this.y > 0) return this.y;
          //   return null;
          // },
          pointFormat: "<b>{point.name}</b>: {point.y}",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: dataLabelsVisible,
            },
          },
        },
        series: [
          {
            name: "Responses",
            colorByPoint: true,
            data: cData,
            dataLabels: singleBarDataLabelConfig({
              layout,
              isVisible: dataLabelsVisible,
              position: dataLabelsPosition,
            }),
          },
        ],
        xAxis: {
          className: layout === "column" ? "x-axis-left-axis" : undefined,
          type: "category",
          title: { text: xAxisLabel },
          visible: showXaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
        },
        yAxis: {
          className:
            layout === "column" ? "y-axis-left-axis" : "y-axis-left-axis-bar",
          labels: {
            style: "none",
          },
          title: { text: yAxisLabel },
          visible: showYaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
        legend: {
          align: legendHorPosition,
          verticalAlign: legendVerPosition,
          layout: legendLayout,
          enabled: legendVisible,
          symbolRadius: legendSymbolShape === "square" ? 0 : null,
        },
        responsive: {
          rules: [
            {
              condition: {
                minWidth: 300,
              },
              chartOptions: {
                chart: {
                  className: "small-chart",
                },
              },
            },
          ],
        },
      };
    }
    return {};
  }, [
    loading,
    legendLayout,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    dataLabelsVisible,
    // JSON.stringify(chartData),
    chartData,
    headerText,
    selectedColorList,
    sortType,
    cData,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
    layout,
    dataLabelsPosition,
  ]);

  useEffect(() => {
    const formattedData = chartData.map((da: SingleChoiceChartData, index) => ({
      id: da.value,
      name: da.value,
      y: da.responses,
      x: index,
    }));

    setCData(formattedData);
  }, [JSON.stringify(chartData)]);

  return (
    <>
      <HighchartsReact ref={theRef} highcharts={Highcharts} options={options} />
    </>
  );
}

export default SingleSeriesBarChart;
