import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  signOut,
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { RootState } from "../../app/store";
import checkAuth from "./helper";
import {
  AuthState,
  LoginPropTypes,
  FirebaseRegisterPropTypes,
  UserType,
} from "./types";
import { getUser, signupUser } from "./loginAPI";

const initialState: AuthState = {
  status: "idle",
  isNotPro: true,
  isAuthenticated: false,
  user: null,
  token: null,
};

const fireBaseAuth = getAuth();

export const registerUser = createAsyncThunk(
  "auth/register",
  async ({
    email,
    firstName,
    lastName,
    password,
  }: FirebaseRegisterPropTypes) => {
    await createUserWithEmailAndPassword(fireBaseAuth, email, password);
    await signupUser({
      firstName,
      lastName,
      email,
    });
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async ({ email, password }: LoginPropTypes) => {
    const userCredential = await signInWithEmailAndPassword(
      fireBaseAuth,
      email,
      password
    );
    // if (!userCredential.user.emailVerified) {
    //   throw new Error("not_verified");
    // }
    const token = await userCredential.user.getIdToken();
    const user = checkAuth(token);
    return { user, token };
  }
);

export const logoutUser = createAsyncThunk("auth/logout", async () => {
  await signOut(fireBaseAuth);
  return null;
});

export const fetchUser = createAsyncThunk("auth/userdetails", async () => {
  const result = await getUser();
  return result.data as UserType;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // setCredentials: (
    //     state,
    //     { payload: { user, token } }: PayloadAction<{ user: UserType; token: string }>
    // ) => {
    //     state.user = user
    //     state.token = token
    // },
    setUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
      state.isNotPro = ["FREE", null, undefined].includes(
        action.payload?.userCredentials?.paymentPlan
      );
    },
    setUserExtraDetail: (state, { payload }) => {
      state.isAuthenticated = true;
      const credentials = { ...state.user?.userCredentials, ...payload };
      state.user = { userCredentials: credentials };
      state.isNotPro = ["FREE", null, undefined].includes(payload?.paymentPlan);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = "idle";
        // state.user = action.payload.user || null;
        state.token = action.payload.token;
        state.isAuthenticated = true;
      })
      .addCase(loginUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = "idle";
        state.user = null;
        state.token = null;
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload || null;
        state.isNotPro = ["FREE", null, undefined].includes(
          action.payload?.userCredentials?.paymentPlan
        );
      })
      .addCase(fetchUser.rejected, (state) => {
        state.status = "failed";
      });
  },
});

export const { setUser, setUserExtraDetail } = authSlice.actions;

export const selectUser = (state: RootState) => state.auth.user;
export const selectIsAuthenticated = (state: RootState) =>
  state.auth.isAuthenticated;

export default authSlice.reducer;
