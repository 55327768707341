import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Wrapper, RowOption, Buttons } from "./style";
import QuestionFormInput from "../QuestionFormInput";
import { useConfiguration } from "../../contexts/Configuration";
import { ReactComponent as RemoveIcon } from "../assets/remove-input.svg";
import { ReactComponent as AddIcon } from "../assets/add-input.svg";

interface DateRowOptionsProps {
  rowList: any[];
}
const DateRowOptions = ({ rowList }: DateRowOptionsProps) => {
  const [rowOptions, setRowOptions] = useState(rowList || []);

  useEffect(() => {
    setRowOptions(rowList || [{ index: 1, value: "Date/Time" }]);
  }, [rowList]);

  const { dispatch } = useConfiguration();

  const onAddOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    const rowIndex = Number(e.currentTarget.getAttribute("data-num"));

    const newOptionValues = [...rowOptions];
    const newValue = { index: rowIndex + 1, value: "" };
    newOptionValues.splice(rowIndex + 1, 0, newValue);
    for (let i = rowIndex + 1; i <= newOptionValues.length; i++) {
      if (newOptionValues[i] !== undefined) {
        newOptionValues[i].index = i;
      }
    }

    dispatch({ type: "UPDATE_DATE_ROW_OPTIONS", payload: newOptionValues });
  };

  const onRemoveOption = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (rowOptions.length > 1) {
      const rowIndex = Number(e.currentTarget.getAttribute("data-num"));
      const newOptionsList = rowOptions.filter(
        (option) => option.index !== rowIndex
      );

      for (let i = rowIndex; i <= newOptionsList.length; i++) {
        if (newOptionsList[i] !== undefined) {
          newOptionsList[i].index = i;
        }
      }

      dispatch({ type: "UPDATE_DATE_ROW_OPTIONS", payload: newOptionsList });
    }
  };

  const onInputChange = (index, newValue) => {
    const newOptionValues = cloneDeep(rowOptions);
    newOptionValues[index].value = newValue;
    dispatch({ type: "UPDATE_DATE_ROW_OPTIONS", payload: newOptionValues });
  };

  return (
    <Wrapper>
      {rowOptions.map((row) => (
        <RowOption key={row.index}>
          <p>Label {row.index}</p>
          <QuestionFormInput
            index={row.index}
            value={row.value}
            $width="60%"
            onChange={onInputChange}
          />
          <Buttons>
            <button type="button" data-num={row.index} onClick={onAddOption}>
              <AddIcon />
            </button>
            <button type="button" data-num={row.index} onClick={onRemoveOption}>
              <RemoveIcon />
            </button>
          </Buttons>
        </RowOption>
      ))}
    </Wrapper>
  );
};

export default DateRowOptions;
