import React, { useContext, useEffect, useMemo, useState } from "react";
import nlp from "compromise";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { ChartContext } from "../../../context/ChartContext";
import Loader from "../../../../common/Loader";

export default function WordCloud({ theRef }: { theRef: any }) {
  const {
    loading,
    headerText,
    resultData: chartData,
    headerTextAlignment,
    selectedColorList,
  } = useContext(ChartContext);

  const [dataWords, setDataWords] = useState(null);

  function toWords(terms, textLength) {
    return terms.map((term) => ({
      name: term.reduced,
      text: term.reduced,
      count: term.count,
      weight: term.count,
      freq: term.count / textLength,
    }));
  }

  const countWords = (str) => str.trim().split(/\s+/).length;

  useEffect(() => {
    const reducer = (acc, curVal) => {
      if (acc.answer) {
        return `${acc.answer}\n${curVal.answer}`;
      }
      return `${acc}\n${curVal.answer}`;
    };

    const newText = chartData.reduce(reducer, "");

    const textWordLength = countWords(newText);

    const doc: any = nlp(newText).normalize({
      whitespace: true, // remove hyphens, newlines, and force one space between words
      punctuation: true, // remove commas, semicolons - but keep sentence-ending punctuation
      case: true, // keep only first-word, and 'entity' titlecasing
      numbers: true, // 'one'  →  '1'
      plurals: true, // 'eyes'  →  'eye'
      verbs: true, // 'swtiched' → 'switch'
    });

    const words = toWords(doc.nouns().out("freq"), textWordLength) // sort by frequency
      .concat(toWords(doc.verbs().out("freq"), textWordLength))
      .concat(toWords(doc.adverbs().out("freq"), textWordLength))
      .concat(toWords(doc.adjectives().out("freq"), textWordLength))
      .sort((a, b) => b.freq - a.freq);

    setDataWords(words);
  }, [JSON.stringify(chartData)]);

  const options = useMemo(() => {
    if (!loading) {
      return {
        accessibility: {
          screenReaderSection: {
            beforeChartFormat:
              "<h5>{chartTitle}</h5>" +
              "<div>{chartSubtitle}</div>" +
              "<div>{chartLongdesc}</div>" +
              "<div>{viewTableButton}</div>",
          },
        },
        colors: selectedColorList,
        series: [
          {
            type: "wordcloud",
            data: dataWords,
            name: "Occurrences",
            style: {
              fontFamily: "Mulish",
            },
          },
        ],
        title: {
          text: headerText,
          align: headerTextAlignment,
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
      };
    }
    return {};
  }, [loading, dataWords, headerText, selectedColorList]);

  if (loading) return <Loader isLoading />;
  return (
    <>
      <HighchartsReact ref={theRef} highcharts={Highcharts} options={options} />
    </>
  );
}
