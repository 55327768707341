import React, { FC, useState, useEffect } from "react";
import TemplateCard from "../../components/common/TemplateCard";

import {
  Nav,
  IconContainer,
  InputContainer,
  MenuItem,
  Sidebar,
  Wrapper,
  Input,
  FilterButton,
  FilterList,
  Body,
  TemplateContainer,
  Container,
} from "./style";
import { templateCategories } from "../../utils";
import { useGetTemplatesQuery } from "../../app/apiSlice";
import { ReactComponent as BackArrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/search.svg";
import Loader from "../../components/common/Loader";

interface FilterProps {
  value?: string;
  tag?: string;
  selectedItemTag?: string;
  setSelectedItemTag?: any;
  onFilterClick?: any;
}

const FilterItem: FC<FilterProps> = ({
  value,
  tag,
  selectedItemTag,
  setSelectedItemTag,
  onFilterClick,
}) => {
  const [isSelected, setIsSelected] = useState(true);

  useEffect(() => {
    if (selectedItemTag !== tag) setIsSelected(false);
  }, [selectedItemTag]);

  const onClick = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSelectedItemTag(tag);
    setIsSelected(true);
    onFilterClick(tag);
  };

  return (
    <FilterButton type="button" isSelected={isSelected} onClick={onClick}>
      {value}
    </FilterButton>
  );
};

const Templates: FC = () => {
  const { data: tempList, isFetching } = useGetTemplatesQuery({});
  const [templateList, setTemplateList] = useState([]);
  const [filteredTemplateListByCategory, setFilteredTemplateListByCategory] =
    useState([]);
  const [filteredTemplateList, setFilteredTemplateList] = useState([]);
  const [selectedItemTag, setSelectedItemTag] = useState("all");
  const [selectedItemValue, setSelectedItemValue] = useState("All");
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    if (!tempList || (Array.isArray(tempList) && tempList.length !== 0)) {
      setTemplateList(tempList);
      setFilteredTemplateList(tempList);
      setFilteredTemplateListByCategory(tempList);
    }
  }, [tempList]);

  const onFilterBySearch = (tempList, searchValue) => {
    const filteredTempList = tempList.filter((temp) =>
      temp.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredTemplateList(filteredTempList);
  };

  const onSearchChange = (val) => {
    setSearchVal(val);
    onFilterBySearch(filteredTemplateListByCategory, val);
  };

  const onFilterClick = (tag) => {
    let tempList;
    if (tag === "all") {
      tempList = templateList;
      setSelectedItemValue("All");
    } else {
      const found = (templateCategory) => templateCategory.includes(tag);
      const newFilteredList = templateList.filter((temp) =>
        found(temp.categories)
      );
      tempList = newFilteredList;

      // update category label

      const selectedCat = templateCategories.find(
        (tempCat) => tempCat.value === tag
      );
      setSelectedItemValue(selectedCat?.label ?? "");
    }
    setFilteredTemplateListByCategory(tempList);
    onFilterBySearch(tempList, searchVal);
  };

  return (
    <Container>
      <Nav>
        <IconContainer>
          <BackArrow />
        </IconContainer>
        <MenuItem>Dashboard</MenuItem>
      </Nav>
      <Wrapper>
        <Sidebar>
          <MenuItem>Templates</MenuItem>
          <p style={{ marginTop: "1rem" }}>
            Browse through our expertly designed survey templates- also fully
            customizable and reusable .{" "}
          </p>

          <InputContainer>
            <IconContainer>
              <SearchIcon />
            </IconContainer>
            <Input
              placeholder="search a template"
              onChange={(e) => onSearchChange(e.target.value)}
            />
          </InputContainer>
          <FilterList>
            <FilterItem
              value="All"
              setSelectedItemTag={setSelectedItemTag}
              selectedItemTag={selectedItemTag}
              tag="all"
              onFilterClick={onFilterClick}
            />
            {templateCategories.map((category) => (
              <FilterItem
                key={category.value}
                value={category.label}
                setSelectedItemTag={setSelectedItemTag}
                selectedItemTag={selectedItemTag}
                tag={category.value}
                onFilterClick={onFilterClick}
              />
            ))}
          </FilterList>
        </Sidebar>
        <Body>
          <p>{`${selectedItemValue} templates`}</p>

          {isFetching || !filteredTemplateList ? (
            <Loader isLoading={isFetching} />
          ) : (
            <>
              {/* <TemplateGrid> */}
              <TemplateContainer>
                {filteredTemplateList.map((temp) => (
                  <TemplateCard
                    key={temp.templateId}
                    image={temp.image}
                    title={temp.title}
                    subTitle={temp.description}
                    templateId={temp.templateId}
                  />
                ))}
              </TemplateContainer>
              {/* </TemplateGrid> */}
            </>
          )}
        </Body>
      </Wrapper>
    </Container>
  );
};

export default Templates;
