import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
  margin-bottom: 40px;
  border: 1px solid #000000;
  background-color: #ffffff;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & div[class~="ant-upload-list"] {
    display: none;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  height: 30px;
  border: 1px solid #000000;
  padding: 0 10px;
  background-color: transparent;
  & > span {
    margin-left: 5px;
  }
`;

export const MoreButton = styled.button`
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  border: none;
  margin-left: 10px;
  background-color: transparent;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 128%;
`;

export const ImageList = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  & > div:not(:last-child) {
    margin-right: 15px;
  }
`;
