import styled, { css } from "styled-components";

export const DraggerContainer = styled.div`
  display: none;
  width: 16px;
  margin-right: 12px;
`;

export const DragDots = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const DragDotsBox = styled.div`
  width: 50%;
  margin: 3px 0;
`;

export const DragDot = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #c4c4c4;
`;

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 200px;
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const FooterControl = styled.button`
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 5px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;

export const FooterControls = styled.div`
  display: flex;
  align-items: center;
  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Footer = styled.div`
  flex: 1 1;
  justify-content: space-between;
  display: none;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  font-family: "Mulish";
  display: flex;
  align-items: flex-start;
  & span {
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 26px;
    color: #222222;
    text-align: left;
    margin-right: 5px;
  }
`;

//export const Wrapper = styled.button`
export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  //min-height: 200px;
  background-color: transparent;
  padding: 20px 0;
  border: none;
  ${(props: { $isSelected: boolean }) => {
    if (props.$isSelected) {
      return css`
        border: 1px solid #c4c4c4;
        //min-height: 240px;
        & ${MainContainer} {
          padding: 0 20px;
          border-left: 3px solid var(--primary-red-color);
          margin-left: -2px;
          min-height: 240px;
        }
        & ${DraggerContainer} {
          display: block;
        }
        & ${Footer} {
          display: flex;
        }
        & ${Header} {
          border-bottom: 0.5px solid #c4c4c4;
        }
      `;
    }
    return css`
      padding: 20px 0;
      & ${MainContainer}:hover {
        min-height: 240px;
      }
      &:hover ${Footer} {
        display: flex;
      }
    `;
  }}
`;

export const QuestionDiv = styled.div`
  display: flex;
  align-items: center;
  & span {
    margin-left: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    font-family: Mulish;
    display: flex;
    align-items: center;
    color: #222222;
  }
`;
export const QuestionBodyContainer = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

// export const Body = styled.div`
//   background-color: ${(props: { $backgroundColor: string }) =>
//     props.$backgroundColor || "#ffffff"};
//   height: 100%;
//   width: min(90%, 900px);
// `;
