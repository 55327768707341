import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  column-gap: 1rem;
`;

export const IconContainer = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const MenuItem = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 23px;
  color: #222222;
  margin-bottom: 0px;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: scroll;
`;

export const Sidebar = styled.div`
  flex: none;
  width: 312px;
  max-width: 312px;
  background-color: #fff;
  height: 100vh;
  border-right: 0.5px solid #c4c4c4;
  padding: 24px;
`;

export const InputContainer = styled.div`
  display: flex;
  border: 1px solid #999999;
  border-radius: 10px;
  margin-top: 32px;
  padding: 12px 24px;
  column-gap: 1rem;
`;

export const Input = styled.input`
  border: none;
  width: 100%;
  outline: none;
`;

export const FilterButton = styled.button`
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: ${(props: { isSelected: boolean }) =>
    !props.isSelected ? "#222222" : "#ce375c"};
  margin-bottom: 20px;
  background-color: unset;
  border: unset;
  text-align: left;
`;

export const FilterList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const Body = styled.div`
  flex-grow: 1;
  padding: 4rem;
  min-height: 100vh;
  overflow-y: scroll;

  & > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const TemplateGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1rem;
`;

export const TemplateContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 2rem;
  padding: 2rem;
  margin-top: 32px;
  margin-bottom: 160px;

  @media (min-width: 768px) {
    & {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: 1440px) {
    & {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
`;
