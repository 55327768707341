import { Action, EditState } from "./types";

export default function configurationReducer(state: EditState, action: Action) {
  switch (action.type) {
    case "INITIALISE": {
      return { ...state, ...action.payload };
    }
    case "UPDATE_COLORS_FROM_THEME": {
      const { title, text, button, buttonText, background } = action.payload;
      return {
        ...state,
        textFontColor: text,
        titleColor: title,
        buttonColor: button,
        buttonTextColor: buttonText,
        backgroundColor: background,
      };
    }
    case "UPDATE_FONT": {
      return {
        ...state,
        font: action.payload.font,
      };
    }
    case "UPDATE_LOGO": {
      return {
        ...state,
        headerLogo: action.payload.logo,
      };
    }
    case "UPDATE_INDIVIDUAL_COLOR": {
      const { colorName, colorValue } = action.payload;
      return {
        ...state,
        [colorName]: colorValue,
      };
    }
    case "UPDATE_INDIVIDUAL_PROPERTY": {
      const { propName, propValue } = action.payload;
      return {
        ...state,
        [propName]: propValue,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}
