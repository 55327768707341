import { JwtPayload } from "jsonwebtoken";

export function isJwtPayload(
  payload: JwtPayload | string | null
): payload is JwtPayload {
  return (payload as JwtPayload).exp !== undefined;
}

interface UserCredentialsType {
  paymentPlan?: "FREE" | "PAID";
}

export interface UserType {
  userId?: any;
  userCredentials?: UserCredentialsType;
}

export interface AuthState {
  status: "idle" | "loading" | "failed" | "success";
  isNotPro: boolean;
  isAuthenticated: boolean;
  user: UserType | null;
  token: string | null;
}

export interface FirebaseRegisterPropTypes {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface ApiRegisterPropTypes {
  email: string;
  firstName: string;
  lastName: string;
}

export interface LoginPropTypes {
  email: string;
  password: string;
}

export interface ExtraUserPropTypes {
  email: string;
  industry: string;
  workforceSize: string;
  jobLevel: string;
  jobRole: string;
  accountType: string;
  onboarded: boolean;
}

export interface AccountDetailsPropTypes {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  language: string;
  accountType: string;
}
