import styled from "styled-components";

export const UploadDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  & > button:first-child {
    margin-right: 8px;
  }
  & div[class~="ant-upload-list"] {
    display: none;
  }

  & > button {
    margin-left: 10px;
  }
`;

export const UploadButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #e4e7ec;
  border-radius: 6px;
  width: 130px;
  height: 36px;
  padding: 6px;
  & svg {
    margin-right: 12px;
  }
  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
