import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  //width: 100%;
`;

export const Left = styled.div`
  display: block;
  color: var(--primary-text-black);
  width: min(450px, 100%);
  & > p:first-child {
    font-weight: 700;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 24px;
  }
  & > p:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  & > button {
    display: flex;
    align-items: center;
    border: none;
    padding: 0 10px;
    & > svg {
      margin-right: 5px;
    }
  }
  & > button:first-child {
    height: 40px;
    background-color: var(--primary-red-color);
    color: #ffffff;
    margin-right: 15px;
  }
  //& > button:last-child {
  //  height: 40px;
  //  background-color: #ffffff;
  //  border: 1px solid black;
  //  color: #000000;
  //}
`;

export const Content = styled.div`
  display: block;
`;

export const TableActions = styled.div`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  width: 100%;
  & > div:first-child {
    margin-right: 15px;
  }

  & div[class="ant-select-selector"] {
    border-radius: unset !important;
  }

  & > span[class~="ant-input-affix-wrapper"] {
    height: 40px;
    width: min(100%, 300px);
  }
`;

export const TableWrapper = styled.div`
  margin-top: 20px;
`;

export const CustomNav = styled(NavLink)`
  color: var(--primary-text-black);
  &:hover {
    color: var(--primary-red-color);
  }
`;
