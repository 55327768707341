import React from "react";
import { Wrapper } from "./style";
import Content from "./components/Content";
import { useParams } from "react-router-dom";

const Body = () => {
  const params = useParams();
  const { tab: currentTab } = params;

  return (
    <Wrapper>
      <Content tab={currentTab} />
    </Wrapper>
  );
};

export default Body;
