import React, { useContext, useState } from "react";
import { Modal, Upload } from "antd";
import { UploadButton, UploadDiv } from "./style";
import { ReactComponent as SystemUploadIcon } from "../../assets/upload-two.svg";
import { ReactComponent as LibraryUploadIcon } from "../../assets/upload-one.svg";
import LogoList from "../LogoList";
import { useEdit } from "../../../../components/Edit/context/EditContext";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { RcFile } from "antd/es/upload";
import { useAppSelector } from "../../../../app/hooks";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";

const UploadBox = ({ uploadType }: { uploadType: "logo" | "header" }) => {
  const [isLogoModalVisible, setIsLogoModalVisible] = useState(false);
  const { dispatch: localDispatch } = useEdit();
  const { user } = useAppSelector((state) => state.auth);
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );

  const client = new S3Client({
    region: process.env.REACT_APP_AWS_S3_REGION,
    credentials: {
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY,
    },
  });

  const onShowLogoModal = () => {
    setIsLogoModalVisible(true);
  };

  const onCloseLogoModal = () => {
    setIsLogoModalVisible(false);
  };

  const onSelectLogo = (url: string) => {
    localDispatch({
      type: "UPDATE_LOGO",
      payload: { logo: url },
    });
    onCloseLogoModal();
  };

  const beforeUpload = (file: RcFile) => {
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      onShowErrorAlert({}, "Image must smaller than 2MB!");
    }
    return isLt2M;
  };

  const onUploadLogo = async (file) => {
    const fileName = file.name;
    const photoKey =
      "library/survey/" + user?.userCredentials?.userId + "/" + fileName;
    const uploadParams = {
      Bucket: process.env.REACT_APP_BUCKET_NAME,
      Key: photoKey,
      Body: file,
    };
    try {
      const data = await client.send(new PutObjectCommand(uploadParams));
      localDispatch({
        type: "UPDATE_LOGO",
        payload: { logo: photoKey },
      });
      onSuccessShowAlert("Logo uploaded");
      return data;
    } catch (error) {
      onShowErrorAlert(error, "Failed to add logo");
    }
  };

  return (
    <div>
      <UploadDiv>
        <Upload
          accept="image/*"
          customRequest={({ file }) => onUploadLogo(file)}
          beforeUpload={beforeUpload}
        >
          <UploadButton>
            <SystemUploadIcon /> <span>Upload</span>
          </UploadButton>
        </Upload>
        <UploadButton onClick={onShowLogoModal}>
          <LibraryUploadIcon /> <span>From Library</span>
        </UploadButton>
      </UploadDiv>
      <Modal
        title=""
        width={600}
        visible={isLogoModalVisible}
        footer={null}
        onCancel={onCloseLogoModal}
        closable={false}
      >
        <LogoList onSelectLogo={onSelectLogo} />
      </Modal>
    </div>
  );
};

export default UploadBox;
