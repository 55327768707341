import React from "react";
import styles from "./Switch.module.css";

const Switch = ({
  checked = false,
  onChange,
}: {
  checked?: boolean;
  onChange: (checked: boolean) => void;
}) => (
  <label htmlFor="toggle" className={styles.container}>
    <input
      id="toggle"
      type="checkbox"
      checked={checked}
      onChange={(e) => onChange(e.target.checked)}
    />
    <span className={styles.slider} />
  </label>
);

export default Switch;
