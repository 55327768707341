import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import groupBy from "lodash/groupBy";
import { Content, Wrapper, Body, SideBar, TabsBody } from "./style";
import AllResponses from "../../components/responses/components/All";
import { DesignNav } from "../../components/DesignNav";
import SocialMedia from "../../components/responses/components/SocialMedia";
import Weblink from "../../components/responses/components/links/Weblink";
import EmailTab from "../../components/responses/components/email/EmailTab";
import {
  useGetSurveyCollectorsQuery,
  useGetResponsesQuery,
} from "../../app/apiSlice";
import type { CollectorType, ResponsesByCollectors } from "./types";
import Loader from "../../components/common/Loader";
import { setAllResponseByCollectors, setResponseCount } from "./collectorSlice";
import { useAppDispatch } from "../../app/hooks";

const { TabPane } = Tabs;

const CollectResponses = () => {
  const dispatch = useAppDispatch();
  const { surveyId } = useParams();
  const [weblinkCollectors, setWeblinkCollectors] = useState<CollectorType[]>(
    []
  );
  const [emailCollectors, setEmailCollectors] = useState<CollectorType[]>([]);
  const [socialCollectors, setSocialCollectors] = useState<CollectorType[]>([]);
  const [responsesByCollectors, setResponsesByCollectors] =
    useState<ResponsesByCollectors>({});

  const { data: collectorsData, isFetching: isFetchingCollectors } =
    useGetSurveyCollectorsQuery({ surveyId });

  const { data: responsesList, isFetching: isFetchingResponses } =
    useGetResponsesQuery(surveyId, {
      refetchOnMountOrArgChange: 60,
    });

  const onTabChange = (key) => {
    console.log(key);
  };

  // useEffect(() => {
  //   // refetch on initial render
  //   refetch();
  // }, []);

  useEffect(() => {
    if (responsesList && responsesList.length) {
      const responsesCollectors = groupBy(responsesList, "collectorId");
      setResponsesByCollectors(responsesCollectors);
      dispatch(setResponseCount(responsesList.length));
      dispatch(setAllResponseByCollectors(responsesCollectors));
    }
  }, [responsesList]);

  useEffect(() => {
    if (collectorsData) {
      const collectorsByType = groupBy(collectorsData, "collectorType");
      setWeblinkCollectors(collectorsByType["WEBLINK"] || []);
      setEmailCollectors(collectorsByType["EMAIL"] || []);
      setSocialCollectors(collectorsByType["SOCIAL"] || []);
    }
  }, [collectorsData]);

  if (!collectorsData || !responsesList) {
    return <Loader isLoading />;
  }
  return (
    <Wrapper>
      <Loader isLoading={isFetchingResponses || isFetchingCollectors} />
      <DesignNav />
      <Content>
        <SideBar></SideBar>
        <Body>
          <TabsBody>
            <Tabs defaultActiveKey="1" onChange={onTabChange}>
              <TabPane tab="All responses" key="1">
                <AllResponses
                  isEmpty={!responsesList.length}
                  collectors={collectorsData}
                  responsesByCollectors={responsesByCollectors}
                  allResponses={responsesList}
                />
              </TabPane>
              <TabPane tab="Weblink" key="2">
                <Weblink
                  collectors={weblinkCollectors}
                  responsesByCollectors={responsesByCollectors}
                />
              </TabPane>
              {/*<TabPane tab="Links" key="2">*/}
              {/*  <Links />*/}
              {/*</TabPane>*/}
              <TabPane tab="Social media" key="3">
                <SocialMedia
                  collectors={socialCollectors}
                  responsesByCollectors={responsesByCollectors}
                />
              </TabPane>
              <TabPane tab="Email" key="4">
                <EmailTab
                  collectors={emailCollectors}
                  responsesByCollectors={responsesByCollectors}
                />
              </TabPane>
            </Tabs>
          </TabsBody>
        </Body>
      </Content>
    </Wrapper>
  );
};

export default CollectResponses;
