import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const OptionRow = styled.div`
  margin-bottom: 15px;
`;
export const OptionRowTitle = styled.p`
  margin-bottom: 7px;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #222222;
`;
export const OptionComponents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
export const ComponentTitle = styled.p`
  font-size: 12px;
  margin-bottom: 5px;
  color: #222222;
  font-weight: 500;
`;
export const DatePlaceHolder = styled.p`
  font-size: 16px;
  font-weight: 400;
  margin-bottom: unset;
  color: #999999;
`;
export const TimeBoxPlaceHolder = styled.p`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: unset;
  color: #222222;
`;

export const DateComponent = styled.div`
  margin-right: 15%;
`;

export const DateBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const DateBoxInput = styled.div`
  width: 150px;
  height: 30px;
  border-bottom: 1px solid #999999;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TimeComponents = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TimeDiv = styled.div`
  margin-right: 10px;
`;
export const TimeDivBoxes = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TimeBox = styled.div`
  width: 50px;
  height: 30px;
  border-bottom: 0.5px solid #c4c4c4;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
export const TimeBoxesDivider = styled.div`
  margin: 0 10px;
`;
