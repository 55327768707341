import React, { useContext, useEffect } from "react";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Wrapper, FormBody, Section } from "./style";
import FormHeader from "../../FormHeader";
import TextEditor from "../../../../../../common/TextEditor";
import QuestionHeader from "../../QuestionHeader";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import { useConfiguration } from "../../../contexts/Configuration";
import { useParams } from "react-router-dom";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";

type Inputs = {
  text: string;
};

const ShortLongAnswerForm = ({
  questionType,
  onCancel,
}: {
  questionType: "ST" | "LT";
  onCancel: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { surveyId } = useParams();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();
  const { selectedQuestion } = useAppSelector((state) => state.design);

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>({ defaultValues: { text: selectedQuestion.text } });

  useEffect(() => {
    if (selectedQuestion.text) {
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const mcValues = {
        type: questionType,
        text: data.text,
        isRequired: false,
      };
      if (!selectedQuestion?.questionId) {
        const result = await addQuestion({
          surveyId,
          questionDetail: mcValues,
        }).unwrap();
        await dispatch(setNewQuestion(result?.question));
        await dispatch(setSelectedQuestion(null));
        onSuccessShowAlert("New question added");
      } else {
        const { questionId } = selectedQuestion;
        const mcQuesInitialObj = pick(selectedQuestion, [
          "type",
          "text",
          "isRequired",
        ]);
        const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
        if (!hasQuestionNotChanged) {
          await editQuestion({
            surveyId,
            questionId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(
            setUpdatedQuestion({
              questionId,
              updatedQues: { ...mcValues, questionId },
            })
          );
        }
        onSuccessShowAlert("Question updated");
      }
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType={questionType} />
          <Section>
            <Controller
              name="text"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <TextEditor field={field} />}
            />
            {errors.text?.type === "required" && (
              <span>Please input a question text</span>
            )}
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default ShortLongAnswerForm;
