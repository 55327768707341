import React, { useContext } from "react";
import { ReactComponent as CopyIcon } from "../../../assets/icons/copy.svg";
import {
  ConfigureButton,
  CopyButton,
  LinkContainer,
  LinkInput,
  Name,
  Wrapper,
} from "./style";
import { copyText } from "../../../../../utils/helpers";
import { SuccessAlertContext } from "../../../../../context/SuccessAlertContext";
import type { CollectorType } from "../../../../../pages/CollectResponses/types";

interface LinkBoxProps {
  name?: string;
  onConfigureClick: (collector: CollectorType) => void;
  linkValue: string;
  collector: CollectorType;
}

const LinkBox = ({
  onConfigureClick,
  linkValue,
  name = "Weblink 1",
  collector,
}: LinkBoxProps) => {
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const onCopy = async () => {
    await copyText(linkValue);
    onSuccessShowAlert("Link copied");
  };
  const onConfigure = () => {
    onConfigureClick(collector);
  };
  return (
    <Wrapper>
      <Name>{name}</Name>
      <LinkContainer>
        <LinkInput readOnly value={linkValue} />
        <CopyButton type="button" onClick={onCopy}>
          <CopyIcon />
        </CopyButton>
        <ConfigureButton type="button" onClick={onConfigure}>
          Configure link
        </ConfigureButton>
      </LinkContainer>
    </Wrapper>
  );
};

export default LinkBox;
