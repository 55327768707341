import React from "react";
import { Wrapper, Section } from "./style";
import TextEditor from "../../../../../common/TextEditor";

const RichTextField = ({
  hasError,
  field,
  initialValue,
}: {
  hasError: boolean;
  field?: any;
  initialValue?: string;
}) => {
  return (
    <Wrapper>
      <Section>
        <TextEditor field={field} />
        {hasError && <span>Please input a question text</span>}
      </Section>
    </Wrapper>
  );
};

export default RichTextField;
