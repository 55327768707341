import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  margin: auto 5% 40px;
  max-height: 370px;
  overflow-y: auto;
  & p {
    margin-bottom: unset;
  }
`;

export const ItemDiv = styled.div`
  padding: 10px 5px;
  border-bottom: 1px solid #e5e5e5;
  &:hover {
    background-color: #f4f5f5;
  }
`;

export const ItemHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 15px;
`;

export const UserLink = styled(NavLink)`
  text-decoration: underline;
  text-decoration-color: var(--sl-button-color);
  color: var(--sl-button-color);
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
`;

export const DateAnswered = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: #737373;
`;

export const ValueText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #32363a;
`;

export const HeaderText = styled.p`
  text-align: ${(props: { $align: string }) => props.$align ?? "left"};
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 16px;
  min-height: 25px;
`;
