import React, { useState } from "react";
import { Wrapper, QaWrapper, Title, QaBody, AddButton } from "./style";
import { updateParamObjectItem } from "../../utils";
import FilterItem from "./FilterItem";
import type { QAFilterProps } from "../../types";

const QuestionAnswerFilter = ({
  filterParams,
  onUpdateFilterParams,
}: QAFilterProps) => {
  const [filterParamsCount, setFilterParamsCount] = useState(0);

  const onAddFilter = () => {
    const newFilterParams = [...filterParams];
    const newFilter = {
      index: filterParamsCount,
      key: filterParamsCount,
      operation: null,
      questionId: null,
      selectedOptions: null,
    };
    newFilterParams.push(newFilter);
    onUpdateFilterParams(newFilterParams);
    setFilterParamsCount(filterParamsCount + 1);
  };

  const onRemoveFilter = ({ id }) => {
    const newFilterParams = filterParams.filter(
      (filParam) => filParam.key !== id
    );
    onUpdateFilterParams(newFilterParams);
  };

  const onChangeQuestion = ({ quesId, key }) => {
    const newParams = updateParamObjectItem({
      params: filterParams,
      keyToReplace: "questionId",
      identifierKeyValue: key,
      newValue: quesId,
    });
    onUpdateFilterParams(newParams);
  };
  const onChangeOperation = ({ operation, key }) => {
    const newParams = updateParamObjectItem({
      params: filterParams,
      keyToReplace: "operation",
      identifierKeyValue: key,
      newValue: operation,
    });
    onUpdateFilterParams(newParams);
  };

  const onChangeResponse = ({ values, key }) => {
    const newParams = updateParamObjectItem({
      params: filterParams,
      keyToReplace: "selectedOptions",
      identifierKeyValue: key,
      newValue: values,
    });
    onUpdateFilterParams(newParams);
  };

  return (
    <Wrapper>
      <QaWrapper>
        <Title>Filter by Question & Answers</Title>
        <QaBody>
          {filterParams.map((filParam) => (
            <FilterItem
              key={filParam.index}
              filterParam={filParam}
              onChangeResponse={onChangeResponse}
              onChangeOperation={onChangeOperation}
              onChangeQuestion={onChangeQuestion}
              onRemoveFilter={onRemoveFilter}
            />
          ))}
        </QaBody>
        <AddButton onClick={onAddFilter} type="button">
          +
        </AddButton>
      </QaWrapper>
    </Wrapper>
  );
};

export default QuestionAnswerFilter;
