import styled from "styled-components";

export const Wrapper = styled.div`
  background: rgba(250, 250, 250, 0.13);
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  background: rgba(250, 250, 250, 0.13);
  height: 100%;
`;

export const Left = styled.div`
  width: 300px;
  height: 100%;
  background-color: #ffffff;
  @media (min-width: 1440px) {
    width: min(30%, 400px);
  }
`;
export const Body = styled.div`
  height: 100%;
  flex: 1;
  background: rgba(250, 250, 250, 0.13);
`;
export const Right = styled.div`
  height: 100%;
  background-color: #ffffff;
  width: 220px;
  @media (min-width: 1440px) {
    width: min(20%, 300px);
  }
`;
