import styled, { css } from "styled-components";

export const Wrapper = styled.button`
  background: #deebff;
  padding: 20px;
  display: flex;
  align-items: flex-start;
  min-height: 80px;
  margin-bottom: 20px;
  border: none;
  text-align: left;
  width: 100%;

  ${(props: { $isSelected: boolean }) => {
    if (props.$isSelected) {
      return css`
        border: 1px solid var(--primary-red-color);
      `;
    }
    return css`
      padding: 20px;
    `;
  }}
`;

export const IconBox = styled.div`
  margin-right: 16px;
`;

export const Content = styled.div`
  height: 100%;
  //overflow-y: auto;
  & > div:first-child {
    font-size: 16px;
    line-height: 20px;
    color: #172b4d;
    margin-bottom: 8px;
  }

  & > div:last-child {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #172b4d;
  }
`;
