import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 14px;
`;

export const MediaButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  & > button {
    margin-right: 30px;
  }
`;

export const MediaButton = styled.button`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  backdrop-filter: blur(4px);
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 160px;
  height: 150px;
  align-items: center;
`;

export const MediaButtonTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 12px;
`;

export const MediaButtonImage = styled.img`
  width: 42px;
  height: 42px;
`;

export const MediaButtonText = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  margin-top: 12px;
`;
