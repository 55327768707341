import React, { useContext } from "react";
import { Wrapper, Table, TBody, Thead } from "./style";
import { ChartContext } from "../../context/ChartContext";

const CustomChartTable = ({ chartData }) => {
  const { tableIsDisplayed } = useContext(ChartContext);

  return (
    <Wrapper $show={tableIsDisplayed}>
      <Table>
        <Thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Responses</th>
          </tr>
        </Thead>
        <TBody>
          {chartData.map((cha, index) => (
            <tr key={`${cha.name}-${cha.index}`}>
              <th scope="row">{cha.name ?? `Image ${index}`}</th>
              <td>{cha.responses}</td>
            </tr>
          ))}
        </TBody>
      </Table>
    </Wrapper>
  );
};

export default CustomChartTable;
