import React, { useEffect, useState } from "react";
import { ButtonWrapper } from "./style";

const ChartButton = ({ icon, name, chartSelected, onItemClick }) => {
  const [isSelected, setIsSelected] = useState(true);

  useEffect(() => {
    if (chartSelected !== name) setIsSelected(false);
  }, [chartSelected]);

  const onClick = (e) => {
    e.preventDefault();
    setIsSelected(true);
    onItemClick(name);
  };

  return (
    <ButtonWrapper $selected={isSelected} onClick={onClick} type="button">
      {icon}
    </ButtonWrapper>
  );
};

export default ChartButton;
