import React, { useContext } from "react";
import { ChartContext } from "../../../context/ChartContext";
import {
  BarDiv,
  BarDivFilled,
  ImageDiv,
  OptionDiv,
  ValueText,
  Wrapper,
} from "./style";
import CustomChartBox from "../CustomChartBox";
import type { ImageChartData } from "../../../context/types";
import { RespondentAnswerCount } from "../../../context/types";

const ProgressBar = ({
  option,
  color,
  countPercentageFormat,
  respondentsDetails,
}: {
  option: ImageChartData;
  color: string;
  countPercentageFormat: "percentage" | "count";
  respondentsDetails: RespondentAnswerCount;
}) => {
  const percentageValue = (
    (option.responses / respondentsDetails.answered) *
    100
  ).toFixed(1);

  return (
    <OptionDiv key={option.url}>
      <ImageDiv>
        <img src={option.link} alt={option.name} />
      </ImageDiv>
      <BarDiv>
        <BarDivFilled $percent={percentageValue} $backgroundColor={color} />
      </BarDiv>
      <ValueText>
        {countPercentageFormat === "percentage"
          ? percentageValue
          : option.responses}
        %
      </ValueText>
    </OptionDiv>
  );
};
export default function ImageDiagram({ theRef }: { theRef: any }) {
  const {
    resultData: chartData,
    selectedColorList,
    countPercentageFormat,
    respondentsDetails,
  } = useContext(ChartContext);

  return (
    <Wrapper ref={theRef}>
      <CustomChartBox>
        {chartData.map((option: ImageChartData, index) => (
          <ProgressBar
            color={selectedColorList[index]}
            option={option}
            key={`${option.name}-${option.index}`}
            countPercentageFormat={countPercentageFormat}
            respondentsDetails={respondentsDetails}
          />
        ))}
      </CustomChartBox>
    </Wrapper>
  );
}
