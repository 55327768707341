import styled from "styled-components";

export const Container = styled.div`
  display: block;
  width: 100vw;
  height: 100vh;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow-y: scroll;
`;

export const Body = styled.div`
  flex-grow: 1;
  min-height: 100vh;
  overflow-y: scroll;
  padding: 32px 54px 200px 54px;
`;

export const Sidebar = styled.div`
  flex: none;
  width: 312px;
  max-width: 312px;
  background-color: #fff;
  height: 100vh;
  padding: 24px;
`;

export const Title = styled.h3`
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
`;

export const TitleContainer = styled.div`
  margin-top: 54px;
`;

export const Paragraph = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  color: #000000;
`;

export const QuestionNum = styled.div`
  text-align: center;
  margin-top: 32px;
`;

export const UseButton = styled.button`
  background: #ce375c;
  border: 1px solid #ce375c;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
  padding: 10px 18px;
  color: #fff;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 38px;
  padding: 0px 1rem;
`;

export const LookingFor = styled.h5`
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin-top: 14px;
  text-align: center;
`;

export const LowerDiv = styled.div`
  text-align: center;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem;
  background-color: #fff;
  column-gap: 1rem;
`;

export const TemplateContainer = styled.div`
  overflow-y: scroll;
`;

export const CreateScratch = styled.button`
  background-color: transparent;
  color: var(--primary-red-color);
  border: none;
`;
