import styled, { css } from "styled-components";

export const ToggleButton = styled.button`
  border: none;
  height: 40px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  flex: 1;

  & > ul[class~="ant-menu"] {
    background-color: #000000;
    color: #ffffff;
    z-index: 10;
    padding-top: 40px;
  }

  & > ul[class~="ant-menu"] > li {
    padding: 10px;
    width: 100%;
  }

  & > ul[class~="ant-menu"] > li[class~="ant-menu-item-selected"] {
    background-color: var(--primary-red-color) !important;
    color: #ffffff;
  }

  //& > ul[class~="ant-menu"] > li[data-menu-id~="notification"] {
  & > ul[class~="ant-menu"] > li[data-menu-id*="notification"] {
    //> li[data-menu-id~="rc-menu-uuid-65296-2-notification"] {
    margin-top: calc(100vh - 104px - 432px);
  }

  & > ul[class~="ant-menu"] > li:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
  }

  ${(props: { $collapsed: boolean }) => {
    if (props.$collapsed) {
      return css`
        & > ul[class~="ant-menu"] {
          width: 70px;
        }

        & > ul[class~="ant-menu"] > li:last-child > svg {
          transform: rotate(270deg);
        }
      `;
    }
    return css`
      & > ul[class~="ant-menu"] {
        width: 180px;
        position: absolute;
        top: 0;
        bottom: 0;
      }

      & > ul[class~="ant-menu"] > li:last-child > svg {
        transform: rotate(90deg);
      }

      //& > ul[class~="ant-menu"] > li:last-child > span {
      //  display: none;
      //}
    `;
  }}
`;
