import React, { useContext, useEffect, useState } from "react";
import {
  Wrapper,
  FormBody,
  Section,
  SubSection,
  ScaleDiv,
  LabelDiv,
} from "./style";
import { SubmitHandler, useForm, Controller } from "react-hook-form";
import FormHeader from "../../FormHeader";
import QuestionHeader from "../../QuestionHeader";
import QuestionFormCheckbox from "../../QuestionFormCheckbox";
import { useConfiguration } from "../../../contexts/Configuration";
import QuestionFormDropdown from "../../QuestionFormDropdown";
import { useAppDispatch, useAppSelector } from "../../../../../../../app/hooks";
import RichTextField from "../../RichTextField";
import {
  setNewQuestion,
  setSelectedQuestion,
  setUpdatedQuestion,
} from "../../../../../../../pages/Design/designSlice";
import pick from "lodash/pick";
import isEqual from "lodash/isEqual";
import { NetworkErrorAlertContext } from "../../../../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../../../../context/SuccessAlertContext";
import {
  useAddQuestionMutation,
  useUpdateQuestionMutation,
} from "../../../../../../../app/apiSlice";
import RequiredCheckbox from "../../RequiredCheckbox";
import { useParams } from "react-router-dom";
import SingleInput from "../../QuestionFormInput/SingleInput";

type Inputs = {
  text: string;
};
const SliderForm = ({ onCancel }: { onCancel: () => void }) => {
  const dispatch = useAppDispatch();
  const { surveyId } = useParams();
  const { selectedQuestion } = useAppSelector((state) => state.design);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: { text: selectedQuestion.text } });
  const [addQuestion, { isLoading: isLoadingAddQues }] =
    useAddQuestionMutation();
  const [editQuestion, { isLoading: isLoadingUpdateQues }] =
    useUpdateQuestionMutation();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);
  const { state: configState, dispatch: localDispatch } = useConfiguration();
  const { isRequired, requiredMessage } = configState;
  const { showAdjustScale, showInput, startPosition, labels, scale } =
    configState.sliderOptions;

  const [showAdjust, setShowAdjust] = useState(showAdjustScale || false);
  const [inputVisible, setInputVisible] = useState(showInput || false);

  const [leftLabel, setLeftLabel] = useState(labels.left);
  const [centerLabel, setCenterLabel] = useState(labels.center);
  const [rightLabel, setRightLabel] = useState(labels.right);

  const [minScale, setMinScale] = useState(scale.min);
  const [maxScale, setMaxScale] = useState(scale.max);
  const [stepScale, setStepScale] = useState(scale.step);
  const [positionStart, setPositionStart] = useState<
    "LEFT" | "CENTER" | "RIGHT"
  >(startPosition);

  const onToggleAdjustScale = (e: any) => {
    setShowAdjust(e.target.checked);
  };

  const onToggleInput = (e: any) => {
    setInputVisible(e.target.checked);
  };

  const onMinScaleChange = (value) => {
    if (minScale == Number(leftLabel)) {
      setLeftLabel(value);
    }
    setMinScale(value);
  };

  const onMaxScaleChange = (value) => {
    if (maxScale == Number(rightLabel)) {
      setRightLabel(value);
    }
    setMaxScale(value);
  };

  useEffect(() => {
    if (selectedQuestion.text) {
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    if (selectedQuestion.text) {
      localDispatch({
        type: "UPDATE_IS_REQUIRED",
        payload: selectedQuestion.isRequired,
      });
      setValue("text", selectedQuestion.text);
    }
  }, [selectedQuestion]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const mcValues = {
        type: "Sl",
        text: data.text,
        isRequired,
        requiredMessage: undefined,
        hasLogic: false,
        sliderOptions: {
          labels: { left: leftLabel, center: centerLabel, right: rightLabel },
          scale: {
            min: minScale,
            max: maxScale,
            step: stepScale,
          },
          showAdjustScale: showAdjust,
          showInput: inputVisible,
          startPosition: positionStart,
        },
      };
      if (isRequired) {
        mcValues.requiredMessage = requiredMessage;
      }
      if (!selectedQuestion?.questionId) {
        const result = await addQuestion({
          surveyId,
          questionDetail: mcValues,
        }).unwrap();
        await dispatch(setNewQuestion(result?.question));
        await dispatch(setSelectedQuestion(null));
        onSuccessShowAlert("New question added");
      } else {
        const { questionId } = selectedQuestion;
        const mcQuesInitialObj = pick(selectedQuestion, [
          "type",
          "text",
          "rowOptions",
          "isRequired",
          "hasLogic",
          "hasRandomizedChoices",
          "requiredMessage",
          "sliderOptions",
        ]);
        const hasQuestionNotChanged = isEqual(mcQuesInitialObj, mcValues);
        if (!hasQuestionNotChanged) {
          await editQuestion({
            surveyId,
            questionId,
            questionDetail: mcValues,
          }).unwrap();
          await dispatch(
            setUpdatedQuestion({
              questionId,
              updatedQues: { ...mcValues, questionId },
            })
          );
        }
        onSuccessShowAlert("Question updated");
      }
    } catch (err) {
      onShowErrorAlert(err, "The question couldn't be saved");
    }
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormHeader onCancel={onCancel} />
        <FormBody>
          <QuestionHeader questionType="Sl" />

          <Controller
            name="text"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <RichTextField
                hasError={errors.text?.type === "required"}
                initialValue={selectedQuestion.text}
                field={field}
              />
            )}
          />
          <Section>
            <RequiredCheckbox questionType="Sl" />
            <div>
              <QuestionFormCheckbox
                value={inputVisible}
                onChange={onToggleInput}
              >
                Show text input
              </QuestionFormCheckbox>
            </div>
          </Section>

          <Section>
            <div>
              <SubSection>
                <p>Labels</p>
                <LabelDiv>
                  <p>Left</p>
                  <SingleInput value={leftLabel} onChange={setLeftLabel} />
                </LabelDiv>
                <LabelDiv>
                  <p>Center(optional)</p>
                  <SingleInput value={centerLabel} onChange={setCenterLabel} />
                </LabelDiv>
                <LabelDiv>
                  <p>Right</p>
                  <SingleInput value={rightLabel} onChange={setRightLabel} />
                </LabelDiv>
              </SubSection>
            </div>
          </Section>

          <Section>
            <div>
              <QuestionFormCheckbox
                value={showAdjustScale}
                onChange={onToggleAdjustScale}
              >
                Adjust slider scale
              </QuestionFormCheckbox>
            </div>
            {showAdjust && (
              <div>
                <SubSection>
                  <p>Scale range</p>
                  <ScaleDiv>
                    <SingleInput
                      min={0}
                      type="number"
                      value={minScale}
                      onChange={onMinScaleChange}
                    />
                    <p>to</p>
                    <SingleInput
                      type="number"
                      value={maxScale}
                      onChange={onMaxScaleChange}
                    />
                  </ScaleDiv>
                </SubSection>
                <SubSection>
                  <p>Step size</p>
                  <SingleInput
                    min={1}
                    type="number"
                    value={stepScale}
                    onChange={setStepScale}
                  />
                </SubSection>
                <SubSection>
                  <p>Start position</p>
                  <QuestionFormDropdown
                    value={positionStart}
                    onChange={setPositionStart}
                    $width="90px"
                    size="large"
                    options={[
                      { value: "LEFT", label: "Left" },
                      { value: "CENTER", label: "Center" },
                      { value: "RIGHT", label: "Right" },
                    ]}
                  />
                </SubSection>
              </div>
            )}
          </Section>
        </FormBody>
      </form>
    </Wrapper>
  );
};

export default SliderForm;
