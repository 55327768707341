import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonWrapper, Wrapper } from "./style";
import { ReactComponent as QuestionIcon } from "../../icons/add-question.svg";
import { ReactComponent as SectionIcon } from "../../icons/add-section.svg";
import { ReactComponent as EditIcon } from "../../icons/design.svg";
import { ReactComponent as LogicIcon } from "../../icons/logic.svg";
import { ReactComponent as SettingsIcon } from "../../icons/settings.svg";
import {
  setConfigurationType,
  setNewSection,
  setSelectedQuestion,
  setSelectedSection,
} from "../../../../pages/Design/designSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";

const Options = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { tab: currentTab, surveyId } = params;
  const dispatch = useAppDispatch();

  const { sectionOrders } = useAppSelector((state) => state.design);

  const onChangeTab = (e: React.MouseEvent<HTMLButtonElement>) => {
    const tab = e.currentTarget.getAttribute("data-tab");
    let navTab = tab;
    if (tab === "pages") {
      navTab = "questions";
    }
    if (["questions", "edit", "settings", "logic", "pages"].includes(tab)) {
      navigate(`/${surveyId}/design/${navTab}`);
    }
  };
  const onAddQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    onChangeTab(e);
  };

  const onClickNewPage = () => {
    dispatch(
      setSelectedSection({
        sectionId: null,
        title: "New page",
        description: "",
      })
    );
    dispatch(setSelectedQuestion(null));
    dispatch(setConfigurationType("PAGE"));
    dispatch(setNewSection({}));
  };

  const onAddSection = (e: React.MouseEvent<HTMLButtonElement>) => {
    onChangeTab(e);
    onClickNewPage();
  };

  return (
    <Wrapper>
      <ButtonWrapper
        $selected={currentTab === "questions"}
        onClick={onAddQuestion}
        data-tab="questions"
        aria-label="Add question"
      >
        <QuestionIcon />
        <span>Question</span>
      </ButtonWrapper>
      <ButtonWrapper
        onClick={onAddSection}
        data-tab="pages"
        aria-label="Add section"
        disabled={
          sectionOrders[sectionOrders.length - 1]?.questions?.length === 0
        }
      >
        <SectionIcon />
        <span>Pages</span>
      </ButtonWrapper>
      <ButtonWrapper
        $selected={currentTab === "edit"}
        onClick={onChangeTab}
        data-tab="edit"
        aria-label="Edit question"
      >
        <EditIcon />
        <span>Design</span>
      </ButtonWrapper>
      <ButtonWrapper
        $selected={currentTab === "logic"}
        onClick={onChangeTab}
        data-tab="logic"
        aria-label="Add logic"
      >
        <LogicIcon />
        <span>Logic</span>
      </ButtonWrapper>
      <ButtonWrapper
        $selected={currentTab === "settings"}
        onClick={onChangeTab}
        data-tab="settings"
        aria-label="Survey settings"
      >
        <SettingsIcon />
        <span>Settings</span>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default Options;
