import { useEffect, useCallback } from "react";
import debounce from "lodash/debounce";
import isEqual from "lodash/isEqual";
import { useParams } from "react-router-dom";
import { useEdit } from "../../context/EditContext";
import { useUpdateSurveyMutation } from "../../../../app/apiSlice";
import { setDisplayOptions } from "../../../../pages/Design/designSlice";

const EditAutoSave = () => {
  const { surveyId } = useParams();

  const [editSurvey, { isLoading: isUpdatingSurvey }] =
    useUpdateSurveyMutation();
  const { state: editState } = useEdit();
  const {
    font,
    logo,
    textFontSize,
    textFontColor,
    headerColor,
    headerLogo,
    titleColor,
    buttonColor,
    buttonTextColor,
    answerColor,
    backgroundColor,
  } = editState;

  const debouncedSave = useCallback(
    debounce(async (newSettingData) => {
      setDisplayOptions(newSettingData);
      await editSurvey({
        surveyId,
        updatedSurvey: newSettingData,
      }).unwrap();
    }, 5000),
    []
  );

  // The magic useEffect hook. This runs only when `experimentData.name` changes.
  // We could add more properties, should we want to listen for their changes.
  useEffect(() => {
    const editSetting = {
      displayOptions: {
        fontFamily: font,
        fontSize: textFontSize,
        headerLogoImageUrl: headerLogo,
        colors: {
          headerBackground: headerColor,
          title: titleColor,
          text: textFontColor,
          answer: answerColor,
          button: buttonColor,
          buttonText: buttonTextColor,
          pageBackground: backgroundColor,
        },
      },
    };

    // const hasConfigNotChanged = isEqual(initialChartSetting, editSetting);
    // if (!hasConfigNotChanged) {
    debouncedSave(editSetting);
    // }
    // debouncedSave is wrapped in a useCallback with an empty dependency list,
    // thus it will not change and in turn will not re-trigger this effect.
  }, [
    font,
    logo,
    textFontSize,
    textFontColor,
    headerColor,
    headerLogo,
    titleColor,
    buttonColor,
    buttonTextColor,
    answerColor,
    backgroundColor,
  ]);

  // Do not display anything on the screen.
  return null;
};

export default EditAutoSave;
