import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  min-height: 350px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  margin-bottom: 10px;
  height: 40px;
  border-bottom: 1px solid #d5dae1;
`;

export const Title = styled.p`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  color: #1a141f;
  flex: 1;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: flex-end;
  height: 40px;
  border-top: 1px solid #d5dae1;
  padding-top: 15px;
`;

export const FooterButtons = styled.div`
  display: flex;
  align-items: center;
  & > button {
    height: 32px;
    padding: 0 10px;
    border: none;
  }
  & > button:first-child {
    background-color: #ffffff;
    border: 1px solid black;
    color: #000000;
    margin-right: 15px;
  }
  & > button:last-child {
    background-color: var(--primary-red-color);
    color: #ffffff;
  }
`;

export const Step = styled.div`
  margin-bottom: 20px;
  & > p {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-text-black);
    margin-bottom: 6px;
  }

  & button {
    border: 1px solid #000000;
    color: #000000;
    padding: 0 10px;
    background-color: #ffffff !important;
    height: 32px;
  }
`;

export const ErrorMessages = styled.div`
  margin-bottom: 20px;
  & > p {
    margin-bottom: 10px;
    color: var(--primary-red-color);
    font-size: 12px;
  }
`;
