import React, { useContext, useEffect, useMemo, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { ChartContext } from "../../../context/ChartContext";

function MultiSeriesBarChart({
  theRef,
  layout = "column",
}: {
  theRef: any;
  layout: string;
}) {
  const {
    chartSelected,
    loading,
    headerText,
    resultData: chartData,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    legendLayout,
    dataLabelsVisible,
    selectedColorList,
    countPercentageFormat,
    // sortType,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
    headerTextAlignment,
    // dataLabelsPosition,
    questionOptions,
  } = useContext(ChartContext);

  const keys = useMemo(
    () => questionOptions.map((option) => option.value),
    [questionOptions]
  );
  const [groupMode, setGroupMode] = useState(undefined);

  const options = useMemo(() => {
    if (!loading) {
      return {
        chart: {
          type: layout,
          style: {
            fontFamily: "Mulish",
          },
        },
        colors: selectedColorList,
        title: {
          text: headerText,
          align: headerTextAlignment,
        },
        legend: {
          align: legendHorPosition,
          verticalAlign: legendVerPosition,
          layout: legendLayout,
          floating: false,
          backgroundColor:
            Highcharts.defaultOptions.legend.backgroundColor || "white",
          borderColor: "#CCC",
          borderWidth: 1,
          shadow: false,
          margin: 25,
          reversed: true,
          enabled: legendVisible,
          symbolRadius: legendSymbolShape === "square" ? 0 : null,
        },
        tooltip: {
          headerFormat: null,
          pointFormat:
            countPercentageFormat === "percentage"
              ? "<b>{series.name}</b>: {point.percentage:.1f} %<br/>Total: {point.stackTotal}"
              : "<b>{series.name}</b>: {series.y}<br/>Total: {point.stackTotal}",
        },
        plotOptions: {
          series: {
            stacking: groupMode,
            dataLabels: {
              enabled: dataLabelsVisible,
              style: {
                textOutline: "none",
              },
              formatter() {
                if (
                  ["grBar", "horGrBar"].includes(chartSelected) ||
                  !this.percentage
                )
                  return this.y;
                // eslint-disable-next-line react/no-this-in-sfc
                if (this.y > 0) {
                  return countPercentageFormat === "percentage"
                    ? `${this.percentage.toFixed(1)}%`
                    : this.y;
                }
                return null;
              },
            },
          },
        },
        series: chartData,
        xAxis: {
          categories: keys,
          title: { text: xAxisLabel },
          visible: showXaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
        },
        yAxis: {
          labels: {
            style: "none",
          },
          title: { text: yAxisLabel },
          visible: showYaxis,
          gridLineColor: "#D3D6DF",
          gridLineDashStyle: "ShortDash",
        },
        navigation: {
          buttonOptions: {
            enabled: false,
          },
        },
        credits: {
          enabled: false,
        },
      };
    }
    return {};
  }, [
    countPercentageFormat,
    keys,
    legendLayout,
    legendVisible,
    legendSymbolShape,
    legendVerPosition,
    legendHorPosition,
    dataLabelsVisible,
    // JSON.stringify(chartData),
    chartData,
    headerText,
    selectedColorList,
    // sortType,
    xAxisLabel,
    yAxisLabel,
    showXaxis,
    showYaxis,
    layout,
    loading,
    groupMode,
    // dataLabelsPosition,
  ]);

  useEffect(() => {
    switch (chartSelected) {
      case "grBar":
      case "horGrBar":
        setGroupMode(undefined);
        break;
      case "divStBar":
      case "horDivStBar":
        setGroupMode("normal");
        break;
      default:
        setGroupMode(undefined);
        break;
    }
  }, [chartSelected]);

  return (
    <>
      <HighchartsReact ref={theRef} highcharts={Highcharts} options={options} />
    </>
  );
}

export default MultiSeriesBarChart;
