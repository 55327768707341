import React, { Dispatch, FC, SetStateAction } from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import IntroModal from "../IntroModal";
import SlideFunction from "../SlideFunction";
import { Wrapper, OutlinedButton, ColoredButton } from "./styles";

interface OnboardingProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const OnboardingScreen: FC<OnboardingProps> = ({ visible, setVisible }) => {
  const [cookies, setCookie] = useCookies(["design-intro"]);
  const { handleCountAndSlide, count, onboardingView } = SlideFunction({
    setVisible,
    setCookie,
    cookieName: "design-intro",
    cookieValue: "design-intro",
  });

  return (
    <>
      {!cookies["design-intro"] && (
        <Modal
          visible={visible}
          centered
          closable={false}
          footer={false}
          bodyStyle={{
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Wrapper ref={onboardingView}>
            <IntroModal
              title="Branding your survey"
              content="There are many ways to customize your survey to complement your website or brand: add logo, brand colors, themes, and fonts to adopt the look and feel you want."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="DANGER"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="DANGER"
              icon="https://assets.surveylake.com/designModal1.png"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Add brand logo"
              content="Add your brand logo to make your survey unique as it reflects the identity of your organization."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="DANGER"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="DANGER"
              icon="https://assets.surveylake.com/designModal2.png"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Adjust themes"
              content="Change the appearance of your survey by changing the colors, fonts, applying a theme, or customizing options like the logo and footer."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="DANGER"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Next
                </ColoredButton>
              }
              bgColor="DANGER"
              icon="https://assets.surveylake.com/designModal3.png"
              pageLength={4}
              count={count}
            />
            <IntroModal
              title="Edit navigation buttons"
              content="You can edit the color and font of the navigation buttons."
              prevBtn={
                <OutlinedButton type="button" onClick={handleCountAndSlide}>
                  Skip
                </OutlinedButton>
              }
              nextBtn={
                <ColoredButton
                  $bgColor="DANGER"
                  type="button"
                  onClick={handleCountAndSlide}
                >
                  Close
                </ColoredButton>
              }
              bgColor="DANGER"
              icon="https://assets.surveylake.com/designModal4.png"
              pageLength={4}
              count={count}
            />
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default OnboardingScreen;
