import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 0;
  height: 100%;
  & form {
    height: calc(100% - 40px);
  }
`;

export const FormBody = styled.div`
  padding: 0 8px;
  overflow-y: auto;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 30px;
  & > p {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--primary-black-color);
    margin-bottom: 10px;
  }
`;

export const SectionDualChild = styled.div`
  display: flex;
  align-items: center;
  & > div:first-child {
    margin-right: 10px;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  & span[class~="ant-radio-checked"]:after {
    border-color: var(--primary-red-color);
  }
  & span[class~="ant-radio-checked"] span[class~="ant-radio-inner"] {
    border-color: var(--primary-red-color);
    &:after {
      background-color: var(--primary-red-color);
      border-radius: 0;
    }
  }
  & span[class~="ant-radio"]:hover span[class~="ant-radio-inner"] {
    border-color: var(--primary-red-color);
  }
  & span[class~="ant-radio-inner"] {
    border-radius: 0;
  }
`;
