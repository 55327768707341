import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 30px;
`;

export const Section = styled.div`
  margin-top: 20px;
  display: ${(props) => (props.$show ? "block" : "none")};
`;

export const Body = styled.div`
  padding: 2px;
  & > label {
    margin-bottom: 10px;
  }
`;

export const Title = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  color: #222222;
`;
