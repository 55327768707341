import React, { useEffect } from "react";
import { SketchPicker } from "react-color";
import { ReactComponent as EditIcon } from "./icons/edit-color.svg";
import {
  ColorDiv,
  CoverDiv,
  PopoverDiv,
  EditColorButton,
  Wrapper,
} from "./style";
import { useEdit } from "../../../../context/EditContext";
import { useGetUserLibraryQuery } from "../../../../../../app/apiSlice";
import Loader from "../../../../../common/Loader";

interface EditColorPicker {
  defaultColor?: string;
  name: string;
}
const EditColorPicker = ({
  defaultColor = "#2fff00",
  name,
}: EditColorPicker) => {
  const { data: library, isLoading } = useGetUserLibraryQuery({});
  const { dispatch: localDispatch } = useEdit();
  const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
  const [selectedColorHex, setSelectedColorHex] = React.useState(defaultColor);
  const [presetColors, setPresetColors] = React.useState(["#ce375c"]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = (color: { hex: string }) => {
    setSelectedColorHex(color.hex);
    localDispatch({
      type: "UPDATE_INDIVIDUAL_COLOR",
      payload: {
        colorName: name,
        colorValue: color.hex,
      },
    });
  };

  useEffect(() => {
    setSelectedColorHex(defaultColor);
  }, [defaultColor]);

  useEffect(() => {
    if (library && library?.colors) {
      const colorStrings = library.colors?.map((col) => col.colorCode);
      setPresetColors(colorStrings);
    } else {
      setPresetColors(["#ce375c"]);
    }
  }, [library]);

  return (
    <Wrapper key={name}>
      <Loader isLoading={isLoading} />
      <EditColorButton onClick={handleClick}>
        <EditIcon />
        <ColorDiv $backgroundColor={selectedColorHex} />
      </EditColorButton>
      {displayColorPicker ? (
        <PopoverDiv>
          <CoverDiv onClick={handleClose} />
          <SketchPicker
            key={name}
            color={selectedColorHex}
            onChange={handleChange}
            presetColors={presetColors}
          />
        </PopoverDiv>
      ) : null}
    </Wrapper>
  );
};

export default EditColorPicker;
