import api from "../../app/api";
import { ApiRegisterPropTypes } from "./types";

export async function signupUser({
  email,
  firstName,
  lastName,
}: ApiRegisterPropTypes) {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/signup`;
  return api.post(url, { firstName, lastName, email });
}

export async function getUser() {
  const url = `${process.env.REACT_APP_SURVEY_API_URL}/user`;
  return api.get(url);
}
