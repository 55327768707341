import styled from "styled-components";
import { Select } from "antd";

export const Wrapper = styled(Select)`
  width: ${(props: { $width?: string }) => props.$width || "150px"};
  background: #ffffff;
  & div[class="ant-select-selector"] {
    padding: 0 0 0 16px !important;
    height: 40px !important;
    border: 1px solid #e4e7ec !important;
    border-radius: 8px !important;
  }

  & span[class="ant-select-arrow"] {
    width: unset !important;
  }

  & span[class="ant-select-selection-item"] {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #344054;
  }
`;

// export const Right = styled.div`
//   @media (min-width: 1440px) {
//   }
// `;
