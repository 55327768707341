import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 0;
  height: 100%;
  & form {
    height: calc(100% - 40px);
  }
`;

export const FormBody = styled.div`
  padding: 0 8px;
  overflow-y: auto;
  height: 100%;
`;

export const Section = styled.div`
  margin-bottom: 30px;
  & > div {
    margin-bottom: 15px;
  }
`;

export const SubSection = styled.div`
  margin-bottom: 30px;
  & > p {
    margin-bottom: 1px;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const ScaleDiv = styled.div`
  display: flex;
  align-items: center;

  & > p {
    margin: 0 10px;
  }
  & > input {
    width: 120px;
  }
`;

export const LabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  & > p {
    margin-right: 10px;
    width: 120px;
    margin-bottom: 0;
  }
`;
