import React, { useContext } from "react";
import { ReactComponent as ConfigureIcon } from "../../assets/icons/configure.svg";
import { ReactComponent as ViewIcon } from "../../assets/icons/eye.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause.svg";
import { ReactComponent as CopyIcon } from "../../assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { MenuButton, Wrapper } from "./style";
import { copyText } from "../../../../utils/helpers";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { useDeleteCollectorMutation } from "../../../../app/apiSlice";
import type { CollectorType } from "../../../../pages/CollectResponses/types";

interface CollectorSettingsProps {
  isEmail?: boolean;
  onConfigureClick: (id: CollectorType) => void;
  // onPauseClick: () => void;
  // onDeleteClick: () => void;
  onEmailViewClick?: () => void;
  collectorItem: any;
}

const CollectorSettings = ({
  onConfigureClick,
  // onPauseClick,
  // onDeleteClick,
  onEmailViewClick,
  isEmail = false,
  collectorItem,
}: CollectorSettingsProps) => {
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const [deleteCollector, { isLoading: isDeletingCollectors }] =
    useDeleteCollectorMutation();

  const onCopy = async () => {
    await copyText(collectorItem.url);
    onSuccessShowAlert("Link copied");
  };

  const onConfigure = () => {
    onConfigureClick(collectorItem);
  };

  const onPauseClick = () => {
    // onConfigureClick("id");
  };

  const onDeleteClick = () => {
    // onConfigureClick("id");
  };

  return (
    <Wrapper>
      {isEmail && (
        <MenuButton type="button" onClick={onEmailViewClick}>
          <ViewIcon />
          <p>View email</p>
        </MenuButton>
      )}
      <MenuButton type="button" onClick={onCopy}>
        <CopyIcon />
        <p>Copy link</p>
      </MenuButton>
      <MenuButton type="button" onClick={onPauseClick}>
        <PauseIcon />
        <p>Pause collector</p>
      </MenuButton>
      <MenuButton type="button" onClick={onConfigure}>
        <ConfigureIcon />
        <p>Configure collector</p>
      </MenuButton>
      <MenuButton type="button" onClick={onDeleteClick}>
        <DeleteIcon />
        <p>Remove collector</p>
      </MenuButton>
    </Wrapper>
  );
};

export default CollectorSettings;
