import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  & p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #333f51;
    margin-bottom: 6px;
  }

  & input {
    border: 1px solid #d5dae1;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;

    padding: 10px 14px;
    height: 46px;

    font-family: "Poppins", "Mulish";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #2a3342;
    width: 100%;
    box-sizing: border-box;
    :active {
      border: 1px solid #000000;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const ButtonWrapper = styled.button`
  border: unset;
  padding: 3px;
  position: absolute;
  top: 10px;
  right: 5px;
  height: 30px;
  box-sizing: border-box;
  background-color: transparent;
`;
