import React, { useContext, useEffect, useState } from "react";
import CSVFileValidator from "csv-file-validator";
import {
  Header,
  Wrapper,
  Title,
  Footer,
  Content,
  FooterButtons,
  Step,
  ErrorMessages,
} from "./style";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { csvImportConfig, downloadTemplate } from "../../utils";
import { useUpdateGroupContactsMutation } from "../../../../app/apiSlice";
import type { ImportRecipientProps } from "../../type";
import Loader from "../../../../components/common/Loader";

const ImportRecipient = ({
  onSave,
  onCancel,
  oldData = [],
  groupId,
}: ImportRecipientProps) => {
  const [selectedFile, setSelectedFile] = useState();
  const [hasCsvErrors, setHasCsvErrors] = useState(false);
  const [csvErrors, setCsvErrors] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const [updateContacts, { isLoading: isAddingNew }] =
    useUpdateGroupContactsMutation();

  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  useEffect(() => {
    if (selectedFile) {
      CSVFileValidator(selectedFile, csvImportConfig)
        .then((csvData) => {
          setCsvData(csvData.data);
          setHasCsvErrors(
            csvData.inValidData && csvData.inValidData.length > 0
          );
          setCsvErrors(csvData.inValidData || []);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selectedFile]);

  const onSubmit = async () => {
    try {
      if (!csvErrors.length) {
        const combinedData = csvData.concat(oldData);
        await updateContacts({
          groupId,
          updatedGroupContacts: { contacts: combinedData },
        }).unwrap();
        onSave();
        onSuccessShowAlert("Contacts imported");
      }
    } catch (err) {
      onShowErrorAlert(err, "Failed to import file");
    }
  };
  return (
    <Wrapper>
      <Loader isLoading={isAddingNew} />
      <Header>
        <Title>Import recipient</Title>
      </Header>
      <Content>
        <Step>
          <p>Step 1: Create an Excel template file to enter data</p>
          <div>
            <button type="button" onClick={downloadTemplate}>
              Download template
            </button>
          </div>
        </Step>
        <Step>
          <p>Step 2: Upload the template file</p>
          <div>
            <input type="file" name="file" onChange={changeHandler} />
            {/*<button type="button">Choose file</button>*/}
          </div>
        </Step>
        {hasCsvErrors && (
          <ErrorMessages>
            {csvErrors.map((err) => (
              <p key={err.message}>{err.message}</p>
            ))}
          </ErrorMessages>
        )}
      </Content>
      <Footer>
        <FooterButtons>
          <button type="button" onClick={onCancel}>
            Cancel
          </button>
          <button type="button" onClick={onSubmit}>
            Import
          </button>
        </FooterButtons>
      </Footer>
    </Wrapper>
  );
};

export default ImportRecipient;
