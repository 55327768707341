import React, { createContext, ReactNode, useEffect, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import isEmpty from "lodash/isEmpty";
import { fetchUser, logoutUser } from "../../pages/Login/loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useNavigate } from "react-router-dom";
import { persistor } from "../../app/store";

interface AuthContextType {
  currentUser: any;
  pending: boolean;
}

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAppSelector((state) => state.auth);

  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const shouldFetchUser = isEmpty(user) && isAuthenticated;
  const appHasUser = !isEmpty(user) && isAuthenticated;

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (shouldFetchUser) {
      dispatch(fetchUser());
    }
  }, [dispatch, shouldFetchUser]);

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, async (theUser) => {
      setCurrentUser(theUser);
      setPending(false);
      if (!theUser && appHasUser) {
        // newUser = null;
        localStorage.removeItem("token");
        localStorage.removeItem("persist:root");
        localStorage.removeItem("u-1-a");
        await persistor.purge();
        await persistor.flush().then();
        await dispatch(logoutUser());
        // await navigate("/login");
      }
    });
  }, [appHasUser, dispatch]);

  return (
    <AuthContext.Provider value={{ currentUser, pending }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
