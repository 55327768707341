import React, { Dispatch, FC, SetStateAction } from "react";
import { Modal } from "antd";
import { useCookies } from "react-cookie";
import SlideFunction from "../../../components/Onboarding/SlideFunction";
import {
  Wrapper,
  ColoredButton,
  Body,
  IntroBanner,
  Title,
  Content,
  Footer,
} from "./styles";

interface OnboardingProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const OnboardingScreen: FC<OnboardingProps> = ({ visible, setVisible }) => {
  const [cookies, setCookie] = useCookies(["library-intro"]);

  const { handleCountAndSlide, onboardingView } = SlideFunction({
    setVisible,
    setCookie,
    cookieName: "library-intro",
    cookieValue: "library-intro",
  });

  return (
    <>
      {!cookies["library-intro"] && (
        <Modal
          visible={visible}
          centered
          closable={false}
          footer={false}
          bodyStyle={{
            boxSizing: "border-box",
            width: "100%",
            borderRadius: "12px",
          }}
        >
          <Wrapper ref={onboardingView}>
            <Body>
              <IntroBanner>
                <img
                  src="https://assets.surveylake.com/mockup.png"
                  alt="mockup"
                  style={{ width: "100%" }}
                />
              </IntroBanner>
              <div>
                <Title>Asset Library</Title>
                <Content>
                  The Asset Library is where you/team members can store, find,
                  and use shared assets like logos, images, brand colors, etc to
                  create surveys.
                </Content>
              </div>
              <Footer>
                {/* <OutlinedButton onClick={handleCountAndSlide}>Skip</OutlinedButton> */}

                <ColoredButton onClick={handleCountAndSlide}>
                  Okay
                </ColoredButton>
              </Footer>
            </Body>
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default OnboardingScreen;
