import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  //height: 100%;
  padding: 20px 0 40px;
  height: unset;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--primary-black-color);
  margin-bottom: 16px;
`;

export const Weblinks = styled.div`
  margin-top: 30px;
  & > div {
    margin-bottom: 20px;
  }
`;

export const MediaButtons = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  & > button {
    margin-right: 30px;
  }
`;

export const TableContainer = styled.div`
  margin-top: 30px;
`;
