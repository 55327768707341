import React, { useContext, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import {
  Content,
  InputBox,
  NoAccount,
  RememberForgot,
  SubmitButtonWrapper,
  Subtitle,
  Title,
  Wrapper,
} from "./style";
import { useResendEmailVerificationMutation } from "../../app/apiSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ReactComponent as SlLogo } from "../../assets/logo-only.svg";
import { Button } from "../../components/common/Button";
import { Input } from "../../components/common/Input";
import { loginUser } from "./loginSlice";
import { SuccessAlertContext } from "../../context/SuccessAlertContext";
import { NetworkErrorAlertContext } from "../../context/NetworkErrorAlert";

type Inputs = {
  email: string;
  password: string;
};

const Login = () => {
  const dispatch = useAppDispatch();
  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const navigate = useNavigate();
  const [showResendButton, setShowResendButton] = useState(false);
  const [loginEmail, setLoginEmail] = useState("");
  const { status } = useAppSelector((state) => state.auth);
  const [resendEmailVerification, { isLoading }] =
    useResendEmailVerificationMutation();

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await dispatch(
        loginUser({
          email: data.email,
          password: data.password,
        })
      ).unwrap();
      navigate("/user/dashboard");
    } catch (err) {
      let message = "Wrong email or password";
      if (err.message === "not_verified") {
        message = "Email is not verified";
        setShowResendButton(true);
        onShowErrorAlert(err, message);
      }
    }
  };

  const onResendClick = async () => {
    try {
      await resendEmailVerification({
        email: loginEmail,
        firstName: null,
      }).unwrap();
      onSuccessShowAlert("The email has been resent");
    } catch (err) {
      onShowErrorAlert(
        err,
        "The email failed to resend. Please contact support"
      );
    }
  };
  const handleEmailChange = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setLoginEmail(value);
  };

  return (
    <Wrapper>
      <Content>
        <SlLogo />
        <Title>Sign in to your account</Title>
        <Subtitle>Start your demo version</Subtitle>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputBox>
            <Controller
              name="email"
              control={control}
              rules={{
                required: true,
                pattern: /^\w+([._+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/i,
              }}
              render={({ field }) => (
                <Input
                  type="email"
                  label="Email"
                  field={field}
                  onChange={handleEmailChange}
                />
              )}
            />
            {errors.email?.type === "required" && (
              <span>The email is required</span>
            )}
            {errors.email?.type === "pattern" && (
              <span>The email is not a valid email pattern</span>
            )}
          </InputBox>
          <InputBox>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input type="password" label="Password" field={field} />
              )}
            />
          </InputBox>
          <RememberForgot>
            <NavLink to="/forgot-password">Forgotten your password?</NavLink>
          </RememberForgot>
          <SubmitButtonWrapper>
            <Button label="Sign in" type="submit" theme="primary" />
            {showResendButton && (
              <Button
                label="Resend verification link"
                type="button"
                theme="alternate"
                onClick={onResendClick}
              />
            )}
          </SubmitButtonWrapper>

          <NoAccount>
            <span>Don’t have an account? </span>
            <NavLink to="/signup">Sign up</NavLink>
          </NoAccount>
        </form>
      </Content>
    </Wrapper>
  );
};

export default Login;
