import React, { useContext } from "react";
import { Input, Radio } from "antd";
import { ChartContext } from "../../../../context/ChartContext";
import { Wrapper, Body, Section, Title } from "./style";
import CustomRadio from "../../../../../common/CustomRadio";

const { TextArea } = Input;
const Annotate = () => {
  const { setHeaderText, headerTextAlignment, onHeaderAlignmentChange } =
    useContext(ChartContext);

  const onTextChange = (e) => {
    setHeaderText(e.target.value);
  };
  return (
    <Wrapper>
      <TextArea rows={4} placeholder="Text" onChange={onTextChange} />
      <Section $show>
        <Title>Alignment</Title>
        <Body>
          <Radio.Group
            onChange={onHeaderAlignmentChange}
            value={headerTextAlignment}
          >
            <CustomRadio value="left">Left</CustomRadio>
            <CustomRadio value="center">Center</CustomRadio>
            <CustomRadio value="right">Right</CustomRadio>
          </Radio.Group>
        </Body>
      </Section>
    </Wrapper>
  );
};

export default Annotate;
