import styled from "styled-components";

export const EmptyTableWrapper = styled.div``;

export const CreateNewButton = styled.button`
  border: none;
  color: #fff;
  background-color: var(--primary-red-color);
  height: 28px;
  width: 92px;
  border-radius: 4px;
`;
