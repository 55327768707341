import React, { ReactNode, useContext } from "react";
import { ChartContext } from "../../../context/ChartContext";
import Loader from "../../../../common/Loader";
import { Container, HeaderText } from "./style";
import CustomChartTable from "../../CustomChartTable";

export default function CustomChartBox({ children }: { children: ReactNode }) {
  const {
    loading,
    headerText,
    headerTextAlignment,
    resultData: chartData,
  } = useContext(ChartContext);

  if (loading) return <Loader isLoading />;
  return (
    <Container>
      <HeaderText $align={headerTextAlignment}>{headerText}</HeaderText>
      {children}
      <CustomChartTable chartData={chartData} />
    </Container>
  );
}
