import styled from "styled-components";
import Alert from "@reach/alert";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: unset !important;
`;

export const AlertBox = styled(Alert)`
  width: 100%;
  padding: 20px !important;
  text-align: center;
  background-color: var(--alert-red);
  color: #ffffff;
  position: relative;
  & > span {
    margin-left: 5px;
  }
`;
