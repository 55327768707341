import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000000;
  margin: 10px 0 30px;
`;

export const Subtitle = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #242424;
  margin: 0 0 30px;
  width: 70%;
`;

export const Button = styled.button`
  width: 200px;
  height: 40px;
  background-color: var(--primary-red-color);
  color: #ffffff;
  border: none;
`;

export const Content = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
