import React, { useEffect, useState } from "react";
import { ColorList, Header, Title, Wrapper, AddBox } from "./style";
import { ReactComponent as PlusIcon } from "../../../../assets/icons/plus.svg";
import ColorModal from "../ColorModal";
import ItemBox from "../ItemBox";
import { useGetUserLibraryQuery } from "../../../../app/apiSlice";

const Colors = () => {
  const [activeColors, setActiveColors] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { data: library, isLoading } = useGetUserLibraryQuery({});

  useEffect(() => {
    if (library && library?.colors) {
      const colorsCopy = [...library.colors];
      const filteredActiveColors = colorsCopy.filter(
        (log) => log.status === "ACTIVE"
      );
      setActiveColors(filteredActiveColors);
    }
  }, [library]);

  const openModal = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <Wrapper>
      <Header>
        <Title>Colors</Title>
      </Header>
      <ColorList>
        {activeColors.map((col) => (
          <ItemBox
            key={col.id}
            id={col.id}
            itemType="color"
            content={col.colorCode}
          ></ItemBox>
        ))}
        <AddBox onClick={openModal}>
          <PlusIcon />
        </AddBox>
      </ColorList>
      {showModal && <ColorModal onCancel={handleModalClose} />}
    </Wrapper>
  );
};

export default Colors;
