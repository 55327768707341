import styled from "styled-components";
import { Select } from "antd";

export const Wrapper = styled(Select)`
  & div[class~="ant-select-selector"] {
    border: 0.5px solid #c4c4c4 !important;
    box-sizing: border-box;
    border-radius: 5px !important;
  }
  width: ${(props: { $width?: string }) => props.$width || "150px"};
  & span[class~="ant-select-selection-item"] {
    font-family: "Mulish", -apple-system, BlinkMacSystemFont, "Segoe UI";
  }
`;
