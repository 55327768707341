import styled from "styled-components";

export const Wrapper = styled.div``;

export const OptionDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 18px;
`;

export const ImageDiv = styled.div`
  width: 120px;
  height: 120px;
  margin-right: 50px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  & img {
    width: 100%;
    object-fit: contain;
    max-height: 100%;
  }
`;

export const BarDiv = styled.div`
  flex: 1 1;
  height: 50px;
  background-color: #f5f5f5;
`;

export const BarDivFilled = styled.div`
  height: 100%;
  width: ${(props: { $percent: string; $backgroundColor: string }) =>
    `${props.$percent}%` ?? "0%"};
  background-color: ${(props) => props.$backgroundColor ?? "#CE375C"};
`;

export const ValueText = styled.p`
  margin-left: 50px;
  font-size: 14px;
  width: 50px;
`;
