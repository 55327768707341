import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../app/firebase";

const uploadImage = ({
  file,
  storagePath,
  useDownloadUrl,
  setUploadingStatus,
  setUploadingProgress,
  onUploadFinish,
  rowIndex,
}) => {
  const storage = getStorage(app);

  // Upload file and metadata to the object 'images/mountains.jpg'
  const storageRef = ref(storage, storagePath);
  const uploadTask = uploadBytesResumable(storageRef, file);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    "state_changed",
    (snapshot) => {
      setUploadingStatus("progress");
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      const progressToTwoDec = Number(progress.toFixed(2));
      setUploadingProgress(progressToTwoDec);
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
        default:
          console.log("default");
      }
    },
    (error) => {
      setUploadingStatus("failed");
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;

        // ...

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          break;
        default:
          console.log("default");
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      getDownloadURL(uploadTask.snapshot.ref)
        .then((downloadURL) => {
          useDownloadUrl(downloadURL);
          onUploadFinish(rowIndex, downloadURL);
        })
        .then(() => {
          setUploadingStatus("done");
        });
    }
  );
};

export default uploadImage;
