import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const RowOption = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  & p {
    margin: 0 10px 0 0;
    font-size: 14px;
  }
`;

export const ImageOption = styled.div`
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 5px;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  & p {
    margin: 0 10px 0 0;
    font-size: 14px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  & button:first-child {
    margin-right: 5px;
    & svg {
      stroke: var(--primary-black-color);
    }
  }

  & button {
    //padding: 10px;
    height: 30px;
    width: 30px;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & button:first-child:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  & button:last-child:hover svg {
    stroke: var(--primary-red-color);
  }
`;
