import styled from "styled-components";

export const Wrapper = styled.input`
  height: 40px;
  border: 0.5px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: ${(props: { $width?: string }) => props.$width || "150px"};
  padding: 0 5px;
`;
