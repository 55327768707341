import React, { useEffect } from "react";
import { Wrapper } from "./style";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
  useGetQuestionsQuery,
  useGetSectionsQuery,
} from "../../../../app/apiSlice";
import { useParams } from "react-router-dom";
import {
  setQuestionsList,
  setSectionsList,
  setSelectedQuestion,
} from "../../../../pages/Design/designSlice";
import EmptyPage from "../EmptyPage";
import Loader from "../../../common/Loader";
import SurveyTitle from "./components/SurveyTitle";
import Page from "../Page";

const SurveyDisplay = () => {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { questionsList, name, instructions } = useAppSelector(
    (state) => state.design
  );

  const {
    data: questionsData,
    isFetching,
    isSuccess,
  } = useGetQuestionsQuery(params.surveyId);

  const { data: sectionsData, isFetching: isFetchingSections } =
    useGetSectionsQuery(params.surveyId);

  useEffect(() => {
    dispatch(setSelectedQuestion(null));
  }, []);

  useEffect(() => {
    if (questionsData) {
      dispatch(setQuestionsList(questionsData));
    }
  }, [questionsData]);

  useEffect(() => {
    if (sectionsData) {
      dispatch(setSectionsList(sectionsData));
    }
  }, [sectionsData]);

  return (
    <Wrapper>
      <Loader isLoading={isFetching} />
      <SurveyTitle
        title={name}
        description={instructions}
        surveyId={params.surveyId}
      />
      {questionsList.length === 0 ? <EmptyPage /> : <Page />}
    </Wrapper>
  );
};

export default SurveyDisplay;
