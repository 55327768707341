import React, { useEffect, useState } from "react";
import {
  QaItem,
  FilterSelect,
  FilterItemWrapper,
  FilterRemoveButton,
} from "./style";
import { questionsOptions } from "../../utils";
import type { FilterItemProps } from "../../types";
import { useAppSelector } from "../../../../../../app/hooks";

const FilterItem = ({
  filterParam,
  onRemoveFilter,
  onChangeQuestion,
  onChangeOperation,
  onChangeResponse,
}: FilterItemProps) => {
  const [selectedOperation, setSelectedOperation] = useState<"AND" | "OR">(
    null
  );
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState(null);
  const [quesOptions, setQuesOptions] = useState([]);
  const [answerOptions, setAnswerOptions] = useState([]);

  const { questionsList, questionsById } = useAppSelector(
    (state) => state.design
  );

  useEffect(() => {
    const responseQuestions = questionsOptions({ questions: questionsList });
    setQuesOptions(responseQuestions);
  }, [questionsList]);

  useEffect(() => {
    if (selectedQuestion) {
      let rowOptions;
      if (selectedQuestion.type === "IC") {
        rowOptions = selectedQuestion.imageOptions.imageRowOptions.map(
          (opt) => ({
            value: opt.index,
            label: opt.value,
          })
        );
      } else if (selectedQuestion.type === "SR") {
        rowOptions = Array.from(
          { length: selectedQuestion.starRatingOptions.starRatingCount },
          (_, num) => ({
            value: num + 1,
            label: num + 1,
          })
        );
      } else {
        rowOptions = selectedQuestion.rowOptions.map((opt) => ({
          value: opt.value,
          label: opt.value,
        }));
      }
      setAnswerOptions(rowOptions);
    }
  }, [selectedQuestion]);

  const onChangeFilterQuestion = ({ quesId, key }) => {
    onChangeQuestion({ quesId, key });
    if (questionsById) {
      const selectedQues = questionsById[quesId];
      setSelectedQuestion(selectedQues);
    }
  };

  const onChangeFilterOperation = ({ operation, key }) => {
    setSelectedOperation(operation);
    onChangeOperation({ operation, key });
    setSelectedOptions(operation === "AND" ? null : []);
  };

  const onChangeFilterValues = ({ values, key }) => {
    setSelectedOptions(values);
    onChangeResponse({ values, key });
  };

  return (
    <FilterItemWrapper>
      <QaItem key={`${filterParam.questionId}-${filterParam.key}`}>
        <FilterSelect
          placeholder=""
          options={quesOptions}
          onChange={(value) =>
            onChangeFilterQuestion({ quesId: value, key: filterParam.key })
          }
          value={filterParam.questionId}
          dropdownMatchSelectWidth={600}
        />
        <FilterSelect
          placeholder=""
          style={{ width: 100 }}
          options={[
            { value: "AND", label: "Is" },
            { value: "OR", label: "Is one of" },
          ]}
          onChange={(value) =>
            onChangeFilterOperation({ operation: value, key: filterParam.key })
          }
          value={filterParam.operation}
        />
        <FilterSelect
          placeholder=""
          options={answerOptions}
          mode={selectedOperation === "OR" ? "multiple" : undefined}
          onChange={(values) =>
            onChangeFilterValues({ values, key: filterParam.key })
          }
          dropdownMatchSelectWidth={false}
          value={selectedOptions}
        />
      </QaItem>
      <FilterRemoveButton
        onClick={() => onRemoveFilter({ id: filterParam.key })}
      >
        x
      </FilterRemoveButton>
    </FilterItemWrapper>
  );
};

export default FilterItem;
