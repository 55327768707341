import React, { createContext, ReactNode, useEffect, useState } from "react";
// import * as Sentry from "@sentry/react";

interface ErrorAlertContextType {
  showAlert: boolean;
  alertMessage: string;
  onShowAlert: (error: any, message: string) => void;
}

export const NetworkErrorAlertContext = createContext<
  ErrorAlertContextType | undefined
>(undefined);

const NetworkErrorAlertContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const onShowAlert = (err: any, message: string): void => {
    setShowAlert(true);
    setAlertMessage(message);
    // Sentry.captureException(err);
  };

  return (
    <NetworkErrorAlertContext.Provider
      value={{
        showAlert,
        alertMessage,
        onShowAlert,
      }}
    >
      {children}
    </NetworkErrorAlertContext.Provider>
  );
};

export default NetworkErrorAlertContextProvider;
