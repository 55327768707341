import React, { useContext } from "react";

import {
  Wrapper,
  QaWrapper,
  Title,
  QaBody,
  QaItem,
  RangeFilterInput,
} from "./style";
import { AnalyticsContext } from "../../../../../../context/AnalyticsContext";

const ResponseDateFilter = () => {
  const { onDateChange, filterDateRangeObj } = useContext(AnalyticsContext);

  const onRangeChange = (dates, dateStrings) => {
    onDateChange(dates, dateStrings);
  };

  return (
    <Wrapper>
      <QaWrapper>
        <Title>Filter by Response date</Title>
        <QaBody>
          <QaItem>
            <p>Submitted on</p>
            <RangeFilterInput
              value={filterDateRangeObj}
              onChange={onRangeChange}
            />
          </QaItem>
        </QaBody>
      </QaWrapper>
    </Wrapper>
  );
};

export default ResponseDateFilter;
