import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Wrapper } from "./style";
import { useConfiguration } from "../../CreateQuestions/components/Configuration/contexts/Configuration";

const TextEditor = ({ field }: { field?: any }) => {
  const editorRef = useRef(null);
  const { state: configState, dispatch: localDispatch } = useConfiguration();

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  const onAddQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    const value1 = e.currentTarget.getAttribute("data-value1");
  };

  const onTextChange = (value) => {
    console.log(value);
    localDispatch({ type: "UPDATE_QUESTION_TYPE", payload: value });
  };

  return (
    <Wrapper>
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        //{...field}
        onEditorChange={(value) => {
          onTextChange(value);
          field.onChange(value);
        }}
        // onEditorChange={field.onChange}
        ref={field.ref}
        onBlur={field.onBlur}
        value={field.value || ""}
        // initialValue={field.defaultValue || ""}
        // initialValue={initialValue}

        // onChange={onAddQuestion}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        // onInit={(evt, editor) => (editorRef.current = editor)}
        // initialValue="<p>This is the initial content of the editor.</p>"
        // value=""
        init={{
          height: 180,
          menubar: false,
          skin_url: "/assets/skins/ui/sl",
          content_css: "/assets/skins/content/sl/content.min.css",
          plugins: ["image", "media", "table", "help"],
          toolbar: "fontsize bold italic underline | forecolor image",
          // images_upload_url: "postAcceptor.php",

          // /* we override default upload handler to simulate successful upload*/
          // images_upload_handler: function (blobInfo, success, failure) {
          //   setTimeout(function () {
          //     /* no matter what you upload, we will turn it into TinyMCE logo :)*/
          //     success(
          //       "http://moxiecode.cachefly.net/tinymce/v9/images/logo.png"
          //     );
          //   }, 2000);
          // },
          image_caption: true,
          images_file_types: "jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp",
          file_picker_types: "image",
          /* and here's our custom image picker*/
          file_picker_callback: (cb, value, meta) => {
            const input = document.createElement("input");
            input.setAttribute("type", "file");
            input.setAttribute("accept", "image/*");

            input.addEventListener("change", (e) => {
              // const file = e.target.files[0];
              // const reader = new FileReader();
              // reader.addEventListener("load", () => {
              //   /*
              //             Note: Now we need to register the blob in TinyMCEs image blob
              //             registry. In the next release this part hopefully won't be
              //             necessary, as we are looking to handle it internally.
              //           */
              //   const id = "blobid" + new Date().getTime();
              //   const blobCache = tinymce.activeEditor.editorUpload.blobCache;
              //   const base64 = reader.result.split(",")[1];
              //   const blobInfo = blobCache.create(id, file, base64);
              //   blobCache.add(blobInfo);
              //
              //   /* call the callback and populate the Title field with the file name */
              //   cb(blobInfo.blobUri(), { title: file.name });
              // });
              // reader.readAsDataURL(file);
            });

            input.click();
          },
          content_style:
            "body { font-family:Mulish,Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </Wrapper>
  );
};

export default TextEditor;
