import React, { useContext } from "react";
import { ChartContext } from "../../context/ChartContext";
import SingleSeriesBarChart from "../charts/SingleSeriesBarChart";
import MultiSeriesBarChart from "../charts/MultiSeriesBarChart";
import PieChart from "../charts/PieChart";
import ImageDiagram from "../charts/ImageDiagram";
import StarDiagram from "../charts/StarDiagram";
import WordCloud from "../charts/WordCloud";
import TextList from "../charts/TextList";
import DateTimeList from "../charts/DateTimeList";
import LineChart from "../charts/LineChart";
import AreaChart from "../charts/AreaChart";
import ChartBox from "./ChartBox";

const ChartDisplay = ({ theRef }: { theRef: any }) => {
  const { chartSelected } = useContext(ChartContext);

  switch (chartSelected) {
    case "ver":
      return (
        <ChartBox key="ver">
          <SingleSeriesBarChart theRef={theRef} layout="column" />
        </ChartBox>
      );
    case "hor":
      return (
        <ChartBox key="hor">
          <SingleSeriesBarChart theRef={theRef} layout="bar" />
        </ChartBox>
      );
    case "grBar":
      return (
        <ChartBox>
          <MultiSeriesBarChart key="grBar" theRef={theRef} layout="column" />
        </ChartBox>
      );
    case "horGrBar":
      return (
        <ChartBox>
          <MultiSeriesBarChart key="horGrBar" theRef={theRef} layout="bar" />
        </ChartBox>
      );
    case "divStBar":
      return (
        <ChartBox>
          <MultiSeriesBarChart key="divStBar" theRef={theRef} layout="column" />
        </ChartBox>
      );
    case "horDivStBar":
      return (
        <ChartBox>
          <MultiSeriesBarChart key="horDivStBar" theRef={theRef} layout="bar" />
        </ChartBox>
      );
    case "pie":
      return (
        <ChartBox>
          <PieChart theRef={theRef} />
        </ChartBox>
      );
    case "donut":
      return (
        <ChartBox>
          <PieChart theRef={theRef} />
        </ChartBox>
      );
    case "image":
      return (
        <ChartBox>
          <ImageDiagram theRef={theRef} />
        </ChartBox>
      );
    case "star":
      return (
        <ChartBox>
          <StarDiagram theRef={theRef} />
        </ChartBox>
      );
    case "cloud":
      return (
        <ChartBox>
          <WordCloud theRef={theRef} />
        </ChartBox>
      );
    case "list":
      return (
        <ChartBox>
          <TextList theRef={theRef} />
        </ChartBox>
      );
    case "dtList":
      return (
        <ChartBox>
          <DateTimeList theRef={theRef} />
        </ChartBox>
      );
    case "line":
      return (
        <ChartBox>
          <LineChart theRef={theRef} />
        </ChartBox>
      );
    case "area":
      return (
        <ChartBox>
          <AreaChart theRef={theRef} />
        </ChartBox>
      );
    default:
      return <div>Chart</div>;
  }
};

export default ChartDisplay;
