import React, { useContext, useState } from "react";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  Wrapper,
  Body,
  Section,
  SectionTitle,
  SectionSubtitle,
  BodyHead,
  BodyTitle,
  CollectorContainer,
  SectionBody,
  SettingsInput,
  SettingsCheckbox,
  SettingsSaveButton,
  SettingsButtonContainer,
  DateTimeContainer,
} from "./style";
import ErrorMessage from "../../../common/ErrorMessage";
import { NetworkErrorAlertContext } from "../../../../context/NetworkErrorAlert";
import { SuccessAlertContext } from "../../../../context/SuccessAlertContext";
import { useUpdateCollectorMutation } from "../../../../app/apiSlice";
import Loader from "../../../common/Loader";
import dayjs from "dayjs";
import { DatePicker, TimePicker } from "../../../common/DatePicker";
import { useAppSelector } from "../../../../app/hooks";
import type {
  ResponseSettingsProps,
  Inputs,
  ResponseCountProps,
} from "./types";

const validateResponseCount = ({
  value,
  responseCount = 1,
  isOnlyManualClosed,
}: ResponseCountProps) => {
  if (isOnlyManualClosed) {
    return true;
  } else {
    if (+value < responseCount) {
      return false;
    }
    return true;
  }
};

const ResponseSettings = ({ collector, onSave }: ResponseSettingsProps) => {
  const { allResponsesByCollectors } = useAppSelector(
    (state) => state.collectors
  );

  const responseCount = allResponsesByCollectors
    ? allResponsesByCollectors[collector.collectorId]?.length
    : 1;

  const [isOnlyManualClosed, setIsOnlyManualClose] = useState(
    collector.hasManualCloseOnly
  );

  const { onShowAlert: onShowErrorAlert } = useContext(
    NetworkErrorAlertContext
  );
  const { onShowAlert: onSuccessShowAlert } = useContext(SuccessAlertContext);

  const [updateCollector, { isLoading: isUpdatingCollectors }] =
    useUpdateCollectorMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues: {
      name: collector.name || "Collector",
      responseNum: collector.maxResponseCount,
      endDate: collector.endDate
        ? dayjs(collector.endDate, "DD-MM-YYYY")
        : null,
      endTime: collector.endTime,
      hasManualCloseOnly: collector.hasManualCloseOnly,
    },
  });

  const onManuallyClosedToggle = (e: CheckboxChangeEvent, field: any) => {
    field.onChange(e);
    setIsOnlyManualClose(e.target.checked);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      const submitData = {
        name: data.name,
        maxResponseCount: !isOnlyManualClosed ? +data.responseNum : null,
        endDate: null,
        endTime: !isOnlyManualClosed && data.endTime ? data.endTime : null,
        hasManualCloseOnly: data.hasManualCloseOnly || false,
      };
      if (typeof data.endDate === "string") {
        submitData.endDate = dayjs(data.endDate).format("DD-MM-YYYY");
      } else if (typeof data.endDate === "object") {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        submitData.endDate = data.endDate.format("DD-MM-YYYY");
      }
      await updateCollector({
        collectorId: collector.collectorId,
        updatedCollector: submitData,
      }).unwrap();
      onSave();

      onSuccessShowAlert("Collector updated");
    } catch (err) {
      onShowErrorAlert(err, "The collector couldn't be saved");
    }
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Loader isLoading={isUpdatingCollectors} />
      <Body>
        <CollectorContainer>
          <SectionTitle>Collector name</SectionTitle>
          <SectionBody>
            <Controller
              name="name"
              control={control}
              rules={{
                required: true,
              }}
              render={({ field }) => <SettingsInput {...field} />}
            />
            {errors.name?.type === "required" && (
              <ErrorMessage message="Collector name is required" />
            )}
          </SectionBody>
        </CollectorContainer>
        <BodyHead>
          <BodyTitle>Settings</BodyTitle>
        </BodyHead>
        <Section>
          <SectionTitle>Response limit</SectionTitle>
          <SectionSubtitle>
            Collector will stop receiving responses once limit is reached.
          </SectionSubtitle>
          <SectionBody>
            <Controller
              name="responseNum"
              control={control}
              rules={{
                required: !isOnlyManualClosed,
                validate: {
                  checkCount: (value) =>
                    validateResponseCount({
                      value,
                      responseCount,
                      isOnlyManualClosed,
                    }),
                },
              }}
              render={({ field }) => (
                <SettingsInput
                  {...field}
                  type="number"
                  min={1}
                  value={field.value || ""}
                  disabled={isOnlyManualClosed}
                ></SettingsInput>
              )}
            />
            {errors.responseNum?.type === "required" && (
              <ErrorMessage message="Response limit is required" />
            )}
            {errors.responseNum?.type === "checkCount" &&
              !isOnlyManualClosed && (
                <ErrorMessage
                  message={`Enter a number greater than ${
                    responseCount
                      ? `the current response count of ${responseCount}`
                      : "0(zero)"
                  }`}
                />
              )}
          </SectionBody>
          <Controller
            name="hasManualCloseOnly"
            control={control}
            render={({ field }) => (
              <SettingsCheckbox
                onChange={(e) => onManuallyClosedToggle(e, field)}
                checked={field.value}
              >
                Off, accept responses until you manually close this collector
              </SettingsCheckbox>
            )}
          />
        </Section>
        <Section>
          <SectionTitle>Cutoff date</SectionTitle>
          <SectionSubtitle>
            Collector will stop receiving responses once limit is reached.
          </SectionSubtitle>
          <DateTimeContainer>
            <Controller
              name="endDate"
              control={control}
              render={({ field }) => (
                <DatePicker
                  disabled={isOnlyManualClosed}
                  onChange={field.onChange}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
            <Controller
              name="endTime"
              control={control}
              render={({ field }) => (
                <TimePicker
                  disabled={isOnlyManualClosed}
                  onChange={field.onChange}
                  format="HH:mm"
                />
              )}
            />
          </DateTimeContainer>
          <Controller
            name="hasManualCloseOnly"
            control={control}
            render={({ field }) => (
              <SettingsCheckbox
                onChange={(e) => onManuallyClosedToggle(e, field)}
                checked={field.value}
              >
                Off, accept responses until you manually close this collector
              </SettingsCheckbox>
            )}
          />
        </Section>
      </Body>
      <SettingsButtonContainer>
        <SettingsSaveButton type="submit">Save</SettingsSaveButton>
      </SettingsButtonContainer>
    </Wrapper>
  );
};

export default ResponseSettings;
