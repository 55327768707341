import styled from "styled-components";
import { Select } from "antd";

export const Wrapper = styled.form`
  width: 100%;
  height: 100%;
`;

export const SectionBox = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SectionLabel = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  width: 100px;
`;

export const ToBox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  width: 100%;
`;

export const FromBox = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 14px;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 31%;
    min-width: 225px;
    max-width: 330px;
  }
`;

export const InputWrapper = styled.div`
  margin-bottom: 14px;
  width: 100%;
`;

export const InputBox = styled.input`
  padding: 8px 12px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const InputLabel = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: #999999;
`;

export const InputSubText = styled.p`
  margin-top: 5px;
  font-size: 12px;
`;

export const SubjectBox = styled.div`
  margin-bottom: 14px;
  width: 100%;
  & > input {
    width: min(100%, 320px);
    margin-bottom: 12px;
  }
`;

export const MailToSelect = styled(Select)`
  height: 30px;
  background: rgba(9, 30, 66, 0.04);
  border-radius: 5px;
  display: flex;
  width: 200px;
  align-items: center;

  & > div[class~="ant-select-selector"] {
    background-color: rgba(9, 30, 66, 0.04) !important;
    border: none !important;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #e4e5e7;
  padding-top: 10px;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
`;

export const PreviewButton = styled.button`
  color: var(--primary-black-color);
  border: 1px solid var(--primary-black-color);
  background-color: #ffffff;
  height: 38px;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
  min-width: 80px;
`;

export const SendButton = styled.button`
  color: #ffffff;
  background-color: var(--primary-red-color);
  height: 38px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  width: 80px;
`;
